import { Typography } from '@mui/material'
import { Box, height, useMediaQuery } from '@mui/system'
import OrangeButton from '../../components/OrangeButton'
import ApeTogetherAnimate from './ApeTogetherAnimate'
import ApeTogetherMobile from './ApeTogetherMobile'
import { PATHS } from '../../constants/config'
import { calc } from 'antd/es/theme/internal'

const BannerHome: React.FC = () => {
  const isDown1023 = useMediaQuery('(max-width:1023px)')
  const isDown992 = useMediaQuery('(max-width:992px)')
  const isDown600 = useMediaQuery('(max-width:600px)')
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      flexDirection={isDown1023 ? 'column' : 'row'}
      justifyContent={'space-between'}
      width={'100%'}
      px={isDown1023 ? '20px' : '36px'}
      pt={isDown992 ? '24px' : ''}
      maxWidth={'1200px'}
      margin={'0 auto'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'start'}
        justifyContent={'center'}
        maxWidth={'455px'}
        sx={{
          marginTop: isDown1023 ? '74px' : ''
        }}
      >
        {isDown1023 ? (
          <Typography
            fontSize={isDown1023 ? 56 : 72}
            fontWeight={600}
            lineHeight={'120%'}
            color="#000"
            letterSpacing={'-0.02em'}
            textAlign={'center'}
            fontFamily={'Hellix'}
            sx={{
              alignSelf: 'center',
            }}
          >
            Buy Before <br />
            the Market
          </Typography>
        ) : (
          <Typography
            fontSize={isDown1023 ? 56 : 72}
            fontWeight={600}
            lineHeight={'100%'}
            color="#000"
            letterSpacing={'-0.02em'}
            textAlign={'left'}
            fontFamily={'Hellix'}
            sx={{
              alignSelf: 'flex-start',
            }}
          >
            Buy <br />
            Before <br />
            the Market
          </Typography>
        )}

        <Typography
          fontSize={isDown1023 ? '17px' : '14px'}
          fontWeight={400}
          lineHeight={'27px'}
          color="#000"
          letterSpacing={'0px'}
          my={3}
          textAlign={isDown1023 ? 'center' : 'left'}
          fontFamily={'Inter'}
          maxWidth={isDown600 ? '303px' : '397px'}
        >
          {
            isDown600 ? 
            <>
            Ape Terminal has offered<br/>
            opportunities to buy in pre-sales<br/>
            along investors like Binance Labs,<br/>
            Samsung NEXT, Arthur Hayes, and<br/>
            many more.
            </> :
            <>Ape Terminal has offered opportunities to buy in pre-sales along
            investors like Binance Labs, Samsung NEXT, Arthur Hayes, and many
            more.</>
          }
          
        </Typography>
        <Box
          sx={{
            borderRadius: '50px',
            width: '100%',
            maxWidth: '370px',
            height: isDown600 ? '61px' : '66px'
          }}
        >
          <OrangeButton
            text={isDown600 ? 'Get Started' : 'Grow Your Porfolio'}
            onClick={() => window.open(PATHS.home)}
            br="50px"
            fs={18}
            fw={600}
            fm="Inter"
            lh="27px"
            h={isDown600 ? '56px' : '61px'}
            w="100%"
            bgColor="#FF9228"
            sx={{
              textTransform: 'none',
              marginLeft: !isDown1023 && '10px',
              marginRight: isDown1023 && '10px',
              margin: isDown1023 && '0 auto',
              maxWidth: '370px',
              border: "1px solid #000000",
              ':hover': {
                  borderBottom: "5px solid #000000",
                  height: isDown600 ? '61px' : '66px',
                }
            }}
          />
        </Box>
      </Box>
      {isDown1023 ? (
        <ApeTogetherMobile sx={{ marginTop: '100px' }} />
      ) : (
        <ApeTogetherAnimate
          sx={{
            marginTop: '50px',
            width: 'auto',
            minWidth: 360,
            maxWidth: 417,
          }}
        />
      )}
    </Box>
  )
}
export default BannerHome