//@ts-nocheck
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAccount, useBalance } from 'wagmi'
import {
  Typography,
  styled,
  Box,
  Grid,
  Button,
  Link,
  useMediaQuery,
} from '@mui/material'
import { LaunchpadIcon } from '../icons/LaunchpadIcon'
import { MenuIcon } from '../icons/MenuIcon'
import { UserPortalIcon } from '../icons/UserPortalIcon'
import { PortfolioIcon } from '../icons/PortfolioIcon'
import { AccountIcon } from '../icons/AccountIcon'
import { CloseIcon } from '../icons/CloseIcon'
import { sideMenuMobile } from './config'
import { PATHS } from '../../constants/config'
import logoPng from '../../assets/ape-terminal-white.svg'
import { ClipboardIcon, GiftICon, BNBIcon } from '../../assets'
// import ButtonArcade from '../../components/Buttons/ButtonArcade'
import GreenButton from '../../components/GreenButton'
import DisconnectWalletModal from '../../components/DisconnectWalletModal'
import { ReactComponent as TelegramNew } from '../../assets/telegram_new.svg'
import { ReactComponent as TwitterNew } from '../../assets/twitter_new.svg'
import {
  getChainCoinNameByChainId,
  shortenAddress,
  truncateExact,
} from '../../utils'
import getIconByNetwork from '../../pages/RevampedOverview/IconByNetwork'
import { useAppSelector } from '../../hooks'
import CopyToClipboard from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'
import moment from 'moment/moment'
import SouthEastIcon from '@mui/icons-material/SouthEast'
import { APP_V1_URL } from '../../constant'

const GiftBoxContainer = styled(Grid)(() => ({
  height: 40,
  padding: '10px 16px',
  backgroundColor: 'rgba(15, 17, 19, 1)',
  width: 'max-content',
  display: 'flex',
  gap: '10px',
  flexDirection: 'row',
  borderRadius: '24px',
}))

const Links = [
  { icon: TelegramNew, link: 'https://t.me/ApeTerminalChat' },
  { icon: TwitterNew, link: 'https://twitter.com/apeterminal' },
]

const BalanceAndAddressBoxContainer = styled(Button)(() => ({
  height: 40,
  padding: '0 16px',
  backgroundColor: 'rgba(15, 17, 19, 1)',
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  width: 'max-content',
  gap: '10px',
  textTransform: 'capitalize',

  '&:hover': {
    backgroundColor: 'rgba(15, 17, 19, 1)',
    opacity: 0.8,
  },
}))

const Divider = styled(Box)(() => ({
  width: '0.5px',
  height: 16,
  backgroundColor: 'rgba(49, 51, 53, 1)',
  margin: '0 10px',
}))

const SidebarMobile = () => {
  const [isNavOpened, setIsNavOpened] = useState(false)
  // const height = window.innerHeight
  const user = useAppSelector(state => state.user)
  const { address, chain } = useAccount()
  const [openDisconnectModal, setOpenDisconnectModal] = useState(false)
  const IconComponent = chain ? getIconByNetwork(chain?.name) : null
  const isDown880 = useMediaQuery('(max-width:880px)')

  const { data: ethBalanceData } = useBalance({
    address,
  })

  const handleClickDisconnect = () => {
    if (address || user.email) {
      setOpenDisconnectModal(true)
    } else setOpenDisconnectModal(true)
  }

  const toggleNav = () => {
    setIsNavOpened(isOpened => !isOpened)
  }
  return (
    <header className="mobile-header">
      <div className="mobile-header-wrapper">
        <nav>
          <ul>
            <li>
              <NavLink
                to={PATHS.userPortal}
                className={({ isActive }) => `${isActive && 'active'}`}
              >
                <UserPortalIcon />
                <span>User Portal</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to={PATHS.portfolio}
                className={({ isActive }) => `${isActive && 'active'}`}
              >
                <PortfolioIcon />
                <span>Portfolio Manager</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to={PATHS.investmentPortal}
                className={({ isActive }) => `${isActive && 'active'}`}
              >
                <LaunchpadIcon />
                <span>Investment Portal</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATHS.account}
                className={({ isActive }) => `${isActive && 'active'}`}
              >
                <AccountIcon />
                <span>Account</span>
              </NavLink>
            </li>
            <li>
              <a href="#menu" onClick={toggleNav} className="mobile-toggle">
                <MenuIcon />
                <span>Menu</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {isNavOpened && (
        <div
          className="navbar-list"
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            paddingBottom:'30px'
          }}
        >
          <Box>
            <div className="header-navbar">
              <a
                onClick={() => setIsNavOpened(false)}
                className="logo"
                href={PATHS.landingPage}
              >
                <img src={logoPng} alt="Mobile navbar logo" />
              </a>
              <div className="close-icon" onClick={toggleNav}>
                <CloseIcon />
              </div>
            </div>
            {(address || user.email) && (
              <Box
                sx={{
                  display: 'flex',
                  gap: '4px',
                  flexDirection: 'column',
                  marginTop: '20px',
                }}
              >
                {address && (
                  <Box
                    sx={{
                      marginTop: '30px',
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <GiftBoxContainer
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                      >
                        <img
                          src={GiftICon}
                          style={{ marginRight: '8px', height: '16px' }}
                          alt="gift icon"
                        />
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={'14px'}
                          lineHeight={'22px'}
                        >
                          $25
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                      >
                        {IconComponent ? (
                          <IconComponent
                            height={'20px'}
                            width={'20px'}
                            style={{ marginRight: '4px' }}
                          />
                        ) : (
                          <img
                            src={BNBIcon}
                            style={{ marginRight: '4px', height: '20px' }}
                            alt="Chain Icon"
                          />
                        )}
                        <Typography
                          color="rgba(255, 255, 255, 1)"
                          fontWeight={500}
                          fontSize={14}
                          fontFamily={'Inter'}
                        >
                          {truncateExact(
                            parseFloat(ethBalanceData?.formatted || '0') || 0,
                            4,
                          )}{' '}
                          {getChainCoinNameByChainId(
                            chain?.id ? chain?.id : 56,
                          )}
                        </Typography>
                      </Box>
                    </GiftBoxContainer>
                  </Box>
                )}
                {/*<BalanceAndAddressBoxContainer>*/}
                <Typography
                  fontWeight={400}
                  component={'span'}
                  fontSize={14}
                  fontFamily={'Inter'}
                  ml={'16px'}
                  color={'rgba(118, 118, 118, 1)'}
                >
                  Address:{' '}
                  <Typography
                    fontWeight={400}
                    component={'span'}
                    fontSize={14}
                    fontFamily={'Inter'}
                    color={'rgba(118, 118, 118, 1)'}
                    sx={{
                      '&:hover': {
                        color: '#63ea71',
                      },
                    }}
                  >
                    {shortenAddress(address!, 10, 4)}
                    {address && (
                      <>
                        <CopyToClipboard
                          text={address!}
                          onCopy={() => toast.success('Copied')}
                        >
                          <img
                            src={ClipboardIcon}
                            style={{ marginLeft: '4px', height: '16px' }}
                            onClick={e => {
                              e.stopPropagation()
                            }}
                            alt="Clipboard Icon"
                          />
                        </CopyToClipboard>
                      </>
                    )}
                  </Typography>
                </Typography>

                {(user.email || user.emailRegisterUserAccount) && (
                  <>
                    <Typography
                      fontWeight={400}
                      fontSize={14}
                      ml={'16px'}
                      fontFamily={'Inter'}
                      color={'rgba(118, 118, 118, 1)'}
                      sx={{
                        // '&:hover': {
                        //   color: '#63ea71',
                        // },
                        marginBlockStart: 0,
                        marginBlockEnd: 0,
                      }}
                    >
                      Email: {user.email || user.emailRegisterUserAccount}
                    </Typography>
                  </>
                )}
                {/*</BalanceAndAddressBoxContainer>*/}
              </Box>
            )}
            <nav>
              <ul className="div-item-navlink">
                {sideMenuMobile.map((navItem, id) => (
                  navItem.isExternal ? 
                  <a href={navItem.link} target='_blank' rel="noreferrer" className={'item-li'}>
                    {navItem.icon}
                    <span>{navItem.label}</span>
                  </a>
                  : 
                  <NavLink
                    to={navItem.link}
                    className={({ isActive }) =>
                      `${isActive ? 'active' : 'item-li'}`
                    }
                    onClick={() => setIsNavOpened(false)}
                  >
                    {navItem.icon}
                    <span>{navItem.label}</span>
                    {navItem.textNavComing && <em>{navItem.textNavComing}</em>}
                  </NavLink>
                ))}
              </ul>
            </nav>
            <div className="social">
              <b
                style={{
                  display: 'inline-block',
                  color: '#4c6951',
                  marginBottom: '1rem',
                  fontWeight: '700',
                  lineHeight: 1,
                  fontSize: '10px',
                }}
              >
                Community
              </b>
              <aside sx={{ marginTop: '5px' }}>
                {/*<a href="https://twitter.com/apeterminal" title="Twitter" target="_blank">*/}
                {/*  <img src={twitterIcon} alt="" />*/}
                {/*</a>*/}
                {/*<a href="https://t.me/ApeTerminalChat" title="Telegram" target="_blank">*/}
                {/*  <img src={telegramIcon} alt="" />*/}
                {/*</a>*/}
                {/*<a href="#" title="Medium" target="_blank">*/}
                {/*  <img src={mediumIcon} alt="" />*/}
                {/*</a>*/}
                {/*<a href="#" title="Discord" target="_blank">*/}
                {/*  <img src={discordIcon} alt="" />*/}
                {/*</a>*/}
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  flexDirection={'row'}
                  width={'100%'}
                  alignItems={'center'}
                  gap={isDown880 ? '12px' : '16px'}
                  paddingTop={isDown880 ? '0' : '0px'}
                >
                  <RenderLinksWithIcon
                    linksArray={Links}
                    width={'90px'}
                    iconWidth={'40px'}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '4px',
                      background:
                        'linear-gradient(180deg, rgba(56, 56, 56, 0.47) 0%, #1C1C1C 100%)',
                      boxShadow:
                        '0px 1px 2px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.12)',
                      borderRadius: '32px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '4px 26px',
                      height: '30px',
                      width: '40px',
                    }}
                  >
                    <Typography
                      component="a"
                      href={APP_V1_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      fontSize={16}
                      fontWeight={600}
                      lineHeight="16px"
                      fontFamily={'Inter'}
                      color={'#B0B4B3'}
                      marginRight={isDown880 ? 'auto' : '0px'}
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                        color: '#C8CBCA',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '32px',
                        backgroundColor: '#000000',
                        height: '24px',
                        width: '32px',
                        padding: '0 21px',
                        '&:hover': { color: '#62EA71' },
                      }}
                    >
                      v1.0
                    </Typography>
                  </Box>
                </Box>
                {/* <a href="#" title="Coin Gecko">
              <img src={geckoIcon} alt="" />
            </a>
            <a href="#" title="Blur">
              <img src={blurIcon} alt="" />
            </a> */}
              </aside>
            </div>
            {/* <Box style={{ marginTop: '10px' }}>
              <b
                style={{
                  color: '#4c6951',
                  marginBottom: '0.5rem',
                  fontWeight: '700',
                  lineHeight: 1,
                  fontSize: '10px',
                }}
              >
                Company
              </b>
              <Box
                display={!isDown880 ? 'flex' : ''}
                width={'100%'}
                flexDirection={'row'}
              >
                <RenderLinks
                  linksArray={[
                    { text: 'FAQ', link: '/faq' },
                    {
                      text: 'Transparency',
                      link: '/transparency',
                      samePage: true,
                    },
                  ]}
                  customStyles={{
                    marginBottom: '20px',
                    justifyContent: 'space-between',
                  }}
                />
              </Box>
            </Box> */}
          </Box>
          <Box>
            <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '16px',
                  }}
                >
                  {(address || user.email) && (
                    <GreenButton
                      fs={12}
                      lh="22.5px"
                      fm="Inter"
                      fw={400}
                      br="24px"
                      text={'Disconnect'}
                      onClick={handleClickDisconnect}
                      outlined={true}
                      w="max-content"
                      sx={{
                        border: '1px solid #08493C',
                        padding: '3px 30px',
                        height: '40px',
                        backgroundColor: 'transparent',
                        '&:hover': {
                          border: '1px solid #27B079',
                          backgroundColor: 'transparent',
                        },
                      }}
                    />
                  )}
                  {/* <ButtonArcade /> */}
                </Box>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  width={'100%'}
                  flexDirection={'column'}
                  alignSelf={'center'}
                  // paddingTop={isDown880 ? '8px' : '24px'}
                  gap={'5px'}
                  sx={{ marginTop: '20px' }}
                  minWidth={'320px'}
                  marginBottom={'10px'}
                >
                  <Typography
                    fontSize={12}
                    fontWeight={400}
                    lineHeight="22px"
                    fontFamily={'Inter'}
                    color={'#7E8180'}
                    width={'inherit'}
                    sx={{
                      textWrap: 'pretty',
                    }}
                  >
                    Ape Terminal © 2025 . All rights reserved.
                  </Typography>
                  <Box
                    display={'flex'}
                    width={'inherit'}
                    justifyContent={'flex-start'}
                  >
                    <Link
                      fontSize={12}
                      fontWeight={400}
                      lineHeight="22px"
                      fontFamily={'Inter'}
                      color={'#7E8180'}
                      href={'/terms-of-service'}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        textDecoration: 'none',
                        textWrap: 'pretty',
                        '&:hover': { color: '#63ea71' },
                        marginRight: '8px',
                      }}
                    >
                      Terms & Conditions
                      <SouthEastIcon
                        sx={{
                          fontSize: 'small',
                          verticalAlign: 'middle',
                          marginLeft: '3px',
                        }}
                      />
                    </Link>
                    <Link
                      fontSize={12}
                      fontWeight={400}
                      lineHeight="22px"
                      fontFamily={'Inter'}
                      color={'#7E8180'}
                      href={'/privacy-policy'}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        textDecoration: 'none',
                        '&:hover': { color: '#63ea71' },
                      }}
                    >
                      Privacy Policy
                      <SouthEastIcon
                        sx={{
                          fontSize: 'small',
                          verticalAlign: 'middle',
                          marginLeft: '3px',
                        }}
                      />
                    </Link>
                  </Box>
                </Box>
              </Box>
          </Box>
        </div>
      )}
      <DisconnectWalletModal
        open={openDisconnectModal}
        setOpen={() => setOpenDisconnectModal(false)}
        onItemClick={() => setIsNavOpened(false)}
      />
    </header>
  )
}
const RenderLinksWithIcon = ({
  linksArray,
  width,
  iconWidth,
}: {
  linksArray: { link: string; icon: any }[]
  width?: string
  iconWidth?: string
}) => (
  <Box
    sx={{
      display: 'flex',
      gap: '4px',
      background:
        'linear-gradient(180deg, rgba(56, 56, 56, 0.47) 0%, #1C1C1C 100%)',
      boxShadow:
        '0px 1px 2px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.12)',
      borderRadius: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px',
      height: '30px',
      width: width,
    }}
  >
    {linksArray.map((link, index) => (
      <Link
        key={index}
        sx={{
          color: '#C8CBCA',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '32px',
          backgroundColor: '#000000',
          height: '24px',
          width: iconWidth,
          '&:hover': { color: '#FF7722' },
        }}
        href={link.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <link.icon className="orange_hover_svg" />
      </Link>
    ))}
  </Box>
)
const RenderLinks = ({
  linksArray,
  customStyles,
}: {
  linksArray: {
    text: string
    link: string
    comingSoon?: boolean
    samePage?: boolean
  }[]
  customStyles?: any
}) => {
  const isDown880 = useMediaQuery('(max-width:880px)')

  return (
    <Box
      sx={{
        paddingLeft: '10px',
        display: 'flex',
        gap: '12px',
        width: 'inherit',
        justifyContent: 'space-between',
        ...customStyles,
      }}
    >
      {linksArray.map((link, index) => (
        <Link
          key={index}
          sx={{
            color: '#C8CBCA',
            whiteSpace: 'nowrap',
            textAlign: index !== 0 && 'right',
            width: isDown880 ? '100%' : 'fit-content',
            '&:hover': {
              opacity: 0.9,
              color: '#63ea71',
              textDecoration: 'none',
            },
          }}
          href={link.comingSoon ? '' : link.link}
          target={link.samePage ? '_self' : '_blank'}
          rel="noopener noreferrer"
        >
          {link.text}
        </Link>
      ))}
    </Box>
  )
}
export default SidebarMobile
