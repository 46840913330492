import { Box, Typography, useMediaQuery } from '@mui/material'
import WalletTrackingItem from './WalletTrackingItem'

const WalletArrdessMask = () => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown600 = useMediaQuery('(max-width:600px)')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isDown1000 ? 'column' : 'row',
        justifyContent: 'center',
        borderRadius: '12px',
        padding: isDown1000 ? '16px' : '24px',
        width: 'max-content',
        overflowWrap: 'anywhere',
        gap: '12px',
        alignItems: 'center',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '-133px',
          alignSelf: 'center',
          textAlign: 'center',
          zIndex: 3,
        }}
      >
        <Typography
          sx={{
            fontSize: isDown600 ? '20px' : '32px',
            fontWeight: 600,
            color: '#fff',
            whiteSpace: 'nowrap',
            fontFamily: 'Hellix',
          }}
        >
          No wallet detected!
        </Typography>
        <Typography
          sx={{
            paddingBottom: '40px',
            margingX: '20px',
            textWrap: 'wrap',
            fontSize: isDown600 ? '20px' : '32px',
            fontWeight: 600,
            color: '#fff',
            whiteSpace: 'nowrap',
            fontFamily: 'Hellix',
          }}
        >
          Search any wallet to view portfolio
        </Typography>

        <WalletTrackingItem
          defaultWrapperClzName="address-mask-container"
          inputStyles={{ width: 600 }}
        />
      </Box>
    </Box>
  )
}
export default WalletArrdessMask
