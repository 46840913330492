export const WrappedEmailIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon-email.be050ec3bb7e1a5bae9a17169c34095f.svg">
        <path
          id="Vector"
          d="M0.666504 16.9565C0.666504 8.31203 7.82994 1.30432 16.6665 1.30432H43.3332C52.1697 1.30432 59.3332 8.31203 59.3332 16.9565V43.0435C59.3332 51.6879 52.1697 58.6956 43.3332 58.6956H16.6665C7.82994 58.6956 0.666504 51.6879 0.666504 43.0435V16.9565Z"
          stroke="white"
          stroke-opacity="0.1"
          stroke-width="0.5"
        />
        <g id="Group" filter="url(#filter0_i_240_4619)">
          <path
            id="Vector_2"
            d="M3.3335 16.9566C3.3335 9.75285 9.30303 3.91309 16.6668 3.91309H43.3335C50.6972 3.91309 56.6668 9.75285 56.6668 16.9566V43.0435C56.6668 50.2472 50.6972 56.087 43.3335 56.087H16.6668C9.30303 56.087 3.3335 50.2472 3.3335 43.0435V16.9566Z"
            fill="#0F1113"
          />
          <path
            id="Vector_3"
            d="M3.3335 16.9566C3.3335 9.75285 9.30303 3.91309 16.6668 3.91309H43.3335C50.6972 3.91309 56.6668 9.75285 56.6668 16.9566V43.0435C56.6668 50.2472 50.6972 56.087 43.3335 56.087H16.6668C9.30303 56.087 3.3335 50.2472 3.3335 43.0435V16.9566Z"
            fill="url(#paint0_linear_240_4619)"
          />
        </g>
        <path
          id="Vector_4"
          d="M40.1335 20.8695H19.8668C18.4735 20.8695 17.3462 21.97 17.3462 23.3152L17.3335 37.9891C17.3335 39.3342 18.4735 40.4347 19.8668 40.4347H40.1335C41.5268 40.4347 42.6668 39.3342 42.6668 37.9891V23.3152C42.6668 21.97 41.5268 20.8695 40.1335 20.8695ZM40.1335 25.7608L30.0002 31.8749L19.8668 25.7608V23.3152L30.0002 29.4293L40.1335 23.3152V25.7608Z"
          fill="#63EA71"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_240_4619"
          x="-16.6665"
          y="3.91309"
          width="73.3335"
          height="62.174"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-20" dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.956863 0 0 0 0 0.992157 0 0 0 0 0.670588 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_240_4619"
          />
        </filter>
        <linearGradient
          id="paint0_linear_240_4619"
          x1="30.0002"
          y1="3.91309"
          x2="30.0002"
          y2="56.087"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.05" />
          <stop offset="1" stop-color="white" stop-opacity="0.01" />
        </linearGradient>
      </defs>
    </svg>
  )
}
