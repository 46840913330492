import { Box, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getReplacedCdnEndpoint } from '../../../../utils'
import { IResponseUserPortalItem } from '../../../../services/modules/participationsV2'
import { getNetworkIconByChain } from '../IconByNetworkUserPortal'
import { StyledBodyTableCell, FixedBodyTableCell } from './TableStyled';
import dayjs from 'dayjs'
import { getUserStatus, computeTicketSize, getStatusStyle } from './helper'

export const getIdoStatusStyle = (sale: IResponseUserPortalItem) => {
  let status = getIdoStatusLabel(sale)

  const styles: Record<string, { backgroundColor: string; color: string }> = {
    live: {
      backgroundColor: 'rgba(99, 234, 113, 0.1)',
      color: '#63EA71',
    },
    investing: {
      backgroundColor: 'rgba(255, 119, 34, 0.1)',
      color: '#FF7722',
    },
    completed: {
      backgroundColor: 'rgba(99, 234, 113, 0.1)',
      color: '#63EA71',
    },
    snapshot: {
      backgroundColor: 'rgba(244, 253, 171, 0.1)',
      color: '#F4FDAB',
    },
  }

  // Return the style for the given status or the default style
  return (
    styles[status] || {
      backgroundColor: 'rgba(29, 31, 33, 1)',
      color: '#B1B4B3',
    }
  )
}

const getIdoStatusLabel = (sale: IResponseUserPortalItem) => {
  let now = dayjs().unix() * 1000

  if (now < sale?.idoEndDate) return 'live'
  if (now > sale?.idoEndDate && now < sale?.idoInvestmentStartDate)
    return 'snapshot'
  if (now >= sale?.idoInvestmentStartDate && now <= sale?.idoInvestmentEndDate)
    return 'investing'
  if (now > sale?.idoInvestmentEndDate) return 'completed'

  return ''
}

type HistoryTableRowProps = {
  sale: IResponseUserPortalItem
  index: number
}

const HistoryTableRow = (props: HistoryTableRowProps) => {
  const { sale, index } = props
  const theme = useTheme()
  const isDown800 = useMediaQuery(theme.breakpoints.down(800))
  const navigate = useNavigate()
  const netWork = sale?.idoClaimNetwork || sale?.tokenNetwork
  const { label, style } = getUserStatus(sale)

  return (
    <TableRow
      key={index}
      onClick={() => navigate(`/project/${sale.projectName}`)}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#000000',
        },
      }}
    >
      {isDown800 ? (
        <>
          <FixedBodyTableCell>
            <img
              src={getReplacedCdnEndpoint(sale.projectLogoUrl)}
              alt={sale.projectName}
              style={{
                width: 40,
                height: 40,
                borderRadius: 10,
                objectFit: 'cover',
              }}
            />
          </FixedBodyTableCell>
          <StyledBodyTableCell>
            <Box ml={'12px'} sx={{ width: '100%', overflow: 'hidden' }}>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: '15px',
                  fontWeight: 400,
                  width: '100%',
                  overflow: 'hidden',
                  display: 'inline-block',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  textOverflow: 'ellipsis',
                }}
              >
                {sale.projectName}
              </Typography>
            </Box>
          </StyledBodyTableCell>
        </>
      ) : (
        <StyledBodyTableCell>
          <Box display="flex" alignItems="center" sx={{ maxWidth: '220px' }}>
            <img
              src={getReplacedCdnEndpoint(sale.projectLogoUrl)}
              alt={sale.projectName}
              style={{
                width: 40,
                height: 40,
                borderRadius: 10,
                objectFit: 'cover',
              }}
            />
            <Box ml={'12px'} sx={{ width: '100%', overflow: 'hidden' }}>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: '15px',
                  fontWeight: 400,
                  width: '100%',
                  overflow: 'hidden',
                  display: 'inline-block',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  textOverflow: 'ellipsis',
                }}
              >
                {sale.projectName}
              </Typography>
              <Typography
                sx={{
                  color: '#7E8180',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
              >
                {sale.tokenSymbol}
              </Typography>
            </Box>
          </Box>
        </StyledBodyTableCell>
      )}
      <StyledBodyTableCell>
        <img width={'24px'} src={getNetworkIconByChain(netWork)} alt={netWork} />
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {sale.idoTotalParticipation
          ? Number(sale.idoTotalParticipation).toLocaleString('en')
          : '0'}
      </StyledBodyTableCell>
      <StyledBodyTableCell>{computeTicketSize(sale)}</StyledBodyTableCell>
      <StyledBodyTableCell>
        <Box
          sx={{
            height: '28px',
            padding: '6px 12px',
            width: 'fit-content',
            borderRadius: '80px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Inter',
            color: getStatusStyle(style),
          }}
        >
          {label}
        </Box>
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        <Box
          sx={{
            height: '28px',
            width: '88px',
            borderRadius: '80px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Inter',
            color: getIdoStatusStyle(sale),
            textTransform: 'capitalize',
          }}
        >
          {getIdoStatusLabel(sale)}
        </Box>
      </StyledBodyTableCell>
    </TableRow>
  )
}

export default HistoryTableRow
