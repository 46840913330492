import { useMediaQuery, useTheme } from "@mui/material";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import SidebarMobile from "../../components/layout/SidebarMobile";
import { useAppSelector } from '../../hooks';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';

const MasterLayout = ({children}) => {
  const theme = useTheme()
  const isDown800 = useMediaQuery(theme.breakpoints.down(800))
  const appState = useAppSelector(state => state.app)
  const { isSidebarCollapse } = appState

  return (
    <div className={`primary-container ${isSidebarCollapse ? 'is-collapse' : ''}`}>
      <div
        className="body-wrapper"
        style={{
          marginBottom: isDown800 ? '100px' : '',
        }}
      >
        <Sidebar />
        <main className="content">
          <Header />
          {/* <Outlet /> */}
          {children}
          <SidebarMobile />
        </main>
      </div>
    </div>
  )
};

export default MasterLayout;
