import { useWeb3Modal } from '@web3modal/wagmi/react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { IResponseUserPortalItem } from 'services/modules/participationsV2'
import { useAccount } from 'wagmi'
import ClaimButtonDisabled from './ClaimButtonDisabled'
import ConnectWalletButton from './ConnectWalletButton'
import {
  computeClaimableAmount,
  computeClaimedAmount,
  getClaimStatus,
  isAdminDepositRefund,
  isAdminDepositVesting,
  isRefunded,
  isRequestRefund,
} from './helper'
import { ClaimStatus } from './LabelConstant'
import RefundButton from './RefundButton'
import VestingClaimButton from './VestingClaimButton'
import { ViewLaunchButton } from './ViewLaunchButton'

type ClaimContainerProps = {
  sale: IResponseUserPortalItem
  onRefundSuccess: (isSuccess?: boolean) => void
  onClaimSuccess: (isSuccess?: boolean) => void
  onRequestRefund: (isRequestSuccess?: boolean) => void
  onOpenToast: (isOpen?: boolean) => void
  onMessage: (message?: string | null | undefined) => void
}

const ClaimButtonContainer = (props: ClaimContainerProps) => {
  const { sale, onClaimSuccess, onRequestRefund, onRefundSuccess } = props
  const navigate = useNavigate()

  const claimedAmount = computeClaimedAmount(sale)
  const isPreTGE = getClaimStatus(sale) === ClaimStatus.PRE_TGE
  const isClaimVesting = computeClaimedAmount(sale) !== 0
  const isRefundable = isPreTGE
    ? false
    : isAdminDepositRefund(sale) && !isRefunded(sale) && !isClaimVesting
  const isClaimable = isPreTGE
    ? false
    : isAdminDepositVesting(sale) && computeClaimableAmount(sale) !== 0

  const now = dayjs().unix() * 1000
  const canRequestRefund =
    sale.idoRefundDeadline &&
    sale.idoRefundDeadline > now &&
    claimedAmount === 0

  const onClickViewLaunch = (e: any) => {
    e.stopPropagation()
    navigate(`/project/${sale.projectName}`)
  }

  const { address } = useAccount()
  const { open: openWeb3Modal, close } = useWeb3Modal()

  const handleConnect = async (e: any) => {
    e.stopPropagation()
    openWeb3Modal()
  }

  if (!address) {
    return <ConnectWalletButton onClick={handleConnect} />
  }

  return isRequestRefund(sale) ? (
    isRefundable ? (
      <RefundButton sale={sale} onRefundSuccess={onRefundSuccess} />
    ) : (
      <ClaimButtonDisabled label="Claim Refund" />
    )
  ) : canRequestRefund ? (
    // <RequestRefund sale={sale} onRequestRefund={onRequestRefund} />
    <ViewLaunchButton onClick={onClickViewLaunch} />
  ) : isClaimable ? (
    <VestingClaimButton sale={sale} onClaimSuccess={onClaimSuccess} />
  ) : (
    <ClaimButtonDisabled />
  )
}

export default ClaimButtonContainer
