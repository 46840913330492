import { PieChartOutlined } from '@ant-design/icons'
import { Modal, Select, Tooltip } from 'antd'
import { isEmpty, toNumber, toString } from 'lodash'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import arrowDownIcon from '../../assets/revamp/icons/arrow_down.svg'
import arrowUpIcon from '../../assets/revamp/icons/arrow_up.svg'
import chevronIcon from '../../assets/revamp/icons/chevron.svg'
import infoIcon from '../../assets/revamp/icons/info.svg'
import Icon from '../../components/common/Icon'
import {
  ETierRange,
  ITokenAssetInfo,
  ITokenTimeSeries,
} from '../../services/modules/portfolio-manager'
import { formatAddress, numberFormat } from '../../utils/function'
import TokenAllowcationChart from './TokenAllowcationChart'
import TotalAssetsChart from './TotalAssetsChart'

interface AssetsOverviewProps {
  currentTier: string
  assets: ITokenAssetInfo[]
  assetsChart: ITokenTimeSeries | null
  onFilter: (filter: string, reset: boolean) => void
}

const calculatedAssets = (assets: ITokenAssetInfo[]): number => {
  if (!assets || !assets.length) return 0

  let total = 0
  assets.forEach(asset => {
    total += parseFloat(asset.usd_value || '0')
  })
  return total
}

const AssetsOverview: React.FC<AssetsOverviewProps> = props => {
  const { assets, assetsChart, onFilter, currentTier } = props
  const [searchParams] = useSearchParams()
  let activeAddress = searchParams.get('address') || ''

  const [totalAssets, setTotalAssets] = useState<number>(0)
  const [totalNFT, setTotalNFT] = useState<number>(0)
  const [totalToClaim, setTotalToClaim] = useState<number>(0)
  const [isOpenModal, setOpenModal] = useState<boolean>(false)

  useMemo(() => {
    const total = calculatedAssets(assets)
    setTotalAssets(total)
  }, [assets])

  const calculateAssetsChange = () => {
    if (!assetsChart || isEmpty(assetsChart?.timeSeries)) return null

    let values = Object.values(assetsChart.timeSeries)

    const firstValue = values?.[0]
    const lastValue = values[values.length - 1]

    const isIncrease = lastValue - firstValue > 0

    const amountChange = numberFormat(Math.abs(lastValue - firstValue), 2, 2)

    const percentageChange = (
      (Math.abs(lastValue - firstValue) * 100) /
      firstValue
    ).toFixed(2)

    return (
      <span className={isIncrease ? 'increase' : ''}>
        ${!toNumber(amountChange) ? 0 : amountChange} (
        <Icon src={isIncrease ? arrowUpIcon : arrowDownIcon} />
        {!toNumber(percentageChange) ? 0 : percentageChange}%) Today
      </span>
    )
  }

  const handleChangeChartTier = (tier: string) => {
    onFilter(tier, true)
    // setFilter(tier)
  }

  const showAllTokens = () => {
    setOpenModal(true)
  }

  return (
    <section className="assets-overview">
      <div className="assets-overview-wrapper">
        <div className="assets-overview--total">
          <div className="assets-overview--total--asset">
            <b>Total Assets</b>
            <span>{numberFormat(totalAssets, 2, 2, '$')}</span>
          </div>
          <div className="assets-overview--total--asset">
            <b>NFT Assets</b>
            <span>{numberFormat(totalNFT, 2, 2, '$')}</span>
          </div>
          <div className="assets-overview--total--asset">
            <b>To Claim</b>
            <span>{numberFormat(totalToClaim, 2, 2, '$')}</span>
          </div>
        </div>
        <div className="assets-overview--graph">
          <div className="graph">
            <div className="graph--header">
              <aside>
                <div>
                  <p>Portfolio</p>
                  <Icon src={infoIcon} />
                  <p>Not counting NFTs</p>
                </div>
                <b>{numberFormat(totalAssets, 2, 2, '$')}</b>

                {calculateAssetsChange()}
              </aside>
              <div className="graph--header--right">
                {!!activeAddress && (
                  <div className="active-address">
                    <Tooltip title={activeAddress}>
                      {formatAddress(activeAddress)}
                    </Tooltip>
                  </div>
                )}
                <span className="switch">
                  <Select
                    options={[
                      {
                        label: '1D',
                        value: ETierRange.day,
                      },
                      {
                        label: '1W',
                        value: ETierRange.week,
                      },
                      {
                        label: '1M',
                        value: ETierRange.month,
                      },
                      {
                        label: '1Y',
                        value: ETierRange.year,
                      },
                    ]}
                    value={currentTier}
                    onChange={value => handleChangeChartTier(value)}
                    suffixIcon={<Icon src={chevronIcon} />}
                  />
                </span>
              </div>
            </div>
            <div>
              {assetsChart ? (
                <TotalAssetsChart
                  height={324}
                  data={assetsChart}
                  xAxisFormat={currentTier === '1d' ? 'hh:mm' : 'D MMM'}
                  tickAmount={8}
                />
              ) : null}
            </div>
          </div>
          <div className="pie">
            <div className="pie--header">
              <span>Token Allocation</span>
              {assets?.length > 4 ? (
                <span className="see-all" onClick={showAllTokens}>
                  See All
                </span>
              ) : null}
            </div>
            {assets && !isEmpty(assets) ? (
              <TokenAllowcationChart data={assets as any[]} />
            ) : (
              <div className="assets-overview--not-found">No assets found</div>
            )}
            <div className="pie--footer">
              <ul>
                {assets &&
                  assets
                    .sort(
                      (a, b) =>
                        parseFloat(b.usd_value || '0') -
                        parseFloat(a.usd_value || '0'),
                    )
                    .map((asset, index) => {
                      if (index > 3) return null

                      return (
                        <li key={index}>
                          <span className="token">{asset.token.denom}</span>
                          <span>
                            {(
                              (parseFloat(asset.usd_value || '0') * 100) /
                              totalAssets
                            ).toFixed(2)}
                            %
                          </span>
                        </li>
                      )
                    })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={
          <>
            <PieChartOutlined />
            <p>Token Allocation</p>
          </>
        }
        className="all-assets-modal"
        centered
        open={isOpenModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        footer={null}
      >
        <div className="all-assets-modal__list">
          <ul>
            {assets &&
              assets
                .sort(
                  (a, b) =>
                    parseFloat(b.usd_value || '0') -
                    parseFloat(a.usd_value || '0'),
                )
                .map((asset, index) => {
                  return (
                    <li key={index}>
                      <div>
                        <img
                          src={asset.token.image ?? ''}
                          alt={toString(asset.token.id)}
                        />
                        <span>
                          {numberFormat(+asset.balance, 2, 2)}{' '}
                          {asset.token.denom}
                        </span>
                      </div>
                      <div>
                        <span>{numberFormat(+asset.usd_value, 2, 2, '$')}</span>
                        <span>
                          {(
                            (parseFloat(asset.usd_value || '0') * 100) /
                            totalAssets
                          ).toFixed(2)}
                          %
                        </span>
                      </div>
                    </li>
                  )
                })}
          </ul>
        </div>
        <div className="all-assets-modal__footer">
          <button onClick={() => setOpenModal(false)}>Close</button>
        </div>
      </Modal>
    </section>
  )
}

export default AssetsOverview
