//@ts-nocheck
import avalancheIcon from "../assets/revamp/images/tokens/Avalanche.png";
import bscIcon from "../assets/revamp/images/bsc.png";
import ethereumIcon from "../assets/revamp/images/tokens/Ethereum.png";
import ethIcon from "../assets/revamp/images/eth.png";
import gnosiscIcon from "../assets/revamp/images/tokens/Gnosis.png";
import hecoIcon from "../assets/revamp/images/tokens/Heco.png";
import polygonIcon from "../assets/revamp/images/tokens/Polygon.png";
import pulseIcon from "../assets/revamp/images/tokens/PulseChain.png";
import solanaIcon from "../assets/revamp/icons/solana_logo.svg";
import { NETWORK_AVAILABLE } from "../constants/pool";

// const BASENAME = import.meta.env.VITE_BASENAME;
const BASENAME = '/';
// const APP_API_URL = import.meta.env.VITE_APP_API_URL;
const APP_API_URL = '';
// const APP_PULSAR_API_URL = import.meta.env.VITE_APP_PULSAR_API_URL;
const APP_PULSAR_API_URL = '/';
const MAX_ALLOCATION = "10000000";
const MENU_ITEMS = [{ path: "/portfolio", label: "Portfolio Manager" }];
const STORAGE_NETWORK_KEY = 'networks';
const ACCESS_TOKEN_KEY = 'x-access-token';
const REFRESH_TOKEN_KEY = 'x-refresh-token';
const ACCESS_TOKEN_LOGIN_GG_KEY = 'x-access-token-login-gg';
const ACCESS_TOKEN_LOGIN_ACCOUNT_KEY = 'x-access-token-login-account';
const SIGNATURE_KEY = 'x-signature';
const MESSAGE_REJECT = 'Transaction Reject';
const MESSAGE_ERROR = 'Transaction Error';
const MESSAGE_SUCCESS = 'Transaction Complete';
const MESSAGE_APPROVE_SUCCESS ='You have approved success!' ;
const MESSAGE_DEPOSIT_SUCCESS = 'Your deposit has been successful!'

const PORTFOLIO_NETWORKS = [
  { id: 43114, icon: avalancheIcon, name: 'Avalanche', code: 'AVALANCHE'},
  { id: 56, icon: bscIcon, name: 'BSC', code: 'BSC'},
  { id: 1, icon: ethereumIcon, name: 'Ethereum', code: 'ETHEREUM'},
  // { id: 100, icon: gnosiscIcon, name: 'Gnosis', code: 'GNOSIS'}, //FIXME: not available
  // { id: 128, icon: hecoIcon, name: 'Heco', code: 'HECO'}, //FIXME: not available
  { id: 137, icon: polygonIcon, name: 'Polygon', code: 'POLYGON'},
  // { id: 369, icon: pulseIcon, name: 'Pulse', code: 'PULSE'}, //FIXME: not available
]

const WALLET_COLORS = ['#00b894', '#fdcb6e', '#d63031', '#e84393', '#6c5ce7', '#dfe6e9', '#fd79a8', '#ff7675', '#fab1a0', '#81ecec', '#74b9ff', '#55efc4', '#a29bfe', '#cd84f1', '#ffcccc', '#ff4d4d', '#ffaf40', '#fffa65', '#3ae374', '#67e6dc', '#17c0eb', '#7158e2', '#3d3d3d']

const CHAIN_ICONS = {
  'BSC': bscIcon,
  'ETHEREUM': ethereumIcon,
  'AVALANCHE': avalancheIcon,
  'POLYGON': polygonIcon,
  'HECO': hecoIcon,
  'GNOSIS': gnosiscIcon,
  'PULSE': pulseIcon,
}

export function getNetworkIcon(network) {
  switch (network) {
    case NETWORK_AVAILABLE.ETH:
      return ethIcon;
    case NETWORK_AVAILABLE.POLYGON:
      return polygonIcon;
    case NETWORK_AVAILABLE.BSC:
      return bscIcon;
    case NETWORK_AVAILABLE.SOL:
      return solanaIcon;
    default:
      return '';
  }
}

export function getNameNetwork(network) {
  switch (network) {
    case NETWORK_AVAILABLE.ETH:
      return 'ETHEREUM';
    case NETWORK_AVAILABLE.POLYGON:
      return 'POLYGON';
    case NETWORK_AVAILABLE.BSC:
      return 'BSC';
    case NETWORK_AVAILABLE.SOL:
        return 'SOLANA';
    default:
      return 'TBA';
  }
}

export function parseTags(pool_tags) {
  return pool_tags.replace(/"/g, "").split(", ");
}

export {
  APP_API_URL,
  MENU_ITEMS,
  MAX_ALLOCATION,
  BASENAME,
  APP_PULSAR_API_URL,
  PORTFOLIO_NETWORKS,
  STORAGE_NETWORK_KEY,
  WALLET_COLORS,
  CHAIN_ICONS,
  ACCESS_TOKEN_KEY,
  ACCESS_TOKEN_LOGIN_GG_KEY,
  ACCESS_TOKEN_LOGIN_ACCOUNT_KEY,
  REFRESH_TOKEN_KEY,
  SIGNATURE_KEY,
  MESSAGE_REJECT,
  MESSAGE_ERROR, 
  MESSAGE_SUCCESS,
  MESSAGE_APPROVE_SUCCESS,
  MESSAGE_DEPOSIT_SUCCESS
};
