import { useEffect, useState } from 'react'
import FeaturedLaunch from '../../../pages/RevampedLaunchpad/FeaturedLaunch'
import { useFetchAllLaunchesQuery } from '../../../services/modules/project'
import _ from 'lodash'
import { Box } from '@mui/system'

export const FeatureProject = () => {
  const {
    data: allLaunchesData,
    isLoading: allLaunchesLoading,
    isError: allLaunchesError,
  } = useFetchAllLaunchesQuery()

  const [featuredLaunch, setFeatureLaunch] = useState<any>()
  useEffect(() => {
    if (allLaunchesData && _.isArray(allLaunchesData)) {
      const feature = allLaunchesData.find(launch => launch.type == 'featured')
      feature && setFeatureLaunch(feature)
    }
  }, [allLaunchesData])

  return (
    <Box className={'feature-project'}>
      {allLaunchesData && featuredLaunch && (
        <FeaturedLaunch featuredLaunchData={featuredLaunch} />
      )}
    </Box>
  )
}
