import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Box from '@mui/material/Box'
import _ from 'lodash'
import Lottie from 'lottie-react'
import React, { useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useAccount } from 'wagmi'
import LogoAnimation from '../../animations/logo loading white.json'
import { ArrowLeft } from '../../assets'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import NewSelectProjectModal from '../../components/NewSelectProjectModal'
import SalesCard from '../../components/SalesCard'
import { PROJECT_TYPE_DICTIONARY } from '../../constants/application'
import { useParticipationEntryPoint } from '../../Context'
import { useAppSelector } from '../../hooks'
import {
  useFetchTotalParticipantsQuery,
  useLazyFetchSingleParticipationQuery,
} from '../../services/modules/participations'
import {
  IdoStatus,
  useFetchAllLaunchesQuery,
  useFetchOneIdoQuery,
  useFetchOneProjectByNameQuery,
} from '../../services/modules/project'
import { scrollToTop } from '../../utils'
import Banner from './Banner'
import ClaimWrapper from './ClaimWrapper'
import IDODetails from './IDODetails'
import InspectInvestors from './inspect_investors.json'
import ParticipationBox from './ParticipationBox'
import ProjectDetails from './ProjectDetails'
import ProjectTabs from './tabs'
import TimeboxList from './TimeboxList'
import TokenTable from './TokenTable'
import { useAnalytics } from '../../AmplitudeContext'

const RevampedOverview: React.FC = () => {
  const params = useParams()
  let projectName = params.projectName || ''
  const navigate = useNavigate()
  const theme = useTheme()
  const { trackEvent } = useAnalytics()
  const appState = useAppSelector(state => state.app)
  const { isSidebarCollapse } = appState
  const isDown1520 = useMediaQuery(theme.breakpoints.down(isSidebarCollapse ? 1390 : 1535))

  const launchesPerPage = 4
  const [visibleLaunches, setVisibleLaunches] = useState(launchesPerPage)
  // const [idoData, setIdoData] = useState<any>(); TODO: check stg 67bcfb6 commit if needed

  const [showProjectSelectionModal, setShowProjectSelectionModal] =
    useState(false)

  const handleShowMore = () => {
    setVisibleLaunches(
      prevVisibleLaunches => prevVisibleLaunches + launchesPerPage,
    )
  }

  const location: any = useLocation()

  // get query params
  const [searchParams] = useSearchParams()
  const forceIdoStatus = searchParams.get('forceIdoStatus')

  useEffect(() => {
    if (searchParams.get('claim')) {
      // setMainTab(2) after 1 second
      setTimeout(() => {
        setMainTab(2)
      }, 1000)
    }
  }, [searchParams])

  const { address } = useAccount()

  const [mainTab, setMainTab] = React.useState(0)
  const handleMainTabChange = (value: number) => {
    setMainTab(value)
  }

  const [mainSaleTab, setMainSaleTab] = React.useState(0)
  const handleMainSaleTabChange = (value: number) => {
    setMainSaleTab(value)
  }
  const [severity, setSeverity] = useState<'success' | 'error'>('success')
  const [message, setMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  //if project name has underscore, replace with %20
  projectName = projectName.replace(/_/g, '%20')
  const { data } = useFetchOneProjectByNameQuery(projectName, {
    refetchOnFocus: false,
  })

  const [
    fetchParticipation,
    { data: foundParticipation, isLoading: foundParticipationLoading },
  ] = useLazyFetchSingleParticipationQuery()

  const { projectLinkSource } = useParticipationEntryPoint()
  const isResearchProject =
    String(_.get(data, 'type')).toLowerCase() === 'research'

  const { data: totalParticipants } = useFetchTotalParticipantsQuery(
    {
      projectId: data ? data._id : '0',
      idoNumber: 0,
    },
    {
      skip: !data,
      pollingInterval: 1500000,
    },
  )

  // const { data: _idoData, isLoading: idoDataLoading } = useFetchOneIdoQuery( TODO: check stg 67bcfb6 commit if needed
  const { data: idoData } = useFetchOneIdoQuery(
    {
      projectId: data ? data._id : '0',
      idoNumber: 0,
    },
    {
      skip: !data,
    },
  )

  const { data: allLaunchesData } = useFetchAllLaunchesQuery()

  // TODO: check stg 67bcfb6 commit if needed
  // useEffect(() => {
  //   if(!_idoData) {
  //     return setIdoData(undefined);
  //   }
  //   setIdoData({
  //     ..._idoData,
  //     status: forceIdoStatus || _idoData.status,
  //   });
  // }, [_idoData, setIdoData]);

  // const { data: allLaunchesData, isLoading: allLaunchesLoading, isError: allLaunchesError } = useFetchAllLaunchesQuery()

  const mainAppToken = useAppSelector((state: any) => state.user.mainAppToken)

  useEffect(() => {
    if (data && address && idoData && mainAppToken) {
      setTimeout(() => {
        fetchParticipation({
          projectId: data._id,
          idoNumber: 0,
          address: address,
        })
      }, 500)
    }
  }, [address, data, idoData, address, mainAppToken])

  useEffect(() => {
    scrollToTop()
  }, [location])

  useEffect(() => {
    const referrer = document.referrer
    const params = new URLSearchParams(location.search)
    const referral_code = params.get('code') || ''
    if (data?.name) {
      trackEvent('ProjectPageOpen', {
        timestamp: new Date().toISOString(),
        referrer,
        project_name: data.name.toLowerCase().replace(' ', '_'),
        project_link_source: referral_code ? 'Referral' : projectLinkSource,
        referral_code: referral_code,
        project_type:
          idoData?.amountToRaise && idoData?.amountToRaise > 0
            ? PROJECT_TYPE_DICTIONARY.IDO
            : PROJECT_TYPE_DICTIONARY.AIRDROP,
      })
    }
  }, [trackEvent, data?.name, idoData?.amountToRaise, location.search, projectLinkSource])

  const handleRedirectBack = () => {
    if (window.history.length > 2) {
      navigate(-1)
    } else {
      navigate(`/project`)
    }
  }

  if (!data)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '50vh',
          height: '100%',
        }}
      >
        <Lottie
          animationData={LogoAnimation}
          loop={true}
          style={{
            height: '200px',
            // width: '100vw',
          }}
        />
      </Box>
    )

  const isMatchedInvestor =
    address &&
    idoData &&
    (idoData.status === IdoStatus.TGE ||
      idoData.status === IdoStatus.COMPLETED) &&
    idoData._id === '6554d8e0bf282065fe95f222' &&
    InspectInvestors.find(
      (investor: any) => investor.toLowerCase() === address.toLowerCase(),
    )

  const isEligibleForClaim =
    address &&
    idoData &&
    (idoData.status === IdoStatus.TGE ||
      idoData.status === IdoStatus.CANCELLED) && !!foundParticipation?.isInvestor

  let showClaim = Boolean(isMatchedInvestor || isEligibleForClaim)

  const otherLaunchesData = !allLaunchesData
    ? []
    : allLaunchesData?.filter((launch: any) => {
        const isUpcoming =
          launch.type === 'upcoming' || launch.type === 'featured'
        const isDifferentID = launch._id !== idoData?.project?._id
        const decodedProjectName = decodeURIComponent(projectName)
        const formattedLaunchRouteName = launch.routeName.replace(/_/g, ' ')
        const isDifferentFromParams = launch?.project?._id !== idoData?.project?._id
        return isDifferentID && isDifferentFromParams && isUpcoming
      })

  return (
    <Box
      // marginTop={'60px'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      pb={12}
      width={'100%'}
      sx={
        {
          // backgroundImage: `url(${DetailBg})`,
          // backgroundSize: 'cover',
          // backgroundRepeat: 'no-repeat',
        }
      }
    >
      <Grid
        sx={{
          width: '100%',
          maxWidth: isSidebarCollapse ? 'calc(50vw + 26.5rem)' : '1200px',
        }}
        display={'flex'}
        flexDirection={'column'}
      >
        <Box
          sx={{
            width: 'max-content',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            alignSelf: 'flex-start',
            opacity: 0.5,
            '&:hover': {
              cursor: 'pointer',
              opacity: 0.8,
            },
          }}
          onClick={handleRedirectBack}
        >
          <img src={ArrowLeft} alt="ArrowLeft" />
          <span>Back</span>
        </Box>

        {data &&
          data.name.toLocaleLowerCase() !== 'inspect' &&
          !isResearchProject && (
            <TimeboxList data={data.timeline} idoData={idoData} />
          )}

        <Grid container justifyContent={'space-between'} spacing={'24px'}>
          <Grid
            item
            xs={12}
            sm={12}
            md={!isSidebarCollapse ? 12 : isDown1520 ? 12 : 8}
            xl={8}
            flexDirection={'column'}
            height={'max-content'}
          >
            <Banner
              socialLinks={data?.socialCommunityLinks}
              website={data?.website}
              name={data.name}
              intro={data.introduction}
              symbol={data.token.symbol}
              projectImageUrl={data.projectImageUrl}
              videoUrl={data.videoUrl}
              logoUrl={data.logoUrl}
              tags={data.tags}
              introMeta={_.get(data, 'introductionMeta')}
            />

            {isDown1520 && (
              <ParticipationBox
                id={data._id}
                ido={idoData}
                name={data.name}
                showTickets={mainSaleTab === 1}
                time={
                  idoData?.status === IdoStatus.PARTICIPATION
                    ? idoData?.endDate
                    : idoData?.status === IdoStatus.PROCESSING
                    ? idoData?.investmentStartDate
                    : idoData?.status === IdoStatus.INVESTMENT
                    ? idoData?.investmentEndDate
                    : idoData?.endDate
                }
                isResearchProject={isResearchProject}
                countdownText={_.get(data, 'countdownText')}
              />
            )}

            <ProjectTabs
              tab={mainTab}
              handleTabChange={handleMainTabChange}
              showClaim={showClaim}
              isResearchProject={isResearchProject}
              showIdoDetails={data && data.name.toLowerCase() !== 'inspect'}
            />

            {mainTab === 0 && (
              <>
                <ProjectDetails projectData={data} />
                {isDown1520 && !isResearchProject && (
                  <Grid item xs={12} container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      smd={12}
                      md={12}
                      lg={2}
                      paddingTop={'0px !important'}
                    />
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      smd={12}
                      md={12}
                      lg={10}
                      paddingTop={'0px !important'}
                    >
                      <TokenTable tokenMetrics={data.token.metrics} />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {mainTab === 1 && (
              <IDODetails
                idoData={idoData}
                token={data.token}
                timeline={data.timeline}
                totalParticipants={totalParticipants}
                isFairlaunch={isResearchProject}
              />
            )}
            {mainTab === 2 && showClaim && (
              <ClaimWrapper
                idoData={idoData}
                project={data}
                closeClaimTab={() => setMainTab(0)}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={!isSidebarCollapse ? 12 : isDown1520 ? 12 : 4} xl={4}>
            <div
              style={{
                position: isDown1520 ? 'static' : 'sticky',
                top: '80px',
                paddingBottom: isDown1520 ? '0' : '93px',
              }}
            >
              {!isDown1520 && (
                <ParticipationBox
                  id={data._id}
                  ido={idoData}
                  name={data.name}
                  showTickets={mainSaleTab === 1} //need contract integration here
                  time={
                    idoData?.status === IdoStatus.PARTICIPATION
                      ? idoData?.endDate
                      : idoData?.status === IdoStatus.PROCESSING
                      ? idoData?.investmentStartDate
                      : idoData?.status === IdoStatus.INVESTMENT
                      ? idoData?.investmentEndDate
                      : idoData?.endDate
                  }
                  isResearchProject={isResearchProject}
                  countdownText={_.get(data, 'countdownText')}
                />
              )}
              <Divider
                sx={{
                  backgroundColor: '#1D1F21',
                  height: '1px',
                  mt: '24px',
                  mb: '48px',
                  display: isDown1520 ? 'block' : 'none',
                }}
              />
              <Box
                justifyContent={'space-between'}
                flexDirection={isDown1520 ? 'column' : 'row'}
                mt={'48px'}
                mb={'24px'}
                alignItems={'center'}
                gap={'16px'}
                sx={{
                  display: otherLaunchesData.length > 0 ? 'flex' : 'none',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Hellix',
                    fontSize: '24px',
                    fontWeight: 600,
                    color: '#FFFFFF',
                    margin: '0px',
                    lineHeight: '31.2px',
                    width: isDown1520 ? '100%' : 'fit-content',
                  }}
                >
                  Other Sales
                </Typography>
              </Box>
              {allLaunchesData
                ?.filter((launch: any) => {
                  const isUpcoming =
                    launch.type === 'upcoming' || launch.type === 'featured'
                  // const isLive =
                  //   launch.type !== 'completed' && launch.type !== 'upcoming'
                  const isDifferentID = launch._id !== idoData?.project?._id
                  const decodedProjectName =
                    data && decodeURIComponent(data.name)
                  const formattedLaunchRouteName = launch.routeName.replace(
                    /_/g,
                    ' ',
                  )

                  const isDifferentFromParams = launch?.project?._id !== idoData?.project?._id

                  return (
                    isDifferentID && isDifferentFromParams && isUpcoming
                    // (mainSaleTab === 0 ? isLive : isUpcoming)
                  )
                })
                // @ts-ignore
                ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                ?.slice(0, visibleLaunches)
                .map((launch: any) => (
                  <SalesCard
                    key={launch._id}
                    heading={launch.projectTitle}
                    titleImage={launch.logoUrl}
                    totalRaise={launch.totalRaise}
                    startsIn={launch.startsIn}
                    registrationStarted={launch.registrationStarted}
                    registrationEndDate={launch.registrationEndDate}
                    routeName={launch.routeName}
                    setMainTab={() => setMainTab(0)}
                  />
                ))}
              {allLaunchesData &&
                visibleLaunches < allLaunchesData.length &&
                allLaunchesData.filter(
                  (launch: any) => launch.type === 'upcoming',
                ).length > 4 && (
                  <OutlinedButton
                    // onClick={handleShowMore}
                    onClick={() => setShowProjectSelectionModal(true)}
                    sx={{
                      height: '41px',
                      width: '100%',
                      backgroundColor: '#000',
                    }}
                  >
                    Show More
                  </OutlinedButton>
                )}
            </div>
          </Grid>
          {!isDown1520 && mainTab === 0 && !isResearchProject && (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={2}
                paddingTop={'0px !important'}
              />
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={10}
                paddingTop={'0px !important'}
              >
                <TokenTable tokenMetrics={data.token.metrics} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <NewSelectProjectModal
        open={showProjectSelectionModal}
        handleClose={() => setShowProjectSelectionModal(false)}
        onSelection={(selectedProject: string) => {
          setShowProjectSelectionModal(false)
          navigate(`/project/${selectedProject}`)
        }}
        projectList={
          allLaunchesData
            ? allLaunchesData?.filter((launch: any) => {
                const isUpcoming =
                  launch.type === 'upcoming' && launch.registrationStarted
                // const isLive =
                //   launch.type !== 'completed' && launch.type !== 'upcoming'
                const isDifferentID = launch._id !== idoData?.project?._id
                const isDifferentFromParams = launch?.project?._id !== idoData?.project?._id
                return (
                  isDifferentID && isDifferentFromParams && isUpcoming
                  // (mainSaleTab === 0 ? isLive : isUpcoming)
                )
              })
            : []
        }
      />
    </Box>
  )
}

export default RevampedOverview
