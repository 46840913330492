import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import {
  FundAnimoca,
  FundBinance,
  FundCoinbase,
  FundHashkey,
  FundOKX,
  FundSamsungNext,
  FundSpartan,
} from '../../assets'

const FundsHome = () => {
  const isDown600 = useMediaQuery('(max-width:600px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown1023 = useMediaQuery('(max-width:1023px)')
  const funds = [
    { src: FundOKX, alt: 'Fund OKX' },
    { src: FundHashkey, alt: 'Fund Hashkey' },
    { src: FundAnimoca, alt: 'Fund Animoca' },
    { src: FundBinance, alt: 'Fund Binance' },
    { src: FundCoinbase, alt: 'Fund Coinbase' },
    { src: FundSamsungNext, alt: 'Fund SamsungNext' },
    { src: FundSpartan, alt: 'Fund Spartan' },
  ]
  return (
    <Box
      sx={{
        padding: isDown1023 ? '106px 0' : '180px 0',
        background: '#fff'
      }}
    >
      <Typography
        className="connect-button__text"
        fontFamily={'Hellix'}
        fontSize={isDown1023 ? 40 : 48}
        fontWeight={600}
        lineHeight={isDown1023 ? '48px' : '52.8px'}
        letterSpacing={'-2%'}
        color={'#000'}
        textTransform={'capitalize'}
        textAlign={'center'}
        style={{ transition: 'all 0.3s' }}
        paddingBottom={isDown767 ? '60px' : '80px'}
        paddingX={isDown767 ? '24px' : '32px'}
      >
        {
          isDown767
          ? <>
            Join the Same<br />Pre-Sales as<br />Crypto's Biggest<br />Funds!
          </>
          : <>Join the Same Pre-Sales as Crypto's Biggest Funds!</>
        }
      </Typography>
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={2500}
        centerMode={true}
        className=""
        containerClass="funds-home"
        itemClass="fund-carousel-item"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1360,
            },
            items: 5,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 960,
              min: 750,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
          smallMobile: {
            breakpoint: {
              max: 750,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1360,
              min: 960,
            },
            items: 3,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {funds.map((fund, index) => (
          <Box
            key={index}
            sx={{
              border: '1px solid #000',
              borderRadius: '20px',
              padding: '29px 42px',
              display: 'flex',
              // maxWidth: '290px',
              backgroundColor: '#fff',
              alignItems: 'center',
              justifyContent: 'center',
              height: '109px',
              marginBottom: '4px',
              '&:hover': {
                border: '1px solid #000',
                background: '#FF9228',
                boxShadow: '0px 4px 0px 0px #000',
              },
            }}
          >
            <img
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
              loading="lazy"
              src={fund.src}
              alt={fund.alt}
            />
          </Box>
        ))}
      </Carousel>
    </Box>
  )
}

export default FundsHome
