import { Box, TableRow, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FixedBodyTableCell, StyledBodyTableCell } from './MySalesTable'
import {
  replaceUnderscoresWithSpaces,
  getReplacedCdnEndpoint,
  getChainIdFromName,
} from '../../utils'
import { useAccount } from 'wagmi'
import { ethers } from 'ethers'
import ClaimNewAbi from '../../contracts/claim_new.json'
import { useNavigate } from 'react-router-dom'
import { CONTRACT_ADDRESS, RPC_URL, RPC_PROVIDER } from '../../constant'

const ClaimRow = ({ sale, index }: any) => {
  const isDown800 = useMediaQuery('(max-width:800px)')

  const { address } = useAccount()
  const navigate = useNavigate()

  const [claimableAmount, setClaimableAmount] = useState(0)

  const [claimedAmount, setClaimedAmount] = useState(0)
  const [totalTokens, setTotalTokens] = useState<string | number>(
    sale?.ido?.totalTokens || 0,
  )

  const idoId = sale?.ido?._id;
  const PONDER_IDO_OID = "65f1c30cf950d4fa807a9f2c";
  const MICROGPT_IDO_OID = "6735e623d93397346746b489";

  // unified vesting
  const claimContractAddr = sale?.ido?.claimContract
  const claimNetwork = sale?.ido?.claimNetwork
  const claimProjectId = sale?.ido?.claimProjectId

  const claimChainId = getChainIdFromName(claimNetwork)

  useEffect(() => {
    ;(async () => {
      try {
        if (
          !sale.ido?.claimContract ||
          (sale.ido.claimNetwork !== 'BSC' &&
            sale.ido.claimNetwork !== 'ETH' &&
            sale.ido.claimNetwork !== 'ARB' &&
            sale.ido.claimNetwork !== 'AVAX' &&
            sale.ido.claimNetwork !== 'BASE')
          )
          return

        const provider = RPC_PROVIDER[sale.ido.claimNetwork];
        if (
          claimChainId &&
          claimProjectId >= 0 &&
          (claimContractAddr ===
            CONTRACT_ADDRESS.APE_VESTING_UNIFIED[claimChainId] || idoId === PONDER_IDO_OID || idoId == MICROGPT_IDO_OID)
        ) {
          // TODO: use human readable format
          const claimContract = new ethers.Contract(
            claimContractAddr,
            [
              {
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'projectId',
                    type: 'uint256',
                  },
                  {
                    internalType: 'address',
                    name: 'account',
                    type: 'address',
                  },
                ],
                name: 'getAccountStatsAt',
                outputs: [
                  {
                    internalType: 'uint256',
                    name: 'refundRequestedAt',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'refundedAt',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'claimedAmount',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'claimableAmount',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
              {
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'projectId',
                    type: 'uint256',
                  },
                ],
                name: 'getProject',
                outputs: [
                  {
                    components: [
                      {
                        internalType: 'uint256',
                        name: 'id',
                        type: 'uint256',
                      },
                      {
                        internalType: 'string',
                        name: 'name',
                        type: 'string',
                      },
                      {
                        internalType: 'bool',
                        name: 'active',
                        type: 'bool',
                      },
                      {
                        internalType: 'uint256',
                        name: 'investors',
                        type: 'uint256',
                      },
                      {
                        internalType: 'bytes32',
                        name: 'merkleProofRoot',
                        type: 'bytes32',
                      },
                      {
                        internalType: 'uint256',
                        name: 'tgeAt',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'tgeAmount',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'cliffDuration',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'vestingDuration',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'vestingAmount',
                        type: 'uint256',
                      },
                      {
                        internalType: 'address',
                        name: 'tokenAddr',
                        type: 'address',
                      },
                      {
                        internalType: 'uint256',
                        name: 'tokenDeposited',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'tokenRemains',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint8',
                        name: 'tokenDecimals',
                        type: 'uint8',
                      },
                      {
                        internalType: 'uint256',
                        name: 'refundInvestors',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'refundDeadlineAt',
                        type: 'uint256',
                      },
                      {
                        internalType: 'address',
                        name: 'refundTokenAddr',
                        type: 'address',
                      },
                      {
                        internalType: 'uint256',
                        name: 'refundAmount',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint8',
                        name: 'refundTokenDecimals',
                        type: 'uint8',
                      },
                      {
                        internalType: 'uint256',
                        name: 'refundTokenDeposited',
                        type: 'uint256',
                      },
                      {
                        internalType: 'uint256',
                        name: 'refundTokenRemains',
                        type: 'uint256',
                      },
                    ],
                    internalType: 'struct VestingProject',
                    name: '',
                    type: 'tuple',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ],
            provider,
          )
          const [accountStats, projectData] = await Promise.all([
            claimContract.getAccountStatsAt(claimProjectId, address),
            claimContract.getProject(claimProjectId),
          ])
          const {
            claimableAmount,
            claimedAmount,
            refundRequestedAt,
            refundedAt,
          } = accountStats
          const { tokenDecimals, tgeAmount, vestingAmount } = projectData
          setClaimableAmount(
            +Number(ethers.utils.formatUnits(claimableAmount, tokenDecimals))
              .toFixed(4)
          )
          setClaimedAmount(
            +Number(ethers.utils.formatUnits(claimedAmount, tokenDecimals))
              .toFixed(4)
          )
          setTotalTokens(() => {
            return +Number(
              ethers.utils.formatUnits(
                tgeAmount.add(vestingAmount),
                tokenDecimals,
              ),
            );
          })
          return
        }

        // initialize claim contract
        const claimContract = new ethers.Contract(
          sale.ido?.claimContract,
          ClaimNewAbi,
          provider,
        )

        // get decimals
        let tokenDecimals = await claimContract.returnTokenDecimal()

        // get claimable amount
        let claimableAmount = await claimContract.getClaimableAmount(address)

        // set claimable amount
        setClaimableAmount(
          Number(
            ethers.utils.formatUnits(
              claimableAmount.toString(),
              Number(tokenDecimals),
            ),
          ),
        )

        // get claimedAmount
        let claimedAmount = await claimContract.claimedAmount(address)

        // set claimed amount
        setClaimedAmount(
          Number(
            ethers.utils.formatUnits(
              claimedAmount.toString(),
              Number(tokenDecimals),
            ),
          ),
        )
      } catch (error) {
        console.log('error', error)
      }
    })()
  }, [sale?.ido?.claimContract, sale?.ido?.claimNetwork, address])

  return (
    <TableRow
      key={index}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#000000',
        },
      }}
      onClick={() => navigate(`/project/${sale.ido.project.name}`)}
    >
      {isDown800 ? (
        <FixedBodyTableCell>
          <img
            src={getReplacedCdnEndpoint(sale.ido.project.logoUrl)}
            alt={sale.ido.project.name}
            style={{ width: 40, height: 40, borderRadius: 10 }}
          />
        </FixedBodyTableCell>
      ) : (
        <StyledBodyTableCell>
          <Box display="flex" alignItems="center">
            <img
              src={getReplacedCdnEndpoint(sale.ido.project.logoUrl)}
              alt={sale.ido.project.name}
              style={{ width: 40, height: 40, borderRadius: 10 }}
            />
            <Box ml={'12px'}>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: '15px',
                  fontWeight: 400,
                }}
              >
                {replaceUnderscoresWithSpaces(sale.ido.project.name)}
              </Typography>
              <Typography
                sx={{
                  color: '#7E8180',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
              >
                {sale.ido.project.token.symbol}
              </Typography>
            </Box>
          </Box>
        </StyledBodyTableCell>
      )}
      <StyledBodyTableCell>
        {Number(claimableAmount.toFixed(2)).toLocaleString('en')}{' '}
        {sale.ido.project.token.symbol}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {Number(totalTokens).toLocaleString('en')}{' '}
        {sale.ido.project.token.symbol}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {Number(claimedAmount.toFixed(2)).toLocaleString('en')}{' '}
        {sale.ido.project.token.symbol}
      </StyledBodyTableCell>
      {/* <StyledBodyTableCell>N/A</StyledBodyTableCell> */}
      <StyledBodyTableCell>
        <Box
          sx={{
            height: '28px',
            width: '69px',
            borderRadius: '80px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Inter',
            border: '1px solid #313335',
            color: '#313335',
            background: '#6FEB7C',
          }}
          onClick={e => {
            e.stopPropagation()
            navigate(`/project/${sale.ido.project.name}?claim=true`)
          }}
        >
          Claim
        </Box>
      </StyledBodyTableCell>
    </TableRow>
  )
}

export default ClaimRow
