import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import { SERVER_URL } from '../constant'
import { RootState } from '../redux/configureStore'
import {
  logout,
  setAccessTokenGoogle,
  setAddressLinkEmail,
  setAddressRegisterUserAccount,
  setEmail,
  setEmailRegisterUserAccount,
  setGoogleToken,
  setIsLoggedIn,
  setIsLoggedInWithEmail,
  setLoginMethod,
  setMainAppToken,
  setNameGoogleToken,
  setProfilePictureUrl,
  setRefferCode,
  setSignature,
  setWalletAddress,
  setWeb3AuthToken,
} from '../redux/slices/userSlice'
import { setSnackbar } from '../redux/slices/themeSlice'

// const baseQuery = fetchBaseQuery({
//   baseUrl: SERVER_URL,
// })

// const baseQueryWithInterceptor: BaseQueryFn<
//   string | FetchArgs,
//   unknown,
//   FetchBaseQueryError
// > = async (args, api, extraOptions) => {
//   const result = await baseQuery(args, api, extraOptions)
//   if (result.error && result.error.status === 401) {
//     console.log('Unauthorized')
//   }
//   return result
// }

const baseQuery = fetchBaseQuery({
  baseUrl: SERVER_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    let mainAppToken = (getState() as RootState).user.mainAppToken

    let accessTokenGoogle = (getState() as RootState).user.accessTokenGoogle

    if (mainAppToken) {
      headers.set('Authorization', `Bearer ${mainAppToken}`)
    }

    if (accessTokenGoogle) {
      headers.set('Authorization', `Bearer ${accessTokenGoogle}`)
    }

    return headers
  },

  cache: 'no-store',

  // credentials: 'include', // This allows server to set cookies //ask waleed why uncommenting it gives cors error
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  let appState = api.getState() as RootState
  let userInfo
  try {
    userInfo = await appState.app.web3AuthInstance?.getUserInfo()
  } catch (e) {
    console.warn('could not fetch user info', e)
  }

  if(result.error) {
    // @ts-ignore
    const isUnauthorizedError = result.error?.status === 401 && result.error?.data?.message === "Unauthorized";
    if(isUnauthorizedError) {
      api.dispatch(setSignature(''))
      api.dispatch(setIsLoggedInWithEmail(false))
      api.dispatch(setIsLoggedIn(false))
      api.dispatch(setEmail(''))
      api.dispatch(setGoogleToken(''))
      api.dispatch(setMainAppToken(''))
      api.dispatch(setWalletAddress(''))
      api.dispatch(setNameGoogleToken(''))
      api.dispatch(setRefferCode(''))
      api.dispatch(setAddressLinkEmail(''))
      api.dispatch(setEmailRegisterUserAccount(''))
      api.dispatch(setAddressRegisterUserAccount(''))
      api.dispatch(setLoginMethod(''))
      api.dispatch(setAccessTokenGoogle(''))
      api.dispatch(setProfilePictureUrl(''));
      return result
    }
  }

  if (result.error) {
    if (
      result.error.data &&
      JSON.stringify(result.error.data).includes(
        'Unable to create profile, address provided is already in use with other user. Use that email to access the account',
      )
    ) {
      api.dispatch(
        setSnackbar({
          open: true,
          severity: 'error',
          message:
            'Unable to create profile, an email is already associated with the wallet address connected',
        }),
      )
      await appState.app.web3AuthInstance?.logout()
      api.dispatch(setWeb3AuthToken(''))
      api.dispatch(setEmail(''))
      api.dispatch(setIsLoggedIn(false))
      api.dispatch(setProfilePictureUrl(''))
    }
  }

  if (
    result.error &&
    JSON.stringify(result.error).includes('ERR_JOSE_GENERIC')
  ) {
    console.error('ERR_JOSE_GENERIC', result.error)
  } else {
    if (result.error && result.error.status === 403) {
      api.dispatch(logout())
      if (userInfo) {
        console.log('🚀 ~ file: api.ts:79 ~ >= ~ userInfo:', userInfo)

        await appState.app.web3AuthInstance?.logout()
      }
      // appState?.app?.web3AuthInstance?.logout()
    }
  }

  // if (
  //   result.error &&
  //   result.error.status === 403 &&
  //   ((result.error.data as any)?.message === 'ERR_JWS_INVALID' ||
  //     (result.error.data as any)?.message === 'ERR_JWS_EXPIRED' ||
  //     (result.error.data as any)?.code === 13001)
  // ) {
  //   console.log('Logging you out')
  //   api.dispatch(logout())
  //   if (userInfo) {
  //     console.log('🚀 ~ file: api.ts:79 ~ >= ~ userInfo:', userInfo)

  //     await appState.app.web3AuthInstance?.logout()
  //   }
  //   // appState?.app?.web3AuthInstance?.logout()

  //   // TODO: Reauth
  // } else if (result.error && result.error.status === 403) {
  //   api.dispatch(logout())
  //   if (userInfo) {
  //     console.log('🚀 ~ file: api.ts:89 ~ >= ~ userInfo:', userInfo)

  //     await appState.app.web3AuthInstance?.logout()
  //   }
  // }
  return result
}

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  refetchOnFocus: false,
})
