import { Box } from '@mui/material'
import { NoData } from '../../assets'

const NoDataBox = () => (
  <Box
    height={'140px'}
    width={'100%'}
    border={'0.5px solid rgba(255, 255, 255, 0.16)'}
    mb={'60px'}
    borderRadius={'12px'}
    bgcolor={'#fff'}
    display={'flex'}
    justifyContent={'center'}
  >
    <img src={NoData} alt="No Data" width={'164px'} loading="lazy" />
  </Box>
)

export default NoDataBox
