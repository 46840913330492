import { CompletedSale } from '../../components/homepage/completed-sales'
import UpcomingSales from '../../components/homepage/upcoming-sale'
import { FeatureProject } from '../../components/revampe-launchpad/feature-project'

export const RevampLaunchPad = () => {
  return (
    <div className="launchpad-main">
      <FeatureProject />
      <UpcomingSales />
      <CompletedSale />
    </div>
  )
}
