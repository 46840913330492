import { Box, useMediaQuery, Typography, useTheme } from '@mui/material'
import Rive, {
  Fit,
  Alignment,
  Layout,
  RuntimeLoader,
} from '@rive-app/react-canvas'
import riveWASMResource from '@rive-app/canvas/rive.wasm'
import {
  // NewHomepageBg,
  NewHomepageBgMobile,
  HeroBackgroundDecor,
  HeroBackgroundDecorMobile,
} from '../../assets'
import Motion from '../../components/Motion/Motion'
import LinkButtons from 'pages/RevampedLaunchpad/LinkButtons'

const STATE_MACHINE_NAME = 'Interaction'

export const Welcome = () => {
  RuntimeLoader.setWasmUrl(riveWASMResource)
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown600 = useMediaQuery('(max-width:600px)')
  const isDown360 = useMediaQuery('(max-width:360px)')
  const isDown1220 = useMediaQuery('(max-width:1220px)')
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown850 = useMediaQuery('(max-width:850px)')
  const isDown1900 = useMediaQuery('(max-width:1900px)')
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: isDown767 ? '400px' : isDown1900 ? '500px' : '550px',
      }}
    >
      <div className="rive-lines">
        <Motion
          threshold={0.1}
          duration={0.8}
          delay={0.2}
          style={{
            display: 'block',
            height: '100%',
            width: '100%',
          }}
        >
          <div
            className="rive-lines__inner"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              pointerEvents: 'none',
              userSelect: 'none',
              zIndex: 1,
              opacity: 0.6,
              aspectRatio: isDown600 ? '16/30' : '16/10',
            }}
          >
            <Rive
              src="rive/grid_header_ape.riv"
              stateMachines={STATE_MACHINE_NAME}
              layout={
                new Layout({
                  fit: isDown600 ? Fit.Cover : Fit.Contain,
                  alignment: Alignment.TopCenter,
                })
              }
            />
          </div>
        </Motion>
      </div>

      <Box
        sx={{
          width: '100%',
          zIndex: 1,
          fontSize: 0,
        }}
      >
        <Box
          style={{
            width: '100vw',
            height: '100%',
            pointerEvents: 'none',
            userSelect: 'none',
            aspectRatio: isDown600 ? '9/16' : '1278/749',
          }}
        ></Box>

        <Motion
          threshold={0.1}
          duration={0.8}
          style={{
            display: 'block',
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <div
            className="hero-background-decor"
            style={{
              width: '100%',
              position: 'absolute',
              left: '0',
              top: isDown600 ? '27%' : '26%',
              pointerEvents: 'none',
              zIndex: 0,
              userSelect: 'none',
            }}
          >
            <img
              src={isDown600 ? HeroBackgroundDecor : HeroBackgroundDecor}
              style={{
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
              }}
              alt="background-decor"
              // className="animate-blinking"
            />
          </div>
        </Motion>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          zIndex: 1,
          position: 'absolute',
          top: isDown600 ? '27%' : '32%',
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          px={isDown360 ? '20px' : '36px'}
          // mb={'112px'}
        >
          <Typography
            fontSize={isMobile ? 42 : 96}
            fontWeight={600}
            lineHeight={'100%'}
            color="#fff"
            letterSpacing={'-0.02em'}
            textAlign={'center'}
            fontFamily={'Hellix'}
            sx={{
              alignSelf: 'center',
            }}
          >
            <Motion
              from={{ opacity: 1, filter: 'blur(5px)' }}
              to={{ filter: 'blur(0px)' }}
              threshold={0.1}
              duration={0.5}
              delay={0.05}
            >
              <Typography
                style={{
                  background:
                    'radial-gradient(77.99% 64.58% at 49.93% 17.71%, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
                fontSize={isDown360 ? 42 : isDown850 ? 42 : 96}
                fontWeight={600}
                lineHeight={'100%'}
                color="#fff"
                letterSpacing={'-0.02em'}
                textAlign={'center'}
                fontFamily={'Hellix'}
                sx={{
                  alignSelf: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                Welcome to
                <br />
                Ape Launchpad
              </Typography>
            </Motion>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
