import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import TransparentButton from '../../components/TransparentButton'
import WinnersTable from './WinnersTable'
import Lottie from 'lottie-react'
import LogoAnimation from '../../animations/logo loading white.json'
import { useFetchTransparencyProjectsQuery } from '../../services/modules/project'
import {
  removeXFromROIAndMakeItPercentage,
  replaceUnderscoresWithSpaces,
} from '../../utils'
import { ExpandIcon, RightArrowSvg } from '../../assets'
import NewSelectProjectModal from '../../components/NewSelectProjectModal'
import { getReplacedCdnEndpoint } from '../../utils'

import Motion from '../../components/Motion/Motion'

interface ProjectDataInterface {
  logo: string
  title: string
  symbol: string
  totalParticipants: string
  selectedForTransparency: boolean
  winners: string[]
  proofUrl: string
  roi?: string
}

const CompletedSalesReport = () => {
  const isDown925 = useMediaQuery('(max-width:925px)')
  const isDown1120 = useMediaQuery('(max-width:1120px)')
  
  const {
    data: transparencyProjects,
    isLoading: transparencyProjectsLoading,
    isError: transparencyProjectsError,
    error: transparencyProjectsErrorData,
  } = useFetchTransparencyProjectsQuery()
  
  const isDown1200 = useMediaQuery('(max-width:1200px)')
  const isDown800 = useMediaQuery('(max-width:800px)')
  
  const [projectList, setProjectList] = useState<any[]>([])
  
  useEffect(() => {
    if (transparencyProjects && !transparencyProjectsLoading) {
      setProjectList(transparencyProjects)
    }
  }, [transparencyProjects])
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: isDown1200 ? (isDown800 ? '32px' : '75px') : '75px'
      }}
    >
      {transparencyProjects && projectList.length > 0 ? (
        <Motion
          from={{ filter: 'blur(5px)' }}
          to={{ filter: 'blur(0px)' }}
          threshold={0.1}
          style={{ display: 'block', width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: isDown1120 ? 'column' : 'row',
              maxWidth: '1600px',
              width: '100%',
              gap: '8px',
            }}
          >
            <ProjectBox
              projectList={projectList}
              setProjectList={setProjectList}
              selectedProject={
                projectList.filter(pro => pro.selectedForTransparency)[0]
              }
            />
            <StatsBox
              selectedProject={
                projectList.filter(pro => pro.selectedForTransparency)[0]
              }
            />
          </Box>
          
          <WinnersTable
            winners={
              projectList.filter(pro => pro.selectedForTransparency)[0].winners
            }
          />
        </Motion>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <Lottie
            animationData={LogoAnimation}
            loop={true}
            style={{
              height: isDown925 ? '120px' : '200px',
            }}
          />
        </Box>
      )}
    </Box>
  )
}

const ProjectBox = ({
                      projectList,
                      setProjectList,
                      selectedProject,
                    }: {
  projectList: ProjectDataInterface[]
  setProjectList: (v: ProjectDataInterface[]) => void
  selectedProject: ProjectDataInterface
}) => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown600 = useMediaQuery('(max-width:600px)')
  const [open, setOpen] = useState(false)
  return (
    <Box
      sx={{
        padding: '16px',
        flex: 1,
        backgroundColor: '#0F1113',
        border: '0.5px solid rgba(255, 255, 255, 0.16)',
        borderRadius: '16px',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <NewSelectProjectModal
        open={open}
        handleClose={() => setOpen(false)}
        onSelection={(project: ProjectDataInterface) => {
          const updList = projectList.map(proj => {
            if (proj.title === project?.title) {
              return {
                ...proj,
                selectedForTransparency: true,
              }
            }
            return { ...proj, selectedForTransparency: false }
          })
          setProjectList(updList)
          setOpen(false)
        }}
        projectList={projectList ? projectList : []}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src={getReplacedCdnEndpoint(selectedProject.logo)}
            alt="proj logo"
            style={{
              width: '48px',
              height: '48px',
              marginRight: '12px',
              border: '0.5px solid #1D1F21',
              borderRadius: '12px',
              overflow: 'hidden',
            }}
          />
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              fontSize={'17px'}
              fontWeight={500}
              lineHeight={'27.2px'}
              fontFamily={'Inter'}
            >
              {replaceUnderscoresWithSpaces(selectedProject?.title)}
            </Typography>
            <Typography
              fontFamily={'Inter'}
              fontSize={'15px'}
              fontWeight={400}
              lineHeight={'22.5px'}
              color={'#B1B4B3'}
            >
              {selectedProject?.symbol}
            </Typography>
          </Box>
        </Box>
        
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{
              height: '36px',
              width: '36px',
              border: ' 1px solid #313335',
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => setOpen(true)}
          >
            <img
              src={ExpandIcon}
              alt="expand"
              style={{ width: '24px', height: '24px' }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

const StatsBox = ({
                    selectedProject,
                  }: {
  selectedProject: ProjectDataInterface
}) => {
  const isDown925 = useMediaQuery('(max-width:925px)')
  
  return (
    <Box
      sx={{
        padding: '16px',
        paddingX: '20px',
        flex: 2,
        backgroundColor: '#0F1113',
        border: '0.5px solid rgba(255, 255, 255, 0.16)',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: isDown925 ? 'column' : 'row',
        gap: isDown925 ? '8px' : '110px',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={isDown925 ? 'column' : 'row'}
        alignItems={'center'}
        gap={isDown925 ? '8px' : '48px'}
        flex={2}
        width={'100%'}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isDown925 ? 'row' : 'column',
            alignItems: 'left',
            justifyContent: 'space-between',
            width: !isDown925 ? 'fit-content' : '100%',
          }}
        >
          <Typography
            fontSize={'15px'}
            fontWeight={400}
            lineHeight={'24px'}
            fontFamily={'Inter'}
            color="#7E8180"
          >
            AVG ROI
          </Typography>
          
          <Typography
            fontSize={'20px'}
            fontWeight={600}
            lineHeight={'28px'}
            color="#FFFFFF"
          >
            {removeXFromROIAndMakeItPercentage(
              selectedProject.roi ? selectedProject.roi : '0',
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isDown925 ? 'row' : 'column',
            alignItems: 'left',
            justifyContent: 'space-between',
            width: !isDown925 ? 'fit-content' : '100%',
          }}
        >
          <Typography
            fontSize={'15px'}
            fontWeight={400}
            lineHeight={'24px'}
            fontFamily={'Inter'}
            color="#7E8180"
          >
            Total Participants
          </Typography>
          
          <Typography
            fontSize={'20px'}
            fontWeight={600}
            lineHeight={'28px'}
            color="#FFFFFF"
          >
            {selectedProject.totalParticipants.toLocaleString()}
          </Typography>
        </Box>
        
        <Box
          sx={{
            display: 'flex',
            flexDirection: isDown925 ? 'row' : 'column',
            alignItems: 'left',
            justifyContent: 'space-between',
            width: !isDown925 ? 'fit-content' : '100%',
          }}
        >
          <Typography
            fontSize={'15px'}
            fontWeight={400}
            lineHeight={'24px'}
            fontFamily={'Inter'}
            color="#7E8180"
          >
            Total Winners
          </Typography>
          
          <Typography
            fontSize={'20px'}
            fontWeight={600}
            lineHeight={'28px'}
            color="#FFFFFF"
          >
            {selectedProject.winners.length.toLocaleString('en')}
          </Typography>
        </Box>
      </Box>
      
      <TransparentButton
        text="Proof"
        toolTipText=""
        tooltipPlacement="top"
        br="40px"
        w={isDown925 ? '100%' : '104px'}
        h="40px"
        border="1px solid #313335"
        onClick={() => {
          window.open(selectedProject.proofUrl, '_blank', 'noopener,noreferrer')
        }}
        sx={{ flex: 'initial' }}
        Icon={RightArrowSvg}
      />
    </Box>
  )
}

export default CompletedSalesReport
