import { formatUnixToDate } from '../../constants/formatDate'
import { NextUnlockIcon } from '../../assets'
import { useFetchUserPortfolioStatsQuery } from '../../services/modules/participations'
import { useAppSelector } from '../../hooks'
import { useAccount } from 'wagmi'
import { useMediaQuery } from '@mui/material'

function formatNumberShort(value: any) {
  const num = Number(value)
  if (isNaN(num)) {
    return 'Invalid number'
  }
  if (num >= 1e12) {
    return (num / 1e12).toFixed(2) + 'T'
  } else if (num >= 1e9) {
    return (num / 1e9).toFixed(2) + 'B'
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(2) + 'M' 
  } else {
    return Number(num.toFixed(2)).toLocaleString('en')
  }
}

const OverviewUserPortal = () => {
  const isDown800 = useMediaQuery('(max-width:800px)')
  const user = useAppSelector(state => state.user)
  const { address } = useAccount()
  const { data: portfolioStats } = useFetchUserPortfolioStatsQuery(undefined, {
    skip: !address && !user.mainAppToken && !user.accessTokenGoogle,
    refetchOnFocus: false,
  })

  const annualizedROI = formatNumberShort(Number(portfolioStats?.annualizedROI))

  return (
    <section className="user-portal-header">
      <div className="user-portal-header-wrapper">
        <aside className="values">
          <span className="values--item">
            <span>Amount Invested</span>
            <b>
              {portfolioStats && portfolioStats?.amountInvested > 0
                ? `$${Number(
                    Number(portfolioStats?.amountInvested).toFixed(2),
                  ).toLocaleString('en')}`
                : '$0,000.00'}
            </b>
          </span>
          {/* <span className="values--item">
            <span>
              <img src={NextUnlockIcon} alt="NextUnlockIcon" />
              Next Unlock
            </span>
            <b>
              {portfolioStats &&
              portfolioStats?.nextUnlock &&
              portfolioStats?.nextUnlock !== 'TBD'
                ? formatUnixToDate(portfolioStats?.nextUnlock)
                : 'TBD'}
            </b>
          </span> */}
          <span className="values--item">
              <span>Invested Projects</span>
              <b>
                {portfolioStats && portfolioStats?.investedProject
                  ? Number(
                      Number(portfolioStats?.investedProject).toFixed(2),
                    ).toLocaleString('en')
                  : '0'}
              </b>
            </span>
        </aside>
        {/* {isDown800 ? (
          <>
            <aside className="values">
              <span className="values--item">
                <span>Invested Projects</span>
                <b>
                  {portfolioStats && portfolioStats?.investedProject
                    ? formatNumberShort(portfolioStats?.investedProject)
                    : '0'}
                </b>
              </span>
              <span className="values--item">
                <span>Investment Gains</span>
                <b>
                  {portfolioStats && portfolioStats?.investmentGain
                    ? `$${formatNumberShort(portfolioStats?.investmentGain)}`
                    : '$0,000.00'}
                </b>
              </span>
            </aside>
            <aside className="values">
              <span className="values--item">
                <span>ROI</span>
                <b>
                  {portfolioStats && portfolioStats?.annualizedROI !== null
                    ? annualizedROI + '%'
                    : '0.00'}
                </b>
              </span>
            </aside>
          </>
        ) : (
          <aside className="values">
            <span className="values--item">
              <span>Invested Projects</span>
              <b>
                {portfolioStats && portfolioStats?.investedProject
                  ? Number(
                      Number(portfolioStats?.investedProject).toFixed(2),
                    ).toLocaleString('en')
                  : '0'}
              </b>
            </span>
            <span className="values--item">
              <span>Investment Gains</span>
              <b>
                {portfolioStats && portfolioStats?.investmentGain
                  ? `$${formatNumberShort(portfolioStats?.investmentGain)}`
                  : '$0,000.00'}
              </b>
            </span>
            <span className="values--item">
              <span>ROI</span>
              <b>
                {portfolioStats && portfolioStats?.annualizedROI !== null
                  ? annualizedROI + '%'
                  : '0.00'}
              </b>
            </span>
          </aside>
        )} */}
      </div>
    </section>
  )
}
export default OverviewUserPortal
