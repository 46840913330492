import { useNavigate } from 'react-router-dom'
import { Box, Typography, useMediaQuery } from '@mui/material'
import GppBadIcon from '@mui/icons-material/GppBad';
import { FilledBlackArrow } from '../../assets'
import GreenButton from '../../components/GreenButton'
import { PATHS } from '../../constants/config'

const RenderCancelled = () => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        backgroundColor: '#000000',
        flex: 1,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: isDown1000 ? '32px' : '0px',
        width: '100%',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        height={'100%'}
        textAlign={'center'}
        paddingX={isDown1000 ? '32px' : '10%'}
        width={'100%'}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '64px',
            height: '64px',
            backgroundColor: '#181a1c',
            borderRadius: '12px',
            margin: isDown1000 ? '64px' : 0,
          }}
        >
          <GppBadIcon
            sx={{
              color: 'rgb(248,122,43)',
              fontSize: 32
            }}
          />
        </Box>

        <Typography
          pt={isDown1000 ? 0 : '32px'}
          marginTop={isDown1000 ? '-45px' : '0px'}
          pb={isDown1000 ? 0 : '24px'}
          fontFamily={'Hellix'}
          fontSize={32}
          fontWeight={600}
          lineHeight={'40px'}
          color={'#FFFFFF'}
        >
          This project was cancelled!
        </Typography>

        <GreenButton
          fs={15}
          lh="22.5px"
          fm="Inter"
          fw={400}
          br="24px"
          text={'Home Page'}
          mt={5}
          onClick={() => navigate(PATHS.home)}
          w="200px"
          Icon={() => (
            <img src={FilledBlackArrow} alt="Filled Black Arrow" />
          )}
        />
      </Box>
    </Box>
  )
}

export default RenderCancelled
