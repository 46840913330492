//@ts-nocheck
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { useScreen } from '../../utils/useScreen'
import { PATHS } from '../../constants/config'
import logoPng from '../../assets/ape-terminal-white.svg'
import Navbar from '../Navbar'
import MobileNavbarNew from './MobileNavbarNew'

const Header = () => {
  const location = useLocation()
  const { id } = useParams()
  const { pathname } = location
  // const isPathnameLaunchpad =
  //   pathname === PATHS.launchpad || pathname === `${PATHS.launchpad}/${id}`
  const { lg } = useScreen()

  return (
    <header
      // className={`${isPathnameLaunchpad ? 'launchpad' : ''} wallet-header`}
      className={`wallet-header`}
    >
      <div className="wallet-header-wrapper">
        <nav>
          <NavLink className="mobile-logo" to={PATHS.landingPage}>
            <img src={logoPng} alt="" />
          </NavLink>
          {!lg && <MobileNavbarNew />}
          {lg && (
            <Navbar isLoading={false} giftBannerHeight={'16px'} />
          )}
        </nav>
      </div>
    </header>
  )
}

export default Header
