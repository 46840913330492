export const Web3General = () => {
  return (
    <div className="ape2">
      <div className="header-about">
        <div className="box-content-header">
          <div className="box-about-pes">
            <img src="assets/images/about/icon-about.svg" alt="icon about" />
            <p className="ape-about-apes">About Apes</p>
          </div>
          <div className="box-ape-terminal">
            <p className="label-ape-terminal">Ape Terminal</p>
            <p className="label-ape-manifesto">Manifesto</p>
          </div>
          <div className="box-list-item-ape-terminal">
            <a className="ape-terminal-item">
              <img src="assets/images/about/efficient.svg" alt="efficient" />
              <p className="label-zero">Zero Upfront Costs</p>
            </a>
            <a className="ape-terminal-item-green">
              <img src="assets/images/about/icon-none.svg" alt="icon none" />
              <p className="label-non">Non-Token Gated</p>
            </a>
            <a className="ape-terminal-item">
              <img
                src="assets/images/about/icon-refunable.svg"
                alt="icon refundable"
              />
              <p className="label-non">Refundable Model</p>
            </a>
            <a className="ape-terminal-item">
              <img src="assets/images/about/verify.svg" alt="verify" />
              <p className="label-no">No KYC Required</p>
            </a>
          </div>
          <div className="info-ape-terminal">
            Ape Terminal is Web3's most liquid primary market, connected to over
            $1.7B+ assets and servicing more than 500,000+ customers.
          </div>
          <a href="https://www.apeterminal.io/" className="button-started">
            Get Started
          </a>
        </div>
        <div className="featured-cta">
          <img
            src="assets/images/strong-apes-tgoether-background.png"
            alt="strong apes together"
            className="strongapes"
          />
          <div className="box-cta-info container">
            <span className="value-user-join" style={{ fontSize: '66px' }}>
              <span className="value-customers">Join 500,000+ Users</span>
            </span>
            <p className="des-value-user">
              Accessing the Industry's Highest ROI Sales
            </p>
            <div className="featured-cta-container">
              <div className="featured-cta-item">
                <img src="assets/images/about/cta-1.png" alt="cta 1" />
                <div className="box-info-item">
                  <p className="value">$1,756,581,920+</p>
                  <p className="des-value-assets des-value">Assets Connected</p>
                </div>
              </div>
              <div className="featured-cta-item">
                <img src="assets/images/about/cta-2.png" alt="cta 2" />
                <div className="box-info-item">
                  <p className="value">1,300%</p>
                  <p className="des-value-average des-value">Average ROI</p>
                </div>
              </div>
              <div className="featured-cta-item">
                <img src="assets/images/about/cta-3.png" alt="cta 3" />
                <div className="box-info-item-left">
                  <p className="value">50+</p>
                  <p className="des-value-countries des-value">Countries</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="featured-rapidly container">
          <img
            src="assets/images/glow-green.png"
            alt="Glow Green"
            className="blurbackright"
          />
          <div className="box-info-rapidly">
            <div className="info-rapidly">
              <div className="box-user">
                <div className="content-user">
                  <img
                    src="assets/images/about/icon-user-rapidly.svg"
                    alt="icon-user-rapidly"
                  />
                  <span className="label-user">Users</span>
                </div>
              </div>
              <p className="text-rapidly">Rapidly Growing Userbase</p>
              <p className="des-rapidly">
                <span className="value-rapidly">
                  Ape Terminal has amassed 500,000+ active
                </span>
                <span className="color-green">
                  <br />
                  accounts,
                </span>{' '}
                and is rapidly growing at over 4,000 active accounts per day.
              </p>
            </div>
            <div className="image-rapidly">
              <img
                src="assets/images/about/image-rapidly-content.png"
                alt="image-rapidly-content"
              />
            </div>
          </div>
        </div>
        <div className="featured-ape-launchpad container">
          <img
            src="assets/images/glow-blue.png"
            alt="Glow blue"
            className="blurbackleft"
          />
          <div className="box-info-launchpad">
            <div className="image-launchpad">
              <img src="assets/images/about/Graphic.png" alt="Graphic" />
            </div>
            <div className="info-launchpad">
              <div className="box-user">
                <div className="content-user">
                  <img
                    src="assets/images/about/icon-ape-launchpad.svg"
                    alt="icon-ape-launchpad"
                  />
                  <span className="label-user">Ape Launchpad</span>
                </div>
              </div>
              <p className="text-launchpad">
                Why <br /> Ape Terminal?
              </p>
              <p className="des-ape-launchpad">
                We connect over 500,000+ users with the most promising startups
                in crypto.
              </p>
              <a href="https://www.apeterminal.io/" className="button-learn">
                Learn More
              </a>
            </div>
          </div>
        </div>
        <div className="featured-ape-card container">
          <div className="card-grid">
            <div className="card">
              <div className="card-icon">
                <div className="card-image">
                  <img
                    src="assets/images/about/market-cap.svg"
                    alt="market-cap"
                  />
                </div>
              </div>
              <h3>Highest ROI Sales</h3>
              <p>
                We work with the best, and the results speak for themselves.
              </p>
            </div>
            <div className="card">
              <div className="card-icon">
                <div className="card-image">
                  <img src="assets/images/about/ok.svg" alt="ok" />
                </div>
              </div>
              <h3>Non-Token Gated Model</h3>
              <p>Zero exposure to depreciating launchpad native tokens.</p>
            </div>
            <div className="card">
              <div className="card-icon">
                <div className="card-image">
                  <img src="assets/images/about/rocket.svg" alt="rocket" />
                </div>
              </div>
              <h3>Superior Listings</h3>
              <p>
                Startups launching on primary exchanges perform the best. We
                ensure ours do.
              </p>
            </div>
            <div className="card">
              <div className="card-icon">
                <div className="card-image">
                  <img src="assets/images/about/roi.svg" alt="roi" />
                </div>
              </div>
              <h3>Limitless Opening Day Volume</h3>
              <p>
                Ape Terminal launches have a proven track record of capturing
                the highest market volume on opening day.
              </p>
            </div>
            <div className="card">
              <div className="card-icon">
                <div className="card-image">
                  <img src="assets/images/about/trading.svg" alt="trading" />
                </div>
              </div>
              <h3>Infinite Post-Launch Liquidity</h3>
              <p>
                The industry's only launchpad that enables startups to secure
                follow-on funding beyond their token sale.
              </p>
            </div>
            <div className="card">
              <div className="card-icon">
                <div className="card-image">
                  <img src="assets/images/about/wallet.svg" alt="wallet" />
                </div>
              </div>
              <h3>Non-Refundable Model</h3>
              <p>
                We take full responsibility for users without penalizing
                founders.
              </p>
            </div>
          </div>
        </div>
        <div className="featured-ape-quarterly container">
          <img
            src="assets/images/glow-green.png"
            alt="Glow Green"
            className="blurbackright"
          />
          <div className="box-info-quarterly">
            <div className="box-content">
              <p>
                Quarterly <br />
                Launchpad Report
              </p>
              <span className="readmore">
                <a href="assets/docs/launchpad-report-web3-general.pdf">
                  <img
                    src="assets/images/button-read-more.svg"
                    alt="read more"
                  />
                </a>
              </span>
            </div>
            <div className="image-quarterly">
              <img
                src="assets/images/about/image-quarterly.png"
                alt="image-quarterly"
              />
            </div>
          </div>
        </div>
        <div className="featured-ape-join-future container">
          <div className="box-join-feature">
            <h3>
              Join the Future <br /> of Crypto
            </h3>
            <p>
              Get started today with zero
              <br />
              upfront costs.
            </p>
            <div className="apply"></div>
            <span className="buttonapply">
              <a
                href="https://www.apeterminal.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="assets/images/button-started.svg"
                  alt="Apply for the Program"
                />
              </a>
            </span>
          </div>
          <img
            src="assets/images/join-the-future.svg"
            alt="Join the Future of Crypto"
            className="desktopimage"
          />
          <img
            src="assets/images/join-the-future-mobile.svg"
            alt="Join the Future of Crypto"
            className="mobileimage"
          />
        </div>
      </div>
    </div>
  )
}
