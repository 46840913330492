import { Button, CircularProgress } from "@mui/material";

type LoadMoreButtonProps = {
  onClick?: (e: any) => void,
  disabled?: boolean | undefined,
  isLoading: boolean
}

const LoadMoreButton = (props: LoadMoreButtonProps) => {
  const { isLoading, disabled, onClick } = props;

  return (
    <Button
      disableRipple
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: 'transparent',
        color: '#FFFFFF',
        cursor: 'pointer',
        border: `1px solid #1D1F21`,
        padding: '10px',
        borderRadius: '24px',
        transition: 'all 0.25s ease',
        fontFamily: 'Inter',
        fontSize: '15px',
        height: '48px',
        width: '100%',
        textTransform: 'none',
        '&:hover': {
          color: '#FFFFFF',
          border: `1px solid #313335`,
          backgroundColor: '#0F1113',
        },
        '&:disabled': {
          color: '#767676',
          backgroundColor: '#0F1113',
          cursor: 'not-allowed',
        }
      }}
    >
      <span>Show More</span>
      { isLoading && <CircularProgress size={13} sx={{ color: '#767676', marginLeft: '12px' }} /> }
    </Button>
  )
}

export default LoadMoreButton
