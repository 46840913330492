import { Button, Typography } from "@mui/material"

type JoinButtonProps = {
  onClick?: (event: any) => void
}

export const ViewLaunchButton = (props: JoinButtonProps) => {
  const { onClick } = props;
  return (
    <Button
      variant="outlined"
      sx={{
        maxWidth: '120px',
        borderRadius: '24px',
        height: '32px',
        border: '1px solid #63EA71',
        display: 'flex',
        '&:hover': {
          border: '1px solid #63EA71',
          backgroundColor: '#63EA71',
          '> p':{
            color: '#000',
            fontWeight: 500,
          }
        },
      }}
      onClick={onClick}
    >
      <Typography
        fontWeight={500}
        fontSize={'12px'}
        lineHeight={'16px'}
        color="#63EA71"
        fontFamily={'Inter'}
        textTransform="capitalize"
      >
        View Launch
      </Typography>
    </Button>
  )
}
