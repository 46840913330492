import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { NotiIconDefault, NotiActiveIcon } from '../../assets/index'
import { useNavigate } from 'react-router-dom'
import BalanceAndAddressBox from '../../components//BalanceAndAddressBox'
import { useAppDispatch, useAppSelector } from '../../hooks/'
import {
  setKycStatus,
  setMainAppToken,
  setWalletAddress,
} from '../../redux/slices/userSlice'
import GiftBox from '../../components/GiftBox'
import useDisconnectWalletAndProfile from '../../hooks/useDisconnectWalletAndEmail'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { useAccount, useSignTypedData } from 'wagmi'
import LoginModal from '../../components/LoginModal'
import ButtonLogin from '../Buttons/ButtonLogin'
import ButtonArcade from '../Buttons/ButtonArcade'
import NotificationHeader from '../../components/NotificationHeader'
import {
  INotificationHistoryResponse,
  useFetchNotificationHistoryAuthQuery,
  useFetchNotificationHistoryQuery,
} from '../../services/modules/notification'
import { useScreen } from '../../utils/useScreen'
import {
  getDiffNotification,
  showDiffNotification,
  storeOldNotifications,
  getOldNotifications,
  OLD_AUTH_NOTIFICATION_KEY,
  OLD_NOTIFICATION_KEY,
} from './helper'
import { getReadNotifications, KEY_READ_NOTIFICATIONS_AUTH, KEY_READ_NOTIFICATIONS_NO_AUTH } from '../../components/NotificationHeader/helper'

interface INavbar {
  isLoading: boolean
  giftBannerHeight: string
  isIncludeLoginModal?: boolean
}

const Navbar: React.FC<INavbar> = ({ isLoading, giftBannerHeight, isIncludeLoginModal = true }) => {
  const navigate = useNavigate()
  const { reset: resetData } = useSignTypedData()
  const [openSignautureModal, setOpenSignautureModal] = useState(false)
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const { md, lg } = useScreen()
  const { address, chain } = useAccount()

  //FIXME: checking first load notification
  const [isAllowShowing, setAllowShowing] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setAllowShowing(true)
    }, 30000);
  }, [])

  const user = useAppSelector(state => state.user)
  const { accessTokenGoogle } = user;
  const { disconnectWalletAndProfile } = useDisconnectWalletAndProfile()

  const dispatch = useAppDispatch()
  const handleProfileIcon = () => {
    if (user.isLoggedIn) disconnectWalletAndProfile()
    else navigate('/portfolio')
  }

  // Notification
  const isLogIn = (user.accessTokenGoogle && user.email) || (user.mainAppToken && user.emailRegisterUserAccount) || user.mainAppToken
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const [showNotifications, setShowNotifications] = useState(false)

  const { data: dataNoti, isLoading: loadingData, isFetching : isFetching } =
    useFetchNotificationHistoryQuery(
      {
        limit: itemsPerPage,
        page: currentPage,
      },
      {
        pollingInterval: 5000,
        skip: !!isLogIn,
      },
    )

  const {
    data: dataNotiAuth,
    refetch: refetchNotificationHistoryAuth,
    isLoading: loadingDataAuth,
    isFetching : isFetchingAuth,
  } = useFetchNotificationHistoryAuthQuery(
    {
      limit: itemsPerPage,
      page: currentPage,
    },
    {
      pollingInterval: 5000,
      skip: !isLogIn,
    },
  )

  const clientReadIds = getReadNotifications(isLogIn ? KEY_READ_NOTIFICATIONS_AUTH : KEY_READ_NOTIFICATIONS_NO_AUTH);
  const data = isLogIn ? dataNotiAuth : dataNoti

  const hasReadNotifications = data?.data.some(
    (notification: INotificationHistoryResponse) => !notification.isRead && !clientReadIds.includes(notification._id),
  )

  const [allNotifications, setAllNotifications] = useState<
    INotificationHistoryResponse[]
  >([])

  const handleRefetchhNotificationList = () => {
    refetchNotificationHistoryAuth();
    storeOldNotifications(dataNotiAuth?.data || [], OLD_AUTH_NOTIFICATION_KEY)
  }

  useEffect(() => {
    if ((address && user.walletAddress) || accessTokenGoogle) {
      storeOldNotifications(dataNotiAuth?.data || [], OLD_AUTH_NOTIFICATION_KEY)
    } else {
      storeOldNotifications(dataNoti?.data || [], OLD_NOTIFICATION_KEY)
    }

  },[loadingData, loadingDataAuth])
 
  // Handle manual push notification when FCM not granted
  useEffect(() => {
    
    if (!isAllowShowing) return;

    if ((!loadingData && isFetching) || (!loadingDataAuth && isFetchingAuth)) {
      let diffNotifications;

      if ((address && user.walletAddress) || accessTokenGoogle) {
        let oldAuth = getOldNotifications(OLD_AUTH_NOTIFICATION_KEY);
        if (oldAuth === null) {
          diffNotifications = [];
          storeOldNotifications((dataNotiAuth?.data || []), OLD_AUTH_NOTIFICATION_KEY)
        } else {
          let oldNoAuth = getOldNotifications(OLD_NOTIFICATION_KEY);
          let oldNotifications = oldAuth.concat(oldNoAuth || [])
          diffNotifications = getDiffNotification(dataNotiAuth?.data || [], oldNotifications)
          storeOldNotifications((dataNotiAuth?.data || []).concat(diffNotifications || []), OLD_AUTH_NOTIFICATION_KEY)
        }
      } else {
        let oldNotifications = getOldNotifications(OLD_NOTIFICATION_KEY);
        diffNotifications = getDiffNotification(dataNoti?.data || [], oldNotifications)
        storeOldNotifications(dataNoti?.data, OLD_NOTIFICATION_KEY)
      }

      if (diffNotifications.length) {
        showDiffNotification(diffNotifications)
      }
    }
  }, [loadingData, isFetching, loadingDataAuth, isFetchingAuth, isAllowShowing])

  useEffect(() => {
    if (!showNotifications) {
      setCurrentPage(1)
      setAllNotifications([])
    }
  }, [showNotifications])

  const handleToggleNotifications = () => {
    setShowNotifications(prevState => !prevState)
  }

  const notificationRef = useRef<HTMLElement | null>(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node)
      ) {
        setShowNotifications(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const elligibleForGiftCard = address

  useEffect(() => {
    if (!address) {
      setOpenSignautureModal(false)
      disconnectWalletAndProfile()
      dispatch(setWalletAddress(''))
      dispatch(setMainAppToken(''))
      resetData()
    }

    if (address && user.walletAddress === '') {
      dispatch(setWalletAddress(address))

      setOpenSignautureModal(true)
    }

    if (
      address &&
      user.walletAddress !== '' &&
      address !== user.walletAddress
    ) {
      dispatch(setWalletAddress(address))
      dispatch(setKycStatus('rejected'))

      setOpenSignautureModal(true)
    }
  }, [address, chain, user.walletAddress])

  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [isSticky, setSticky] = useState(false)

  const handleScroll = () => {
    const currentScrollPos = window.scrollY
    setSticky(currentScrollPos > 0)
    setPrevScrollPos(currentScrollPos)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos])

  const bellIcon = () => {
    if (isLogIn) {
      return hasReadNotifications
        ? <img src={NotiActiveIcon} width={32} height={32} alt="NotiIconActive" />
        : <img src={NotiIconDefault} width={32} height={32} alt="NotiIconDefault" />
    }

    if (data && data?.data && data?.data.length && !loadingData) {
      return hasReadNotifications
        ? <img src={NotiActiveIcon} width={32} height={32} alt="NotiActiveIcon" />
        : <img src={NotiIconDefault} width={32} height={32} alt="NotiIconDefault" />
    }

    return <img src={NotiIconDefault} width={32} height={32} alt="NotiIconDefault" />
  }

  return (
    <>
      <Box
        className="navbar-container"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Box
          className="navbar-container--giftcard"
          display={'flex'}
          flexDirection={'column'}
          alignItems={'start'}
          sx={{
            cursor: 'pointer',
            float: 'left',
            flex: address || elligibleForGiftCard ? 1 : 0,
          }}
        ></Box>
        <Box
          className="navbar-container__actions"
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap-reverse',
            gap: '20px',
          }}
        >
          <Box
            sx={{
              maxHeight: '40px',
              gap: '8px',
              display:
                address || elligibleForGiftCard || user.email ? 'flex' : 'none',
              float: 'right',
              flex: address && elligibleForGiftCard && user.email ? 1 : '',
              justifyContent: 'right',
            }}
          >
            {elligibleForGiftCard && <GiftBox mobile={false} />}
            {(address || user.email || user.emailRegisterUserAccount) && (
              <BalanceAndAddressBox />
            )}

            {(address || user.email || user.emailRegisterUserAccount) && (
              <Box
                sx={{
                  backgroundColor: 'rgba(15, 17, 19, 1)',
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                  textAlign: 'center',
                }}
              >
                <div
                  onClick={handleProfileIcon}
                  style={{
                    borderRadius: user.isLoggedIn ? '15px' : '0px',
                    cursor: 'pointer',
                    marginTop: '8px',
                  }}
                >
                  <Jazzicon
                    diameter={24}
                    seed={jsNumberForAddress(
                      address || user.email || user.emailRegisterUserAccount,
                    )}
                  />
                </div>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '16px',
            }}
          >
            <ButtonLogin onClick={() => setOpenLoginModal(true)} />
            <Box
              onClick={handleToggleNotifications}
              ref={notificationRef}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              sx={{
                cursor: 'pointer',
                position: md ? 'relative' : '',
              }}
            >
              {bellIcon()}
              {showNotifications && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '48px',
                    transform: 'translateX(-50%)',
                    zIndex: 999,
                  }}
                >
                  <NotificationHeader
                    loadingData={loadingData}
                    loadingDataAuth={loadingDataAuth}
                    data={data}
                    currentPage={currentPage}
                    refetchNotificationHistoryAuth={
                      handleRefetchhNotificationList
                    }
                    setAllNotifications={setAllNotifications}
                    allNotifications={allNotifications}
                    setCurrentPage={setCurrentPage}
                    showNotifications={showNotifications}
                    setShowNotifications={setShowNotifications}
                  />
                </Box>
              )}
            </Box>
            {/* <ButtonArcade /> */}
          </Box>
        </Box>
      </Box>

      {
        isIncludeLoginModal && <LoginModal
          open={openLoginModal}
          onClose={() => setOpenLoginModal(false)}
          openSignautureModal={openSignautureModal}
          setOpenSignautureModal={setOpenSignautureModal}
        />
      }
    </>
  )
}
export default Navbar
