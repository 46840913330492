import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import {
  StepArrow,
  StepArrowDown,
  StepArrowSmall,
  ParticipateStepOne,
  ParticipateStepTwo,
  ParticipateStepThree,
  ParticipateStepFour,
  ReferencesBg,
} from '../../assets'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import { useWalletInfo, useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount, useBalance } from 'wagmi'
import { useFetchAllLaunchesQuery } from '../../services/modules/project'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import KYCButton from '../../components/KYCBox/kycButton'
import { useAnalytics } from '../../AmplitudeContext'
import { useCode } from '../../hooks/useReferralCode'
import { useParticipationEntryPoint } from '../../Context'

interface ParticipationBoxProps {
  id: number
  heading: string
  paragraph: string
  buttonText?: string
  onClick?: () => void
}

interface StyledBoxProps {
  boxId: number
}

const headingColors = ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']

// const CircleContainer = styled('div')(({ theme }) => ({
//   width: 64,
//   height: 64,
//   background: '#0F1113',
//   boxShadow:
//     '0px 0.5px 0px rgba(255, 255, 255, 0.15), inset 0px 0.5px 0px rgba(255, 255, 255, 0.16)',
//   borderRadius: '50%',
//   display: 'flex',
//   position: 'absolute',
//   justifyContent: 'center',
//   alignItems: 'center',
//   textAlign: 'center',
//   [theme.breakpoints.down('lg')]: {
//     position: 'relative',
//     width: 48,
//     height: 48,
//   },
// }))

// const NumberInsideCircle = styled(Typography)(({ theme }) => ({
//   fontFamily: 'Hellix',
//   fontWeight: 600,
//   fontSize: '28px',
//   lineHeight: '120%',
//   letterSpacing: '-0.01em',
//   color: '#FFFFFF',
//   [theme.breakpoints.down('lg')]: {
//     fontSize: '24px',
//   },
// }))

const ParticipationBoxContainer = styled('div')<StyledBoxProps>(
  ({ boxId, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '300px',
    height: '100%',
    background: '#0F1113',
    borderRadius: '16px',
    padding: '24px',
    margin: '0.5px',
    width: `calc(100% - 1px)`,
    position: 'relative',
    transition: 'all 0.3s ease',
    border: '1px solid rgba(0,0,0,0.2)',
    '&:hover': {
      boxShadow:
        boxId === 1
          ? 'inset 35px 30px 120px rgba(244, 253, 171, 0.05)'
          : boxId === 2
          ? 'inset 35px 30px 120px rgba(255, 177, 82, 0.05)'
          : boxId === 3
          ? 'inset 35px 30px 120px rgba(255, 119, 34, 0.05)'
          : 'none',
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
      gap: '18px',
      padding: '16px',
      alignItems: 'stretch',
      minHeight: 0,
      height: '100%',
      flexGrow: 1,
      overflow: 'hidden',
    },
  }),
)

const ParticipationBoxImage = styled('img')({
  width: '50%',
  height: '20px',
  marginLeft: 'auto',
})

const StyledValueText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Hellix',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '150%',
  letterSpacing: '-1%',
  fontSize: '24px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '20px',
  },
}))

const StyledDescriptionText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '22.5px',
  letterSpacing: '-1%',
  color: '#7E8180',
  marginTop: '8px',
  marginBottom: '36px',
  [theme.breakpoints.down('lg')]: {
    marginBottom: '15px',
  },
}))

const StyledBox = styled(Box)(({ theme }) => ({
  background:
    'linear-gradient(142.67deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.09) 51.04%, rgba(255, 255, 255, 0.16) 100%)',
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'center',
}))

const stepsImage = [
  ParticipateStepOne,
  ParticipateStepTwo,
  ParticipateStepThree,
  ParticipateStepFour,
]

const VerticalParticipationBox: React.FC<ParticipationBoxProps> = ({
  id,
  heading,
  paragraph,
  buttonText,
  onClick,
}) => {
  const { open } = useWeb3Modal()
  const { address } = useAccount()
  const { data: ethBalanceData } = useBalance({
    address,
  })
  const { walletInfo } = useWalletInfo()
  const prevWalletInfoPSRef = useRef(walletInfo)
  const { trackEvent, updateUserProperties } = useAnalytics()
  const code = useCode()

  const userKycStatus = useAppSelector(state => state.user.kycStatus)

  return (
    <StyledBox>
      <ParticipationBoxContainer boxId={id}>
        <Box
          alignItems="center"
          height={'auto'}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flexGrow={1}
        >
          {/* <CircleContainer>
            <NumberInsideCircle>{id}</NumberInsideCircle>
          </CircleContainer> */}
          <img
            src={stepsImage[id - 1]}
            alt={`Participation Box ${id}`}
            style={{ width: '48px', height: '48px' }}
          />
          <img
            // src={id === 3 ? StepArrowSmall : StepArrowDown}
            src={StepArrowDown}
            alt={`Participation Box ${id}`}
            style={{
              display: id !== 4 ? 'flex' : 'none',
              width: '20px',
              marginTop: 'auto',
            }}
          />
        </Box>

        <Box
          width={'100%'}
          height={'auto'}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Box>
            <StyledValueText color={headingColors[id - 1]}>
              {heading}
            </StyledValueText>
            <StyledDescriptionText>{paragraph}</StyledDescriptionText>
          </Box>

          <Box
            sx={{
              width: '100%',
            }}
          >
            {!address && id !== 1 ? (
              <></>
            ) : buttonText && id === 3 && userKycStatus !== 'approved' ? (
              <KYCButton component="homepage" />
            ) : (
              buttonText && (
                <OutlinedButton
                  sx={{
                    height: '43px',
                    padding: '10px 24px',
                  }}
                  onClick={() =>
                    id === 2 ? onClick && onClick() : id === 1 ? open() : null
                  }
                  disabled={id === 1 && address ? true : false}
                >
                  {id === 3 && userKycStatus === 'approved'
                    ? 'Completed'
                    : id === 1 && address
                    ? 'Connected'
                    : buttonText}
                </OutlinedButton>
              )
            )}
          </Box>
        </Box>
      </ParticipationBoxContainer>
    </StyledBox>
  )
}

const ParticipationBox: React.FC<ParticipationBoxProps> = ({
  id,
  heading,
  paragraph,
  buttonText,
  onClick,
}) => {
  const { address } = useAccount()
  const { open } = useWeb3Modal()
  const { setParticipationEntryPoint } = useParticipationEntryPoint()
  const handleClick = async () => {
    if (id === 2) {
      onClick && onClick()
    } else if (id === 1) {
      await open()
      setParticipationEntryPoint('StepsSection')
    }
  }

  const userKycStatus = useAppSelector(state => state.user.kycStatus)
  return (
    <StyledBox>
      <ParticipationBoxContainer boxId={id}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          width="100%"
          // marginTop={'24px'}
          minHeight={'24px'}
        >
          {/* Circle Container */}
          {/* <CircleContainer>
            <NumberInsideCircle>{id}</NumberInsideCircle>
          </CircleContainer>
          */}

          <img
            src={stepsImage[id - 1]}
            alt={`Participation Box ${id}`}
            style={{ width: '48px', height: '48px' }}
          />

          <ParticipationBoxImage
            src={StepArrow}
            alt={`Participation Box ${id}`}
            style={{
              display: id !== 4 ? 'block' : 'none',
            }}
          />
        </Box>

        <Box width={'100%'}>
          {/* Heading and Description */}
          <Box marginTop={'32px'}>
            <StyledValueText color={headingColors[id - 1]}>
              {heading}
            </StyledValueText>
            <StyledDescriptionText>{paragraph}</StyledDescriptionText>
          </Box>

          {/* Button */}
          <Box
            sx={{
              position: 'absolute',
              bottom: 24,
              width: 'calc(100% - 48px)',
            }}
          >
            {!address && id !== 1 ? (
              <></>
            ) : buttonText && id === 3 && userKycStatus !== 'approved' ? (
              <KYCButton component="homepage" />
            ) : (
              buttonText && (
                <OutlinedButton
                  sx={{
                    height: '43px',
                    padding: '10px 24px',
                  }}
                  onClick={() => handleClick()}
                  // onClick={() =>
                  //   id === 2 ? onClick && onClick() : id === 1 ? handleWalletConnected() : null
                  // }
                  disabled={id === 1 && address ? true : false}
                >
                  {id === 3 && userKycStatus === 'approved'
                    ? 'Completed'
                    : id === 1 && address
                    ? 'Connected'
                    : buttonText}
                </OutlinedButton>
              )
            )}
          </Box>
        </Box>
      </ParticipationBoxContainer>
    </StyledBox>
  )
}

const ParticipationSection: React.FC = () => {
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown1200 = useMediaQuery('(max-width:1200px)')

  const navigate = useNavigate()
  const { setProjectLinkSource } = useParticipationEntryPoint()

  const {
    data: allLaunchesData,
    isLoading: allLaunchesLoading,
    isError: allLaunchesError,
  } = useFetchAllLaunchesQuery()

  const participationBoxes = [
    {
      id: 1,
      heading: 'Connect wallet ',
      paragraph: 'Connect your favorite crypto wallet.',
      buttonText: 'Connect',
    },
    {
      id: 2,
      heading: 'Choose IDO',
      paragraph:
        'Join your first sale and be eligible for a chance to win $25 in free IDO tokens.',
      buttonText: 'Participate',
    },
    {
      id: 3,
      heading: 'Complete KYC',
      paragraph: 'You only need to KYC when you win.',
      buttonText: 'Complete now',
    },
    {
      id: 4,
      heading: 'Join IDO',
      paragraph:
        'Pay in crypto, contribute to your winning sale, claim your tokens upon official listing.',
    },
  ]

  const onParticipateClick = () => {
    let featuredLaunch = allLaunchesData?.filter(
      launch => launch.type == 'featured',
    )[0]
    // setProjectLinkSource('Featured')
    navigate(`/project/${featuredLaunch?.routeName}`)
  }

  return (
    <Grid container spacing={isDown767 ? 1 : 2} pb={isDown1200 ? '32px' : '120px'}>
      <Grid item xs={12} textAlign={'center'} justifyContent={'center'} display={'flex'}>
        <Box className="completed-sales--title" >
          <Typography
            fontWeight={600}
            fontSize={isDown767 ? 32 : 32}
            lineHeight={isDown767 ? '32px' : '48px'}
            letterSpacing={'0px'}
            fontFamily={'Hellix'}
            color="#FFFFFF"
            mb={1}
          >
            Don’t Miss Out on
            <br />
            Your Gains!
          </Typography>
          <Typography
            fontFamily={'Inter'}
            color="#767676"
            fontSize={isDown767 ? 17 : 18}
            lineHeight={'27px'}
            fontWeight={400}
            p={'0 80px 24px'}
          >
            <span>Join the same pre-sales as the biggest names in crypto.</span>
          </Typography>
        </Box>
      </Grid>

      {participationBoxes.map((box: ParticipationBoxProps, index: number) => (
        <React.Fragment key={index}>
          {isDown1200 ? (
            <Grid key={box.id} item xs={12} sm={12} smd={12} md={12}>
              <VerticalParticipationBox {...box} onClick={onParticipateClick} />
            </Grid>
          ) : (
            <Grid key={box.id} item xs={12} sm={12} smd={12} md={3}>
              <ParticipationBox {...box} onClick={onParticipateClick} />
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  )
}

export default ParticipationSection
