import { Box, useMediaQuery } from '@mui/material'
import { useState } from 'react'
import chevronIcon from '../../assets/revamp/icons/chevron.svg'
import globalIcon from '../../assets/revamp/icons/global.svg'
import searchIcon from '../../assets/revamp/icons/search.svg'
import Icon from '../../components/common/Icon'
import WalletTrackingItem from './WalletTrackingItem'

interface PageHeaderProps {
  onOpenNetwork: () => void
  // onBundled: (bundled: any) => void
}

const PageHeader: React.FC<PageHeaderProps> = props => {
  const { onOpenNetwork } = props

  const isDown800 = useMediaQuery('(max-width:800px)')
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false)

  const handleClickSearchIcon = () => {
    if (isDown800) {
      setSearchOpen(!isSearchOpen) // Toggle search bar on mobile
    }
  }

  return (
    <>
      <section className="page-title">
        <div className="page-title-wrapper">
          <h1
            className="h2"
            style={{
              marginTop: isDown800 ? '-10px' : '0',
            }}
          >
            Overview
          </h1>
          <div className="page-title--form">
            {isDown800 ? (
              <Box
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <Box
                  sx={{
                    cursor: 'pointer',
                    height: '24px',
                    width: '24px',
                  }}
                  onClick={onOpenNetwork}
                >
                  <Icon src={globalIcon} width={24} height={24} />
                </Box>
                <Box
                  sx={{
                    cursor: 'pointer',
                    height: '24px',
                    width: '24px',
                  }}
                  onClick={handleClickSearchIcon}
                >
                  <Icon src={searchIcon} alt="Search" width={24} height={24} />
                </Box>
              </Box>
            ) : (
              <div
                className="page-title--form--network"
                onClick={onOpenNetwork}
              >
                <Icon src={globalIcon} width={24} />
                <b>Networks</b>
                <Icon src={chevronIcon} width={16} />
              </div>
            )}
            {!isDown800 && <WalletTrackingItem />}
          </div>
        </div>
        {isSearchOpen && isDown800 && (
          <div
            className="page-title-wrapper"
            style={{
              width: '100%',
            }}
          >
            <div
              className="page-title--form"
              style={{
                width: '100%',
              }}
            >
              <WalletTrackingItem
                defaultInputClzName="ant-input-affix-wrapper"
                wrapperStyles={{
                  width: '100%',
                }}
                inputStyles={{
                  width: '100%',
                  minWidth: 'unset',
                }}
              />
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default PageHeader
