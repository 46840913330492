import dayjs from "dayjs";
import { IResponseUserPortalItem } from "../../../../services/modules/participationsV2";
import { ADMIN_DEPOSIT_EVENT_NAME, CLAIM_EVENT_NAME, ClaimStatus, IDOStatus } from "./LabelConstant";
import BigNumber from "bignumber.js";

export const computeClaimedAmount = (sale: IResponseUserPortalItem) => {
  if (!sale.claimedInformation || !sale.claimedInformation.length) return 0;

  let claimedFilter = sale.claimedInformation.map(claim => claim.eventName === CLAIM_EVENT_NAME.CLAIMED ? claim.amount : '0').filter(amount => amount !== '0');
  if (!claimedFilter.length) return 0;

  const total: BigNumber = claimedFilter.reduce((acc: BigNumber, num: any) => acc.plus(new BigNumber(num)), new BigNumber(0));

  return Number(total.toString());
}

export const computeTotalTokens = (sale: IResponseUserPortalItem) => {
  return Number(sale.idoVestingAmount || 0) + Number(sale.idoTgeAmount || 0);
}

export const computeClaimableAmount = (sale: IResponseUserPortalItem) => {
  const now = dayjs().unix() * 1000
  const claimedAmount = computeClaimedAmount(sale)
  const tgeAmount = Number(sale.idoTgeAmount)
  const vestingAmount = Number(sale.idoVestingAmount)
  const idoCliffDuration = sale.idoCliffDuration * 1000 // ms
  const allocate = vestingAmount * (now - (sale.idoTgeDate + idoCliffDuration))
  const duration = sale.idoVestingDuration - sale.idoTgeDate - idoCliffDuration

  if (now < sale.idoTgeDate) {
    return 0;
  }

  if (now <= sale.idoTgeDate + idoCliffDuration) {
    return tgeAmount - claimedAmount;
  }

  let totalAmount: any = tgeAmount + (allocate / duration);
  if (totalAmount > vestingAmount + tgeAmount) {
    totalAmount = vestingAmount + tgeAmount;
  }

  const totalAmountBN = new BigNumber(totalAmount)

  return totalAmountBN.minus(new BigNumber(claimedAmount)).toNumber();
}

export const computeDistribution = (sale: IResponseUserPortalItem) => {
  const claimedAmount = BigNumber(computeClaimedAmount(sale) || 0)

  return claimedAmount.plus(computeClaimableAmount(sale)).toNumber()
}

export const computeTicketSize = (sale: IResponseUserPortalItem) => {
  if (!sale.idoTotalWinner) return '$0';
  let ticketSize = sale.idoAmountToRaise / sale.idoTotalWinner;

  return ticketSize && ticketSize > 0 ? '$' + Number(ticketSize.toFixed(2)).toLocaleString('en') : '$0'
}

export const isRefunded = (sale: IResponseUserPortalItem) => {
  if (!sale.claimedInformation || !sale.claimedInformation.length) return false;

  let refundedAtFilter = sale.claimedInformation.filter(claim => claim.eventName === CLAIM_EVENT_NAME.REFUNDED)

  return refundedAtFilter.length
}

export const isRequestRefund = (sale: IResponseUserPortalItem) => {
  if (!sale.claimedInformation || !sale.claimedInformation.length) return false;

  let requestRefundFilter = sale.claimedInformation.filter(claim => claim.eventName === CLAIM_EVENT_NAME.REQUEST_REFUND)

  return requestRefundFilter.length
}

export const isAdminDepositRefund = (sale: IResponseUserPortalItem) => {
  if (!sale.adminDepositEvents || !sale.adminDepositEvents.length) return false

  const adminDepositRefundFilter = sale.adminDepositEvents.filter(event => event.eventName === ADMIN_DEPOSIT_EVENT_NAME.REFUND_DEPOSITED);

  return adminDepositRefundFilter.length
}

export const isAdminDepositVesting = (sale: IResponseUserPortalItem) => {
  if (!sale.adminDepositEvents || !sale.adminDepositEvents.length) return false

  const adminDepositVestingFilter = sale.adminDepositEvents.filter(event => event.eventName === ADMIN_DEPOSIT_EVENT_NAME.VESTING_DEPOSITED);

  return adminDepositVestingFilter.length
}

export const getRefundedAt = (sale: IResponseUserPortalItem) => {
  if (!sale.claimedInformation || !sale.claimedInformation.length) return 0

  let refundedFilter = sale.claimedInformation.filter(
    claim => claim.eventName === CLAIM_EVENT_NAME.REFUNDED,
  )

  if (!refundedFilter.length) return 0

  return dayjs(refundedFilter[0].time).unix()
}

export const getClaimStatus = (sale: IResponseUserPortalItem) => {
  let now = dayjs().unix() * 1000;

  if (!sale || !sale.isInvestor)
    return sale.idoStatus

  if (isRefunded(sale))
    return ClaimStatus.REFUNDED

  if (isRequestRefund(sale))
    return ClaimStatus.REQUEST_REFUND

  if (!sale.adminDepositEvents || !sale.adminDepositEvents.length || now < sale.idoTgeDate)
    return ClaimStatus.PRE_TGE

  let remainingAmount = computeTotalTokens(sale) - computeClaimedAmount(sale);
  if (remainingAmount === 0)
    return ClaimStatus.CLAIMED

  if ((sale.idoTgeDate < now && now < sale.idoVestingDuration) || remainingAmount != 0)
    return ClaimStatus.DISTRIBUTING
  
  return sale.idoStatus || ''
}

export const getUserStatus = (sale: IResponseUserPortalItem, isSaleTable = false) => {
  const status = {
    registered: { label: 'Registered', style: 'registered' },
    won: { label: 'Won', style: 'won' },
    lost: { label: 'Lost', style: 'lost' },
    invested: { label: 'Invested', style: 'invested' },
    claimable: { label: 'Claimable', style: 'claimable' },
    claimed: { label: 'Claimed', style: 'claimed' },
    refundRequested: { label: 'Refund Requested', style: 'refundRequested' },
    refunded: { label: 'Refunded', style: 'refunded' },
    cancelled: { label: 'Project cancelled', style: 'cancelled' },
    notParticipated: { label: 'No participation', style: 'notParticipated' },
  }

  //FIXME: should have participationInfo history API
  if (isSaleTable && (!sale.participationInfo || !sale.participationInfo.length)) {
    return status.notParticipated
  }

  //FIXME: should have participationInfo history API
  const isWinner = sale.isWinner !== undefined ? sale.isWinner : sale?.participationInfo[0]?.isWinner
  const isInvestor = sale.isInvestor !== undefined ? sale.isInvestor : sale?.participationInfo[0]?.isInvestor

  // After user registered
  if (sale.idoStatus === IDOStatus.PARTICIPATION) {
    return status.registered
  }

  // When project is investment
  if (sale.idoStatus === IDOStatus.PROCESSING && sale.idoSnapshotDoneAt) {
    return isWinner ? status.won : status.lost
  }

  // When project is investment
  if (sale.idoStatus === IDOStatus.INVESTMENT) {
    // If user is isInvestor
    if (isInvestor) return status.invested

    return isWinner ? status.won : status.lost
  }

  // When project is completed
  const refundedAt = getRefundedAt(sale)
  if (sale.idoStatus === IDOStatus.COMPLETED) {
    if (refundedAt && refundedAt > 0) return status.refunded

    if (isInvestor) return status.invested

    return isWinner ? status.won : status.lost
  }

  // When project is cancelled
  if (sale.idoStatus === IDOStatus.CANCELLED) {
    return status.cancelled
  }

  // When project is TGE
  if (sale.idoStatus === IDOStatus.TGE) {
    if (refundedAt && refundedAt > 0) {
      return status.refunded
    }

    let isRefundDeposited = isAdminDepositRefund(sale)
    const isRequested = isRequestRefund(sale)
    if (isRequested && !isRefundDeposited) {
      return status.refundRequested
    }

    // If user has claimed the reward
    const claimed = computeClaimedAmount(sale)
    const totalTokens = computeTotalTokens(sale)
    if (claimed && claimed > 0 && claimed === totalTokens) {
      return status.claimed
    }

    // If user still have reward
    let investEndDate = dayjs().isAfter(sale.idoInvestmentEndDate)
    let isVestingDeposited = isAdminDepositVesting(sale)
    const claimable = computeClaimableAmount(sale)
    if (investEndDate && isInvestor && claimable > 0 && !isRequested && isVestingDeposited) {
      return status.claimable
    }

    // If user still have reward
    if (investEndDate && isInvestor && claimable > 0 && isRequested && isRefundDeposited) {
      return status.claimable
    }

    // If user isInvestor and reward is calculating
    if (isInvestor) return status.invested

    // If user not isInvestor
    if (sale.idoSnapshotDoneAt) {
      return isWinner ? status.won : status.lost
    }

    // If user not isInvestor
    return isWinner ? status.won : status.lost
  }

  return status.registered
}


export const getStatusStyle = (status: string) => {
  const styles: Record<string, { backgroundColor: string; color: string }> = {
    won: {
      backgroundColor: 'rgba(99, 234, 113, 0.1)',
      color: '#63EA71',
    },
    invested: {
      backgroundColor: 'rgba(255, 119, 34, 0.1)',
      color: '#FF7722',
    },
    registered: {
      backgroundColor: 'rgba(244, 253, 171, 0.1)',
      color: '#F4FDAB',
    },
    claimed: {
      backgroundColor: 'rgba(99, 234, 113, 0.1)',
      color: '#63EA71',
    },
    refunded: {
      backgroundColor: 'rgba(29, 31, 33, 1)',
      color: '#B1B4B3',
    },
    refundRequested: {
      backgroundColor: 'rgba(29, 31, 33, 1)',
      color: '#B1B4B3',
    },
    claimable: {
      backgroundColor: 'rgba(29, 31, 33, 1)',
      color: '#B1B4B3',
    },
    lost: {
      backgroundColor: 'rgba(29, 31, 33, 1)',
      color: '#FF6868',
    },
    snapshot: {
      backgroundColor: 'rgba(244, 253, 171, 0.1)',
      color: '#F4FDAB',
    },
    live: {
      backgroundColor: 'rgba(29, 31, 33, 1)',
      color: '#63EA71',
    },
    ended: {
      backgroundColor: 'rgba(29, 31, 33, 1)',
      color: '#B1B4B3',
    },
    cancelled: {
      backgroundColor: 'rgba(29, 31, 33, 1)',
      color: '#B1B4B3',
    },
    notParticipated: {
      backgroundColor: 'rgba(29, 31, 33, 1)',
      color: '#B1B4B3',
    },
  }

  // Return the style for the given status or the default style
  return (
    styles[status] || {
      backgroundColor: 'rgba(29, 31, 33, 1)',
      color: '#B1B4B3',
    }
  )
}
