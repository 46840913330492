import React from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import { IFilterStatus, ClaimStatus } from './components/LabelConstant'

interface FilterStatusMySalesProps {
  status: IFilterStatus
  onChange: (status: IFilterStatus) => void
}

export const getStatusStyleFilter = (status: string) => {
  switch (status) {
    case IFilterStatus.TGE:
    case ClaimStatus.PRE_TGE:
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#63EA71',
      }
    case IFilterStatus.COMPLETED:
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#63EA71',
      }
    case IFilterStatus.INVESTMENT:
      return {
        backgroundColor: 'rgba(99, 234, 113, 0.1)',
        color: '#fe7f30',
      }
    case IFilterStatus.PARTICIPATION:
    case ClaimStatus.DISTRIBUTING:
      return {
        backgroundColor: 'rgba(244, 253, 171, 0.1)',
        color: '#F4FDAB',
      }
    case IFilterStatus.ALL:
    case ClaimStatus.CLAIMED:
    case ClaimStatus.REFUNDED:
      return {
        backgroundColor: 'rgba(99, 234, 113, 0.1)',
        color: '#B1B4B3',
      }
    case ClaimStatus.REQUEST_REFUND:
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#B1B4B3',
      }
    case IFilterStatus.CANCELLED:
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#dc2626',
      }
    default:
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#B1B4B3',
      }
  }
}

export const getStatusStyleFilterHeader = (status: string) => {
  switch (status) {
    case IFilterStatus.CLAIM:
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#63EA71',
      }
    case IFilterStatus.SALES:
      return {
        backgroundColor: 'rgba(99, 234, 113, 0.1)',
        color: '#fff',
      }
    case IFilterStatus.HISTORY:
      return {
        backgroundColor: 'rgba(244, 253, 171, 0.1)',
        color: '#F4FDAB',
      }
    default:
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#B1B4B3',
      }
  }
}

const FilterStatusMySales: React.FC<FilterStatusMySalesProps> = ({
  status,
  onChange,
}) => {
  const activeTabBorder = '0.4px solid rgba(255, 255, 255, 0.16)'
  const isDown800 = useMediaQuery('(max-width:800px)')

  const statusArray = [
    IFilterStatus.SALES,
    IFilterStatus.CLAIM,
    IFilterStatus.HISTORY,
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        width: '100%',
      }}
    >
      <Typography sx={{ width: 'max-content' }}> Project Status</Typography>
      <Grid
        container
        borderRadius={'25px'}
        bgcolor={'#1c232a'}
        maxWidth={'565px'}
        width={'100%'}
        flex={1}
        item
        xs={5}
        justifyContent={isDown800 ? 'start' : 'center'}
        alignItems={isDown800 ? 'start' : 'center'}
        display={'flex'}
        paddingX={'10px'}
        paddingY={isDown800 ? '8px' : '8px'}
        marginBottom={'20px'}
        className="participation-type-tabs"
      >
        {statusArray.map((item, index) => (
          <Grid
            item
            xs={4}
            key={index}
            display={'flex'}
            width={isDown800 ? 'max-content' : '110px'}
            height={'28px'}
            padding={isDown800 ? '3px 20px' : ''}
            justifyContent={'center'}
            alignItems={'center'}
            border={status === item ? activeTabBorder : 'none'}
            onClick={() => {
              onChange(item)
            }}
            sx={{
              cursor: 'pointer',
              borderRadius: '25px',
              ...(status === item
                ? getStatusStyleFilterHeader(item)
                : {
                    color: '#B1B4B3',
                  }),
            }}
          >
            <Typography
              fontSize={'12px'}
              fontWeight={500}
              lineHeight={'22.5px'}
              fontFamily={'Inter'}
              sx={{
                color:
                  status === item
                    ? getStatusStyleFilterHeader(item).color
                    : '#B1B4B3',
              }}
            >
              {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default FilterStatusMySales
