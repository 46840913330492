import { useCallback, useEffect, useRef, useState } from 'react'
import {
  StyledHeadTableCell,
  FixedHeadTableCell,
} from './components/TableStyled'
import { Box, useMediaQuery } from '@mui/system'
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import OutlinedButton from '../../../components/Buttons/OutlinedButton'
import {
  IResponseUserPortalItem,
  IResponseUserPortalMeta,
} from '../../../services/modules/participationsV2'
import ClaimTableRow from './components/ClaimTableRow'
import ClaimSuccessModal from '../../../components/ClaimSuccessModal'
import RequestRefundSuccessModal from '../../../components/RequestRefundSuccessModal'
import { GreenTick } from '../../../assets'
import LoadMoreButton from './components/LoadMoreButton'

const ClaimTable = ({ claimSales, meta, isShowButton, isFetching, handleShowMore, refetchCurrentData }: {
  isFetching: boolean
  isShowButton: boolean
  meta: IResponseUserPortalMeta | undefined
  claimSales: IResponseUserPortalItem[]
  handleShowMore: (isCollapes: boolean) => void
  refetchCurrentData: () => void
}) => {
  const isDown800 = useMediaQuery('(max-width:800px)')
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [openClaimSuccessModal, setOpenClaimSuccessModal] = useState(false)
  const [onRefundSuccess, setOpenRefundSuccess] = useState(false)
  
  const scrollToBottom = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop =
        tableContainerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [claimSales])

  const handleShowMoreWithScroll = (isCollapes = false) => {
    handleShowMore(isCollapes)
  }

  const onHandleRefundSuccess = useCallback(
    async (isSuccess: boolean | undefined) => {
      setOpenClaimSuccessModal(!!isSuccess)
      refetchCurrentData()
    },
    [refetchCurrentData]
  );

  const onHandleRequestRefund = useCallback((isSuccess: boolean | undefined) => {
    setOpenRefundSuccess(!!isSuccess)
    refetchCurrentData()
  }, [refetchCurrentData])

  const onHandleClaimSuccess = useCallback((isSuccess: boolean | undefined) => {
    setOpenClaimSuccessModal(!!isSuccess)
    refetchCurrentData()
  }, [refetchCurrentData]);

  return (
    <>
      <Box width={'100%'} mb={'60px'}>
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          paddingLeft={'0px'}
          paddingTop={'0px'}
        >
          <TableContainer
            component={Paper}
            ref={tableContainerRef}
            sx={{
              height: '100%',
              // maxHeight: '414px',
              border: '1px solid #1D1F21',
              boxShadow: 'none',
              backgroundImage: 'none',
              flexShrink: 1,
              width: '100%',
              borderRadius: '16px',
              '&::-webkit-scrollbar': {
                display: 'flex',
                height: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#313335',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#0F1113',
              },
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                // minHeight: claimSales.length <= 5 ? '100%' : '408px',
                height: '100%',
                flexShrink: 0,
              }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    textWrap: 'nowrap',
                  }}
                >
                  {isDown800 ? (
                    <>
                      <FixedHeadTableCell>Project</FixedHeadTableCell>
                      <StyledHeadTableCell>Project Name</StyledHeadTableCell>
                    </>
                  ) : (
                    <StyledHeadTableCell>Project</StyledHeadTableCell>
                  )}
                  {/* <StyledHeadTableCell>Next Unlock</StyledHeadTableCell> */}
                  <StyledHeadTableCell>Chain</StyledHeadTableCell>
                  <StyledHeadTableCell>Status</StyledHeadTableCell>
                  <StyledHeadTableCell>Ticket Size</StyledHeadTableCell>
                  {/* <StyledHeadTableCell>Allocation</StyledHeadTableCell>
                  <StyledHeadTableCell>Distribution</StyledHeadTableCell> */}
                  <StyledHeadTableCell>Claimable</StyledHeadTableCell>
                  <StyledHeadTableCell>Claimed</StyledHeadTableCell> 
                  <StyledHeadTableCell>Action</StyledHeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody
                style={{
                  // overflowY: 'hidden',
                  overflowX: 'auto',
                  backgroundColor: '#0F1113',
                }}
              >
                {claimSales.map((sale: IResponseUserPortalItem) => (
                  <ClaimTableRow
                    key={sale.id}
                    sale={sale}
                    onClaimSuccess={onHandleClaimSuccess}
                    onRequestRefund={onHandleRequestRefund}
                    onRefundSuccess={onHandleRefundSuccess}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {!isShowButton && Number(meta?.total_records) > 5 && (
          <Box mt={2} textAlign="center">
            {Number(meta?.total_records) > claimSales.length && (
              <LoadMoreButton isLoading={isFetching} disabled={isFetching} onClick={() => handleShowMoreWithScroll()} />
            )}
            {claimSales.length > 5 && (
              <OutlinedButton
                onClick={() => handleShowMoreWithScroll(true)}
                sx={{ height: '48px', width: '100%', marginTop: '12px' }}
              >
                Collapse
              </OutlinedButton>
            )}
          </Box>
        )}
      </Box>
      <ClaimSuccessModal
        open={openClaimSuccessModal}
        handleClose={() => setOpenClaimSuccessModal(false)}
        title="Claimed Successfully"
        subtext="Your tokens have been claimed successfully!"
        imageSrc={GreenTick}
      />
      <RequestRefundSuccessModal
        open={onRefundSuccess}
        handleClose={() => setOpenRefundSuccess(false)}
        title="Request Successfully"
        subtext="Your request refund has been sent successfully!"
        imageSrc={GreenTick}
      />
    </>
  )
}

export default ClaimTable
