import { Button, CircularProgress, Typography } from '@mui/material'
import { GreyFilledArrow } from '../../assets'

const GreenButton = ({
  mt = 0,
  text,
  content,
  Icon,
  mb = 0,
  h,
  w,
  fs,
  fw,
  lh,
  br,
  onClick,
  sx,
  disabled = false,
  IconStart,
  fm,
  outlined,
  mr,
  p,
  border,
  id,
  isLoading = false,
  DisabledIcon,
  textColor,
}: {
  mt?: number
  mb?: number
  text?: string
  content?: React.ReactNode
  Icon?: any
  p?: string
  h?: string
  w?: string
  fs?: number | string
  fw?: number
  lh?: string
  br?: string
  sx?: any
  onClick?: (e?: any) => void
  disabled?: boolean
  IconStart?: any
  fm?: string
  outlined?: boolean
  mr?: number
  border?: any
  id?: string
  isLoading?: boolean
  DisabledIcon?: any
  textColor?: any
}) => {
  return (
    <Button
      id={id}
      sx={{
        mt: mt,
        mb: mb,
        mr: mr,
        padding: p ? p : 'auto',
        height: h,
        width: w ? w : '100%',
        borderRadius: br,
        backgroundColor: outlined ? 'transparent' : '#63EA71',
        fontFamily: fm ? fm : 'Hellix',
        border: border ? border : outlined && !disabled ? '1px solid #08493C' : 'none',
        gap: '10px',
        color: disabled ? '#B1B4B3' : '#63EA71',
        transition: 'all 0.2s ease',
        '&:disabled': {
          background: '#333333 !important',
          color: '#B1B4B3',
          border: 'none',
          cursor: 'not-allowed',
          pointerEvents: 'all !important',
        },
        '&:hover': {
          background: outlined
            ? 'transparent'
            : disabled
            ? '#333333'
            : '#27B079',
          border: outlined && !disabled ? '1px solid #27B079' : 'none',
          color: disabled ? '#B1B4B3' : '#63EA71',
          // cursor: 'not-allowed',
          pointerEvents: 'all !important',
        },
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      {isLoading ? (
        <CircularProgress
          size="20px"
          sx={{ marginRight: '7px', color: '#208B4A' }}
        />
      ) : disabled ? (
        DisabledIcon ? <DisabledIcon/> : 
        <img
          src={GreyFilledArrow}
          style={{
            marginRight: '7px',
          }}
          alt=""
        />
      ) : (
        IconStart && <IconStart />
      )}
      {text && (
        <Typography
          fontSize={fs ? fs : 17}
          fontWeight={fw ? fw : 500}
          lineHeight={lh && lh}
          color={textColor ? textColor : (outlined ? disabled ? "#B1B4B3" : '#63EA71' : '#000')}
          textTransform={'capitalize'}
          sx={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            whiteSpace: "nowrap"
          }}
          fontFamily={fm ? fm : 'Hellix'}
        >
          {text}
        </Typography>
      )}
      {content && (
        content
      )}
      {Icon && <Icon />}
    </Button>
  )
}

export default GreenButton
