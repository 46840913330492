import { Typography, useTheme } from '@mui/material'
import { Box, useMediaQuery } from '@mui/system'
import {
  ArrowRight,
  CompleteKyc,
  CompleteKycMobile,
  JoinIdo,
  JoinIdoMobile,
  Profile,
  ProfileMobile,
} from '../../assets'
import GreenButton from '../../components/GreenButton'
import { PATHS } from '../../constants/config'

const StepBox = ({
  step,
  image,
  title,
  description,
  buttonText,
  sx,
  onClick,
}: {
  step: number
  image: string
  title: string
  description: string
  buttonText: string
  sx?: any
  onClick?: (e?: any) => void
}) => {
  const theme = useTheme()
  const isBetweenScreen = useMediaQuery(theme.breakpoints.between(767, 1200))
  const isDown767 = useMediaQuery('(max-width:767px)')
  return (
    <Box
      sx={{
        background: '#FFFFFF',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: isBetweenScreen ? 'start' : 'center',
        justifyContent: 'center',
        padding: '38px 31px',
        position: 'relative',
        marginBottom: '4px',
        border: '1px solid transparent',
        gap: '24px',
        width: isBetweenScreen ? '90%' : '100%',
        '&:hover': {
          border: '1px solid #000000',
          boxShadow: '0px 4px 0px 0px #000000',
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isBetweenScreen ? 'row' : 'column',
          gap: isBetweenScreen ? '40px' : '24px',
          alignItems: isBetweenScreen ? 'start' : 'center',
        }}
      >
        <img width={'90px'} loading="lazy" src={image} alt={title} />
        <Box
          sx={{
            maxWidth: '225px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Hellix',
              fontSize: '20px',
              fontWeight: 600,
              lineHeight: '26px',
              textAlign: isBetweenScreen ? 'left' : 'center',
              color: '#000',
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '26px',
              textAlign: isBetweenScreen ? 'left' : 'center',
              color: '#000',
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: '50px',
          height: '53px',
          '&:hover': {
            borderBottom: '5px solid #000',
          }
        }}
      >
        <GreenButton
          fs={15}
          lh="22.5px"
          fm="Inter"
          fw={500}
          br="24px"
          text={buttonText}
          border="1px solid #000"
          w="146px"
          h="48px"
          sx={{
            backgroundColor: isDown767 ? '#FF9228' : '#63EA71',
            '&:hover': {
              backgroundColor: isDown767 ? '#FF9228' : '#63EA71',
              transition: 'unset',
            }
          }}
          onClick={onClick}
        />
      </Box>
      <Box
        sx={{
          border: '1px solid #000',
          backgroundColor: '#F4FDAB',
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          left: isDown767 ? '50%' : 0,
          transform: isDown767
            ? 'translate(-50%, -50%)'
            : 'translate(-35%, -35%)',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Hellix',
            fontWeight: 500,
            fontSize: isDown767 ? '20px' : '24px',
            color: '#000',
            textAlign: 'center',
          }}
        >
          {step}
        </Typography>
      </Box>
    </Box>
  )
}

const JoinYourFirst = () => {
  const isDown992 = useMediaQuery('(max-width:992px)')
  const isDown766 = useMediaQuery('(max-width:766px)')
  const isDown1023 = useMediaQuery('(max-width:1023px)')
  const theme = useTheme()
  const isBetweenScreen = useMediaQuery(theme.breakpoints.between(767, 1200))
  const isBetweenScreen1200 = useMediaQuery(
    theme.breakpoints.between(767, 1366),
  )
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: isBetweenScreen1200 ? '90%' : '1200px',
        paddingY: isDown1023 ? '20px' : '140px',
        width: '100%',
        margin: '0 auto',
        gap: isDown1023 ? '32px' : '64px',
        paddingX: isDown766 ? '25px' : isBetweenScreen ? '16px' : '',
      }}
    >
      <Typography
        sx={{
          color: '#000',
          fontFamily: 'Hellix',
          fontSize: isDown1023 ? '40px' : '48px',
          fontWeight: 600,
          lineHeight: isDown1023 ? '48px' : '52.8px',
          textAlign: 'center',
          marginBottom: isDown992 ? '32px' : '',
        }}
      >
        Join Your First Sale in Under 30 Seconds!
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isDown766
            ? 'column'
            : isBetweenScreen
            ? 'column'
            : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '24px',
        }}
      >
        <StepBox
          step={1}
          image={isDown766 ? ProfileMobile : Profile}
          title="Create Account"
          description="Sign up and connect your favorite crypto wallet."
          buttonText="Sign Up"
          onClick={() => window.open(PATHS.home)}
        />
        <img
          src={ArrowRight}
          style={{
            width: isDown1023 ? '35px' : '',
            margin: isDown1023 ? '0 auto' : '',
            transform: isDown766
              ? 'rotate(90deg)'
              : isBetweenScreen
              ? 'rotate(90deg)'
              : 'rotate(0deg)',
          }}
          alt="ArrowRight"
        />
        <StepBox
          step={2}
          image={isDown766 ? JoinIdoMobile : JoinIdo}
          title="Join IDO"
          description="Create account and connect your favorite crypto wallet."
          buttonText="Choose IDO"
          sx={{
            marginTop: isDown766 ? '24px' : '',
          }}
          onClick={() => window.open(PATHS.home)}
        />
        <img
          src={ArrowRight}
          style={{
            width: isDown1023 ? '35px' : '',
            margin: isDown1023 ? '0 auto' : '',
            transform: isDown766
              ? 'rotate(90deg)'
              : isBetweenScreen
              ? 'rotate(90deg)'
              : 'rotate(0deg)',
          }}
          alt="ArrowRight"
        />
        <StepBox
          step={3}
          image={isDown766 ? CompleteKycMobile : CompleteKyc}
          title="Complete KYC"
          description="Complete fast KYC verification to be eligible for participation in IDO."
          buttonText="Complete KYC"
          sx={{
            marginTop: isDown766 ? '24px' : '',
          }}
          onClick={() => window.open(PATHS.home)}
        />
      </Box>
    </Box>
  )
}

export default JoinYourFirst
