import { useCallback, useState } from 'react'
import _ from 'lodash'
import { ethers } from 'ethers'
import { AbiFunction } from 'viem'
import { switchChain } from '@wagmi/core'
import { useAccount } from 'wagmi'
import { useEthersSigner, wagmiConfig } from '../../../../components/Web3Provider'
import { CONTRACT_ADDRESS, MAIN_CHAIN_ID, RPC_PROVIDER } from '../../../../constant'
import InvestmentUnifiedAbi from '../../../../contracts/ape-investment-unified.json'
import VestingUnifiedAbi from '../../../../contracts/ape-vesting-unified.json'
import { getChainIdFromName } from '../../../../utils/index'
import toaster from 'react-hot-toast'
import { getExceptionMessage, getChainName } from '../../../../utils/index'
import GreenButton from '../../../../components/GreenButton'
import { FilledBlackArrow } from '../../../../assets';

const VESTING_UNIFIED_ABI: AbiFunction[] = VestingUnifiedAbi as AbiFunction[]
const APE_INVESTMENT_UNIFIED_ABI: AbiFunction[] = InvestmentUnifiedAbi as AbiFunction[]

const PONDER_PROJECT_OID = '65f1c302ee95544c6558f145'
const MICROGPT_PROJECT_OID = '6735e623d93397346746b482'
const RWA_INC_OID = '673de40392f33ef5f26c1387'
const BRAVO_READY_OID = '673f2e402eda3206ccc98a3b'

const MIN_GAS_LIMIT: Record<string, BigInt> = {
  UV_CLAIM: 250_000n,
  UV_REFUND_REQUEST: 250_000n,
}

const VestingClaimButton = ({ sale, onClaimSuccess}: any) => {
  const [isLoading, setIsLoading] = useState(false)
  const { address, chainId } = useAccount()
  const signer = useEthersSigner({ chainId })

  const projectOid = _.get(sale, 'project._id')
  const netWork = sale?.idoClaimNetwork || sale?.tokenNetwork
  const claimChainId: number | undefined = getChainIdFromName(netWork)
  const claimContract = sale?.idoClaimContract || CONTRACT_ADDRESS.APE_VESTING_UNIFIED[claimChainId!]
  const claimProjectId = Number(sale?.projectVestingId) || 0
  const investmentProjectId = Number(sale?.investmentProjectId) || Number(sale?.projectContractId) || 0

  const iconStartFn = useCallback(() => (
    <img
      src={FilledBlackArrow} alt="filled black arrow"
      style={{ marginRight: '8px', marginLeft: '-4px' }}
    />
  ), [])

  const onUvClaim = async() => {
    if (!address) {
      throw new Error('Wallet not connected')
    }
    if (!claimChainId) throw new Error('claimChainId is not set')
  
    if (!claimContract) {
      throw new Error('No claimContract has been set')
    }
    if (!(claimProjectId >= 0)) {
      throw new Error('No claimProjectId has been set')
    }
    if (chainId !== claimChainId) {
      toaster.error(`Please switch network to ${getChainName(claimChainId)}`)
      await switchChain(wagmiConfig, { chainId: claimChainId })
      return
    }
    try {
      setIsLoading(true)
      const vestingContract = new ethers.Contract(
        claimContract,
        VESTING_UNIFIED_ABI as any[],
        signer,
      )
  
      let investmentContractAddress: string = CONTRACT_ADDRESS.APE_INVESTMENT_UNIFIED[MAIN_CHAIN_ID];
      if ([PONDER_PROJECT_OID, MICROGPT_PROJECT_OID, RWA_INC_OID, BRAVO_READY_OID].includes(projectOid)) {
        investmentContractAddress = '0x597b53a550e788cd7fbfb6f281dade989c107293'
      }
      
      const apeInvestmentUnifiedContract = new ethers.Contract(
        investmentContractAddress,
        // @ts-ignore
        APE_INVESTMENT_UNIFIED_ABI,
        RPC_PROVIDER[MAIN_CHAIN_ID],
      )
  
      const investmentProof = await apeInvestmentUnifiedContract.getInvestmentProof(investmentProjectId, address)
  
      let estGas: ethers.BigNumber | BigInt = await vestingContract.estimateGas.claimToken(claimProjectId, investmentProof)
  
      estGas = estGas.toBigInt()
      if (estGas < MIN_GAS_LIMIT.UV_CLAIM) {
        estGas = MIN_GAS_LIMIT.UV_CLAIM
      }
  
      const tx = await vestingContract.claimToken(claimProjectId, investmentProof, {gasLimit: estGas})
  
      await tx.wait(2)
      setIsLoading(false)
      onClaimSuccess(true)
      return true;
    } catch (e) {
      console.error('vestingClaim error', e)
      toaster.error(getExceptionMessage(e))
      setIsLoading(false)
      onClaimSuccess(false)
      return false;
    }
  }

  return (
    <GreenButton
      fs={12}
      lh="22.5px"
      fm="Inter"
      fw={400}
      br="24px"
      IconStart={iconStartFn}
      DisabledIcon={iconStartFn}
      text={'Claim'}
      disabled={isLoading}
      onClick={e => {
        e.stopPropagation()
        onUvClaim();
      }}
      isLoading={isLoading}
      outlined={false}
      h="32px"
      w="fit-content"
      textColor="#000"
      sx={{
        gap: '0',
        padding: '0 16px',
        background: '#63EA71',
        color: 'red',
        opacity: isLoading ? '0.8' : '1',
      }}
    />
  )
}

export default VestingClaimButton
