import React from 'react'
import Rive, { Layout, Fit } from '@rive-app/react-canvas'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Box, styled } from '@mui/system'
import Skeleton from '@mui/material/Skeleton'

const stats = [
  {
    rive: '../rive/ape_element_01-new.riv',
    label: 'AVG ROI',
    value: '15x',
    color: '#FFFFFF',
  },
  {
    rive: '../rive/ape_element_02-new.riv',
    label: 'Participants',
    value: '200,000+',
    color: '#FFFFFF',
  },
  {
    rive: '../rive/ape_element_03-new.riv',
    labelTop: 'Total Assets',
    label: 'Connected',
    value: '$1,000,000,000+',
    color: '#FFFFFF',
  },
]

export const StyledLabelText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  letterSpacing: '-0.01em',
  color: '#7E8180',
}))

export const StyledValueText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Hellix',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '130%',
  letterSpacing: '-0.01em',
  overflowWrap: 'break-word',
  [theme.breakpoints.down('sm')]: {
    overflowWrap: 'anywhere',
  },
}))

const AnimatedStatistics = ({ launchpadStats }: any) => {
  const theme = useTheme()
  const isDown999 = useMediaQuery('(max-width:999px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown350 = useMediaQuery('(max-width:350px)')
   const isBetween = useMediaQuery(theme.breakpoints.between(1200, 1340))
  const STATE_MACHINE_NAME = 'Interaction'
  
  const largestValue = Math.max(
    launchpadStats?.averageROI || 0,
    launchpadStats?.totalParticipation || 0,
    launchpadStats?.totalAssetsConnected || 0,
  )
  
  const baseFontSize = isDown767 ? 32 : 40
  const updatedFontSize =
    largestValue > 100000000
      ? baseFontSize - 6
      : largestValue > 1000000
        ? baseFontSize - 4
        : baseFontSize
  const fontSize = isDown350 ? 22 : updatedFontSize
  
  return (
    <Grid
      container
      spacing={2}
      justifyContent={'center'}
      sx={{ flexWrap: 'wrap' }}
    >
      <Grid item xs={12}>
        <div
          className="rive-animation__wrapper"
          style={{
            display: 'grid',
            gridTemplateColumns: isDown999
              ? 'repeat(1, minmax(0, 1fr))'
              : 'repeat(3, minmax(0, 1fr))',
            gap: '8px',
          }}
        >
          {stats.map(({ rive, label, labelTop, value, color }, index) => {
            let formattedValue =
              index === 0
                ? Number(launchpadStats?.averageROI)?.toFixed(2)
                : index === 1
                  ? launchpadStats?.totalParticipation
                  : Number(launchpadStats?.totalAssetsConnected)?.toFixed(0)
            
            return (
              <div
                key={index}
                className="rive-animation__item"
                style={{
                  width: '100%',
                  pointerEvents: isDown999 ? 'none' : 'auto',
                  aspectRatio: '16/5',
                  position: 'relative',
                }}
              >
                <div
                  className="rive-animation__item-info"
                  style={{
                    position: 'absolute',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: 0,
                    width: '100%',
                    textAlign: index === 1 ? 'left' : 'center',
                    pointerEvents: 'none',
                  }}
                >
                  {index === 2 && <StyledLabelText>{labelTop}</StyledLabelText>}
                  {Number.isNaN(Number(formattedValue)) ? (
                    <Box width={'100%'} display={'flex'} justifyContent={label != 'Participants' ? 'center' : ''}>
                      <Skeleton variant="text" width={"40%"} height={fontSize} />
                    </Box>
                  ) : (
                    <StyledValueText color={color} fontSize={isBetween ? 25 : fontSize}>
                      {index === 2 && '$'}
                      {''}
                      {Number(formattedValue).toLocaleString('en') || 0}
                    </StyledValueText>
                  )}
                  <StyledLabelText>{label}</StyledLabelText>
                </div>
                <Rive
                  src={rive}
                  stateMachines={STATE_MACHINE_NAME}
                  layout={new Layout({ fit: Fit.Contain })}
                />
              </div>
            )
          })}
        </div>
      </Grid>
    </Grid>
  )
}

export default AnimatedStatistics
