import { Box } from '@mui/material'
import { useAppSelector } from '../../hooks'
import AccountLogin from './AccountLogin'
import { useAccount } from 'wagmi'
import AccountInfo from './AccountInfo'

const Account = () => {
  const user = useAppSelector(state => state.user)
  const { address } = useAccount()
  const isLogIned = address || user.email
  return (
    <Box
      sx={{
        width: '100%',
        display: isLogIned ? 'block' : 'flex'
      }}
    >
      {isLogIned ? <AccountInfo /> : <AccountLogin />}
    </Box>
  )
}
export default Account
