import {
  Box,
  Grid,
  Skeleton,
  Typography,
  Divider,
  useMediaQuery,
} from '@mui/material'
import _ from 'lodash'
import Countdown from 'react-countdown'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  EndsIn,
  SaleType,
  IconRocket,
  IconCrown,
  CommentsIcon,
} from '../../assets'
import { useParticipationEntryPoint } from '../../Context'
import { getReplacedCdnEndpoint } from '../../utils'
import { ComingsoonCover, ComingsoonAvatar } from '../../assets'
import { ASSETS_CDN_ENDPOINT } from '../../constant'
import GreenButton from '../GreenButton'

// cloudflare served this file as an empty file so have to add an workaround
const TotalRaise = `${ASSETS_CDN_ENDPOINT}/app-icons/total_raise.svg`

export const logoByHost: Record<string, any> = {
  'Ape Terminal': `${ASSETS_CDN_ENDPOINT}/backers/apeterminal.png`,
  'Layer AI': `${ASSETS_CDN_ENDPOINT}/backers/layerai.png`,
  'Happy Cat': `${ASSETS_CDN_ENDPOINT}/backers/happycat.png`,
  RWAX: `${ASSETS_CDN_ENDPOINT}/backers/rwax.png`,
}

export const getLogoUrlByHost = (host: string) => {
  return (
    logoByHost[host] ||
    `${ASSETS_CDN_ENDPOINT}/backers/${String(host)
      .toLowerCase()
      .replace(/ /g, '-')}.png`
  )
}

export const RenderLaunchHostedBy = (options: {
  hostedBy?: string | string[]
  height?: string
}) => {
  let { hostedBy, height = '1.75rem' } = options
  if (typeof hostedBy == 'string') {
    hostedBy = [hostedBy]
  }
  if (!hostedBy || !hostedBy.length) return <span style={{ color: '#B1B4B3' }}>N/A</span>
  return (
    <Box
      display={'flex'}
      gap={'0.25rem'}
      // justifyContent={'center'}
      alignItems={'center'}
    >
      {hostedBy.map((vendor, index) => {
        return (
          <img
            key={index}
            src={
              logoByHost[vendor] ||
              `${ASSETS_CDN_ENDPOINT}/backers/${String(
                vendor,
              ).toLowerCase()}.png`
            }
            style={{
              height,
              width: 'auto',
            }}
            alt=""
          />
        )
      })}
    </Box>
  )
}

interface IUpcomingLaunchCard {
  image: string
  heading: string
  titleImage: string
  chips?: string[]
  totalRaise?: number
  startsIn?: number
  saleType?: string
  marketMaker?: string
  buttonText?: string
  timePassed?: boolean
  comingSoon?: boolean
  registrationStarted?: boolean
  active?: boolean
  type?: string
  videoUrl?: string
  registrationEndDate?: string
  routeName?: string
  started?: boolean
  backedByLogo?: any
  backers?: string[]
  highlights?: string
  description?: string
  socialLinks?: string[]
  hostedBy?: string | string[]
}

const NewUpcomingLaunchCard: React.FC<IUpcomingLaunchCard> = ({
  image,
  heading,
  titleImage,
  chips,
  totalRaise,
  startsIn,
  saleType,
  marketMaker,
  routeName,
  buttonText,
  comingSoon,
  registrationStarted,
  active,
  type,
  videoUrl,
  registrationEndDate,
  started,
  backedByLogo,
  backers,
  description,
  socialLinks,
  hostedBy,
}) => {
  const [timePassed, setTimePassed] = useState(false)

  const navigate = useNavigate()

  const isDown449 = useMediaQuery('(max-width:449px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown1152 = useMediaQuery('(max-width:1152px)')
  const isDown1050 = useMediaQuery('(max-width:1152px)')
  const isDown1600 = useMediaQuery('(max-width:1600px)')

  const isEndRegister = () => {
    if (!registrationEndDate) return false

    const endRegisterDate = parseInt(registrationEndDate || '0')

    return endRegisterDate && endRegisterDate < new Date().getTime()
  }

  // run useEffect every second
  useEffect(() => {
    if (!startsIn) return
    const interval = setInterval(() => {
      if (startsIn && startsIn <= new Date().getTime()) {
        setTimePassed(true)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [startsIn])

  const { setProjectLinkSource } = useParticipationEntryPoint()
  const statusTags = ['live', 'refundable', 'non-refundable']
  const isFairLaunch = ['AI Agent Drop', 'Fair Launch', 'Creator Drop']
    .map(t => t.toLowerCase())
    .includes(String(saleType).toLowerCase())

  const cardDesc = type === 'coming-soon' && !isDown1050 ? 'TBA' : description

  return (
    <Box
      sx={{
        background:
          'linear-gradient(142.67deg, rgba(255, 185, 0, 0.3) 0%, rgba(255, 255, 255, 0.09) 51.04%, rgba(255, 185, 0, 0.3) 100%)',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
        flex: 1,

        minWidth: isDown449
          ? `calc(${window.innerWidth}px - 60px)`
          : isDown767
          ? '278px'
          : isDown1600
          ? '330px'
          : '350px',
        maxWidth: isDown449
          ? `calc(${window.innerWidth}px - 60px)`
          : isDown767
          ? '350px'
          : '390px',
      }}
    >
      <Box
        sx={{
          background:
            'radial-gradient(60.16% 54.61% at 50% 116.08%, rgba(255, 185, 0, 0.08) 0%, rgba(255, 185, 0, 0) 100%), #0F1113',
          borderRadius: '16px',
          border: 'none',
          width: `calc(100% - 1px)`,
          margin: '0.5px',
          transition: 'all 0.3s',
          position: 'relative',
          '&:hover > .launch-card-hover-bg': {
            opacity: '1 !important',
          },
        }}
        display={'flex'}
        flexDirection={'column'}
      >
        <div
          className="launch-card-hover-bg"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
            borderRadius: '16px',
            transition: 'all 0.3s',
            opacity: 0,
            pointerEvents: 'none',
            userSelect: 'none',
            background:
              'radial-gradient(60.16% 54.61% at 50% 116.08%, rgba(255, 185, 0, 0.14) 0%, rgba(255, 185, 0, 0) 100%), #0F1113',
          }}
        />
        <>
          {videoUrl && !isDown1152 ? (
            <Box
              position={'relative'}
              sx={{
                width: '100%',
                // height: 250,
                height: '219px',
                overflow: 'hidden',
                display: 'flex',
                zIndex: 1,
                borderRadius: '12px',
              }}
            >
              <video
                autoPlay
                loop
                muted
                playsInline
                style={{
                  width: `calc(100% - 16px)`,
                  marginTop: '8px',
                  marginRight: '8px',
                  marginLeft: '8px',
                  objectFit: 'cover',
                  borderRadius: '12px',
                  height: '209px',
                }}
                poster={image}
              >
                <source
                  src={getReplacedCdnEndpoint(videoUrl)}
                  type="video/mp4"
                />
              </video>
              {type !== 'coming-soon' && (
                <>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '4px',
                      right: 0,
                      padding: '8px',
                      zIndex: 1,
                      maxWidth: '70%',
                    }}
                  >
                    <Grid container gap={'4px 0px'} justifyContent={'flex-end'}>
                      {(chips || [])
                        .filter(chip =>
                          statusTags.includes(String(chip).toLowerCase()),
                        )
                        .map((chip, index) => (
                          <ChipText key={index} text={chip} color={'#B1B4B3'} />
                        ))}
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      padding: '8px',
                      zIndex: 1,
                      maxWidth: '70%',
                    }}
                  >
                    <Grid container gap={'4px 0px'} justifyContent={'flex-end'}>
                      {(chips || [])
                        .filter(
                          chip =>
                            !statusTags.includes(String(chip).toLowerCase()),
                        )
                        .map((chip, index) => (
                          <ChipText key={index} text={chip} color={'#B1B4B3'} />
                        ))}
                    </Grid>
                  </Box>
                </>
              )}
            </Box>
          ) : (
            <Box
              position={'relative'}
              sx={{
                width: '100%',
                // height: 250,
                height: '219px',
                overflow: 'hidden',
                display: 'flex',
                zIndex: 1,
                borderRadius: '12px',
              }}
            >
              <img
                src={image || ComingsoonCover}
                style={{
                  width: `calc(100% - 16px)`,
                  marginTop: '8px',
                  marginRight: '8px',
                  marginLeft: '8px',
                  objectFit: 'cover',
                  borderRadius: '12px',
                  height: '209px',

                  filter: !active && type === 'coming-soon' ? '' : 'none',
                }}
                alt="launch-banner"
                loading="lazy"
              />
              {(type !== 'coming-soon' ||
                (type === 'coming-soon' && _.isArray(chips))) && (
                <>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '4px',
                      right: 0,
                      padding: '8px',
                      zIndex: 1,
                      maxWidth: '70%',
                    }}
                  >
                    <Grid container gap={'4px 0px'} justifyContent={'flex-end'}>
                      {(chips || [])
                        .filter(chip =>
                          statusTags.includes(String(chip).toLowerCase()),
                        )
                        .map((chip, index) => (
                          <ChipText key={index} text={chip} color={'#B1B4B3'} />
                        ))}
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      padding: '8px',
                      zIndex: 1,
                      maxWidth: '70%',
                    }}
                  >
                    <Grid container gap={'4px 0px'} justifyContent={'flex-end'}>
                      {(chips || [])
                        .filter(
                          chip =>
                            !statusTags.includes(String(chip).toLowerCase()),
                        )
                        .map((chip, index) => (
                          <ChipText key={index} text={chip} color={'#B1B4B3'} />
                        ))}
                    </Grid>
                  </Box>
                </>
              )}
            </Box>
          )}
        </>
        {/* )} */}

        <img
          src={titleImage || ComingsoonAvatar}
          style={{
            filter: !active && type === 'coming-soon' ? '' : 'none',
            height: '56px',
            width: '56px',
            marginRight: '10px',
            overflow: 'hidden',
            // borderRadius: '16px',
            marginTop: '-40px',
            position: 'relative',
            zIndex: 2,
            left: isDown767 ? '16px' : '32px',
            borderRadius: '12px',
            objectFit: 'cover',
            // border: '0.5px solid rgba(0, 0, 0, 0.5)',
          }}
          alt="launch-logo"
          loading="lazy"
        />
        <Box
          padding={isDown767 ? '0px 16px 40px 16px' : '0px 32px 24px'}
          style={{ position: 'relative', zIndex: 2, flexGrow: 1 }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'16px'}
            justifyContent={'space-between'}
            height={'100%'}
          >
            <Typography
              fontWeight={isDown767 ? 500 : 600}
              fontSize={isDown767 ? 17 : 24}
              lineHeight={isDown767 ? '27.2px' : '31.2px'}
              fontFamily={isDown767 ? 'Inter' : 'Hellix'}
              color={'rgba(255, 255, 255, 1)'}
              marginTop={'8px'}
              sx={{
                width: '100%',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                textOverflow: 'ellipsis',
              }}
            >
              {heading ? heading : 'TBA'}
            </Typography>

            {/* {!!cardDesc ? (
              <Box
                sx={{
                  width: '300px',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  textOverflow: 'ellipsis',
                }}
              >
                <Typography
                  fontWeight={400}
                  fontSize={15}
                  lineHeight={'22.5px'}
                  fontFamily={'Inter'}
                  color={'#767676'}
                >
                  {cardDesc}
                </Typography>
              </Box>
            ) : null} */}

            <Box display={'flex'} flexDirection={'column'} gap={'24px'}>
              <Box>
                {type === 'coming-soon' ? (
                  <>
                    <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                        gap={'8px'}
                      >
                        <img src={TotalRaise} alt="" />
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={400}
                          fontSize={14}
                          lineHeight={'24px'}
                          color="#767676"
                        >
                          {isFairLaunch ? 'Initial Market Cap' : 'Total Raise'}
                        </Typography>
                      </Box>
                      <Typography
                        fontFamily={'Inter'}
                        fontWeight={500}
                        fontSize={14}
                        lineHeight={'24px'}
                        color={'#B1B4B3'}
                      >
                        {'TBA'}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      marginTop={'10px'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                        gap={'8px'}
                      >
                        <img src={EndsIn} alt="ends-in" />
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={400}
                          fontSize={14}
                          lineHeight={'24px'}
                          color="#767676"
                        >
                          {!started ? 'Starts In' : 'Ends In'}
                        </Typography>
                      </Box>
                      <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'24px'}
                          color={'#B1B4B3'}
                        >
                          {'TBA'}
                        </Typography>
                      {/* {registrationStarted && registrationEndDate ? (
                        <Countdown
                          date={registrationEndDate}
                          renderer={({
                            days,
                            hours,
                            minutes,
                            seconds,
                            completed,
                          }) => {
                            let _timerString = ''

                            if (days > 0) {
                              _timerString = `${days}d ${hours}h ${minutes}m ${seconds}s`
                            } else if (hours > 0) {
                              _timerString = `${hours}h ${minutes}m ${seconds}s`
                            } else {
                              _timerString = `${minutes}m ${seconds}s`
                            }
                            return (
                              <Typography
                                fontFamily={'Inter'}
                                fontWeight={600}
                                fontSize={14}
                                lineHeight={'16.8px'}
                                sx={{
                                  alignSelf: 'center',
                                  background: '#1d1f21',
                                  height: '28px',
                                  borderRadius: '60px',
                                  padding: '5px 10px',
                                }}
                              >
                                <span
                                  style={{
                                    background:
                                      'linear-gradient(90.22deg, #FF7722 0.16%, #F4FDAB 99.78%)',
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                  }}
                                >
                                  {completed ? 'Ended' : _timerString}
                                </span>
                              </Typography>
                            )
                          }}
                        />
                      ) : timePassed && registrationStarted ? (
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'24px'}
                          color={'#ff7722'}
                        >
                          Started
                        </Typography>
                      ) : (
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'24px'}
                          color={'#B1B4B3'}
                        >
                          {!started ? 'Coming Soon' : 'TBA'}
                        </Typography>
                      )} */}
                    </Grid>
                    <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      marginTop={'10px'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                        gap={'8px'}
                      >
                        <img src={SaleType} alt="sale-type" />
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={400}
                          fontSize={14}
                          lineHeight={'24px'}
                          color="#767676"
                        >
                          Sale Type
                        </Typography>
                      </Box>
                      <Typography
                        fontFamily={'Inter'}
                        fontWeight={500}
                        fontSize={14}
                        lineHeight={'32px'}
                        color={'#B1B4B3'}
                      >
                        {'TBA'}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      marginTop={'10px'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                        gap={'8px'}
                      >
                        <img src={IconCrown} alt="hosted-by" />
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'24px'}
                          color="#767676"
                        >
                          Hosted By
                        </Typography>
                      </Box>
                      <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'24px'}
                          color={'#B1B4B3'}
                        >
                          {'TBA'}
                        </Typography>
                      {/* {registrationStarted ? (
                        <RenderLaunchHostedBy hostedBy={hostedBy} />
                      ) : (
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'24px'}
                          color={'#B1B4B3'}
                        >
                          {'TBA'}
                        </Typography>
                      )} */}
                    </Grid>
                    {/* <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      marginTop={'10px'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                        gap={'8px'}
                      >
                        <img src={IconCrown} alt="market-maker" />
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'24px'}
                          color="#767676"
                        >
                          Social Media
                        </Typography>
                      </Box>
                      <Typography
                        fontFamily={'Inter'}
                        fontWeight={500}
                        fontSize={14}
                        lineHeight={'24px'}
                        color={'#B1B4B3'}
                      >
                        TBA
                      </Typography>
                    </Grid> */}
                    <Divider
                        style={{
                          padding: '0.25rem 0rem',
                          marginBottom: '0.25rem',
                          borderBottom: '1px solid #bdbdbd',
                        }}
                    />
                    <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      marginTop={'10px'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                        gap={'8px'}
                      >
                        <img src={IconRocket} alt="backed-by" />
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'24px'}
                          color="#767676"
                        >
                          Backed By
                        </Typography>
                      </Box>
                      <Box display={'flex'} gap={'2px'}>
                      <Typography
                            fontFamily={'Inter'}
                            fontWeight={500}
                            fontSize={14}
                            lineHeight={'24px'}
                            color={'#B1B4B3'}
                          >
                            {'TBA'}
                          </Typography>
                        {/* {_.isArray(backers) ? (
                          backers.map((backer: string, index: number) => {
                            return (
                              <img
                                key={index}
                                src={`${ASSETS_CDN_ENDPOINT}/backers/${String(
                                  backer,
                                ).toLowerCase()}.png`}
                                style={{
                                  height: '1.75rem',
                                  width: 'auto',
                                  alignSelf: 'center',
                                  // background: "#1e1e1e",
                                  padding: '0rem 0.25rem',
                                  // borderRadius: "100%",
                                }}
                                alt=""
                              />
                            )
                          })
                        ) : (
                          <Typography
                            fontFamily={'Inter'}
                            fontWeight={500}
                            fontSize={14}
                            lineHeight={'24px'}
                            color={'#B1B4B3'}
                          >
                            {'N/A'}
                          </Typography>
                        )} */}
                      </Box>
                    </Grid>
                    <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      marginTop={'10px'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                        gap={'8px'}
                      >
                        <img src={CommentsIcon} alt="comments" />
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'24px'}
                          color="#767676"
                        >
                          Comments
                        </Typography>
                      </Box>
                      <Typography
                        fontFamily={'Inter'}
                        fontWeight={500}
                        fontSize={14}
                        lineHeight={'24px'}
                        color={'#B1B4B3'}
                      >
                        {'TBA'}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                        gap={'8px'}
                      >
                        <img src={TotalRaise} alt="" />
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={400}
                          fontSize={14}
                          lineHeight={'24px'}
                          color="#767676"
                        >
                          {isFairLaunch ? 'Initial Market Cap' : 'Total Raise'}
                        </Typography>
                      </Box>
                      <Typography
                        fontFamily={'Inter'}
                        fontWeight={500}
                        fontSize={14}
                        lineHeight={'24px'}
                        color={'#B1B4B3'}
                      >
                        {totalRaise === -1 || !registrationStarted
                          ? 'TBA'
                          : totalRaise === -2
                          ? 'Launch Drop'
                          : `$${totalRaise?.toLocaleString('en')}`}
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      marginTop={'10px'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                        gap={'8px'}
                      >
                        <img src={EndsIn} alt="ends-in" />
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={400}
                          fontSize={14}
                          lineHeight={'24px'}
                          color="#767676"
                        >
                          {!started ? 'Starts In' : 'Ends In'}
                        </Typography>
                      </Box>
                      {registrationStarted && registrationEndDate ? (
                        <Countdown
                          date={registrationEndDate}
                          renderer={({
                            days,
                            hours,
                            minutes,
                            seconds,
                            completed,
                          }) => {
                            let _timerString = ''

                            if (days > 0) {
                              _timerString = `${days}d ${hours}h ${minutes}m ${seconds}s`
                            } else if (hours > 0) {
                              _timerString = `${hours}h ${minutes}m ${seconds}s`
                            } else {
                              _timerString = `${minutes}m ${seconds}s`
                            }
                            return (
                              <Typography
                                fontFamily={'Inter'}
                                fontWeight={600}
                                fontSize={14}
                                lineHeight={'16.8px'}
                                sx={{
                                  alignSelf: 'center',
                                  background: '#1d1f21',
                                  height: '28px',
                                  borderRadius: '60px',
                                  padding: '5px 10px',
                                }}
                              >
                                <span
                                  style={{
                                    background:
                                      'linear-gradient(90.22deg, #FF7722 0.16%, #F4FDAB 99.78%)',
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                  }}
                                >
                                  {completed ? 'Ended' : _timerString}
                                </span>
                              </Typography>
                            )
                          }}
                        />
                      ) : timePassed && registrationStarted ? (
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'24px'}
                          color={'#ff7722'}
                        >
                          Started
                        </Typography>
                      ) : (
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'24px'}
                          color={'#B1B4B3'}
                        >
                          {!started ? 'Coming Soon' : 'TBA'}
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      marginTop={'10px'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                        gap={'8px'}
                      >
                        <img src={SaleType} alt="sale-type" />
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={400}
                          fontSize={14}
                          lineHeight={'24px'}
                          color="#767676"
                        >
                          Sale Type
                        </Typography>
                      </Box>
                      <Typography
                        fontFamily={'Inter'}
                        fontWeight={500}
                        fontSize={14}
                        lineHeight={'32px'}
                        color={'#B1B4B3'}
                      >
                        {registrationStarted ? saleType : 'TBA'}
                      </Typography>
                    </Grid>

                    <>
                      <Grid
                        container
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        marginTop={'10px'}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          flexWrap={'wrap'}
                          gap={'8px'}
                        >
                          <img src={IconCrown} alt="hosted by" />
                          <Typography
                            fontFamily={'Inter'}
                            fontWeight={500}
                            fontSize={14}
                            lineHeight={'24px'}
                            color="#767676"
                          >
                            Hosted By
                          </Typography>
                        </Box>
                        {registrationStarted ? (
                          <RenderLaunchHostedBy hostedBy={hostedBy} />
                        ) : (
                          <Typography
                            fontFamily={'Inter'}
                            fontWeight={500}
                            fontSize={14}
                            lineHeight={'24px'}
                            color={'#B1B4B3'}
                          >
                            TBA
                          </Typography>
                        )}
                      </Grid>
                      <Divider
                        style={{
                          padding: '0.25rem 0rem',
                          marginBottom: '0.25rem',
                          borderBottom: '1px solid #bdbdbd',
                        }}
                      />
                      <Grid
                        container
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        marginTop={'10px'}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          flexWrap={'wrap'}
                          gap={'8px'}
                        >
                          <img src={IconRocket} alt="backed-by" />
                          <Typography
                            fontFamily={'Inter'}
                            fontWeight={500}
                            fontSize={14}
                            lineHeight={'24px'}
                            color="#767676"
                          >
                            Backed By
                          </Typography>
                        </Box>
                        <Box display={'flex'} gap={'2px'}>
                          {_.isArray(backers) && backers.length > 0 ? (
                            backers.map((backer: string, index: number) => {
                              return (
                                <img
                                  key={index}
                                  src={`${ASSETS_CDN_ENDPOINT}/backers/${String(
                                    backer,
                                  ).toLowerCase()}.png`}
                                  style={{
                                    height: '1.75rem',
                                    width: 'auto',
                                    alignSelf: 'center',
                                  }}
                                  alt=""
                                />
                              )
                            })
                          ) : (
                            <Typography
                              fontFamily={'Inter'}
                              fontWeight={500}
                              fontSize={14}
                              lineHeight={'24px'}
                              color={'#B1B4B3'}
                            >
                              {'N/A'}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid
                        container
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        marginTop={'10px'}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          flexWrap={'wrap'}
                          gap={'8px'}
                        >
                          <img src={CommentsIcon} alt="comments" />
                          <Typography
                            fontFamily={'Inter'}
                            fontWeight={500}
                            fontSize={14}
                            lineHeight={'24px'}
                            color="#767676"
                          >
                            Comments
                          </Typography>
                        </Box>
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'24px'}
                          color={'#B1B4B3'}
                        >
                          {'N/A'}
                        </Typography>
                      </Grid>
                    </>
                  </>
                )}
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {started && type !== 'coming-soon' ? (
                  <>
                    {
                      <GreenButton
                        fs={15}
                        lh="22.5px"
                        fm="Inter"
                        fw={400}
                        br="24px"
                        text={isEndRegister() ? 'View Launch' : 'Participate'}
                        // mt={3}
                        onClick={() => {
                          setProjectLinkSource('Current')
                          navigate(`/project/${routeName}`)
                        }}
                        outlined={true}
                        w="200px"
                      />
                    }
                  </>
                ) : (
                  // !!startsIn && (
                  //   <Button
                  //     sx={{
                  //       backgroundColor: 'transparent',
                  //       borderRadius: '24px',
                  //       padding: '14px 32px',
                  //       alignSelf: 'center',
                  //       border: '0.5px solid #313335',
                  //       width: '200px',
                  //       mt: 3,
                  //       '&:hover': {
                  //         backgroundColor: 'transparent',
                  //         opacity: 0.8,
                  //       },
                  //       '&:disabled': {
                  //         backgroundColor: 'transparent',
                  //         cursor: 'not-allowed',
                  //       },
                  //     }}
                  //     variant="contained"
                  //     disabled
                  //   >
                  //     <Typography
                  //       fontSize={15}
                  //       fontWeight={400}
                  //       lineHeight={'21.5px'}
                  //       color="#FFFFFF"
                  //       textTransform={'capitalize'}
                  //     >
                  //       Coming Soon
                  //     </Typography>
                  //   </Button>
                  // )
                  <Box
                    sx={{
                      height: '50px',
                    }}
                  ></Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NewUpcomingLaunchCard

export const ChipText = ({ text, color, isBigger, bgColor }: any) => {
  const isDown1152 = useMediaQuery('(max-width:1152px)')
  const isRefundable = String(text).toLowerCase() === 'refundable'
  const isLive = String(text).toLowerCase() === 'live'
  const isNonRefundable =
    String(text).toLowerCase() === 'non refundable' ||
    String(text).toLowerCase() === 'non-refundable'

  return (
    <Box
      sx={{
        background: isRefundable
          ? 'linear-gradient(90deg, rgba(238,134,65,1) 0%, rgba(240,201,128,1) 80%, rgba(244,247,178,1) 100%)'
          : isLive
          ? '#63EA71'
          : bgColor || 'rgba(255,255,255,0.3)',
        color: '#fff',
        borderRadius: '60px',
        padding:
          isBigger && !isDown1152 ? '7px 12px 7px 12px' : '4px 8px 4px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mr: '4px',
        border: isNonRefundable
          ? '2px solid #EE813EDD'
          : '0px solid transparent',
      }}
    >
      <Typography
        fontWeight={500}
        fontSize={isBigger && !isDown1152 ? 15 : 12}
        lineHeight={'18px'}
        color={
          !isRefundable && !isLive
            ? isNonRefundable
              ? '#EE813E'
              : color
            : 'black'
        }
        fontFamily={'Inter'}
      >
        {text}
      </Typography>
    </Box>
  )
}

const SkeletonLoader = ({
  firstLineWidth,
  secondLineWidth,
  thirdLineWidth,
}: any) => {
  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      height={'24px'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        flexWrap={'wrap'}
        gap={'12px'}
      >
        <Skeleton variant="circular" width={16} height={16} animation={false} />
        <Skeleton
          variant="rectangular"
          width={firstLineWidth}
          height={12}
          animation={false}
        />
        <Skeleton
          variant="rectangular"
          width={secondLineWidth}
          height={12}
          animation={false}
        />
      </Box>
      <Skeleton
        variant="rectangular"
        width={thirdLineWidth}
        height={12}
        animation={false}
      />
    </Grid>
  )
}
