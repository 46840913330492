import { useEffect, useState } from 'react'
import { ILaunch, useFetchAllLaunchesQuery } from '../../../services/modules/project'
import UpcomingLaunches from './UpcomingLaunches'

const TabName = {
  upcoming: 'Upcoming',
}

const UpcomingSales = () => {
  const [activeTab, setActiveTab] = useState(TabName.upcoming)

  const handleTabClick = (tab: any) => {
    setActiveTab(tab)
  }

  const {
    data: allLaunchesData,
  } = useFetchAllLaunchesQuery()

  const [featuredLaunch, setFeatureLaunch] = useState<ILaunch | undefined>(undefined);

useEffect(() => {
  if (allLaunchesData && Array.isArray(allLaunchesData)) {
    setFeatureLaunch(
      allLaunchesData.find(launch => launch.type === 'featured'),
    );
  }
}, [allLaunchesData]);


  return (
    <section className="upcoming-launches home">
      <div className="upcoming-launches-wrapper">
        <div className="section-title">
          <nav className="launchpad-toggle-wrapper">
            <ul>
              <li
                className={`toggle1 ${
                  activeTab === TabName.upcoming ? 'active' : ''
                }`}
              >
                <span onClick={() => handleTabClick(TabName.upcoming)}>
                  Live and Upcoming Sales
                </span>
              </li>
            </ul>
          </nav>
        </div>
        <div className="slider-toggle-tabs">
          {activeTab === TabName.upcoming && (
            <>
              {allLaunchesData && allLaunchesData.length > 0 && (
                <UpcomingLaunches
                  upcomingLaunchesData={allLaunchesData?.filter(
                    launch =>
                      launch.type === 'upcoming' ||
                      launch.type === 'coming-soon' ||
                      (featuredLaunch &&
                        launch.type === 'featured' &&
                        launch._id !== featuredLaunch._id),
                  )}
                />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default UpcomingSales
