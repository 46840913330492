import { Box, BoxProps } from '@mui/system'
import { motion, useAnimationControls } from 'framer-motion'
import { forwardRef, useEffect } from 'react'
import { AIcon, ApeTogetherDoor, InsideDoorImage } from '../../assets'

const ApeTogetherAnimate = forwardRef<HTMLDivElement, BoxProps>(
  (props, ref) => {
    const borderControl = useAnimationControls()
    const borderDoorControl = useAnimationControls()
    const insideControl = useAnimationControls()
    const leftControl = useAnimationControls()
    const rightControl = useAnimationControls()
    const aIconControl = useAnimationControls()
    const item1Control = useAnimationControls()
    const item2Control = useAnimationControls()
    const item3Control = useAnimationControls()
    const item4Control = useAnimationControls()
    const item5Control = useAnimationControls()

    useEffect(() => {
      ;(async () => {
        await Promise.all([
          aIconControl.start({
            rotate: ['0deg', '480deg', '480deg', '360deg'],
            transition: { duration: 2, delay: 1 },
          }),
          (async () => {
            await aIconControl.start({
              y: '1%',
              transition: { duration: 0.1, delay: 1.7 },
            })
            await aIconControl.start({
              y: '2%',
              transition: { duration: 0.1, delay: 1.5 },
            })
          })(),
        ])
        await Promise.all([
          aIconControl.start({
            x: '-200%',
            transition: { duration: 1, delay: 1 },
          }),
          leftControl.start({
            x: '-200%',
            transition: { duration: 1, delay: 1 },
          }),
          rightControl.start({
            x: '200%',
            transition: { duration: 1, delay: 1 },
          }),
          insideControl.start({
            opacity: 1,
            transition: { duration: 1, delay: 1.3 },
          }),
          borderControl.start({
            borderColor: '#FFA64F38',
            transition: { duration: 1, delay: 1.3 },
          }),
          borderDoorControl.start({
            borderColor: '#FFF',
            transition: { duration: 1, delay: 1.3 },
          }),
        ])
        await Promise.all([
          item1Control.start({
            y: '0%',
            opacity: 1,
            transition: { duration: 0.5 },
          }),
          item2Control.start({
            y: '0%',
            opacity: 1,
            transition: { duration: 0.5 },
          }),
          item3Control.start({
            y: '0%',
            opacity: 1,
            transition: { duration: 0.5 },
          }),
          item4Control.start({
            y: '0%',
            opacity: 1,
            transition: { duration: 0.5 },
          }),
          item5Control.start({
            y: '0%',
            opacity: 1,
            transition: { duration: 0.5 },
          }),
        ])
      })()
    }, [
      aIconControl,
      borderControl,
      borderDoorControl,
      insideControl,
      item1Control,
      item2Control,
      item3Control,
      item4Control,
      item5Control,
      leftControl,
      rightControl,
    ])

    return (
      <Box
        ref={ref}
        {...props}
        sx={{
          height: 'auto',
          aspectRatio: 417 / 620,
          position: 'relative',
          mx: '80px',
          ...props.sx,
        }}
      >
        <motion.div
          animate={borderControl}
          style={{
            width: '100%',
            height: '100%',
            border: '16px solid #63EA71',
            borderBottom: 0,
            borderRadius: '72px 72px 0px 0px',
            padding: '16px 16px 0px 16px',
            background: '#F6F7FD',
            boxSizing: 'border-box',
          }}
        >
          <motion.div
            style={{
              border: '1px solid #000',
              borderRadius: '40px 40px 0px 0px',
              borderBottom: 0,
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
            animate={borderDoorControl}
          >
            <Box
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                background: '#000',
              }}
            >
              <motion.div
                animate={insideControl}
                style={{ width: '100%', height: '100%' }}
                initial={{ opacity: 0 }}
              >
                <img
                  src={InsideDoorImage}
                  alt="InsideDoor"
                  style={{ width: '100%', height: 'auto' }}
                />
              </motion.div>
            </Box>
            <motion.div
              animate={leftControl}
              style={{ width: '50%', height: '100%', overflow: 'hidden' }}
              initial={{ x: '0%' }}
            >
              <img
                src={ApeTogetherDoor}
                alt="ApeTogether"
                style={{ width: '200%', height: 'auto' }}
              />
            </motion.div>
            <motion.div
              animate={rightControl}
              style={{
                width: '50%',
                height: '100%',
                overflow: 'hidden',
                position: 'relative',
              }}
              initial={{ x: '0%' }}
            >
              <img
                src={ApeTogetherDoor}
                alt="ApeTogether"
                style={{
                  width: '200%',
                  height: 'auto',
                  position: 'absolute',
                  right: '0',
                }}
              />
            </motion.div>
            <motion.div
              animate={aIconControl}
              style={{ position: 'absolute', width: '53.6%', aspectRatio: 1 }}
              initial={{ x: '0%', y: '0%', rotate: '0deg' }}
            >
              <img
                src={AIcon}
                alt="AIcon"
                style={{ width: '100%', height: '100%' }}
              />
            </motion.div>
          </motion.div>
        </motion.div>
        <motion.div
          animate={item1Control}
          style={{
            position: 'absolute',
            minWidth: 200,
            padding: '16px 20px',
            left: -100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            top: '10%',
            background: '#FFF',
            gap: '4px',
            borderRadius: '20px',
          }}
          initial={{ y: '50%', opacity: 0 }}
        >
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '36px',
              color: '#FF7722',
              lineHeight: '40px',
            }}
          >
            $65M+
          </Box>
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              color: '#000',
              letterSpacing: '-2%',
            }}
          >
            Total Value Distributed
          </Box>
        </motion.div>
        <motion.div
          animate={item2Control}
          style={{
            position: 'absolute',
            minWidth: 200,
            padding: '16px 20px',
            left: -150,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            top: '40%',
            background: '#FFF',
            gap: '4px',
            borderRadius: '20px',
          }}
          initial={{ y: '50%', opacity: 0 }}
        >
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '36px',
              color: '#000',
              lineHeight: '40px',
            }}
          >
            1,712,421
          </Box>
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              color: '#000',
              letterSpacing: '-2%',
            }}
          >
            Active Arcade Players
          </Box>
        </motion.div>
        <motion.div
          animate={item3Control}
          style={{
            position: 'absolute',
            minWidth: 200,
            padding: '16px 20px',
            left: -90,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            top: '70%',
            background: '#FFF',
            gap: '4px',
            borderRadius: '20px',
          }}
          initial={{ y: '50%', opacity: 0 }}
        >
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '36px',
              color: '#FF7722',
              lineHeight: '40px',
            }}
          >
            $7.5M+
          </Box>
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              color: '#000',
              letterSpacing: '-2%',
            }}
          >
            Amount Raised
          </Box>
        </motion.div>
        <motion.div
          animate={item4Control}
          style={{
            position: 'absolute',
            minWidth: 200,
            padding: '16px 20px',
            right: -100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            top: '25%',
            background: '#FFF',
            gap: '4px',
            borderRadius: '20px',
          }}
          initial={{ y: '50%', opacity: 0 }}
        >
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '36px',
              color: '#000',
              lineHeight: '40px',
            }}
          >
            $1.85B+
          </Box>
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              color: '#000',
              letterSpacing: '-2%',
            }}
          >
            Connected Assets
          </Box>
        </motion.div>
        <motion.div
          animate={item5Control}
          style={{
            position: 'absolute',
            minWidth: 200,
            padding: '16px 20px',
            right: -110,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            top: '65%',
            background: '#FFF',
            gap: '4px',
            borderRadius: '20px',
          }}
          initial={{ y: '50%', opacity: 0 }}
        >
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '36px',
              color: '#000',
              lineHeight: '40px',
            }}
          >
            521,459
          </Box>
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              color: '#000',
              letterSpacing: '-2%',
            }}
          >
            Participants
          </Box>
        </motion.div>
      </Box>
    )
  },
)
export default ApeTogetherAnimate
