import { Box, useMediaQuery } from '@mui/material'
import { TextLogo } from '../../assets'
import { PATHS } from '../../constants/config'
import { Link, useLocation } from 'react-router-dom'
import navList from './list'
import { useEffect, useState } from 'react'
import { Container } from '../../components/Navbar/styles'
import NavItemHome from './NavItemHome'
import MobileNavbar from './MobileNavbar'
import ButtonArcade from './ButtonLaunchpad'
import Navbar from '../../components/Navbar'


const NavBarHome = () => {
  const { pathname } = useLocation()
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [isSticky, setSticky] = useState(false)
  const isDown1200 = useMediaQuery('(max-width:1200px)')
  const isDown1050 = useMediaQuery('(max-width:1050px)')

  const handleScroll = () => {
    const currentScrollPos = window.scrollY
    setSticky(currentScrollPos > 0)
    setPrevScrollPos(currentScrollPos)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos])
  return (
    <Container
      container
      padding={isDown1200 ? '12px 15px' : '18px 24px 18px 24px'}
      sx={{
        position: isSticky ? 'fixed' : 'absolute',
        top: 0,
        width: '100%',
        height: isSticky ? '70px' : 'auto',
        backdropFilter: isSticky ? 'blur(8px)' : 'none',
        backgroundColor: '#fff',
        opacity: isSticky ? 1 : 0.8,
        zIndex: isSticky ? 1001 : 1000,
        transition:
          'background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, height 0.3s ease-in-out',
        marginBottom: '100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap:'32px',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
          <Link to={PATHS.landingPage}>
            <img src={TextLogo} alt="logo" width="134" height="36" />
          </Link>
        </Box>
        {/* <Box
          sx={{
            gap: '2px',
            padding: '5px 3px',
            alignSelf: 'center',
            maxHeight: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {navList.map(nav => {
            return (
              <NavItemHome
                key={nav.name}
                text={nav.name}
                href={nav.href}
                target={nav.target}
                textStyles={{
                  fontSize: 18,
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  border: 'none',
                  backgroundColor:
                    pathname === nav.href
                      ? 'rgba(111, 235, 124, 0.03)'
                      : 'transparent',
                  color: pathname === nav.href ? '#63EA71' : '#000',
                }}
                active={pathname === nav.href}
              />
            )
          })}
        </Box>
        <ButtonArcade /> */}
        <Box
          sx={{
            gap: '2px',
            padding: '5px 3px',
            alignSelf: 'center',
            maxHeight: '48px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#F4FDAB',
            width: '114px',
            height: '48px',
            borderRadius: '50px',
            border: '1px solid #000',
            textAlign: 'center',
            cursor: 'pointer',
            transition: 'ease-in-out 0.3s',
            '&:hover': {
              backgroundColor: '#FF7722',
            }
          }}
          onClick={() => {window.open(PATHS.home)}}
        >
          <span style={{color: '#000', fontSize: '15px', display: 'block', width: '100%'}}>Launch App</span>
        </Box>

        {/* Hidden element for notification landing page*/}
        <Box sx={{display: 'none'}}>
          <Navbar isLoading={false} giftBannerHeight={'16px'} isIncludeLoginModal={false} />
        </Box>
      </Box>
    </Container>
  )
}
export default NavBarHome
