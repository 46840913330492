import { FormControl, TextField, Typography } from '@mui/material'
import { Box, useMediaQuery } from '@mui/system'
import { isArray, noop } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CancelIcon } from '../../assets'
import { VerifiedIcon } from '../../assets/revamp/icons/VerifiedIcon'
import ConfirmModal, { ConfirmModalProps } from '../../components/ConfirmModal'
import GreenButton from '../../components/GreenButton'
import VerifyEmailModal, {
  EVerifyEmailActionType,
} from '../../components/VerifyEmailModal'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setSnackbar } from '../../redux/slices/themeSlice'
import {
  useFetchUserSubscribeQuery,
  useRequestOtpMutation,
  useSubscribeForNewsLetterAuthMutation,
  useUnSubscribeForNewsLetterMutation,
} from '../../services/modules/users'
import { validateEmail } from '../../utils/function'

const SubscribeSubscription: React.FC = () => {
  const [subscribeForNewsLetterAuth] = useSubscribeForNewsLetterAuthMutation()
  const [unSubscribeForNewsLetter] = useUnSubscribeForNewsLetterMutation()
  const [requestOtp] = useRequestOtpMutation()

  const isDown767 = useMediaQuery('(max-width:767px)')
  const dispatch = useAppDispatch()

  const user = useAppSelector(state => state.user)
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false)
  const [confirmModalProps, setConfirmModalProps] =
    useState<ConfirmModalProps | null>(null)

  const { data, refetch } = useFetchUserSubscribeQuery(undefined, {
    skip: !user.accessTokenGoogle && !user.mainAppToken,
  })

  const isEmailVerified = useMemo(
    () => !!data?.verifiedAt && data?.receivedEmail,
    [data],
  )

  const [valueEmail, setValueEmail] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [isSubscribed, setIsSubscribed] = useState(data?.isSubscribed || false)
  const [focusVisible, setFocusVisible] = useState(false)
  const [nextSendableTime, setNextSendableTime] = useState<number | undefined>(
    undefined,
  )
  const [isUnsubscribing, setIsUnsubscribing] = useState(false)
  const [isMainButtonLoading, setIsMainButtonLoading] = useState(false)

  const showMessage = useCallback(
    (message: string, severity?: 'success' | 'error') =>
      dispatch(
        setSnackbar({
          open: true,
          severity: severity ?? 'error',
          message,
        }),
      ),
    [dispatch],
  )

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value
    setValueEmail(email)
    if (email && !validateEmail(email)) {
      setError('Please enter a valid email address.')
    } else {
      setError(null)
    }
  }

  useEffect(() => {
    if (data?.isSubscribed !== undefined) {
      setIsSubscribed(data.isSubscribed)
    }
    if (data?.receivedEmail) {
      setValueEmail(data?.receivedEmail)
    }
  }, [data])

  useEffect(() => {
    if ((user.email || user.emailRegisterUserAccount) && !data?.receivedEmail) {
      setValueEmail(user.email || user.emailRegisterUserAccount || '')
    }
  }, [user.email, user.emailRegisterUserAccount])

  const handleUnSubscribe = useCallback(async () => {
    try {
      setIsUnsubscribing(true)
      setIsSubscribed(false)
      if (user.accessTokenGoogle || user.mainAppToken) {
        const result = await unSubscribeForNewsLetter().unwrap()
        if (result) {
          showMessage(
            `${result.message.split('!')[0]} ${valueEmail} !`,
            'success',
          )
          setValueEmail('')
          refetch()
        }
      }
    } catch (error) {
      refetch()
      setIsSubscribed(false)
      console.log(error, 'error')
    } finally {
      setIsUnsubscribing(false)
      // setShowConfirmModal(false)
    }
  }, [
    refetch,
    showMessage,
    unSubscribeForNewsLetter,
    user.accessTokenGoogle,
    user.mainAppToken,
    valueEmail,
  ])

  const handleVerifyEmail = useCallback(
    async (isResend?: boolean, newEmail?: string) => {
      try {
        setIsMainButtonLoading(true)
        const response = await requestOtp({
          email: newEmail ?? data?.receivedEmail ?? '',
        }).unwrap()
        if (response?.nextSendableTime) {
          setNextSendableTime(response?.nextSendableTime)
          showMessage(` ${response?.message}!`, 'success')
          if (isResend) {
            return
          }
          setShowVerifyEmailModal(true)
        }
      } catch (error: any) {
        const displayMessage = isArray(error?.data?.message)
          ? error?.data?.message?.[0]
          : error?.data?.message

        showMessage(displayMessage)
        refetch()
      } finally {
        setIsMainButtonLoading(false)
      }
    },
    [requestOtp, data?.receivedEmail, showMessage, refetch],
  )

  const handleSubscribe = useCallback(async () => {
    if (!validateEmail(valueEmail)) {
      setError('Please enter a valid email address.')
      return
    }

    try {
      // setIsSubscribed(true)
      setIsMainButtonLoading(true)
      const result = await subscribeForNewsLetterAuth({
        email: valueEmail,
      }).unwrap()
      if (result) {
        const exclamationCount = result.message.split('!').length - 1
        const parts = result.message.split('!')
        let message = `${parts[0]}!`

        if (exclamationCount > 1) {
          message += `${parts[1]} ${valueEmail} !`
        } else {
          message += `${parts[1]} ${valueEmail} !`
        }

        showMessage(message, 'success')
        refetch()
        handleVerifyEmail(false, valueEmail)
      }
    } catch (error: any) {
      const { message } = error.data
      setError(message)
      refetch()
      setIsSubscribed(false)
      console.log(error, 'error')
    } finally {
      setIsMainButtonLoading(false)
    }
  }, [
    handleVerifyEmail,
    refetch,
    showMessage,
    subscribeForNewsLetterAuth,
    valueEmail,
  ])

  const onFocusVisible = (visible: boolean) => {
    setFocusVisible(visible)
  }

  const buttonInfo = useMemo(() => {
    if (isSubscribed && data?.receivedEmail && !!data?.verifiedAt) {
      return {
        label: 'Subscribed',
        handler: () => {
          setConfirmModalProps({
            open: true,
            onClose: () => setConfirmModalProps(null),
            desc: 'You will miss out opportunitites! Are you sure you want to unsubscribe to Ape Newsletter?',
            onCancel: () => setConfirmModalProps(null),
            onConfirm: async () => {
              await handleUnSubscribe()
              setConfirmModalProps(null)
            },
            buttonDisabled: isUnsubscribing,
            buttonLoading: isUnsubscribing,
          })
        },
      }
    }

    if (!isSubscribed && data?.receivedEmail && !data?.verifiedAt) {
      return {
        label: 'Verify',
        handler: () => handleVerifyEmail(),
      }
    }

    return {
      label: 'Subscribe',
      handler: () => handleSubscribe(),
    }
  }, [
    data?.receivedEmail,
    data?.verifiedAt,
    handleSubscribe,
    handleUnSubscribe,
    handleVerifyEmail,
    isSubscribed,
    isUnsubscribing,
  ])

  return (
    <Box width={'100%'}>
      <Box
        sx={{
          paddingBottom: '153px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'start',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'start',
            gap: '16px',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: '#fff',
              fontFamily: 'Hellix',
              fontSize: '24px',
              fontWeight: '600',
              lineHeight: '32px',
              textAlign: 'left',
            }}
          >
            Subscription
          </Typography>
          <Box
            sx={{
              border: '1px solid #00000033',
              borderRadius: '16px',
              display: 'flex',
              background: '#0f1113',
              justifyContent: 'space-between',
              flexDirection: isDown767 ? 'column' : 'row',
              alignItems: isDown767 ? 'start' : 'center',
              padding: '12px 16px',
              width: '100%',
              gap: isDown767 ? '16px' : '',
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: '#fff',
                  fontFamily: 'Hellix',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                Subscribe to our newsletter
              </Typography>
              <Typography
                sx={{
                  color: '#7E8180',
                  fontFamily: 'Inter',
                  fontSize: '13px',
                  fontWeight: '400',
                  lineHeight: '15.6px',
                  paddingTop: '10px',
                }}
              >
                Subscribe to receive emails on our latest project, don’t miss
                your investment opportunity
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isDown767 ? 'column' : 'row',
                alignItems: isDown767 ? 'start' : 'center',
                gap: '16px',
              }}
            >
              <FormControl fullWidth={isDown767 ? true : false}>
                <TextField
                  value={valueEmail}
                  placeholder="Enter your email"
                  error={!!error}
                  helperText={error}
                  onChange={handleEmailChange}
                  onFocus={() => {
                    onFocusVisible && onFocusVisible(true)
                  }}
                  onBlur={() => {
                    onFocusVisible && onFocusVisible(false)
                  }}
                  autoComplete="off"
                  variant="outlined"
                  InputProps={{
                    readOnly: isSubscribed || !!data?.receivedEmail,
                    renderSuffix: () => {
                      // if (!isSubscribed && !data?.receivedEmail) {
                      //   return null
                      // }

                      if (isEmailVerified) {
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: '10px',
                            }}
                          >
                            <VerifiedIcon />
                          </Box>
                        )
                      }

                      if (
                        !isSubscribed &&
                        data?.receivedEmail &&
                        !data?.verifiedAt
                      ) {
                        return (
                          <img
                            src={CancelIcon}
                            alt="cancel-icon"
                            onClick={() =>
                              setConfirmModalProps({
                                open: true,
                                onClose: () => setConfirmModalProps(null),
                                desc: `Are you sure you want to remove email: ${data?.receivedEmail}? You can update your email later`,
                                onCancel: () => setConfirmModalProps(null),
                                onConfirm: async () => {
                                  await handleUnSubscribe()
                                  setConfirmModalProps(null)
                                },
                                buttonDisabled: isUnsubscribing,
                                buttonLoading: isUnsubscribing,
                              })
                            }
                            style={{
                              cursor: 'pointer',
                              width: '20px',
                              height: '20px',
                              borderRadius: '100%',
                              backgroundColor: '#1D1F21',
                              padding: '4px',
                              color: '#B1B4B3',
                              marginRight: '10px',
                            }}
                          />
                        )
                      }

                      return null
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      '&:hover': {
                        '& fieldset': { border: 'none' },
                      },
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px !important',
                      border:
                        focusVisible && !error
                          ? '0.5px solid white'
                          : '0.5px solid rgba(255, 255, 255, 0.16)',
                      fontFamily: 'Inter',
                      fontSize: '17px',
                      fontWeight: '500',
                      lineHeight: '32px',
                      color: '#fff !important',
                      outline: 'unset !important',
                    },

                    '& .MuiOutlinedInput-input': {
                      color: '#fff !important',
                      outline: 'unset !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      outline: 'unset !important',
                    },
                  }}
                />
              </FormControl>
              <GreenButton
                fs={15}
                lh="22.5px"
                fm="Inter"
                fw={400}
                br="24px"
                text={buttonInfo?.label}
                onClick={buttonInfo?.handler || noop}
                isLoading={isMainButtonLoading}
                disabled={isMainButtonLoading}
                outlined={true}
                w={'fit-content'}
                sx={{
                  minWidth: '71px',
                  border: '1px solid #08493C',
                  padding: '16px',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    border: '1px solid #27B079',
                    backgroundColor: 'transparent',
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {showVerifyEmailModal ? (
        <VerifyEmailModal
          open={showVerifyEmailModal}
          onClose={() => setShowVerifyEmailModal(false)}
          actionType={EVerifyEmailActionType.verifyEmail}
          onResend={() => handleVerifyEmail(true)}
          onSuccess={message => {
            showMessage(message ?? '', 'success')
            refetch()
          }}
          onError={message => {
            showMessage(message ?? '')
          }}
          subcribedEmail={data?.receivedEmail}
          nextSendableTime={nextSendableTime}
        />
      ) : null}

      {confirmModalProps ? <ConfirmModal {...confirmModalProps} /> : null}
    </Box>
  )
}

export default SubscribeSubscription
