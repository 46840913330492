import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import {
  FilledBlackArrow,
  SolanaLogo,
  SolanaPattern,
  Disconnect,
} from '../../assets'
import GreenButton from '../../components/GreenButton'
import { useState } from 'react'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import React, { useCallback } from 'react'
import toast from 'react-hot-toast'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { APPLICATION_WEB3_SIGNIN_MSG } from '../../constant'
import { getExceptionMessage } from '../../utils'
import { base58 } from 'ethers/lib/utils'
import _ from 'lodash'

const WalletSignSolana = ({ onSubmit, isBusy: _isBusy }: any) => {
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const { setVisible } = useWalletModal()
  const { connection } = useConnection()
  const { publicKey, sendTransaction, wallet, connected, disconnect } =
    useWallet()

  const _onSubmit = async () => {
    setIsBusy(true)
    const message = APPLICATION_WEB3_SIGNIN_MSG
    try {
      if (!publicKey || !connected || !wallet)
        throw new Error('Sol wallet not connected')
      const signMessageFn = _.get(wallet, 'adapter.signMessage')
      if (typeof signMessageFn != 'function') {
        throw new Error('Sol wallet does not support message signing')
      }
      const signature = base58.encode(
        // @ts-ignore
        await wallet.adapter.signMessage(new TextEncoder().encode(message)),
      )
      onSubmit({
        solanaAddress: publicKey.toString(),
        signature,
        message,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsBusy(false)
    }
  }

  const connectedSolanaAddress = publicKey ? publicKey.toString() : ''

  return (
    <Box
      sx={{
        backgroundColor: '#000000',
        flex: 1,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: isDown1000 ? '32px' : '0px',
        width: '100%',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        height={'100%'}
        textAlign={'center'}
        paddingX={isDown1000 ? '32px' : '10%'}
        width={'100%'}
      >
        <img
          src={isDown1000 ? SolanaPattern : SolanaLogo}
          alt="solana-logo"
          style={{
            width: isDown1000 ? '208px' : '64px',
            height: isDown1000 ? '208px' : '64px',
          }}
        />
        <Typography
          pt={isDown1000 ? 0 : '32px'}
          marginTop={isDown1000 ? '-45px' : '0px'}
          pb={isDown1000 ? 0 : '24px'}
          fontFamily={'Hellix'}
          fontSize={32}
          fontWeight={600}
          lineHeight={'40px'}
          color={'#FFFFFF'}
        >
          Solana address
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyItems: 'center',
          }}
        >
          <Box
            component={'input'}
            type="text"
            placeholder=""
            value={connectedSolanaAddress || 'please connect wallet'}
            readOnly
            sx={{
              width: '100%',
              height: '48px',
              padding: '0 16px',
              marginTop: isDown1000 ? '40px' : '8px',
              borderRadius: '8px',
              border: '1px solid rgba(255, 255, 255, 0.09)',
              backgroundColor: '#0F1113',
              color: '#7E8180',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '150%',
              caretColor: '#63EA71',
              caretShape: 'block',
              '&:focus-visible': {
                outline: 'none',
                border: '1px solid rgba(255, 255, 255, 0.09)',
              },
            }}
          />
          <Box
            sx={{
              height: '48px',
              padding: '0 0 0 16px',
              marginTop: isDown1000 ? '40px' : '8px',
              cursor: 'pointer',
              display: !publicKey ? 'none' : 'auto',
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Button
              disableRipple
              sx={{
                padding: 0,
                minWidth: 'auto',
              }}
              onClick={() => {
                disconnect()
              }}
            >
              <img src={Disconnect} width={44} height={44} />
            </Button>
          </Box>
        </Box>
        <Box mt={'24px'}>
          <span>
            This is the wallet you will receive your IDO tokens.
            However you will still need to fund the IDO with your BSC wallet.
          </span>
        </Box>
        <GreenButton
          disabled={isBusy || _isBusy}
          fs={15}
          lh="22.5px"
          fm="Inter"
          fw={400}
          br="24px"
          text={connected ? 'Submit Address' : 'Connect'}
          mt={3}
          onClick={
            connected
              ? _onSubmit
              : () => {
                  setVisible(true)
                }
          }
          w="auto"
          IconStart={() => (
            <img src={FilledBlackArrow} alt="filled-black-arrow" />
          )}
        />
      </Box>
    </Box>
  )
}

export default WalletSignSolana
