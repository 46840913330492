import { CloseOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { useSearchParams } from 'react-router-dom'
import { formatAddress } from '../..//utils/function'
import searchIcon from '../../assets/revamp/icons/search.svg'
import bscIcon from '../../assets/revamp/images/tokens/Bitcoin.png'
import ethereumIcon from '../../assets/revamp/images/tokens/Ethereum.png'
import gnosiscIcon from '../../assets/revamp/images/tokens/Gnosis.png'
import hecoIcon from '../../assets/revamp/images/tokens/Heco.png'
import polygonIcon from '../../assets/revamp/images/tokens/Polygon.png'
import pulseIcon from '../../assets/revamp/images/tokens/PulseChain.png'
import Icon from '../../components/common/Icon'
import { useAppSelector } from '../../hooks'
import StorageService from '../../utils/storage'

interface SearchItem {
  address: string
}

export interface IWalletAddressItemProps {
  inputStyles?: React.CSSProperties
  wrapperStyles?: React.CSSProperties
  defaultWrapperClzName?: string
  defaultInputClzName?: string
}

const WalletTrackingItem: React.FC<IWalletAddressItemProps> = ({
  defaultWrapperClzName = '',
  defaultInputClzName = '',
  inputStyles = {},
  wrapperStyles = {},
}) => {
  const user = useAppSelector(state => state.user)
  const { walletAddress, mainAppToken } = user
  const [, setSearchParams] = useSearchParams({
    address: !!mainAppToken ? walletAddress : '',
  })

  const [recentSearches, setRecentSearch] = useState<SearchItem[] | null>(null)
  const [address, setAddress] = useState<string>('')
  const [isOpenRecent, setOpenRecent] = useState<boolean>(false)
  const recentSearchRef = useRef<HTMLDivElement>(null)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    setAddress(value)
  }

  const handleKeyup = (event: KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault()

    if (event.key === 'Enter') {
      if (address) {
        setSearchParams({ address })
        // Find and update history search
        let history = StorageService.get('history') as SearchItem[]
        if (!history) {
          StorageService.set('history', [{ address }])
        } else {
          let addresses = history.filter(i => i.address === address)
          if (!addresses.length) {
            history.push({ address })
            StorageService.set('history', history)
          }
        }

        setOpenRecent(true)
        setRecentSearch(history)
        setAddress('')
      }
    }
  }

  const handleClickInput = () => {
    if (recentSearches && recentSearches.length) {
      setOpenRecent(true)
    }
  }

  const handleClickRecentItem = (address: string) => {
    setSearchParams({ address })
    setOpenRecent(false)
    // setOpenBundle(false)
  }

  const handleRemoveRecentSearch = (address: string) => {
    let history = StorageService.get('history') as SearchItem[]
    if (history) {
      let index = history.findIndex(i => i.address === address)
      if (index !== -1) {
        history.splice(index, 1)
        StorageService.set('history', history)
        setRecentSearch(history)
      }
    }
  }

  useEffect(() => {
    const handleClick = (event: Event) => {
      if (
        recentSearchRef.current &&
        !recentSearchRef.current.contains(event.target as Node) &&
        (event.target as HTMLElement).tagName !== 'INPUT'
      ) {
        setOpenRecent(false)
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    let history = StorageService.get('history') as SearchItem[]
    setRecentSearch(history)
  }, [])

  return (
    <div
      className={`search-container ${defaultWrapperClzName}`}
      style={{ ...wrapperStyles }}
    >
      <Input
        prefix={<Icon src={searchIcon} alt="Search" />}
        className={`${isOpenRecent ? 'active' : ''} ${defaultInputClzName}`}
        type="text"
        placeholder="Track any wallet address or ENS name"
        value={address}
        onChange={handleInputChange}
        onKeyUp={handleKeyup}
        onClick={handleClickInput}
        style={{ ...inputStyles }}
      />

      {isOpenRecent && (
        <div className="search-recent" ref={recentSearchRef}>
          <p>Recent Searches:</p>
          <div className="recent-box">
            <ul className="recent-addresses">
              {recentSearches &&
                [...recentSearches].reverse().map(search => (
                  <li className="search-item" key={search.address}>
                    <div
                      className="search-item__container"
                      onClick={() => {
                        handleClickRecentItem(search.address)
                      }}
                    >
                      <div className="search-item__logo">
                        <Jazzicon
                          diameter={24}
                          seed={jsNumberForAddress(search.address)}
                        />
                      </div>
                      <div className="search-item__info">
                        <p className="search-address">
                          {formatAddress(search.address)}
                        </p>
                        <div className="search-item__networks">
                          <img src={bscIcon} alt="" />
                          <img src={ethereumIcon} alt="" />
                          <img src={gnosiscIcon} alt="" />
                          <img src={hecoIcon} alt="" />
                          <img src={polygonIcon} alt="" />
                          <img src={pulseIcon} alt="" />

                          <div className="network-count">17</div>
                        </div>
                      </div>
                    </div>
                    <CloseOutlined
                      onClick={() => handleRemoveRecentSearch(search.address)}
                    />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default WalletTrackingItem
