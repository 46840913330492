export const PATHS = {
  landingPage: '/',
  home: '/app',
  userPortal: '/user-portal',
  portfolio: '/portfolio-manager',
  connectFi: '/connectFi',
  investmentPortal: '/project',
  apeArcade: 'https://arcade.apeterminal.io',
  launchpad: '/launchpad',
  launchPools: '/launch-pools',
  yieldbox: '/yieldbox',
  spend: '/spend',
  trading: '/trading',
  account: '/portfolio',
  settings: '/settings',
  news: '/news',
  guide: '/guide',
  faq: '/faq',
  transparency: '/transparency',
  whitepaper: '/whitepaper',
  register: '/launchpad/:id',
  farmingDetail: '/connectfi/:id',
  about: {
    web3General: '/about/web3-general',
    web2Institutions: '/about/web2-institutions',
    web2Retail: '/about/web2-retail',
  },
}

export const labelNavbar = {
  Home: 'Home',
  launchpad: 'Launchpad',
  userPortal: 'User Portal',
  portfolio: 'Portfolio Manager',
  connectFi: 'ConnectFi',
  investmentPortal: 'Investment Portal',
  apeArcade: 'Ape Arcade',
  // launchpad: 'Investment Portal',
  launchPools: 'Launch Pools',
  yieldBox: 'YieldBox',
  spend: 'SPEND',
  Trading: 'Trading',
  account: 'Account',
  settings: 'Settings',
  faq: 'FAQ',
  transaction: 'Transaction',
  about: {
    title: 'About',
    web3General: 'Web3 General',
    web2Institutions: 'Web2 Institutions',
    web2Retail: 'Web2 Retail',
  },
  product: {
    title: 'Product',
    investmentPortal: 'Investment Portal',
  },
  transparency: 'Transparency',
  apeActivator: 'Ape Activator',
  news: 'News',
  guide: 'Guide',
  whitepaper: 'Whitepaper',
}
