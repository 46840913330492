import { Box, BoxProps } from '@mui/system'
import { forwardRef, useRef } from 'react'
import { ApeTogether } from '../../assets'

const ApeTogetherMobile = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const item1Ref = useRef<HTMLDivElement | null>(null)
  const item2Ref = useRef<HTMLDivElement | null>(null)

  const handleScroll: BoxProps['onScroll'] = e => {
    const getOpacity = (top: number) =>
      Math.min(1, Math.max(0, (top - e.currentTarget.scrollTop) / 100)).toFixed(
        2,
      )
    if (item1Ref.current) item1Ref.current.style.opacity = getOpacity(282)
    if (item2Ref.current) item2Ref.current.style.opacity = getOpacity(382)
  }

  return (
    <Box
      ref={ref}
      {...props}
      sx={{
        width: '100%',
        height: 442,
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'center',
        ...props.sx,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          textAlign: 'center',
          pointerEvents: 'none',
        }}
      >
        <img
          src={ApeTogether}
          alt="ApeTogether"
          style={{
            width: 'auto',
            height: '100%',
            aspectRatio: 417 / 627,
            margin: '0px auto',
          }}
        />
      </Box>
      <Box
        sx={{
          width: 'calc(100% + 40px)',
          height: '100%',
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'auto',
          mx: '-20px',
          zIndex: 1,
        }}
        onScroll={handleScroll}
      >
        <Box
          sx={{
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '20px',
            paddingTop: '300px',
            paddingBottom: '20px',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Box
            ref={item1Ref}
            sx={{
              minWidth: 200,
              padding: '16px 20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#FFF',
              gap: '4px',
              borderRadius: '20px',
            }}
          >
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '36px',
                color: '#FF7722',
                lineHeight: '40px',
              }}
            >
              $65M+
            </Box>
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                color: '#000',
                letterSpacing: '-2%',
              }}
            >
              Total Value Distributed
            </Box>
          </Box>
          <Box
            ref={item2Ref}
            sx={{
              minWidth: 200,
              padding: '16px 20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#FFF',
              gap: '4px',
              borderRadius: '20px',
            }}
          >
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '36px',
                color: '#000',
                lineHeight: '40px',
              }}
            >
              1,712,421
            </Box>
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                color: '#000',
                letterSpacing: '-2%',
              }}
            >
              Active Arcade Players
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: 200,
              padding: '16px 20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#FFF',
              gap: '4px',
              borderRadius: '20px',
            }}
          >
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '36px',
                color: '#FF7722',
                lineHeight: '40px',
              }}
            >
              $7.5M+
            </Box>
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                color: '#000',
                letterSpacing: '-2%',
              }}
            >
              Amount Raised
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: 200,
              padding: '16px 20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#FFF',
              gap: '4px',
              borderRadius: '20px',
            }}
          >
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '36px',
                color: '#000',
                lineHeight: '40px',
              }}
            >
              $1.85B+
            </Box>
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                color: '#000',
                letterSpacing: '-2%',
              }}
            >
              Connected Assets
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: 200,
              padding: '16px 20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#FFF',
              gap: '4px',
              borderRadius: '20px',
            }}
          >
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '36px',
                color: '#000',
                lineHeight: '40px',
              }}
            >
              521,459
            </Box>
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                color: '#000',
                letterSpacing: '-2%',
              }}
            >
              Participants
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
})
export default ApeTogetherMobile
