import { CHAIN_ID as SUPPORTED_CHAIN_ID } from './constants/chains';
import {
  PRODUCTION_ENV,
  // UAT_ENV,
  // DEV_ENV,
} from "./constants/environments";
import { ethers } from 'ethers';
import _ from 'lodash';

// Env variable by each environment
export const APP_ENV = process.env.REACT_APP_NODE_ENV || 'production';
const ENVs = (envName = 'production') => {
  let envs = {
    production: PRODUCTION_ENV,
    // uat: UAT_ENV,
    // development: DEV_ENV,
  }

  // @ts-ignore
  return envs[envName] || PRODUCTION_ENV;
}

export const IS_DEV_MODE = !['production', 'uat'].includes(APP_ENV);
export const IS_PRODUCTION = APP_ENV === 'production';

export const TOAST_NOTIFICATION_TIMEOUT = process.env.REACT_APP_TOAST_NOTIFICATION_TIMEOUT || 10000 //ms
export const CHAIN_ID = SUPPORTED_CHAIN_ID;
export const MAIN_CHAIN_ID = IS_DEV_MODE ? CHAIN_ID.BSC_TESTNET : CHAIN_ID.BSC ;
export const APPLICATION_URL = window.location.origin
export const APPLICATION_NAME = 'Ape Launchpad'
export const KYC_CLIENT_ID = ENVs(APP_ENV).KYC_CLIENT_ID || 'ape_terminal_9eafe';
export const APPLICATION_WEB3_SIGNIN_MSG = `Sign in to the ${APPLICATION_NAME}`
export const SERVER_URL = ENVs(APP_ENV).SERVER_URL || 'https://api-ng.apeterminal.io/api';
export const GOOGLE_CLIENT_ID = ENVs(APP_ENV).GOOGLE_CLIENT_ID || "733766919259-u5t95890bv6q8beidh2i7bk0qef0q5fd.apps.googleusercontent.com";
export const APP_V1_URL = 'https://launchpad-dec2024.apeterminal.io';

// gulf countries are not allowed to join the sales
export const IS_GCC_WEBSITE = window.location.hostname === "gcc.apeterminal.io";

// export const RPC_URL = {
//   BSC:  "https://bsc.blockpi.network/v1/rpc/public",
//   ETH: "https://ethereum.blockpi.network/v1/rpc/public",
//   ARB: "https://arbitrum.blockpi.network/v1/rpc/public",
//   AVAX: "https://avalanche.blockpi.network/v1/rpc/public",
// };

export const RPC_URL = {
  BSC:  "https://rpc.ankr.com/bsc/aac5fc0039a8c2fcd977e0db92fdc26c8126b2e45974f6bfb8b97c26be4578fb",
  [CHAIN_ID.BSC]: "https://rpc.ankr.com/bsc/aac5fc0039a8c2fcd977e0db92fdc26c8126b2e45974f6bfb8b97c26be4578fb",
  ETH: "https://rpc.ankr.com/eth/aac5fc0039a8c2fcd977e0db92fdc26c8126b2e45974f6bfb8b97c26be4578fb",
  [CHAIN_ID.ETH]: "https://rpc.ankr.com/eth/aac5fc0039a8c2fcd977e0db92fdc26c8126b2e45974f6bfb8b97c26be4578fb",
  POLYGON: "https://rpc.ankr.com/polygon/aac5fc0039a8c2fcd977e0db92fdc26c8126b2e45974f6bfb8b97c26be4578fb",
  [CHAIN_ID.POLYGON]: "https://rpc.ankr.com/polygon/aac5fc0039a8c2fcd977e0db92fdc26c8126b2e45974f6bfb8b97c26be4578fb",
  ARB: "https://rpc.ankr.com/arbitrum/aac5fc0039a8c2fcd977e0db92fdc26c8126b2e45974f6bfb8b97c26be4578fb",
  [CHAIN_ID.ARB]: "https://rpc.ankr.com/arbitrum/aac5fc0039a8c2fcd977e0db92fdc26c8126b2e45974f6bfb8b97c26be4578fb",
  AVAX: "https://rpc.ankr.com/avalanche/aac5fc0039a8c2fcd977e0db92fdc26c8126b2e45974f6bfb8b97c26be4578fb",
  [CHAIN_ID.AVAX]: "https://rpc.ankr.com/avalanche/aac5fc0039a8c2fcd977e0db92fdc26c8126b2e45974f6bfb8b97c26be4578fb",
  BSC_TESTNET: 'https://rpc.ankr.com/bsc_testnet_chapel',
  [CHAIN_ID.BSC_TESTNET]: 'https://rpc.ankr.com/bsc_testnet_chapel',
  BASE: 'https://rpc.ankr.com/base/aac5fc0039a8c2fcd977e0db92fdc26c8126b2e45974f6bfb8b97c26be4578fb',
  [CHAIN_ID.BASE]: 'https://rpc.ankr.com/base/aac5fc0039a8c2fcd977e0db92fdc26c8126b2e45974f6bfb8b97c26be4578fb',
  ETH_SEPOLIA: 'https://rpc.ankr.com/eth_sepolia',
  [CHAIN_ID.ETH_SEPOLIA]: 'https://rpc.ankr.com/eth_sepolia',
  POLYGON_AMOY: 'https://rpc.ankr.com/polygon_amoy',
  [CHAIN_ID.POLYGON_AMOY]: 'https://rpc.ankr.com/polygon_amoy',
};

export const RPC_PROVIDER = _.mapValues(RPC_URL, (v: string, k: string|number) => {
  const chainId = _.get(CHAIN_ID, k);
  return new ethers.providers.JsonRpcProvider(v, +k > 0 ? +k : chainId);
});

//@ts-ignore
export const CONTRACT_ADDRESS = ENVs(APP_ENV).CONTRACT_ADDRESS;

export const WALLET_CONNECT_PROJECT_ID = "1a7e8d507a1b268947b7a23c971a35b3";

// we try to make less database changes as possible on the migration to Azure from AWS
// so will only do a manual endpoint swap on frontend
export const ASSETS_CDN_ENDPOINT_DEPRECATED = "https://launchpad-bucket.s3.amazonaws.com";
export const ASSETS_CDN_ENDPOINT = "https://cdn.apeterminal.io";

export const ENABLE_ANALYTIC = ["www.apeterminal.io", "apeterminal.io"].includes(window.location.hostname);

export const ETH_TOPIC_HASH = {
  // Transfer (index_topic_1 address from, index_topic_2 address to, uint256 value)
  ERC20_TRANSFER: "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
}

export const DEFAULTS :Record<string, any> = {
  PROJECT_AVATAR_URL: "https://ape-launchpad-cdn.b-cdn.net/shared/comingsoon-avatar.svg",
  // PROJECT_BANNER_URL: "https://placehold.co/760x404/000000/636363.jpg"
  PROJECT_BANNER_URL: "https://ape-launchpad-cdn.b-cdn.net/shared/comingsoon-cover.svg"
}

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyCzM_krmAPuYEoFjkS8SwWdoKFYECpzJyY",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "ape-launchpad-913ab.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "ape-launchpad-913ab",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "ape-launchpad-913ab.firebasestorage.app",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "924546717394",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:924546717394:web:1082562e243411e1447f0b",
  vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY || 'BKzgphHKTNfZpI-NICW211Y57-5WSamvc11L2RAsTJgPOEsXpbiDjtEGdYDU5DDrBoM6EgJ2EuLywlsenap_DHQ',
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "G-QTC8XTJEFR"
};

// @ts-ignore
window['FIREBASE_CONFIG'] = FIREBASE_CONFIG;
