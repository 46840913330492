import BigNumber from 'bignumber.js';

/**
 * Check the args is an Object
 *
 * @param value
 *
 * @returns {boolean}
 */
function isObject(value) {
  const type = typeof value

  return value != null && (type === 'object' || type === 'function') && !Array.isArray(value)
}

/**
 * Check the args is empty
 *
 * @param value
 *
 * @returns {boolean}
 */
function isEmpty(value) {
  if (value === null || value === undefined) {
    return true
  }

  if (typeof value === 'string' && value.trim() === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return Object.entries(value).length === 0 && value.constructor === Object
}

/**
 * Listen event, handle paste plan text
 *
 * @param {Object} event
 */
const onPastePlanText = (event = null) => {
  if (event !== null) {
    event.preventDefault()
    let text = (event.originalEvent || event).clipboardData.getData('text/plain')

    // insert text manually
    document.execCommand('insertText', false, text)
  }
}

/**
 * Copy the content to clipboard
 *
 * @param {String} text
 */
const copyToClipboard = (text) => {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

/**
 * Loosely validate a URL `string`.
 * Source: https://github.com/segmentio/is-url/blob/master/index.js
 *
 * @param {String} string
 * @return {Boolean}
 */
function isUrl(string) {
  if (typeof string !== 'string') {
    return false
  }

  /* eslint-disable no-useless-escape */
  const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/
  const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/
  const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/
  /* eslint-enable */

  const match = string.match(protocolAndDomainRE)
  if (!match) {
    return false
  }

  const everythingAfterProtocol = match[1]
  if (!everythingAfterProtocol) {
    return false
  }

  return localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol)
}

/**
 * Format number with fraction.
 * 
 * @param {Number} number
 * @param {Number} min
 * @param {Number} max
 * 
 * @returns {String}
 */
function numberFormat(number, min = 0, max = 0, unit = '') {
  if(number && number < 0.01) return `<${unit}0.01`;

  // return number.toLocaleString(undefined, { minimumFractionDigits: min, maximumFractionDigits: max });
  const roundedNumber = Math.floor(number * Math.pow(10, max))
    
  const options = {
    minimumFractionDigits: min,
    maximumFractionDigits: max
  };
  return `${unit}${(roundedNumber/Math.pow(10, max)).toLocaleString(undefined, options)}`;
}

/**
 * Turn bignumber to readable number
 * 
 * @param bigNumber number
 * @param decimal number
 * 
 * @returns string|number
 */
function convertBigNumber(bigNumber, decimal = 18) {
  if (!bigNumber) return 0;

  if (bigNumber._isBigNumber) {
    return bigNumber.div(new BigNumber(10).pow(decimal).toString()).toNumber();
  }

  let number = new BigNumber(bigNumber);

  return number.div(new BigNumber(10).pow(decimal).toString()).toNumber();
}

/**
 * 
 * @param {Number} number 
 * @returns {String}
 */
function formatNumberWithSuffix(number) {
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixIndex = 0;

  while (number >= 1000 && suffixIndex < suffixes.length - 1) {
    number /= 1000;
    suffixIndex++;
  }

  const formattedNumber = suffixIndex > 0 ? number.toFixed(2) : Math.floor(number);

  return formattedNumber + suffixes[suffixIndex];
}

/**
 * Format address display
 * 
 * @param {String} address
 * @param {Number} startLength
 * @param {Number} endLength
 * 
 * @returns string
 */
function formatAddress(address = '', startLength = 6, endLength = 3) {
  if (!address) return '';

  const suffix = address.substring(0, startLength);
  const prefix = address.slice(address.length - endLength);

  return `${suffix}...${prefix}`;
}

const nFormatterSI = (number, digits = 0) => {
  if (isNaN(number) || !isFinite(number)) return "NaN";
  if (number === 0) return "0";

  const SI = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "K" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "G" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const num = Math.abs(number);
  let i;
  for (i = 0; i < SI.length - 1; i++) {
    if (num < SI[i + 1].value) {
      break;
    }
  }
  return (number / SI[i].value).toFixed(digits).replace(rx, "$1") + SI[i].symbol;
}

const formatRoundDown = (num, decimals = 2) => {
  const formattedNumber = new BigNumber(+num).toFormat(
    decimals,
    BigNumber.ROUND_DOWN
  );

  return formattedNumber;
};
const createBigNumber = (value) => {
  return new BigNumber(+value);
};

const generateUUID =() => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const getDisplayPositions = (totlaRecords, displayCount) => {
  const distance = Math.floor(totlaRecords / displayCount)
  const step = distance >= 1 ? distance : 1
  const res = []

  for (let i = step; i <= totlaRecords - step; i += step) {
    res.push(i)
  }

  return res
}

export {
  isUrl,
  copyToClipboard,
  isObject,
  isEmpty,
  onPastePlanText,
  numberFormat,
  convertBigNumber,
  formatNumberWithSuffix,
  formatAddress,
  nFormatterSI,
  formatRoundDown,
  createBigNumber,
  generateUUID,
  validateEmail,
  getDisplayPositions
}
