import { CHAIN_ID } from '../chains';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'https://api-ng.apeterminal.io/api';
export const KYC_CLIENT_ID = 'ape_terminal_9eafe';
export const GOOGLE_CLIENT_ID = '733766919259-u5t95890bv6q8beidh2i7bk0qef0q5fd.apps.googleusercontent.com';

export const CONTRACT_ADDRESS = {
  APE_INVESTMENT: {
    [CHAIN_ID.BSC]: "0x9B0Eba47330C54E8Ec051F673319de2046e6dA2e",
  },
  APE_VESTING_UNIFIED: {
    [CHAIN_ID.ETH]: "0x8407490c88667C1C5Ca2910f95Dd4027C84e1804",
    [CHAIN_ID.ARB]: "0x3614d378ab6334F65942dA5d4A5423710bCcD362",
    [CHAIN_ID.BASE]: "0x33b239885466b3a5791bFAb7023752950784D122",
    [CHAIN_ID.BSC]: "0x632137641FFd923217c4b289C4159Ee480360098",
    [CHAIN_ID.AVAX]: "0x3614d378ab6334F65942dA5d4A5423710bCcD362",
  },
  APE_INVESTMENT_UNIFIED: {
    [CHAIN_ID.BSC]: "0x9B0Eba47330C54E8Ec051F673319de2046e6dA2e",
  },
  APE_REFUND_UNIFIED: {
    [CHAIN_ID.BSC]: "0xE27E1b4B593fC4bCA68bBD753133C88D519E8B8b",
  },
  USDC: {
    [CHAIN_ID.BSC]: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  }
}
