//@ts-nocheck
import { NavLink } from 'react-router-dom'
import { sideMenu } from './config'
import { Box, Link, useMediaQuery, Typography } from '@mui/material'
import SouthEastIcon from '@mui/icons-material/SouthEast'
import { LogoWhite } from '../../assets'
import { ReactComponent as TelegramNew } from '../../assets/telegram_new.svg'
import { ReactComponent as TwitterNew } from '../../assets/twitter_new.svg'
import { PATHS } from '../../constants/config'
import { APP_V1_URL } from '../../constant'
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const Links = [
  { icon: TelegramNew, link: 'https://t.me/ApeTerminalChat' },
  { icon: TwitterNew, link: 'https://twitter.com/apeterminal' },
]

const SidebarCollapse = () => {
  const isDown880 = useMediaQuery('(max-width:880px)')

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: `calc(100vh - 30px)`,
      }}
    >
      <Box>
        <a className="logo" href={PATHS.landingPage} style={{ width: '42px' }}>
          <img src={LogoWhite} alt="Ape Terminal" />
        </a>
        <nav className='is-collapse'>
          <ul>
            {sideMenu.menuHeader.map((navItem, id) => (
              <li key={id}>
                <Tooltip
                  classes="sidebar-label"
                  slotProps={{
                    tooltip: {
                      sx: {
                        marginTop: '6px',
                        width: '120px',
                      }
                    }
                  }}
                  title={
                    <Box
                      sx={{
                        //padding: '6px 12px'
                      }}
                    >
                      <span>{navItem.label}</span>
                    </Box>
                  }
                  placement="right"
                >
                  <NavLink
                    to={navItem.link}
                    className={({ isActive }) => `${isActive && 'active'}`}
                  >
                    {navItem.icon}
                  </NavLink>
                </Tooltip>
              </li>
            ))}
          </ul>
          <ul>
            {/* <b>Products</b> */}
            {sideMenu.ProductsMenu.map((navItem, id) => (
              <li key={id}>
                {
                  navItem.isExternal ?
                    <Tooltip
                      classes="sidebar-label"
                      slotProps={{
                        tooltip: {
                          sx: {
                            marginTop: '6px',
                            // width: '120px',
                          }
                        }
                      }}
                      title={
                        <Box
                          sx={{
                            //padding: '6px 12px'
                          }}
                        >
                          <span>{navItem.label}</span>
                        </Box>
                      }
                      placement="right"
                    >
                      <a href={navItem.link} target='_blank' className={({ isActive }) => `${isActive && 'active'}`}>
                        {navItem.icon}
                        {/* <span>{navItem.label}</span> */}
                      </a>
                    </Tooltip>
                    :
                    <Tooltip
                      classes="sidebar-label"
                      slotProps={{
                        tooltip: {
                          sx: {
                            marginTop: '6px',
                            // width: '120px',
                          }
                        }
                      }}
                      title={
                        <Box
                          sx={{
                            //padding: '6px 12px'
                          }}
                        >
                          <span>{navItem.label}</span>
                        </Box>
                      }
                      placement="right"
                    >
                      <NavLink
                        to={navItem.link}
                        className={({ isActive }) =>
                          isActive && navItem.link !== '#'
                            ? 'active'
                            : navItem.link !== '#'
                              ? ''
                              : 'cusor-default'
                        }
                      >
                        {navItem.icon}
                      </NavLink>
                    </Tooltip>
                }
              </li>
            ))}
          </ul>
          <ul>
            {/* <b>Account and Tools</b> */}
            {sideMenu.AccountandTools.map((navItem, id) => (
              <li key={id}>
                <Tooltip
                  classes="sidebar-label"
                  slotProps={{
                    tooltip: {
                      sx: {
                        marginTop: '5px',
                        // width: '120px',
                      }
                    }
                  }}
                  title={
                    <Box
                      sx={{
                        //padding: '6px 12px'
                      }}
                    >
                      <span>{navItem.label}</span>
                    </Box>
                  }
                  placement="right"
                >
                  <NavLink
                    to={navItem.link}
                    className={({ isActive }) =>
                      isActive && navItem.link !== '#'
                        ? 'active'
                        : navItem.link !== '#'
                          ? ''
                          : 'cusor-default'
                    }
                  >
                    {navItem.icon}
                    {/* <span>{navItem.label}</span>
                  {navItem.textNavComing && (
                    <em>{navItem.textNavComing}</em>
                  )} */}
                  </NavLink>
                </Tooltip>
              </li>
            ))}
          </ul>
          <ul>
            {/* <b>Company</b> */}
            {sideMenu.Company.map((navItem, id) => (
              <li key={id}>
                <Tooltip
                  classes="sidebar-label"
                  slotProps={{
                    tooltip: {
                      sx: {
                        marginTop: '6px',
                        // width: '120px',
                      }
                    }
                  }}
                  title={
                    <Box
                      sx={{
                        //padding: '6px 12px'
                      }}
                    >
                      <span>{navItem.label}</span>
                    </Box>
                  }
                  placement="right"
                >
                  <NavLink
                    to={navItem.link}
                    className={({ isActive }) =>
                      isActive && navItem.link !== '#'
                        ? 'active'
                        : navItem.link !== '#'
                          ? ''
                          : 'cusor-default'
                    }
                  >
                    {navItem.icon}
                    {/* <span>{navItem.label}</span>
                  {navItem.textNavComing && (
                    <em>{navItem.textNavComing}</em>
                  )} */}
                  </NavLink>
                </Tooltip>
              </li>
            ))}
          </ul>
        </nav>
        <div className="social">
          {/* <b>Community</b> */}
          <aside>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              flexDirection={'row'}
              width={'100%'}
              alignItems={'center'}
              gap={isDown880 ? '12px' : '16px'}
              borderTop={isDown880 ? '1px solid #1D1F21' : 'none'}
              paddingTop={isDown880 ? '0' : '0px'}
            >
              <RenderLinksWithIcon
                linksArray={Links}
                width={isDown880 ? '100%' : '90px'}
                iconWidth={isDown880 ? '100%' : '40px'}
                isDown880={isDown880}
              />
            </Box>
          </aside>
        </div>
      </Box>
      <Box
        sx={{
          marginBottom: '32px',
          // marginLeft: '12px',
          cursor: 'pointer',
          textAlign: 'center',
          width: '50px',
        }}
      >
        <Tooltip
          placement="right-start"
          title={
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}
              flexDirection={'column'}
              alignSelf={'center'}
              gap={'6px'}
              sx={{ marginTop: '20px' }}
              marginBottom={'10px'}
            >
              <Typography
                fontSize={12}
                fontWeight={400}
                lineHeight="22px"
                fontFamily={'Inter'}
                color={'#7E8180'}
                width={'inherit'}
                sx={{
                  textWrap: 'pretty',
                }}
              >
                Ape Terminal © 2025 . All rights reserved.
              </Typography>
              <Box
                display={'flex'}
                width={'inherit'}
                justifyContent={'flex-start'}
              >
                <Link
                  fontSize={12}
                  fontWeight={400}
                  lineHeight="22px"
                  fontFamily={'Inter'}
                  color={'#7E8180'}
                  href={'/terms-of-service'}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: 'none',
                    textWrap: 'pretty',
                    '&:hover': { color: '#63ea71' },
                    marginRight: '8px',
                  }}
                >
                  Terms & Conditions
                  <SouthEastIcon
                    sx={{
                      fontSize: 'small',
                      verticalAlign: 'middle',
                      marginLeft: '3px',
                    }}
                  />
                </Link>
                <Link
                  fontSize={12}
                  fontWeight={400}
                  lineHeight="22px"
                  fontFamily={'Inter'}
                  color={'#7E8180'}
                  href={'/privacy-policy'}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: 'none',
                    '&:hover': { color: '#63ea71' },
                  }}
                >
                  Privacy Policy
                  <SouthEastIcon
                    sx={{
                      fontSize: 'small',
                      verticalAlign: 'middle',
                      marginLeft: '3px',
                    }}
                  />
                </Link>
              </Box>
            </Box>
          }
        >
          <InfoIcon />
        </Tooltip>

      </Box>
    </Box>
  )
}

const RenderLinksWithIcon = ({
  linksArray,
  width,
  iconWidth,
  isDown880,
}: {
  linksArray: { link: string; icon: any }[]
  width?: string
  iconWidth?: string
  isDown880?: any
}) => (
  <Box
    className="is-collapse"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      // background:
      //   'linear-gradient(180deg, rgba(56, 56, 56, 0.47) 0%, #1C1C1C 100%)',
      // boxShadow:
      //   '0px 1px 2px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.12)',
      // borderRadius: '32px',
      // justifyContent: 'center',
      // alignItems: 'center',
      // padding: '4px',
      // height: '30px',
      // width: width,
    }}
  >
    {linksArray.map((link, index) => (
      <Box
        sx={{
          display: 'flex',
          gap: '4px',
          background:
            'linear-gradient(180deg, rgba(56, 56, 56, 0.47) 0%, #1C1C1C 100%)',
          boxShadow:
            '0px 1px 2px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.12)',
          borderRadius: '32px',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '4px',
          height: '30px',
        }}
      >
        <Link
          key={index}
          sx={{
            color: '#C8CBCA',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '32px',
            backgroundColor: '#000000',
            height: '24px',
            width: iconWidth,
            '&:hover': { color: '#FF7722' },
          }}
          href={link.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <link.icon className="orange_hover_svg" />
        </Link>
      </Box>
    ))}
    <Box
      sx={{
        display: 'flex',
        gap: '4px',
        background:
          'linear-gradient(180deg, rgba(56, 56, 56, 0.47) 0%, #1C1C1C 100%)',
        boxShadow:
          '0px 1px 2px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.12)',
        borderRadius: '32px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 26px',
        height: '30px',
        width: '40px',
      }}
    >
      <Typography
        component="a"
        href={APP_V1_URL}
        target="_blank"
        rel="noopener noreferrer"
        fontSize={16}
        fontWeight={600}
        lineHeight="16px"
        fontFamily={'Inter'}
        color={'#B0B4B3'}
        marginRight={isDown880 ? 'auto' : '0px'}
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
          color: '#C8CBCA',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '32px',
          backgroundColor: '#000000',
          height: '24px',
          width: '32px',
          padding: '0 21px',
          '&:hover': { color: '#62EA71' },
        }}
      >
        v1.0
      </Typography>
    </Box>
  </Box>
)
const RenderLinks = ({
  linksArray,
  customStyles,
}: {
  linksArray: {
    text: string
    link: string
    comingSoon?: boolean
    samePage?: boolean
  }[]
  customStyles?: any
}) => {
  const isDown880 = useMediaQuery('(max-width:880px)')

  return (
    <Box
      sx={{
        paddingLeft: '10px',
        display: 'flex',
        gap: '12px',
        width: 'inherit',
        justifyContent: 'space-between',
        ...customStyles,
      }}
    >
      {linksArray.map((link, index) => (
        <Link
          key={index}
          sx={{
            textAlign: index !== 0 && 'right',
            color: '#C8CBCA',
            whiteSpace: 'nowrap',
            width: isDown880 ? '100%' : 'fit-content',
            '&:hover': {
              opacity: 0.9,
              color: '#63ea71',
              textDecoration: 'none',
            },
          }}
          href={link.comingSoon ? '' : link.link}
          target={link.samePage ? '_self' : '_blank'}
          rel="noopener noreferrer"
        >
          {link.text}
        </Link>
      ))}
    </Box>
  )
}

export default SidebarCollapse
