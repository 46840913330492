import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { IncomingSvg, IncomingSvgMobile } from '../../assets'
import GreenButton from '../../components/GreenButton'
import { PATHS } from '../../constants/config'

const InComming = () => {
  const navigate = useNavigate()

  const isDown767 = useMediaQuery('(max-width:767px)')
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: `calc(100vh - 266px)`,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
          padding: "0 20px"
        }}
      >
        <Box style={{}}>
          <Typography
            fontSize={isDown767 ? 48 : 64}
            fontWeight={600}
            lineHeight={'100%'}
            color="#63ea71"
            letterSpacing={'-0.02em'}
            textAlign={'center'}
            fontFamily={'Hellix'}
            sx={{
              alignSelf: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            Coming Soon
          </Typography>
        </Box>
        <GreenButton
          text={'Go back home'}
          br="24px"
          fs={15}
          fw={400}
          fm="Inter"
          lh="22.5px"
          mt={10}
          w={"fit-content"}
          p="14px 32px"
          h="48px"
          // w={isDown1152 ? '100%' : 'fit-content'}
          onClick={() => navigate(PATHS.home)}
        />
      </Box>
    </Box>
  )
}

export default InComming
