import {
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useState } from 'react'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'
import Motion from '../../components/Motion/Motion'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../constants/config'

const FAQHome = () => {
  const isDown992 = useMediaQuery('(max-width:992px)')
  const isDown766 = useMediaQuery('(max-width:766px)')
  const [expanded, setExpanded] = useState<number | null>(null)
  const theme = useTheme()
  const isBetweenScreen = useMediaQuery(theme.breakpoints.between(767, 1200))


  const questions = [
    {
      id: 1,
      question: 'Do I need an account to join a sale?',
      answer:
        'No. Ape Terminal offers the smoothest participation experience. Opt into any sale without an account. Just connect your web3 wallet. We get 0 access to your assets as you never need to sign any transaction. Only if you win, you will then have to make an account.',
    },
    {
      id: 2,
      question: 'Is there a fee?',
      answer:
        'There are 0 fees to opt in. There are 0 token staking requirements. Only winners pay, and they pay only a 25% fee on their won tokens.',
    },
    {
      id: 3,
      question: 'Do I need to KYC to opt in?',
      answer:
        'While users don’t need to KYC for applying to an IDO, they must pass it to contribute if they win. If users win an allocation but fail to contribute, their allocation is backstopped by company treasury to ensure the IDO is completed.',
    },
    {
      id: 4,
      question: 'How can I increase my win chance?',
      answer:
        'Refer friends to each sale to increase your chances',
    },
    {
      id: 5,
      question: 'What happens to unsold tickets?',
      answer:
        'Any unused tickets for an IDO are bought by the treasury instead.',
    },
    {
      id: 6,
      question:
        'Is my ticket for contributing to the IDO guaranteed after winning the raffle?',
      answer:
        "No, winning the raffle gives you the chance to contribute but doesn't guarantee a spot due to the limited number of contribution spots compared to the number of raffle winners. It's important to act quickly to secure your contribution.",
    },
    {
      id: 7,
      question:
        'Can the contribution to the IDO close before the published time?',
      answer:
        'Yes, the contribution window can close early if the IDO hard cap amount reaches its capacity before the scheduled end of the contribution period. Therefore, timely action is required.',
    },
    // {
    //   id: 8,
    //   question:
    //     'What is the Refund Policy, and How Does it Work?',
    //   answer:
    //   <>
    //   <span style={{display: 'block', marginBottom: '12px'}}>The refund policy applies to most upcoming sales on Ape Terminal starting October 2024</span>
    //   <span style={{display: 'block', marginBottom: '12px'}}>On the day of a project’s Token Generation Event (TGE), users may either request a refund or claim their tokens. The refund request period will remain open for 24 hours following the TGE, after which funds become available for claiming within 24 hours.</span>
    //   <span style={{display: 'block'}}>In rare cases, certain sales will be non-refundable, and these will be clearly marked as such.</span>
    //   </>
    // },
    // {
    //   id: 9,
    //   question:
    //     'Is the Refund Policy Optional for Investors, or Will It Be Enforced Automatically?',
    //   answer:
    //     'The refund policy is entirely optional for investors. Users have a 24-hour window to decide whether to request a refund.',
    // },
    // {
    //   id: 10,
    //   question:
    //     'Will a 25% Fee Apply if I Opt for a Refund?',
    //   answer:
    //     'No, it will not. If you opt for a refund, you will receive your full contributed amount.',
    // },
    // {
    //   id: 11,
    //   question:
    //     'Are All Sales on Ape Terminal Refundable?',
    //   answer: <>
    //     <span style={{display: 'block', marginBottom: '6px'}}>Yes, starting from October 2024, most upcoming projects on Ape Terminal will adopt a refundable offering frame.</span>
    //     <span style={{display: 'block' }}>In rare cases, certain sales will be non-refundable, and these will be clearly marked as such.</span>
    //   </>
    // },
    // {
    //   id: 12,
    //   question:
    //     'If I Request a Refund 1 Day After Listing, Will I Receive My Money Back Immediately, or Will All Refunds Be Issued at the End of the Refund Period?',
    //   answer:
    //     'Refunds will be available 24 hours after the 24-hour refund period ends.',
    // },
    // {
    //   id: 13,
    //   question:
    //     'Do We Only Have 30 Minutes After TGE to Decide Whether to Refund or Claim?',
    //   answer: 'No, you have a 24-hour period to request a refund.',
    //   // <>
    //   // <span style={{display: 'block', marginBottom: '12px'}}>No, you have a 24-hour period to request a refund.</span>
    //   // <span style={{display: 'block'}}>In rare cases, certain sales will be non-refundable, and these will be clearly marked as such.</span>
    //   // </>
    // },
    // {
    //   id: 14,
    //   question:
    //     'Which blockchain will be used to claim the refund once it’s available?',
    //   answer:
    //     'All refunds will be processed and claimed on BSC.',
    // },
    {
      id: 15,
      question: 'How does Ape Terminal select its projects?',
      answer: <>
        <span style={{display: 'block', marginBottom: '12px'}}>Ape Terminal mainly assesses the potential of secondary liqudity of the token being offered. This assessment considers the narrative, project's marketing resources, and distribution resources.</span>
        <span style={{display: 'block'}}>The product associated with the token is also considered but there is greater emphasis on community growth measures.</span>
      </>
    },
    {
      id: 16,
      question: 'Are sales on Ape Terminal non-refundable?',
      answer: <>Some sales are non-refundable and some sales are refundable. Please read our <a href={PATHS.faq + '#refunds-faq'} target="_blank" style={{color: '#ff9228'}}>Refund FAQ</a> for details on how our Refund Policy works.</>
    },
  ]

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      maxWidth={isBetweenScreen ? '90%' : '996px'}
      margin={'0 auto'}
      width={'100%'}
      paddingX={isDown766 ? '25px' : isBetweenScreen ? '16px' : ''}
      sx={{ backgroundColor: '#fff' }}
    >
      <Motion>
        <Typography
          sx={{
            fontFamily: 'Hellix',
            fontSize: isDown992 ? '40px' : '56px',
            fontWeight: 600,
            color: '#000',
            margin: '0px',
            lineHeight: isDown992 ? '48px' : '61.6px',
            textAlign: 'center',
          }}
        >
          Frequently Asked Questions
        </Typography>
      </Motion>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isDown992 ? 'column' : 'row',
          gap: isDown992 ? '18px' : '24px',
          marginTop: '64px',
        }}
      >
        <Box>
          {questions.map((item: any, index) => (
            <Motion
              from={{ y: 10, filter: 'blur(5px)' }}
              to={{ filter: 'blur(0px)' }}
              delay={(0.05 * index) as number}
              style={{
                display: 'block',
                marginBottom: '8px',
              }}
              key={item.id}
            >
              <Accordion
                expanded={expanded === item.id}
                onChange={() =>
                  {
                    if (item.answer) {
                      setExpanded(expanded === item.id ? null : item.id)
                    }
                  }
                }
                sx={{
                  boxShadow: 'unset',
                  backgroundColor: 'transparent',
                  backgroundImage: 'none',
                  borderRadius: '24px',
                  transition: 'all 0.3s',
                  '&.Mui-expanded': {
                    margin: '0px',
                    backgroundColor: '#F4FDAB',
                  },
                  '&::before': {
                    display: 'none',
                  },
                  '&:last-of-type': {
                    borderBottomLeftRadius: '24px',
                    borderBottomRightRadius: '24px',
                  },
                  '&:first-of-type': {
                    borderTopLeftRadius: '24px',
                    borderTopRightRadius: '24px',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === item.id ? (
                      <RemoveOutlinedIcon
                        sx={{
                          color: '#000',
                          height: '20px',
                          width: '20px',
                          marginLeft: '8px',
                          transition: 'color 0.3s',
                        }}
                      />
                    ) : (
                      <AddOutlinedIcon
                        sx={{
                          color: '#000',
                          height: '20px',
                          width: '20px',
                          marginLeft: '8px',
                          transition: 'color 0.3s',
                        }}
                      />
                    )
                  }
                  sx={{
                    margin: '0px',
                    height: 'fit-content',
                    paddingX: '20px',
                    minHeight: '10px',
                    borderRadius: '12px',
                    '&.Mui-expanded': {
                      minHeight: '10px',
                      color: '#fff',
                    },
                    '& .MuiAccordionSummary-content': {
                      marginTop: '18px',
                      marginBottom: expanded === item.id ? '8px' : '18px',
                      minHeight: '10px',
                    },
                    '&:hover .faq__question': {
                      color: '#000',
                    },
                    '&:hover .MuiAccordionSummary-expandIconWrapper svg': {
                      color: '#000',
                    },
                    '&:hover': {
                      backgroundColor: expanded ? 'transparent' : '#F3F3F3',
                    },
                  }}
                  className="group"
                >
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize:  isDown766 ? '18px' :'20px',
                      fontWeight: 600,
                      color: expanded === item.id ? '#000' : '#000',
                      lineHeight: '32px',
                      transition: 'color 0.3s',
                      marginBlockEnd: '0',
                      marginBlockStart: '0',
                    }}
                    className="faq__question"
                  >
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '18px',
                    fontWeight: 300,
                    color: '#000',
                    padding: '20px',
                    borderRadius: '24px',
                    paddingBottom: '16px',
                    lineHeight: '24px',
                  }}
                >
                  {item.answer}
                </Typography>
              </Accordion>
            </Motion>
          ))}
        </Box>
      </Box>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: '20px',
          fontWeight: 600,
          color: '#000',
          lineHeight: '32px',
          transition: 'color 0.3s',
          marginBlockEnd: '0',
          marginBlockStart: '0',
          padding: '18px 16px',
          cursor: 'pointer',
        }}
        sx={{
          '&:hover': {
            backgroundColor: '#F3F3F3',
          }
        }}
      >
        Have more questions? Check out our full{' '}
        <NavLink
          to={PATHS.faq}
          style={{
            fontFamily: 'Inter',
            fontSize: '20px',
            fontWeight: 600,
            color: '#FF9228',
            lineHeight: '32px',
            transition: 'color 0.3s',
            textDecoration: 'underline',
          }}
          target="_blank"
        >
          FAQ
        </NavLink>
        .
      </Typography>
    </Box>
  )
}

export default FAQHome
