export const WalletIcon = ({width = 12, fill = 'rgb(77, 182, 172)'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={width}
      viewBox="0 0 11 10"
      style={{fill}}>
        <path d="M9.909 2.25h-8.19a.344.344 0 1 1 0-.687h8.25c.19 0 .344-.154.344-.344 0-.57-.462-1.031-1.032-1.031H1.375C.615.188 0 .803 0 1.563v6.875c0 .759.616 1.375 1.375 1.375h8.534C10.51 9.813 11 9.35 11 8.78v-5.5c0-.568-.49-1.031-1.091-1.031Zm-.972 4.469a.688.688 0 1 1 0-1.375.688.688 0 0 1 0 1.375Z">
        </path>
    </svg>
  );
};
