import { Typography } from '@mui/material'
import { Box, useMediaQuery } from '@mui/system'
import {
  AITProtocol,
  BgK9Finance,
  StarHeroes,
  BgAITProtocol,
  BgStarHeroes,
  K9Finance,
} from '../../assets'
import { useTheme } from '@mui/material'

const ReferenceCard = ({
  backgroundImage,
  logo,
  title,
  usersWonText,
  prizeAmount,
  prizeTickets,
  listedValue,
}: {
  backgroundImage: string
  logo: string
  title: string
  usersWonText: string
  prizeAmount: string
  prizeTickets: string
  listedValue: string
}) => {
  const theme = useTheme()
  const isDown787 = useMediaQuery('(max-width:787px)')
  const isBetweenScreen = useMediaQuery(theme.breakpoints.between(767, 992))
  return (
    <Box
      sx={{
        border: '1px solid #000',
        boxShadow: '0px 4px 0px 0px #000',
        borderRadius: '16px',
        overflow: 'hidden',
        height: '100%',
        backgroundColor: '#fff',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <img
          loading="lazy"
          src={backgroundImage}
          alt={title}
          style={{ width: '100%', borderRadius: '16px 16px 0 0' }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={logo} alt={`${title} Logo`} />
          <Typography
            sx={{
              fontFamily: 'Hellix',
              fontSize: isBetweenScreen ? '20px' : '24px',
              fontWeight: 600,
              lineHeight: '33.6px',
              color: '#000',
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: '240px',
          margin: '0 24px',
          py: 3,
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '17px',
            fontWeight: 400,
            lineHeight: '25.5px',
            textAlign: 'center',
            color: '#000',
          }}
        >
          {usersWonText} <br />
          <span style={{ fontWeight: 700 }}>{prizeAmount}</span>
          <span> {prizeTickets}</span>
        </Typography>
        <hr
          style={{
            border: '1px solid #000',
            margin: '16px 0',
          }}
        />
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '17px',
            textAlign: 'center',
            color: '#000',
          }}
        >
          Listed value at
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Hellix',
            fontSize: '40px',
            fontWeight: 700,
            lineHeight: '56px',
            color: '#FF9228',
            textAlign: 'center',
          }}
        >
          {listedValue}
        </Typography>
      </Box>
    </Box>
  )
}

const ReferencesHome: React.FC = () => {
  const theme = useTheme()
  const isDown787 = useMediaQuery('(max-width:787px)')
  const isDown1023 = useMediaQuery('(max-width:1023px)')
  const isBetweenScreen = useMediaQuery(theme.breakpoints.between(767, 992))
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '64px',
        maxWidth: '1200px',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Hellix',
          fontSize: isDown1023 ? '40px' : '48px',
          fontWeight: 600,
          lineHeight: isDown1023 ? '48px' : '57.6px',
          textAlign: 'center',
          color: '#000',
        }}
      >
        References
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: isDown787 ? '32px' : '16px',
          flexDirection: isDown787 ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ReferenceCard
          backgroundImage={BgStarHeroes}
          logo={StarHeroes}
          title="StarHeroes"
          usersWonText="800 users won partial unlock"
          prizeAmount="$250"
          prizeTickets="StarHeroes tickets"
          listedValue="$12,500+"
        />
        <ReferenceCard
          backgroundImage={BgAITProtocol}
          logo={AITProtocol}
          title="AIT Protocol"
          usersWonText="100 users won fully unlocked"
          prizeAmount="$1,000"
          prizeTickets="$AIT tickets"
          listedValue="$80,000+"
        />
        <ReferenceCard
          backgroundImage={BgK9Finance}
          logo={K9Finance}
          title="K9 Finance"
          usersWonText="400 users won fully unlocked"
          prizeAmount="$250"
          prizeTickets="$KNINE tickets"
          listedValue="$8,000+"
        />
      </Box>
    </Box>
  )
}

export default ReferencesHome
