export const UserPortalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.96667 5.6V2.73335H13.2666V5.6H8.96667ZM2.73334 8.46665V2.73335H7.0333V8.46665H2.73334ZM8.96667 13.2667V7.53335H13.2666V13.2667H8.96667ZM2.73334 13.2667V10.4H7.0333V13.2667H2.73334Z"
        fill="#F1EDF2"
        fillOpacity="0.6"
      />
    </svg>
  );
};
