import React, { useState } from 'react'
import { Alert, Box, Snackbar, Typography, useMediaQuery } from '@mui/material'
import ConnectButton from '../../components/Buttons/ConnectButton'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useParticipationEntryPoint } from '../../Context'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useAccount } from 'wagmi'
import {
  setIsLoggedInWithEmail,
  setLoginMethod,
  setMainAppToken,
  setWalletAddress,
} from '../../redux/slices/userSlice'
import { shortenAddress } from '../../utils'
import DisconnectButton from './DisconnectButton'

type BoxType = 'wallet' | 'email'

interface BoxShowDataProfileProps {
  heading: string
  subheading: string
  isShowButton?: boolean
  type: BoxType
  setShowLoader: (open: boolean) => void
}

const BoxShowDataProfile: React.FC<BoxShowDataProfileProps> = ({
  heading,
  subheading,
  isShowButton = false,
  type,
  setShowLoader,
}) => {
  const dispatch = useAppDispatch()
  const { open: openWeb3Modal } = useWeb3Modal()
  const { setParticipationEntryPoint } = useParticipationEntryPoint()

  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  const { address } = useAccount()
  const user = useAppSelector(state => state.user)
  const [openToast, setOpenToast] = useState(false)
  const [message, setMessage] = useState('')

  const isShowButtonConnect =
    type === 'wallet' &&
    isShowButton &&
    !heading &&
    !subheading &&
    address !== user.addressLinkEmail

  const isLoginGG = user.addressLinkEmail && user.email
  const isLoginMetamask =
    user.emailRegisterUserAccount && user.addressRegisterUserAccount

  const isAddressLinkEmail =
    user.addressLinkEmail &&
    type === 'wallet' &&
    address !== user.addressLinkEmail &&
    address !== user.addressRegisterUserAccount

  const handleCloseToast = () => {
    setOpenToast(false)
  }

  const handleConnect = async () => {
    openWeb3Modal()
    if (!user.addressLinkEmail) {
      dispatch(setWalletAddress(''))
      dispatch(setMainAppToken(''))
      dispatch(setIsLoggedInWithEmail(true))
    }
    if (isAddressLinkEmail) {
      dispatch(setLoginMethod('metamask'))
    }
    setParticipationEntryPoint('Header')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#0f1113',
        justifyContent: 'space-between',
        borderRadius: '12px',
        padding: isDown1000 ? '16px' : '24px',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        width: isDown767 ? '100%' : '50%',
        overflowWrap: 'anywhere',
        gap: '12px',
        minHeight: '150px',
        alignItems: 'flex-start',
      }}
    >
      {heading && subheading && !isShowButton && (
        <Box display="flex" alignItems="start">
          <Box ml={isDown1000 ? '12px' : '16px'}>
            <Typography
              sx={{
                color: '#fff',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
              }}
            >
              {heading}
            </Typography>
            <Typography
              sx={{
                color: '#fff',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '16.8px',
              }}
            >
              {type === 'wallet'
                ? shortenAddress(subheading, 5, 4)
                : subheading}
            </Typography>
          </Box>
        </Box>
      )}

      {isAddressLinkEmail && isShowButton && (
        <Box display="flex" alignItems="start">
          <Box ml={isDown1000 ? '12px' : '16px'}>
            <Typography
              sx={{
                color: '#fff',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
              }}
            >
              Address Link Email
            </Typography>
            <Typography
              sx={{
                color: '#7E8180',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '16.8px',
              }}
            >
              {shortenAddress(
                user.addressLinkEmail || user.addressRegisterUserAccount,
                5,
                4,
              )}
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          gap: isDown767 ? '16px' : '0',
          flexDirection: isDown767 ? 'column' : 'row',
        }}
      >
        {isShowButtonConnect && (
          <Box>
            <ConnectButton text="Connect Wallet" onClick={handleConnect} />
          </Box>
        )}
        {(isLoginGG || isLoginMetamask) && type === 'wallet' && (
          <Box>
            <DisconnectButton
              setShowLoader={setShowLoader}
              setOpenToast={setOpenToast}
              setMessage={setMessage}
            />
          </Box>
        )}
      </Box>
      <Snackbar
        open={openToast}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: '100%', zIndex: 1000 }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default BoxShowDataProfile
