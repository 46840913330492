import {
  ArbitrumIcon,
  AvalancheIcon,
  BaseIcon,
  BNBIcon,
  BSCIcon,
  BTCIcon,
  CasperIcon,
  EthereumIcon,
  InjectiveIcon,
  PolygonIcon,
  SolanaIcon,
  SuiIcon,
} from '../../../assets'

const networkIcons: {  [key: string]: string } = {
  'Ethereum': EthereumIcon,
  'ETH': EthereumIcon,
  'ETH_SEPOLIA': EthereumIcon,
  'BSC': BSCIcon,
  'bsc': BSCIcon,
  'Bsc': BSCIcon,
  'BSC_TESTNET': BNBIcon,
  'Binance Smart Chain (Testnet)': BNBIcon,
  'Binance Smart Chain': BSCIcon,
  'Solana': SolanaIcon,
  'Avalanche (C-Chain)': AvalancheIcon,
  'AVAX': AvalancheIcon,
  'Arbitrum': ArbitrumIcon,
  'ARB': ArbitrumIcon,
  'Injective': InjectiveIcon,
  'Bitcoin': BTCIcon,
  'MATIC': PolygonIcon,
  'POLYGON': PolygonIcon,
  'polygon': PolygonIcon,
  'Base': BaseIcon,
  'base': BaseIcon,
  'BASE': BaseIcon,
  'Sui': SuiIcon,
  'SUI': SuiIcon,
  'Casper': CasperIcon,
  'CASPER': CasperIcon,
};

export function getNetworkIconByChain(chain: string): string {
  return networkIcons[chain] || BNBIcon;  
}
