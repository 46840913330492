import { Box, width } from "@mui/system";
// import { RWASUIAirdropBanner } from "../../assets";
import { RWASUIAirdropTasks } from "../../assets";
import { useMediaQuery } from '@mui/material'

const RwaSUIAirdrop = ({ isBreakTitle = true }) => {
  const isDown767 = useMediaQuery('(max-width:767px)')

  return (
    <Box
      sx={{
        width: '100%',
        marginBottom: isDown767 ? '32px' : '100px',
      }}
    >
      <Box
        margin={'0 auto'}
        sx={{
          width: isDown767 ? '100%' : '80%'
        }}
      >
        {/* <img src={RWASUIAirdropBanner} alt="" style={{width: '100%', objectFit: 'cover'}} /> */}
        <img src={RWASUIAirdropTasks} alt="" style={{width: '100%', objectFit: 'cover'}} />
      </Box>
    </Box>

  )
}

export default RwaSUIAirdrop
