import { Dayjs } from 'dayjs'
import { api } from '../../../services/api'

export enum IFilterStatus {
  ALL = 'All',
  PARTICIPATION = 'Participation',
  INVESTMENT = 'Investment',
  TGE = 'TGE',
  COMPLETE = 'Complete',
}

export interface IResponseUserPortalItem {
  id: string
  nextUnlock: number
  projectName: string
  projectLogoUrl: string
  routeName: string
  idoStatus: string
  allocation: number
  distribution: number
  valueLocked: number
  tokenSymbol: string
  idoTotalParticipation: number
  idoTotalAssetsConnected: number
  amount: number
  invested: boolean
  idoClaimNetwork: string
  tokenNetwork: string
  investmentDate: string
  isWinner: boolean
  idoClaimContract: string
  projectClaimId: number
  projectVestingId: number
  projectContractId: number
  participationDate: string
  investmentProjectId: number
  projectIsLegacyProject: boolean
  idoSnapshotDoneAt: number | null
  isInvestor: boolean
  idoTgeAmount: string
  idoVestingAmount: string
  amountRequestRefund: number
  idoCliffDuration: number
  idoTotalWinner: number
  idoAmountToRaise: number 
  idoInvestmentStartDate: number
  idoInvestmentEndDate: number
  idoVestingDuration: number // API: duration = idoVestingDuration - idoTgeDate
  idoStartDate: number
  idoEndDate: number
  idoRefundDeadline: number | null
  claimableAmount: number
  idoTgeDate: number
  idoClaimUrl: any
  idoId: string
  participationInfo: ParticipationInfo[]
  claimedInformation: ClaimedInformation[]
  adminDepositEvents: AdminDepositEvents[]
  idoPastAirdropTxs?: string[]
  idoPastClaimContracts?: string[]
}

interface ClaimedInformation {
  eventName: string;
  totalAmount?: string;
  amount?: string;
  time?: string;
}

interface ParticipationInfo {
  invested: boolean;
  isWinner: boolean;
  isInvestor: boolean;
  ticketCount: number;
  participationDate: string;
  investmentDate: string;
}

interface AdminDepositEvents {
  eventName: string;
  amount: string;
  time: string;
}

export interface IResponseUserPortalMeta {
  page_size: number
  total_page: number
  total_records: number
}

export interface IResponseUserPortal {
  data: IResponseUserPortalItem[]
  meta: IResponseUserPortalMeta
}

export const participationApi = api.injectEndpoints({
  endpoints: build => ({
    fetchUserParticipationsV2: build.query<
      IResponseUserPortal,
      {
        page: number
        limit: number
        name?: string
        status?: string
      }
    >({
      query: ({ page, limit, name, status }) => {
        const params = new URLSearchParams()
        params.append('page', page.toString())
        params.append('limit', limit.toString())

        if (name) params.append('name', name)
        if (status) params.append('status', status)

        return `participation/user/all/v2?${params.toString()}`
      },
    }),
    fetchUserParticipationsSales: build.query<
      IResponseUserPortal,
      {
        page: number
        limit: number
        name?: string
      }
    >({
      query: ({ page, limit, name }) => {
        const params = new URLSearchParams()
        params.append('page', page.toString())
        params.append('limit', limit.toString())

        if (name) params.append('name', name)

        return `participation/user/sales?${params.toString()}`
      },
    }),
    fetchUserParticipationsClaim: build.query<
      IResponseUserPortal,
      {
        page: number
        limit: number
        name?: string
      }
    >({
      query: ({ page, limit, name }) => {
        const params = new URLSearchParams()
        params.append('page', page.toString())
        params.append('limit', limit.toString())

        if (name) params.append('name', name)

        return `participation/user/claim?${params.toString()}`
      },
    }),
    fetchUserParticipationsHistory: build.query<
      IResponseUserPortal,
      {
        page: number
        limit: number
        name?: string
      }
    >({
      query: ({ page, limit, name }) => {
        const params = new URLSearchParams()
        params.append('page', page.toString())
        params.append('limit', limit.toString())

        if (name) params.append('name', name)

        return `participation/user/history?${params.toString()}`
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useFetchUserParticipationsV2Query,
  useFetchUserParticipationsSalesQuery,
  useFetchUserParticipationsClaimQuery,
  useFetchUserParticipationsHistoryQuery,
} = participationApi
