import { Box, useMediaQuery, useTheme } from '@mui/material'
import React, { useRef } from 'react'
import BannerHome from './BannerHome'
import FundsHome from './FundsHome'
import './index.css'
import ReferencesHome from './ReferencesHome'
import UnparalleledFeatures from './UnparalleledFeatures'
import JoinYourFirst from './JoinYourFirst'
import GetStartedIn from './GetStartedIn'
import QuestionHome from './QuestionHome'
import FAQHome from './FAQHome'
import WhatApeTerminal from './WhatApeTerminal'
import CreateAccount from './CreateAccount'
import FooterHome from './FooterHome'
import NavBarHome from './NavbarHome'
import LandingVideo from './LandingVideo'
import { bgImageVideo } from '../../assets'

const RevampedHome: React.FC = () => {
  const isDown1023 = useMediaQuery('(max-width:1023px)')
  const isDown787 = useMediaQuery('(max-width:787px)')
  const theme = useTheme()
  const isBetweenScreen = useMediaQuery(theme.breakpoints.between(767, 1366))
  const landingMainVideoRef = useRef<HTMLDivElement | null>(null);

  const onClickScrollToMainVideo = () => {
    if (landingMainVideoRef.current) {
      landingMainVideoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <>
      <NavBarHome />
      <Box
        width={'100%'}
        alignSelf={'center'}
        display={'flex'}
        flexDirection={'column'}
        paddingTop={'76px'}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '8px',
            background: '#F3F3F3',
          }}
        >
          <BannerHome />
        </Box>
        <Box
          width={'100%'}
          alignSelf={'center'}
          display={'flex'}
          flexDirection={'column'}
          position={'relative'}
          sx={{ backgroundColor: '#fff' }}
        >
          {/* Funds Section */}
          <FundsHome />

          {/* References Section */}
          <Box
            sx={{
              paddingBottom: isDown787 ? '118px' : '180px',
              paddingX: isDown1023 ? '25px' : '',
              backgroundColor: '#fff',
            }}
          >
            <ReferencesHome />
          </Box>

          {/* CreateAccount Section */}
          <Box
            sx={{
              marginBottom: isDown1023 ? '106px' : '180px',
            }}
          >
            <CreateAccount onClickScrollToMainVideo={onClickScrollToMainVideo}/>
          </Box>

          {/* Features Section */}
          <Box
            sx={{
              paddingBottom: isDown1023 ? '106px' : '180px',
              paddingX: isDown1023 ? '25px' : '',
              backgroundColor: '#fff',
            }}
          >
            <UnparalleledFeatures />
          </Box>

          {/* Join Section */}
          <Box
            sx={{
              background: '#F3F3F3',
            }}
          >
            <JoinYourFirst />
          </Box>

          {/* Video Section */}
          <Box
            id="landing-main-video"
            ref={landingMainVideoRef}
            sx={{
              maxWidth: isBetweenScreen ? '90%' : '1200px',
              margin: '0 auto',
              paddingTop: isDown1023 ? '106px' : '180px',
              paddingBottom: isDown1023 ? '106px' : '180px',
              width: '100%',
              paddingX: isDown1023 ? '25px' : '',
              backgroundColor: '#fff',
            }}
          >
            {/* <img
              style={{
                width: '100%',
              }}
              src={bgImageVideo}
              alt="bgImageVideo"
            /> */}
            <LandingVideo />
          </Box>

          {/* Get Started Section */}
          <Box
            sx={{
              marginBottom: isDown1023 ? '106px' : '180px',
              paddingX: isDown1023 ? '25px' : '',
              background: '#F3F3F3',
            }}
          >
            <GetStartedIn />
          </Box>
          {/* What Ape Terminal Section */}
          <Box
            sx={{
              marginBottom: isDown1023 ? '106px' : '180px',
              backgroundColor: '#fff',
            }}
          >
            <WhatApeTerminal />
          </Box>

          {/* FAQ Section */}
          <FAQHome />

          {/* Questions Section */}
          <Box
            sx={{
              marginY: isDown1023 ? '106px' : '180px',
            }}
          >
            <QuestionHome />
          </Box>
        </Box>
        <FooterHome />
      </Box>
    </>
  )
}

export default RevampedHome
