import { useMediaQuery } from '@mui/system'
import moment from 'moment'
import { useMemo } from 'react'
import Chart from 'react-apexcharts'
import { getDisplayPositions, numberFormat } from '../../utils/function'

interface TotalAssetsChartProps {
  data: {
    timeSeries: Record<string, number>
  }
  height?: number
  xAxisFormat?: string
  tickAmount?: number
}

const TotalAssetsChart: React.FC<TotalAssetsChartProps> = props => {
  const { data, height = 197, xAxisFormat = 'hh:mm', tickAmount = 8 } = props
  const isDown767 = useMediaQuery('(max-width:767px)')

  const timeSeriesData = useMemo(() => data?.timeSeries ?? {}, [data])

  const dataTimeseries = useMemo(
    () => Object.values(timeSeriesData),
    [timeSeriesData],
  )
  const dataTimeLabels = Object.keys(timeSeriesData)

  const series = [
    {
      data: dataTimeseries,
    },
  ]

  const filteredTimeLabels = useMemo(() => {
    const displayPositions = getDisplayPositions(
      dataTimeLabels.length,
      tickAmount + 1,
    )
    return dataTimeLabels.map((label, index) => {
      if (displayPositions.includes(index)) {
        return moment(parseInt(label) * 1000).format(xAxisFormat)
      }
      return ''
    })
  }, [dataTimeLabels, tickAmount, xAxisFormat])

  const options = useMemo(() => {
    const minValue = Math.min(...dataTimeseries) * 0.995
    const maxValue = Math.max(...dataTimeseries)
    return {
      chart: {
        height: height ? height : 350,
        type: 'area',
        toolbar: { show: false },
        events: {},
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      grid: {
        show: false,
        padding: {
          left: -15,
          right: -20,
        },
      },
      colors: ['#6EF49F'],
      fill: {
        color: '#6EF49F',
        type: 'gradient',
        gradient: {
          shadeIntensity: 0,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        show: false,
        min: minValue,
        max: maxValue,
      },
      xaxis: {
        categories: filteredTimeLabels,
        labels: {
          style: {
            colors: '#fff',
            fontSize: '10px',
            cssClass: 'overview-chart-label',
          },
          rotate: isDown767 ? -45 : 0,
          maxHeight: 100,
          hideOverlappingLabels: true,
          showDuplicates: false,
          clip: true,
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        crosshairs: {
          show: true,
          opacity: 1,
          position: 'front',
          color: '#6EF49F',
        },
        tickPlacement: 'between',
        // tickAmount: 8,
        // tickInterval: '2 hour',
      },
      markers: {
        colors: '#6EF49F',
        hover: {
          sizeOffset: 3,
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
        y: {
          formatter: function (val: number) {
            return val
          },
        },
        custom: function ({
          series,
          seriesIndex,
          dataPointIndex,
        }: {
          series: number[][]
          seriesIndex: number
          dataPointIndex: number
        }) {
          return `<div class="apexcharts-assets-tooltip">
            <p>${numberFormat(
              series[seriesIndex][dataPointIndex],
              2,
              2,
              '$',
            )}</p>
            <p>${moment(parseInt(dataTimeLabels[dataPointIndex]) * 1000).format(
              'D MMM hh:mm A',
            )}</p>
          </div>`
        },
      },
    }
  }, [dataTimeLabels, dataTimeseries, filteredTimeLabels, height, isDown767])

  return (
    <Chart
      options={options as any}
      series={series}
      type="area"
      height={height}
    />
  )
}

export default TotalAssetsChart
