import { useMediaQuery } from '@mui/system'
import OrangeButton from '../../components/OrangeButton'
import { useAccount } from 'wagmi'
import { useAppSelector } from '../../hooks'

const ButtonLogin = ({ onClick }: { onClick?: () => void }) => {
  const isDown1023 = useMediaQuery('(max-width:1023px)')
  const { address } = useAccount()
  const user = useAppSelector(state => state.user)

  const shouldHideLoginButton = !!address || !!user.email

  if (shouldHideLoginButton) {
    return null
  }
  return (
    <OrangeButton
      text="Login"
      br="24px"
      fs={15}
      fw={500}
      fm="Inter"
      lh="22.5px"
      p="14px 32px"
      h={isDown1023 ? '40px' : '48px'}
      w="max-content"
      onClick={onClick}
      sx={{
        textTransform: 'none',
        marginLeft: !isDown1023 && '10px',
        marginRight: isDown1023 && '10px',
        ':hover': {
          // backgroundColor: 'rgba(255, 119, 34, 0.7) !important',
          opacity: '0.8',
          // backgroundColor: '#f4650c !important',
        }
      }}
    />
  )
}

export default ButtonLogin
