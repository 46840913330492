import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Box, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import { getReplacedCdnEndpoint } from '../../../../utils'
import { IResponseUserPortalItem } from 'services/modules/participationsV2'
import { getNetworkIconByChain } from '../IconByNetworkUserPortal'
import { StyledBodyTableCell, FixedBodyTableCell } from './TableStyled'
import { JoinButton } from './JoinButton'
import { ViewLaunchButton } from './ViewLaunchButton'
import { InvestButton } from './InvestButton'
import { computeTicketSize, getUserStatus, getStatusStyle } from './helper'

type SaleTableRowProps = {
  sale: IResponseUserPortalItem,
  index: number
}

const SaleTableRow = (props: SaleTableRowProps) => {
  const { sale, index } = props
  const theme = useTheme()
  const isDown800 = useMediaQuery(theme.breakpoints.down(800))
  const navigate = useNavigate()
  const netWork = sale?.idoClaimNetwork || sale?.tokenNetwork
  const { label: userStatusLabel, style: userStatusStyle } = getUserStatus(sale, true)
  const isLive = dayjs().isBefore(sale.idoEndDate)

  const onClickRegister = (event: any) => {
    event.stopPropagation()
    navigate(`/project/${sale.projectName}`)
  }

  const onClickInvest = (event: any) => {
    event.stopPropagation()
    navigate(`/participate/${sale.projectName}/0`)
  }

  const onClickViewLaunch = (event: any) => {
    event.stopPropagation()
    navigate(`/project/${sale.projectName}`)
  }

  const projectAction = () => {
    let isRegistable = dayjs().isBefore(sale.idoEndDate);
    let isParticipated = sale.participationInfo && sale.participationInfo.length;

    if (!isParticipated && isRegistable) {
      return <JoinButton onClick={onClickRegister} />
    }

    if (!isParticipated) {
      return <ViewLaunchButton onClick={onClickViewLaunch} />;  
    }

    let isInvestable = dayjs().isAfter(sale.idoInvestmentStartDate) && dayjs().isBefore(sale.idoInvestmentEndDate);
    if (sale.participationInfo[0].isWinner && !sale.participationInfo[0]?.isInvestor && isInvestable) {
      return <InvestButton onClick={onClickInvest} />
    }

    return <ViewLaunchButton onClick={onClickViewLaunch} />
  }

  return (
    <TableRow
      key={index}
      onClick={() => navigate(`/project/${sale.projectName}`)}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#000000',
        },
      }}
    >
      {isDown800 ? (
        <>
          <FixedBodyTableCell>
            <img
              src={getReplacedCdnEndpoint(sale.projectLogoUrl)}
              alt={sale.projectName}
              style={{
                width: 40,
                height: 40,
                borderRadius: 10,
                objectFit: 'cover',
              }}
            />
          </FixedBodyTableCell>
          <StyledBodyTableCell>
            <Box ml={'12px'} sx={{ width: '100%', overflow: 'hidden' }}>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: '15px',
                  fontWeight: 400,
                  width: '100%',
                  overflow: 'hidden',
                  display: 'inline-block',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  textOverflow: 'ellipsis',
                }}
              >
                {sale.projectName}
              </Typography>
            </Box>
          </StyledBodyTableCell>
        </>
      ) : (
        <StyledBodyTableCell>
          <Box display="flex" alignItems="center" sx={{ maxWidth: '220px' }}>
            <img
              src={getReplacedCdnEndpoint(sale.projectLogoUrl)}
              alt={sale.projectName}
              style={{
                width: 40,
                height: 40,
                borderRadius: 10,
                objectFit: 'cover',
              }}
            />
            <Box ml={'12px'} sx={{ width: '100%', overflow: 'hidden' }}>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: '15px',
                  fontWeight: 400,
                  width: '100%',
                  overflow: 'hidden',
                  display: 'inline-block',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  textOverflow: 'ellipsis',
                }}
              >
                {sale.projectName}
              </Typography>
              <Typography
                sx={{ color: '#7E8180', fontSize: '12px', fontWeight: 400 }}
              >
                {sale.tokenSymbol}
              </Typography>
            </Box>
          </Box>
        </StyledBodyTableCell>
      )}
      <StyledBodyTableCell>
        <img width={'24px'} src={getNetworkIconByChain(netWork)} alt={netWork} />
      </StyledBodyTableCell>
      <StyledBodyTableCell>{computeTicketSize(sale)}</StyledBodyTableCell>
      <StyledBodyTableCell>
        <Box
          sx={{
            height: '28px',
            width: '88px',
            borderRadius: '80px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Inter',
            color: getStatusStyle(isLive ? 'live' : 'ended'),
          }}
        >
          <>{isLive ? 'Live' : 'Ended'}</>
        </Box>
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {userStatusLabel ? (
          <Box
            sx={{
              padding: '6px 12px',
              width: 'fit-content',
              height: '28px',
              borderRadius: '80px',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              fontSize: '12px',
              fontWeight: 500,
              fontFamily: 'Inter',
              color: getStatusStyle(userStatusStyle),
            }}
          >
            {userStatusLabel}
          </Box>
        ) : (
          ''
        )}
      </StyledBodyTableCell>
      <StyledBodyTableCell>{projectAction()}</StyledBodyTableCell>
    </TableRow>
  )
}

export default SaleTableRow
