//@ts-nocheck
import { HomeIcon } from '../icons/HomeIcon'
import { UserPortalIcon } from '../icons/UserPortalIcon'
import { PortfolioIcon } from '../icons/PortfolioIcon'
import { LaunchpadIcon } from '../icons/LaunchpadIcon'
import { AccountIcon } from '../icons/AccountIcon'
import { GuideIcon } from '../icons/GuideIcon'
import { ApeArcadeIcon } from '../../assets'
import { PATHS, labelNavbar } from '../../constants/config'
//TODO: MUI icons must change to design icon
import NewspaperIcon from '@mui/icons-material/Newspaper'
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut'
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

const ApeArcadeSVGIcon = () => {
  return (
    <img
      src={ApeArcadeIcon}
      width={'24px'}
      alt="ape arcade icon"
    />
  )
}

export const sideMenu = {
  menuHeader: [
    {
      label: labelNavbar.Home,
      icon: <HomeIcon />,
      link: PATHS.home,
    },
    {
      label: labelNavbar.userPortal,
      icon: <UserPortalIcon />,
      link: PATHS.userPortal,
    },
    // {
    //   label: labelNavbar.portfolio,
    //   icon: <PortfolioIcon />,
    //   link: PATHS.portfolio,
    // },
  ],
  ProductsMenu: [
    {
      label: labelNavbar.investmentPortal,
      icon: <LaunchpadIcon />,
      link: PATHS.investmentPortal,
    },
    {
      label: labelNavbar.apeArcade,
      icon: <ApeArcadeSVGIcon />,
      link: PATHS.apeArcade,
      isExternal: true,
    },
    {
      label: labelNavbar.launchPools,
      icon: <SwitchAccessShortcutIcon className="overview-sidebar-icon" />,
      link: PATHS.launchPools,
      // textNavComing: 'Coming Soon',
    },
  ],
  AccountandTools: [
    {
      label: labelNavbar.account,
      icon: <AccountIcon />,
      link: PATHS.account,
    },

    // {
    //   label: labelNavbar.news,
    //   icon: <NewspaperIcon className="overview-sidebar-icon" />,
    //   link: PATHS.news,
    //   // textNavComing: 'Coming Soon',
    // },
    // {
    //   label: labelNavbar.guide,
    //   icon: <GuideIcon />,
    //   link: PATHS.guide,
    //   // textNavComing: 'Coming Soon',
    // },
  ],
  About: [
    {
      label: labelNavbar.about.title,
      icon: <AccountIcon />,
      subMenu: [
        {
          label: labelNavbar.about.web3General,
          link: PATHS.about.web3General,
        },
        {
          label: labelNavbar.about.web2Institutions,
          link: PATHS.about.web2Institutions,
        },
        {
          label: labelNavbar.about.web2Retail,
          link: PATHS.about.web2Retail,
        },
      ],
    },
  ],
  Company: [
    {
      label: labelNavbar.faq,
      icon: <LiveHelpIcon className="overview-sidebar-icon" />,
      link: PATHS.faq,
    },
    {
      label: labelNavbar.transparency,
      icon: <ContentPasteSearchIcon className="overview-sidebar-icon" />,
      link: PATHS.transparency,
    },
  ],
}

export const sideMenuMobile = [
  {
    label: labelNavbar.Home,
    icon: <HomeIcon />,
    link: PATHS.home,
  },
  {
    label: labelNavbar.launchPools,
    icon: <SwitchAccessShortcutIcon className="overview-sidebar-icon" />,
    link: PATHS.launchPools,
    // textNavComing: 'Coming Soon',
  },
  {
    label: labelNavbar.apeArcade,
    icon: <ApeArcadeSVGIcon />,
    link: PATHS.apeArcade,
    isExternal: true,
  },
  // {
  //   label: labelNavbar.account,
  //   icon: <AccountIcon />,
  //   link: PATHS.account,
  // },
  // {
  //   label: labelNavbar.news,
  //   icon: <NewspaperIcon className="overview-sidebar-icon" />,
  //   link: PATHS.news,
  //   // textNavComing: 'Coming Soon',
  // },
  // {
  //   label: labelNavbar.guide,
  //   icon: <GuideIcon />,
  //   link: PATHS.guide,
  //   // textNavComing: 'Coming Soon',
  // },
  {
    label: labelNavbar.faq,
    icon: <LiveHelpIcon className="overview-sidebar-icon" />,
    link: PATHS.faq,
  },
  {
    label: labelNavbar.transparency,
    icon: <ContentPasteSearchIcon className="overview-sidebar-icon" />,
    link: PATHS.transparency,
  },
]
