import { numberFormat } from '../../utils/function'

interface WalletTitleProps {
  count: number
  total: number
}

const WalletTitle: React.FC<WalletTitleProps> = props => {
  const { count, total } = props

  return (
    <div className="wallet-overview--header">
      <aside>
        <div />
        <span>
          <h2>Wallet Tokens</h2>
          <p>{count} Positions</p>
        </span>
      </aside>
      <b>{numberFormat(total, 2, 2, '$')}</b>
    </div>
  )
}

export default WalletTitle
