import { message } from 'antd'
import { cloneDeep } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { useAppSelector } from '../../hooks'
import {
  ETierRange,
  useFetchUserAssetsQuery,
  useFetchUserAssetsTimeSeriesQuery,
} from '../../services/modules/portfolio-manager'
import { PORTFOLIO_NETWORKS, STORAGE_NETWORK_KEY } from '../../utils/constants'
import StorageService from '../../utils/storage'
import AssetsOverview from './AssetsOverview'
import NetworksModal from './NetWorksModal'
import PageHeader from './PageHeader'
import WalletOverview from './WalletOverview'

import WalletAddressMask from './WalletAddressMask'
import AnimatedLoading from './AnimatedLoading'

const RevampPortfolioManager: React.FC = () => {
  const [, contextHolder] = message.useMessage()
  const [searchParams, setSearchParams] = useSearchParams()
  const activeAddress = searchParams.get('address') || ''
  const [tier, setTier] = useState<string>(ETierRange.day)
  const [networks, setNetworks] = useState<string[]>([])
  const [isOpenNetworkModal, setOpenNetworkModal] = useState<boolean>(false)
  const user = useAppSelector(state => state.user)
  const { walletAddress, mainAppToken } = user
  const { address } = useAccount()
  const isLogIned = address || user.email

  const chains = useMemo(() => {
    const storageNetworks =
      StorageService.get(STORAGE_NETWORK_KEY) ||
      PORTFOLIO_NETWORKS.map(n => n.code)
    return storageNetworks
  }, [networks])

  const { data: userAssetsRes, isFetching: isFetchingUserAssets } =
    useFetchUserAssetsQuery(
      {
        chains,
        wallet: activeAddress,
      },
      {
        skip: !activeAddress,
        refetchOnMountOrArgChange: true,
      },
    )

  const { data: userChartData, isFetching: isFetchingUserChartData } =
    useFetchUserAssetsTimeSeriesQuery(
      {
        tierName: tier,
        chains,
        wallet: activeAddress,
      },
      {
        skip: !activeAddress,
        refetchOnMountOrArgChange: true,
      },
    )

  const userAssetData = useMemo(() => {
    if (userAssetsRes) {
      const { tokenAssets } = userAssetsRes
      return cloneDeep(tokenAssets)
    }
    return []
  }, [userAssetsRes])

  const chartData = useMemo(() => cloneDeep(userChartData), [userChartData])

  const handleChangeNetworks = (networks: string[]) => {
    setNetworks(networks)
    setOpenNetworkModal(false)
  }

  const closeNetworkModal = () => {
    setOpenNetworkModal(false)
  }

  useEffect(() => {
    if (!activeAddress && walletAddress && mainAppToken) {
      setSearchParams({ address: walletAddress })
    }
  }, [mainAppToken])

  if (!isLogIned && !activeAddress) {
    return <WalletAddressMask />
  }

  if (isFetchingUserAssets || isFetchingUserChartData) {
    return <AnimatedLoading />
  }

  return (
    <div className="page-wrapper" style={{ width: '100%' }}>
      <PageHeader
        onOpenNetwork={() => setOpenNetworkModal(true)}
        // onBundled={handleSearchBundle}
      />
      <AssetsOverview
        assets={userAssetData}
        assetsChart={chartData ?? null}
        currentTier={tier} // onFilter={fetchUserAssets}
        onFilter={filter => setTier(filter)}
      />
      {userAssetData.length > 0 && <WalletOverview assets={userAssetData} />}

      {isOpenNetworkModal ? (
        <NetworksModal
          open={isOpenNetworkModal}
          onSave={handleChangeNetworks}
          onClose={closeNetworkModal}
        />
      ) : null}
      {contextHolder}
    </div>
  )
}

export default RevampPortfolioManager
