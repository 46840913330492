import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { StyledSubText, StyledText } from '.'
import StatsBox from './StatsBox'
import CompletedBox from './CompletedBox'
import { useAccount, useSignMessage } from 'wagmi'
import WalletBox from './WalletBox'
import WalletSignSolana from './WalletSignSolana'
import { useUpdateSolanaAddressInParticipationMutation } from '../../services/modules/participations'
import { useAppDispatch } from '../../hooks'

const RenderSolanaAddress = ({
  idoData,
  score,
  totalParticipants,
  totalAssetsConnected,
  multiplierData,
  userParticipation,
  updateSolanaAddressInParticipation,
  isBusy,
}: any) => {

  const dispatch = useAppDispatch()

  const onClick = (payload :Record<string, any>) => {
    updateSolanaAddressInParticipation({
      participationId: userParticipation?._id || '',
      solanaAddress: payload.solanaAddress,
      signature: payload.signature,
      message: payload.message,
    })
  }

  return (
    <WalletSignSolana
      onSubmit={onClick}
      isBusy={isBusy}
    />
  )
}

export default RenderSolanaAddress
