import React, { FC, useMemo } from 'react'
import { CssBaseline, PaletteMode, ThemeOptions } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useThemeSwitch } from '../hooks/switchTheme'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    smd: true
    md: true
    lg: true
    xl: true
    mobile: false
    tablet: false
    laptop: false
    desktop: false
  }

  interface Palette {
    regular: Palette['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    orangeContained: true
    grayContained: true
    greenContainer: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    green: Palette['primary']
    idoHeadings: Palette['primary']
  }

  interface PaletteOptions {
    green?: PaletteOptions['primary']
    idoHeadings?: PaletteOptions['primary']
  }
}

const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      smd: 992,
      md: 1152,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: {
            main: '#fff',
          },
          background: {
            default: '#091612',
            paper: '#091612',
          },
          text: {
            primary: '#fff',
            secondary: '#000',
          },
          secondary: {
            main: '#0F1113',
          },
          green: {
            main: '#6FEB7C',
          },
          idoHeadings: {
            main: '#F4FDAB',
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: '#0c031a',
            light: '#190a30',
            dark: '#080113',
          },
          secondary: {
            main: '#0F1113',
          },
          background: {
            default: '#091612',
            paper: '#091612',
          },
          text: {
            primary: '#fff',
            secondary: '#000',
          },
          info: {
            main: '#B1B4B3',
          },
          success: {
            main: '#F4FDAB',
          },
          warning: {
            main: '#F72',
          },
          green: {
            main: '#6FEB7C',
          },
          idoHeadings: {
            main: '#F4FDAB',
          },
        }),
  },

  // default InputLabel props

  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontWeight: '700',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          //font family
          fontFamily: 'Hellix,Inter',
          color: '#fff',
          outline: '#fff',

          // active styles
          '&:focus': {
            borderColor: '#fff',
            outline: 0,
          },

          // hover styles
          '&:hover': {
            color: '#fff',
          },

          // disabled styles
          '&:disabled': {
            color: '#fff',
          },
        },
      },
    },
    MuiButton: {
      // default button props
      defaultProps: {
        // hover
        // sx: {
        //   "&:hover": {
        //     backgroundColor: "transparent",
        //   },
        // },
      },
      styleOverrides: {
        contained: {
          background: '#FF7722',
          color: '#000',
          borderRadius: '0px',
          '&:hover': {
            background: '#FF7722',
            color: '#000',
          },
          height: 50,
          '&:disabled': {
            background: '#FF7722',

            color: '#000',
          },
        },

        outlined: {
          color: '#FFFFFF',
          border: '1px solid #FFFFFF',
        },
      },

      variants: [
        {
          props: { variant: 'orangeContained' },
          style: {
            textTransform: 'none',
            borderRadius: '0px',
            background: '#F72',
            color: '#000',
            fontSize: '18px',

            '&:hover': {
              background: 'rgba(255,119,34,.8)',
              color: '#000',
            },
          },
        },

        {
          props: { variant: 'grayContained' },
          style: {
            textTransform: 'none',
            borderRadius: '0px',
            background: '#333',
            color: '#FFF',
            '&:hover': {
              background: '#333',
              color: '#FFF',
            },
          },
        },

        {
          props: { variant: 'greenContainer' },
          style: {
            textTransform: 'none',
            borderRadius: '0px',
            background: '#63EA71',
            color: '#000',
            fontWeight: 600,
            '&:hover': {
              background: '#63EA71',
              color: '#000',
            },
            ':disabled': {
              background: '#333',
              color: '#FFF',
              cursor: 'not-allowed !important',
            },
          },
        },
      ],
    },

    MuiChip: {
      styleOverrides: {
        filled: {
          background: 'rgba(255, 255, 255, 0.11)',
          textTransform: 'uppercase',
        },
      },
    },

    MuiTypography: {
      //define primary and secondary colors for typography

      styleOverrides: {
        root: {
          color: '#fff',
        },
        h1: {
          fontSize: '60px',
          fontWeight: '700',
          '@media (max-width:600px)': {
            fontSize: '40px',
          },
        },
        h2: {
          fontSize: '32px',
          fontWeight: '600',
          '@media (max-width:600px)': {
            fontSize: '28px',
          },
        },
        h3: {
          fontSize: '24px',
          '@media (max-width:600px)': {
            fontSize: '20px',
          },
        },
        h4: {
          fontSize: '20px',
          '@media (max-width:600px)': {
            fontSize: '16px',
          },
        },

        h5: {
          fontSize: '18px',
          '@media (max-width:600px)': {
            fontSize: '14px',
          },
        },
        h6: {
          fontSize: '16px',
          '@media (max-width:600px)': {
            fontSize: '12px',
          },
        },
        body1: {
          fontSize: '14px',
          '@media (max-width:600px)': {
            // fontSize: '10px',
          },
        },
      },
      defaultProps: {
        color: '#fff',
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          border: '1px solid #FFFFFF',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'rgba(255,255,255,0.5)',
          border: '1px solid #FFFFFF',
          fontWeight: '700',
          '&.Mui-selected': {
            color: '#6FEB7C',
            fontWeight: '700',
            border: '1px solid #FFFFFF',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          background: '#0F1113',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: '#000',
          color: '#FFF',
          border: '1px solid #313335',
          textAlign: 'center',
          padding: '8px 12px',
          fontSize: '12px',
          borderRadius: '8px',
        },
      },
    },
  },

  typography: {
    fontFamily: 'Hellix,Inter',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
})
//
const Theme = ({ children }: any) => {
  const [mode] = useThemeSwitch()

  const theme = useMemo(
    () => createTheme(getDesignTokens(mode as PaletteMode)),
    [mode],
  )

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </>
  )
}

export default Theme
