import {
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { CircledInfo } from '../../assets'
import GreenButton from '../../components/GreenButton'
import { useEffect, useState } from 'react'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'

import Motion from '../../components/Motion/Motion'
import { margin } from '@mui/system'
import OrangeButton from '../../components/OrangeButton'
import ScrollToHash from './ScrollToHash'

const FAQ = ({ isBreakTitle = true }) => {
  const isDown600 = useMediaQuery('(max-width:600px)')
  const [expanded, setExpanded] = useState<number | null>(null)

  const questions = [
    {
      id: 1,
      question: 'Do I need an account to join a sale?',
      answer:
        'No. Ape Terminal offers the smoothest participation experience. Opt into any sale without an account. Just connect your web3 wallet. We get 0 access to your assets as you never need to sign any transaction. Only if you win, you will then have to make an account.',
    },
    {
      id: 2,
      question: 'Is there a fee?',
      answer:
        'There are 0 fees to opt in. There are 0 token staking requirements. Only winners pay, and they pay only a 25% fee on their won tokens.',
    },
    {
      id: 3,
      question: 'Do I need to KYC to opt in?',
      answer:
        'While users don’t need to KYC for applying to an IDO, they must pass it to contribute if they win. If users win an allocation but fail to contribute, their allocation is backstopped by company treasury to ensure the IDO is completed.',
    },
    {
      id: 4,
      question: 'How can I increase my win chance?',
      answer:
        'Refer friends, use the Terminal’s sandwich and snipe products, or help push the Ape Terminal or project’s socials.',
    },
    {
      id: 5,
      question: 'What happens to unsold tickets?',
      answer:
        'Any unused tickets for an IDO are bought by the treasury instead.',
    },
    {
      id: 6,
      question:
        'Is my ticket for contributing to the IDO guaranteed after winning the raffle?',
      answer:
        "No, winning the raffle gives you the chance to contribute but doesn't guarantee a spot due to the limited number of contribution spots compared to the number of raffle winners. It's important to act quickly to secure your contribution.",
    },
    {
      id: 7,
      question:
        'Can the contribution to the IDO close before the published time?',
      answer:
        'Yes, the contribution window can close early if the IDO hard cap amount reaches its capacity before the scheduled end of the contribution period. Therefore, timely action is required.',
    },
    {
      id: 15,
      question: 'How does Ape Terminal select its projects?',
      answer: <>
        <span style={{display: 'block', marginBottom: '12px'}}>Ape Terminal mainly assesses the potential of secondary liqudity of the token being offered. This assessment considers the narrative, project's marketing resources, and distribution resources.</span>
        <span style={{display: 'block'}}>The product associated with the token is also considered but there is greater emphasis on community growth measures.</span>
      </>
    },
    {
      id: 16,
      question: 'Are sales on Ape Terminal non-refundable?',
      answer: 'Some sales are non-refundable and some sales are refundable. Please read our Refund FAQ for details on how our Refund Policy works.'
    },
  ]

  const questionsRefunds = [
    {
      id: 8,
      question:
        'What is the Refund Policy, and How Does it Work?',
      answer:
      <>
      <span style={{display: 'block', marginBottom: '12px'}}>The refund policy applies to most upcoming sales on Ape Terminal starting October 2024</span>
      <span style={{display: 'block', marginBottom: '12px'}}>On the day of a project’s Token Generation Event (TGE), users may either request a refund or claim their tokens. The refund request period will remain open for 24 hours following the TGE, after which funds become available for claiming within 24 hours.</span>
      <span style={{display: 'block'}}>In rare cases, certain sales will be non-refundable, and these will be clearly marked as such.</span>
      </>
    },
    {
      id: 9,
      question:
        'Is the Refund Policy Optional for Investors, or Will It Be Enforced Automatically?',
      answer:
        'The refund policy is entirely optional for investors. Users have a 24-hour window to decide whether to request a refund.',
    },
    {
      id: 10,
      question:
        'Will a 25% Fee Apply if I Opt for a Refund?',
      answer:
        'No, it will not. If you opt for a refund, you will receive your full contributed amount.',
    },
    {
      id: 11,
      question:
        'Are All Sales on Ape Terminal Refundable?',
      answer: <>
        <span style={{display: 'block', marginBottom: '6px'}}>Yes, starting from October 2024, most upcoming projects on Ape Terminal will adopt a refundable offering frame.</span>
        <span style={{display: 'block' }}>In rare cases, certain sales will be non-refundable, and these will be clearly marked as such.</span>
      </>
    },
    {
      id: 12,
      question:
        'If I Request a Refund 1 Day After Listing, Will I Receive My Money Back Immediately, or Will All Refunds Be Issued at the End of the Refund Period?',
      answer:
        'Refunds will be available 24 hours after the 24-hour refund period ends.',
    },
    {
      id: 13,
      question:
        'Do We Only Have 30 Minutes After TGE to Decide Whether to Refund or Claim?',
      answer: 'No, you have a 24-hour period to request a refund.',
      // <>
      // <span style={{display: 'block', marginBottom: '12px'}}>No, you have a 24-hour period to request a refund.</span>
      // <span style={{display: 'block'}}>In rare cases, certain sales will be non-refundable, and these will be clearly marked as such.</span>
      // </>
    },
    {
      id: 14,
      question:
        'Which blockchain will be used to claim the refund once it’s available?',
      answer:
        'All refunds will be processed and claimed on BSC.',
    },
  ]

  useEffect(() => {
      // Function to scroll to the element with the ID from the hash
      const scrollToElement = () => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.getElementById(hash.substring(1)); // Remove the '#' from the hash
          if (element) {
            setTimeout(() => {
              element.scrollIntoView({ behavior: 'smooth' });
            }, 1000)
          }
        }
      };
  
      // Scroll on initial load
      scrollToElement();
  
      // Scroll on hash change
      window.addEventListener('hashchange', scrollToElement);
  
      // Cleanup the event listener
      return () => {
        window.removeEventListener('hashchange', scrollToElement);
      };
    }, []);
  

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      // maxWidth={'996px'}
      width={'100%'}
      sx={{paddingBottom: '64px'}}
    >
      <Motion from={{ y: 0, filter: 'blur(5px)' }} to={{ filter: 'blur(0px)' }}>
        <Typography
          sx={{
            fontFamily: 'Hellix',
            fontSize: isDown600 ? '32px' : '32px',
            fontWeight: 600,
            color: '#FFFFFF',
            margin: '0px',
            lineHeight: isDown600 ? '38.4px' : '61.6px',
            textAlign: 'left',
          }}
        >
          Frequently Asked {isBreakTitle && <br />}Questions
        </Typography>
      </Motion>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isDown600 ? 'column' : 'row',
          gap: isDown600 ? '18px' : '24px',
          marginTop: '32px',
        }}
      >
        <Box>
          <Box>
            <Typography
              sx={{
                fontFamily: 'Hellix',
                fontSize: '24px',
                fontWeight: 600,
                color: '#FFFFFF',
                marginBottom: '12px',
                lineHeight: isDown600 ? '38.4px' : '61.6px',
                textAlign: 'left',
              }}
            >
              General
            </Typography>
            {questions.map((item, index) => (
              <Motion
                from={{ y: 10, filter: 'blur(5px)' }}
                to={{ filter: 'blur(0px)' }}
                delay={(0.05 * index) as number}
                style={{
                  display: 'block',
                  marginBottom: '8px',
                }}
                key={item.id}
              >
                <Accordion
                  expanded={expanded === item.id}
                  onChange={() => {
                    if (item.answer) {
                      setExpanded(expanded === item.id ? null : item.id)
                    }
                  }
                  }
                  sx={{
                    backgroundColor: '#0F1113',
                    backgroundImage: 'none',
                    borderRadius: '12px',
                    border: '0.5px solid #0F1113',
                    transition: 'all 0.3s',
                    '&.Mui-expanded': {
                      margin: '0px',
                      border: '0.5px solid #313335',
                    },
                    '&::before': {
                      display: 'none',
                    },
                    '&:last-of-type': {
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                    },
                    '&:first-of-type': {
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px',
                    },
                    '&:hover': {
                      border: '0.5px solid #313335',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === item.id ? (
                        <RemoveOutlinedIcon
                          sx={{
                            color: '#767676',
                            height: '20px',
                            width: '20px',
                            marginLeft: '8px',
                            transition: 'color 0.3s',
                          }}
                        />
                      ) : (
                        <AddOutlinedIcon
                          sx={{
                            color: '#767676',
                            height: '20px',
                            width: '20px',
                            marginLeft: '8px',
                            transition: 'color 0.3s',
                          }}
                        />
                      )
                    }
                    sx={{
                      margin: '0px',
                      height: 'fit-content',
                      paddingX: '20px',
                      minHeight: '10px',
                      borderRadius: '12px',
                      '&.Mui-expanded': {
                        minHeight: '10px',
                      },
                      '& .MuiAccordionSummary-content': {
                        marginTop: '18px',
                        marginBottom: expanded === item.id ? '8px' : '18px',
                        minHeight: '10px',
                      },
                      '&:hover .faq__question': {
                        color: '#ffffff',
                      },
                      '&:hover .MuiAccordionSummary-expandIconWrapper svg': {
                        color: '#ffffff',
                      },
                    }}
                    className="group"
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '17px',
                        fontWeight: 500,
                        color: expanded === item.id ? '#ffffff' : '#B1B4B3',
                        lineHeight: '27px',
                        transition: 'color 0.3s',
                      }}
                      className="faq__question"
                    >
                      {item.question}
                    </Typography>
                  </AccordionSummary>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#B1B4B3',
                      paddingX: '20px',
                      paddingBottom: '16px',
                      lineHeight: '24px',
                    }}
                  >
                    {item.answer}
                  </Typography>
                </Accordion>
              </Motion>
            ))}
          </Box>
          
          <Box id="refunds-faq">
            <Typography
              sx={{
                fontFamily: 'Hellix',
                fontSize: '24px',
                fontWeight: 600,
                color: '#FFFFFF',
                marginTop: '64px',
                marginBottom: '12px',
                lineHeight: isDown600 ? '38.4px' : '61.6px',
                textAlign: 'left',
              }}
            >
              Refunds
            </Typography>
            {questionsRefunds.map((item, index) => (
              <Motion
                from={{ y: 10, filter: 'blur(5px)' }}
                to={{ filter: 'blur(0px)' }}
                delay={(0.05 * index) as number}
                style={{
                  display: 'block',
                  marginBottom: '8px',
                }}
                key={item.id}
              >
                <Accordion
                  expanded={expanded === item.id}
                  onChange={() => {
                    if (item.answer) {
                      setExpanded(expanded === item.id ? null : item.id)
                    }
                  }
                  }
                  sx={{
                    backgroundColor: '#0F1113',
                    backgroundImage: 'none',
                    borderRadius: '12px',
                    border: '0.5px solid #0F1113',
                    transition: 'all 0.3s',
                    '&.Mui-expanded': {
                      margin: '0px',
                      border: '0.5px solid #313335',
                    },
                    '&::before': {
                      display: 'none',
                    },
                    '&:last-of-type': {
                      borderBottomLeftRadius: '12px',
                      borderBottomRightRadius: '12px',
                    },
                    '&:first-of-type': {
                      borderTopLeftRadius: '12px',
                      borderTopRightRadius: '12px',
                    },
                    '&:hover': {
                      border: '0.5px solid #313335',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === item.id ? (
                        <RemoveOutlinedIcon
                          sx={{
                            color: '#767676',
                            height: '20px',
                            width: '20px',
                            marginLeft: '8px',
                            transition: 'color 0.3s',
                          }}
                        />
                      ) : (
                        <AddOutlinedIcon
                          sx={{
                            color: '#767676',
                            height: '20px',
                            width: '20px',
                            marginLeft: '8px',
                            transition: 'color 0.3s',
                          }}
                        />
                      )
                    }
                    sx={{
                      margin: '0px',
                      height: 'fit-content',
                      paddingX: '20px',
                      minHeight: '10px',
                      borderRadius: '12px',
                      '&.Mui-expanded': {
                        minHeight: '10px',
                      },
                      '& .MuiAccordionSummary-content': {
                        marginTop: '18px',
                        marginBottom: expanded === item.id ? '8px' : '18px',
                        minHeight: '10px',
                      },
                      '&:hover .faq__question': {
                        color: '#ffffff',
                      },
                      '&:hover .MuiAccordionSummary-expandIconWrapper svg': {
                        color: '#ffffff',
                      },
                    }}
                    className="group"
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '17px',
                        fontWeight: 500,
                        color: expanded === item.id ? '#ffffff' : '#B1B4B3',
                        lineHeight: '27px',
                        transition: 'color 0.3s',
                      }}
                      className="faq__question"
                    >
                      {item.question}
                    </Typography>
                  </AccordionSummary>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#B1B4B3',
                      paddingX: '20px',
                      paddingBottom: '16px',
                      lineHeight: '24px',
                    }}
                  >
                    {item.answer}
                  </Typography>
                </Accordion>
              </Motion>
            ))}
          </Box>
        </Box>

        <Motion
          from={{ y: 10, filter: 'blur(5px)' }}
          to={{ filter: 'blur(0px)' }}
          threshold={0.2}
          style={{
            display: 'block',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#0F1113',
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              borderRadius: '12px',
              height: 'fit-content',
              border: '1px solid rgba(0,0,0,0.2)',
              marginTop: isDown600 ? '32px' : '72px',
            }}
          >
            <img
              src={CircledInfo}
              alt="Circled Info"
              height={'32px'}
              width={'32px'}
            />
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '21px',
                color: '#B1B4B3',
              }}
            >
              Can't find what you're looking for?
            </Typography>
            <GreenButton
              fs={15}
              lh="22.5px"
              fm="Inter"
              fw={400}
              br="24px"
              text="Submit"
              onClick={() => {
                // open link in different tab
                window.open('https://t.me/ApeTerminalChat', '_blank')
              }}
              outlined
              w="148px"
              h="40px"
              border={'1px solid #08493C'}
              />
          </Box>
        </Motion>
      </Box>
    </Box>
  )
}

export default FAQ
