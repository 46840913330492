import { SetStateAction, useEffect, useState } from 'react'
import NoDataTable from '../NoDataTable'
import NoDataBox from '../NoDataBox'
import {  useMediaQuery } from '@mui/material'
import SkeletonHistoryTable from '../SkeletonHistoryTable'
import { useAppSelector } from '../../../../hooks'
import { useAccount } from 'wagmi'
import {
  IResponseUserPortalItem,
  useFetchUserParticipationsHistoryQuery,
} from '../../../../services/modules/participationsV2'
import { useLocation } from 'react-router-dom'
import HistoryTable from '../HistoryTable'

interface LocationState {
  activeTab?: ETabsName
}

export enum ETabsName {
  IDOs = 'IDOs',
  Claim = 'Claim',
  Referral = 'Referrals',
}
const historyHeaders = [
  'Project',
  'Chain',
  'Participants',
  'Ticket Size',
  'User Status',
  'IDO Status',
]

type HistoryTableComponentProps = {
  keyword: string,
  isVisible: boolean,
  isRefresh: boolean,
}

const HistoryTableComponent = (props: HistoryTableComponentProps) => {
  const { keyword, isVisible, isRefresh } = props

  const limit = 5
  const location = useLocation()
  const { state } = location as { state: LocationState | null }
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const [page, setPage] = useState(1)
  const [data, setData] = useState<IResponseUserPortalItem[]>([])
  const [debouncedSearchName, setDebouncedSearchName] = useState('')
  const [activeTab, setActiveTab] = useState(ETabsName.IDOs)
  const user = useAppSelector(state => state.user)
  const { accessTokenGoogle, mainAppToken } = user
  const { address } = useAccount()
  const [isShowButton, setIsShowButton] = useState(false)

  // Fetch user paticipate sales
  const {
    data: fetchedData,
    isLoading,
    isFetching,
    refetch: fetchUserParticipationHistory,
  } = useFetchUserParticipationsHistoryQuery(
    { page, limit, name: keyword || undefined },
    {
      skip: !address && !mainAppToken && !accessTokenGoogle,
    },
  )

  useEffect(() => {
    if (mainAppToken && address) {
      setPage(1)
      fetchUserParticipationHistory()
    }
  }, [keyword])

  useEffect(() => {
    if (!isVisible || isRefresh) {
      setPage(1)
    }

    if (isRefresh) {
      fetchUserParticipationHistory()
    }
  }, [isVisible, isRefresh])

  // Concat data when load more
  useEffect(() => {
    if (fetchedData?.data && (address || accessTokenGoogle)) {
      if (page === 1) {
        setData(fetchedData.data)
      } else {
        setData(prevData => [...prevData, ...fetchedData.data])
      }
    }
  }, [address, accessTokenGoogle, fetchedData])

  useEffect(() => {
    if (mainAppToken && address || accessTokenGoogle) {
      fetchUserParticipationHistory()
    } else {
      setData([])
    }
  }, [mainAppToken, address, accessTokenGoogle, fetchUserParticipationHistory])

  useEffect(() => {
    if (state?.activeTab) {
      setActiveTab(state.activeTab)
    }
  }, [state])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchName(keyword)
    }, 700)
    return () => {
      clearTimeout(handler)
    }
  }, [keyword])

  useEffect(() => {
    if (debouncedSearchName) {
      if (fetchedData && fetchedData?.meta?.total_records <= 5) {
        setPage(1)
        setIsShowButton(true)
      } else {
        setIsShowButton(false)
      }

      fetchUserParticipationHistory()
    } else {
      setIsShowButton(false)
    }
  }, [activeTab, debouncedSearchName, fetchUserParticipationHistory])

  const handleShowMore = (isCollapes = false) => {
    if (isCollapes) {
      setPage(1)
      return
    }

    if (Number(fetchedData?.meta?.total_records) > data.length) {
      setPage(p => p + 1)
    } else {
      setPage(1)
    }
  }

  return (
    <>
      {isLoading ? (
        <SkeletonHistoryTable />
      ) : data?.length ? (
        <HistoryTable
          isFetching={isFetching}
          isShowButton={isShowButton}
          mySales={data}
          meta={fetchedData?.meta}
          handleShowMore={isCollapes => handleShowMore(isCollapes)}
        />
      ) : (
        <>
          {!isDown1000 ? (
            <NoDataTable headers={historyHeaders} />
          ) : (
            <NoDataBox />
          )}
        </>
      )}
    </>
  )
}

export default HistoryTableComponent
