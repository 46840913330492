import React from 'react'
import { useLocation } from 'react-router-dom'

import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import Layout from '../../pages/layouts'
import RevampedHome from '../../pages/RevampedHome'

const ThemeContainerStyles = styled(Grid)(({ theme }) => ({
  // background: 'linear-gradient(0deg, #0F051F, #0F051F)',
  backgroundColor: theme.breakpoints.down('md')
    ? theme.palette.text.primary
    : theme.palette.background.paper,

  background: '#091612',

  display: 'flex',
  height: '100%',
  // minHeight: '100vh',
}))

const ThemeContainer = ({ children }: any) => {
  const location = useLocation()
  const isLandingPage = ['','/'].includes(location.pathname)

  return (
    isLandingPage 
    ? <RevampedHome />
    : <ThemeContainerStyles
      container
      flexDirection={'column'}
      //   sx={{
      //     padding:
      //       location.pathname === '/' ? '0px' : '0px 100px 0px 100px',
      //   }}
    >
      <Layout>{children}</Layout>
    </ThemeContainerStyles>
  )
}

export default ThemeContainer
