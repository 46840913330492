export enum IFilterStatus {
  ALL = 'All',
  PARTICIPATION = 'Participation',
  PROCESSING = 'Processing',
  INVESTMENT = 'Investment',
  TGE = 'TGE',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  MY_INVESTMENT = 'My Sales',
  LIVE_SALE = 'Live Sales',
  SALES = 'Sales',
  CLAIM = 'Claims',
  HISTORY = 'History',
}

export enum IDOStatus {
  PARTICIPATION = 'Participation',
  PROCESSING = 'Processing',
  INVESTMENT = 'Investment',
  TGE = 'TGE',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export enum ClaimStatus {
  PRE_TGE = 'Pre-TGE',
  DISTRIBUTING = 'Distributing',
  CLAIMED = 'Claimed',
  REFUNDED = 'Refunded',
  REQUEST_REFUND = 'Refund Requested',
}

export enum CLAIM_EVENT_NAME {
  CLAIMED = 'Vesting_Claimed',
  REFUNDED  = 'Refund_Refunded',
  REQUEST_REFUND = 'Refund_RefundRequested',
}

export enum ADMIN_DEPOSIT_EVENT_NAME {
  VESTING_DEPOSITED = 'Vesting_ProjectTokenDeposited',
  REFUND_DEPOSITED  = 'Refund_ProjectRefundTokenDeposited',
}
