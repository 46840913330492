import { useCallback, useMemo, useState } from 'react'
import { useAccount } from 'wagmi'
import { GreenFilledArrow } from '../../assets'
import ConfirmModal from '../../components/ConfirmModal'
import GreenButton from '../../components/GreenButton'
import { useAppDispatch, useAppSelector } from '../../hooks'
import useDisconnectWalletAndProfile from '../../hooks/useDisconnectWalletAndEmail'
import {
  useFetchNotificationHistoryQuery,
  userApi,
} from '../../services/modules/notification'
import {
  useUnLinkEmailWidthAddressMutation,
  useVerifyGoogleTokenMutation,
} from '../../services/modules/users'
import { shortenAddress } from '../../utils'
import {
  initialStateSnackbar,
  setSnackbar,
} from '../../redux/slices/themeSlice'

interface DisconnectButtonProps {
  textLabel?: string
}

const DisconnectButton: React.FC<DisconnectButtonProps> = ({ textLabel }) => {
  const { disconnectWalletAndProfile } = useDisconnectWalletAndProfile()
  const [unLinkEmailWidthAddress] = useUnLinkEmailWidthAddressMutation()
  const [verifyGoogleToken] = useVerifyGoogleTokenMutation()
  const dispatch = useAppDispatch()
  const { address } = useAccount()
  const user = useAppSelector(state => state.user)
  const [loading, setLoading] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [isUnlinking, setIsUnlinking] = useState(false)

  const showMessage = useCallback(
    (message: string, severity?: 'success' | 'error') =>
      dispatch(
        setSnackbar({
          open: true,
          severity: severity ?? 'error',
          message,
        }),
      ),
    [dispatch],
  )

  const { refetch } = useFetchNotificationHistoryQuery({
    limit: 10,
    page: 1,
  })

  // const handleUnLinkEmailWidthAddress = useCallback(async () => {
  //   if (user.accessTokenGoogle || user.mainAppToken) {
  //     setLoading(true)
  //     try {
  //       const result = await unLinkEmailWidthAddress().unwrap()
  //       const addressPart = shortenAddress(
  //         address || user.addressLinkEmail || user.addressRegisterUserAccount,
  //         5,
  //         4,
  //       )
  //       const emailPart = user.email || user.emailRegisterUserAccount
  //       if (result) {
  //         setMessage(`Wallet ${addressPart} is unlinked to email ${emailPart}`)
  //         dispatch(setAccessTokenGoogle('')) // remove access token login gg
  //         setOpenToast(true)
  //         if (user.email && user.googleToken && user.nameGoogleToken) {
  //           const data = await verifyGoogleToken({
  //             email: user.email,
  //             googleToken: user.googleToken,
  //             fullName: user.nameGoogleToken,
  //           }).unwrap()
  //           if (data) {
  //             refetch()
  //             dispatch(userApi.util.resetApiState())
  //           }
  //         }
  //         setTimeout(() => {
  //           if (address && !user.email) {
  //             dispatch(setEmail(''))
  //             dispatch(setEmailRegisterUserAccount(''))
  //           }
  //           if (user.addressLinkEmail && address !== user.addressLinkEmail) {
  //             dispatch(setAddressLinkEmail(''))
  //             dispatch(setAddressRegisterUserAccount(''))
  //           }
  //           if (address === user.addressLinkEmail) {
  //             if (user.isLoggedInWithEmail || !user.isLoggedInWithEmail) {
  //               disconnect()
  //               dispatch(setAddressLinkEmail(''))
  //               dispatch(setAddressRegisterUserAccount(''))
  //               dispatch(setSignature(''))
  //               dispatch(setAccessTokenGoogle('')) // remove access token login gg
  //               dispatch(setMainAppToken('')) // remove access token login wallet
  //             }
  //           }

  //           setOpenToast(false)
  //           setLoading(false)
  //         }, 3000)
  //       }
  //     } catch (error: any) {
  //       setMessage(error?.data?.message)
  //       setOpenToast(true)
  //       setLoading(false)
  //       console.log('Error unlinking email:', error)

  //       setTimeout(() => {
  //         setOpenToast(false)
  //       }, 3000)
  //     }
  //   }
  // }, [
  //   address,
  //   disconnect,
  //   dispatch,
  //   refetch,
  //   setMessage,
  //   setOpenToast,
  //   unLinkEmailWidthAddress,
  //   user.accessTokenGoogle,
  //   user.addressLinkEmail,
  //   user.addressRegisterUserAccount,
  //   user.email,
  //   user.emailRegisterUserAccount,
  //   user.googleToken,
  //   user.isLoggedInWithEmail,
  //   user.mainAppToken,
  //   user.nameGoogleToken,
  //   verifyGoogleToken,
  // ])

  const addressPart = useMemo(
    () =>
      shortenAddress(
        address || user.addressLinkEmail || user.addressRegisterUserAccount,
        5,
        4,
      ),
    [address, user.addressLinkEmail, user.addressRegisterUserAccount],
  )

  const handleUnLinkEmailWidthAddress = async () => {
    try {
      setIsUnlinking(true)
      const result = await unLinkEmailWidthAddress().unwrap()

      const emailPart = user.email || user.emailRegisterUserAccount
      if (result) {
        showMessage(
          `Wallet ${addressPart} is unlinked to email ${emailPart}`,
          'success',
        )
        if (user.email && user.googleToken && user.nameGoogleToken) {
          const data = await verifyGoogleToken({
            email: user.email,
            googleToken: user.googleToken,
            fullName: user.nameGoogleToken,
          }).unwrap()
          if (data) {
            refetch()
            dispatch(userApi.util.resetApiState())
          }
        }
        setTimeout(() => {
          dispatch(setSnackbar(initialStateSnackbar))
          setLoading(false)
        }, 3000)
      }
    } catch (error) {
      //
    } finally {
      setIsUnlinking(false)
      disconnectWalletAndProfile()
    }
  }

  const confirmUnlink = useCallback(() => {
    setShowConfirmModal(true)
  }, [])

  return (
    <>
      <GreenButton
        fs={15}
        lh="22.5px"
        fm="Inter"
        fw={400}
        br="24px"
        disabled={loading}
        text={textLabel ?? 'Disconnect'}
        onClick={confirmUnlink}
        outlined={true}
        IconStart={() => <img src={GreenFilledArrow} alt="DisconnectIcon" />}
        mt={1}
        sx={{
          border: '1px solid #08493C',
          padding: '3px 30px',
          height: '40px',
          backgroundColor: 'transparent',
          '&:hover': {
            border: '1px solid #27B079',
            backgroundColor: 'transparent',
          },
        }}
      />
      {showConfirmModal ? (
        <ConfirmModal
          open={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          desc={`You will not be able to see IDOs project you have joined! Are you sure you want to unlink wallet ${addressPart} ?`}
          onCancel={() => setShowConfirmModal(false)}
          onConfirm={handleUnLinkEmailWidthAddress}
          buttonDisabled={isUnlinking}
          buttonLoading={isUnlinking}
        />
      ) : null}
    </>
  )
}
export default DisconnectButton
