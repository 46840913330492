import { Typography, Box, useMediaQuery, useTheme } from '@mui/material'
import { arrowGetStartedIcon } from '../../assets'
import OrangeButton from '../../components/OrangeButton'
import { PATHS } from '../../constants/config';

const InfoCard = ({ title, subtitle }: { title: string; subtitle: string }) => {
  const isDown600 = useMediaQuery('(max-width:600px)')
  const theme = useTheme()
  const isBetweenScreen = useMediaQuery(theme.breakpoints.between(767, 1200))
  const isBetweenScreen1200 = useMediaQuery(
    theme.breakpoints.between(767, 1366),
  )
  return (
    <Box
      sx={{
        border: '1px solid #000',
        borderRadius: '20px',
        display: 'flex',
        width: isDown600 ? '100%' : isBetweenScreen ? 'max-content' : '227px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '103px',
        backgroundColor: '#F4FDAB',
        marginBottom: '4px',
        paddingX: isBetweenScreen
          ? '30px'
          : isBetweenScreen1200
          ? '24px'
          : 'auto',
        boxShadow: '0px 4px 0px 0px #000',
      }}
    >
      <Typography
        sx={{
          color: '#000',
          fontFamily: 'Hellix',
          fontSize: '36px',
          fontWeight: 600,
          lineHeight: '52.8px',
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: '#000',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '22px',
          textAlign: 'center',
        }}
      >
        {subtitle}
      </Typography>
    </Box>
  )
}

const GetStartedIn = () => {
  const isDown1023 = useMediaQuery('(max-width:1023px)')
  const isDown600 = useMediaQuery('(max-width:600px)')
  const theme = useTheme()
  const isBetweenScreen = useMediaQuery(theme.breakpoints.between(767, 1200))
  const isBetweenScreen1200 = useMediaQuery(
    theme.breakpoints.between(767, 1366),
  )
  const infoData = [
    { title: '$65M+', subtitle: 'Total Value Distributed' },
    { title: '1,712,421', subtitle: 'Active Arcade Players' },
    { title: '$7.5M+', subtitle: 'Amount Raised' },
    { title: '521,459', subtitle: 'Participants' },
    { title: '$1.85B+', subtitle: 'Connected Assets' },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: isDown600 ? '' : '64px',
        maxWidth: isBetweenScreen1200 ? '90%' : '1200px',
        width: '100%',
        paddingY: '88px',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          flexDirection: isDown600 ? 'column' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Typography
            sx={{
              color: '#000',
              fontFamily: 'Hellix',
              fontSize: isDown1023 ? '40px' : '48px',
              fontWeight: 600,
              lineHeight: isDown1023 ? '48px' : '52.8px',
              textAlign: 'left',
            }}
          >
            Get Started In a Few Minutes!
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontSize: isDown1023 ? '16px' : '22px',
              fontWeight: 400,
              lineHeight: isDown1023 ? '24px' : '35px',
              textAlign: 'left',
              color: '#000',
            }}
          >
            Create an account and gain access to the same pre-sales as crypto's
            biggest funds.
          </Typography>
        </Box>
        <Box
          sx={{
            width: isDown600 ? '100%' : '214px',
            borderRadius: '50px',
            minWidth: '212px',
            height: '53px',
            margin: isDown600 ? '40px auto' : '',
            '&:hover': {
              borderBottom: '5px solid #000'
            }
          }}
        >
          <OrangeButton
            text="Sign Up Now"
            br="24px"
            fs={15}
            fw={500}
            fm="Inter"
            lh="22.5px"
            bgColor="#FF9228"
            border="1px solid #000"
            IconStart={() => (
              <img
                src={arrowGetStartedIcon}
                style={{
                  marginRight: '10px',
                }}
                alt="arrowGetStartedIcon"
              />
            )}
            p="14px 32px"
            h="48px"
            w='100%'
            sx={{
              minWidth: '100%',
              // margin: isDown600 ? '40px auto' : '',
              textTransform: 'none',
              maxWidth: isDown600 ? '100%' : '212px',
            }}
            onClick={() => window.open(PATHS.home)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isDown600 ? 'column' : isBetweenScreen ? 'row' : 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '16px',
          flexWrap: 'wrap',
        }}
      >
        {infoData.map((item, index) => (
          <InfoCard key={index} title={item.title} subtitle={item.subtitle} />
        ))}
      </Box>
    </Box>
  )
}

export default GetStartedIn
