import React from 'react'
import { Dialog, IconButton, Box, Typography } from '@mui/material'
import { LightCross } from '../../assets'

interface ClaimSuccessModalProps {
  open: boolean
  handleClose: (e?: any) => void
  title: string
  subtext: string
  imageSrc: string
}

const ClaimSuccessModal: React.FC<ClaimSuccessModalProps> = ({
  open,
  handleClose,
  title,
  subtext,
  imageSrc,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: '12px',
          maxWidth: '468px',
          width: '100%',
          height: '340px',
          backgroundColor: '#0F1113',
          borderRadius: '12px',
          border: '0.5px solid rgba(255, 255, 255, 0.16)',
          backgroundImage:
            'radial-gradient(69.23% 49.77% at 40.74% -11.21%, rgba(200, 254, 11, 0.08) 0%, rgba(200, 254, 11, 0) 100%)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          edge="end"
          onClick={handleClose}
          aria-label="close"
          sx={{
            backgroundColor: '#1D1F21',
            height: '40px',
            width: '40px',
            marginRight: '0px',
            '&:hover': {
              backgroundColor: '#1d1f21cc',
            },
          }}
        >
          <img src={LightCross} alt="close" />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '20px',
          height: '100%',
          width: '70%',
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        <img
          src={imageSrc}
          alt="Claim Success"
          style={{
            width: '64px',
            height: '64px',
          }}
        />
        <Typography
          sx={{
            paddingTop: '32px',
            paddingBottom: '12px',
            fontSize: '32px',
            fontWeight: 600,
            color: '#FFF',
            fontFamily: 'Hellix',
          }}
        >
          {title}
        </Typography>

        <Typography
          sx={{
            color: '#7E8180',
            fontSize: '16px',
            fontWeight: 400,
            fontFamily: 'Inter',
          }}
        >
          {subtext}
        </Typography>
      </Box>
    </Dialog>
  )
}

export default ClaimSuccessModal
