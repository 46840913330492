import { Button, Typography } from "@mui/material"

export const RefundedButton = () => {

  return (
    <Button
      variant="greenContainer"
      // startIcon={<img src={FilledBlackArrow} alt="filled black arrow" />}
      sx={{
        maxWidth: '120px',
        borderRadius: '24px',
        height: '32px',
        border: '1px solid #404040',
        display: 'flex',
        background: '#404040',
        pointerEvents: 'none',
        opacity: '0.8',
      }}
    >
      <Typography
        fontWeight={600}
        fontSize={'12px'}
        lineHeight={'16px'}
        color="#fca5a5"
        fontFamily={'Inter'}
        textTransform="capitalize"
      >
        Refunded
      </Typography>
    </Button>
  )
}
