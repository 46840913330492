import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  arrowAccountCIcon,
  BgCreateAccount,
  watchAccountIcon,
  BgCreateAccountMobile,
} from '../../assets'
import { PATHS } from '../../constants/config'

type CreateAccountProps = {
  onClickScrollToMainVideo?: (e?: any) => void
}

const CreateAccount = (props: CreateAccountProps) => {
  const { onClickScrollToMainVideo } = props;

  const isDown1023 = useMediaQuery('(max-width:1023px)')
  const isDown766 = useMediaQuery('(max-width:766px)')
  const theme = useTheme()
  const isBetweenScreen = useMediaQuery(theme.breakpoints.between(767,992))
  
  return (
    <Box
      sx={{
        backgroundImage: `url(${isDown766 ? BgCreateAccountMobile : BgCreateAccount})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: isDown766 ? '357px' : '400px',
        // height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: isDown766 ? '20px' : '34px',
          marginTop: isDown766 ? '' : '-50px',
        }}
      >
        <Typography
          sx={{
            color: '#000',
            fontFamily: 'Hellix',
            fontSize: isDown1023 ? '40px' : '48px',
            fontWeight: 600,
            lineHeight: isDown1023 ? '44px' : '52.8px',
            textAlign: 'center',
            maxWidth: '554px',
            width: '100%',
          }}
        >
          Create an Account and Get <span style={{fontWeight: 700, fontSize: isDown1023 ? '40px' : '48px'}}>$25</span> in IDO Credit
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
            width: isDown766 ? '90%' : 'auto',
            flexDirection: isDown766 ? 'column' : 'row',
          }}
        >
          <Box
            sx={{
              background: '#000000',
              borderRadius: '50px',
              paddingY: '12px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              justifyContent: 'center',
              width: isDown766 ? '303px' : '202px',
              paddingRight: '24px',
              paddingLeft: '12px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.8)',
              }
            }}
          >
            <img src={arrowAccountCIcon} alt="arrowAccountCIcon" />
            <Typography
              sx={{
                color: '#63EA71',
                fontFamily: 'Inter',
                fontSize: '15px',
                fontWeight: 500,
                lineHeight: '22.5px',
                textAlign: 'center',
              }}
              onClick={() => window.open(PATHS.home)}
            >
              Create an Account
            </Typography>
          </Box>
          <Box
            sx={{
              background: '#000000',
              borderRadius: '50px',
              paddingY: '12px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              justifyContent: 'center',
              width: isDown766 ? '303px' : '202px',
              paddingRight: '24px',
              paddingLeft: '12px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.8)',
              }
            }}
            onClick={onClickScrollToMainVideo}
          >
            <img src={watchAccountIcon} alt="watchAccountIcon" />
            <Typography
              sx={{
                color: '#FF9228',
                fontFamily: 'Inter',
                fontSize: '15px',
                fontWeight: 500,
                lineHeight: '22.5px',
                textAlign: 'center',
              }}
            >
              Watch Tutorial
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default CreateAccount
