//@ts-nocheck
import { Box } from '@mui/material'
import { ReactComponent as TelegramNew } from '../../assets/telegram_new.svg'
import { ReactComponent as TwitterNew } from '../../assets/twitter_new.svg'
import SidebarCollapse from './SidebarCollapse'
import SidebarExpand from './SidebarExpand'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useDispatch } from 'react-redux'
import { setToggleSidebar } from '../../redux/slices/appSlice'
import { useAppSelector } from '../../hooks'

const Sidebar = () => {
  const dispatch = useDispatch()
  const appState = useAppSelector(state => state.app)
  const { isSidebarCollapse } = appState
  
  const toggleSidebar = () => {
    dispatch(setToggleSidebar(!isSidebarCollapse));
  }

  return (
    <header className={`header ${isSidebarCollapse ? 'is-collapse' : ''}`}>
      <Box
        sx={{
          position: 'absolute',
          top: '85px',
          right: '-12px',
          zIndex: '1',
          cursor: 'pointer',
          border: '1px solid #113817',
          height: '28px',
          width: '28px',
          textAlign: 'center',
          padding: '3px 0',
          borderRadius: '50px',
          backgroundColor: '#091612',
        }}
        onClick={toggleSidebar}
      >
        { isSidebarCollapse ? <ArrowForwardIcon fontSize='20px' /> : <ArrowBackIcon fontSize='20px' color='#3cf637'/> }
      </Box>
      <div className="header-wrapper">
        {
          isSidebarCollapse ? <SidebarCollapse /> : <SidebarExpand />
        }
      </div>
    </header>
  )
}

export default Sidebar
