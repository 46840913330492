import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from '@mui/material'
import { IResponseUserPortalItem, IResponseUserPortalMeta } from '../../../services/modules/participationsV2'
import OutlinedButton from '../../../components/Buttons/OutlinedButton'
import { useEffect, useRef } from 'react'
import { StyledHeadTableCell, FixedHeadTableCell } from './components/TableStyled';
import HistoryTableRow from './components/HistoryTableRow'
import LoadMoreButton from './components/LoadMoreButton';

const HistoryTable = ({
  mySales,
  handleShowMore,
  meta,
  isShowButton,
  isFetching,
}: {
  isFetching: boolean
  isShowButton: boolean
  meta: IResponseUserPortalMeta | undefined
  mySales: IResponseUserPortalItem[]
  handleShowMore: (isCollapes: boolean) => void
}) => {
  const theme = useTheme()
  const isDown800 = useMediaQuery(theme.breakpoints.down(800))
  const isDown1520 = useMediaQuery(theme.breakpoints.down(1520))
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop =
        tableContainerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [mySales])

  const handleShowMoreWithScroll = (isCollapes = false) => {
    handleShowMore(isCollapes)
  }

  return (
    <Box width={'100%'} mb={'60px'}>
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        paddingLeft={'0px'}
        paddingTop={'0px'}
        maxWidth={isDown1520 ? '996px' : '100%'}
      >
        <TableContainer
          component={Paper}
          ref={tableContainerRef}
          sx={{
            height: '100%',
            // maxHeight: '414px',
            border: '1px solid #1D1F21',
            boxShadow: 'none',
            backgroundImage: 'none',
            flexShrink: 1,
            width: '100%',
            borderRadius: '16px',
            '&::-webkit-scrollbar': {
              display: 'flex',
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#313335',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#0F1113',
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              minHeight: mySales.length <= 5 ? '100%' : '408px',
              height: '100%',
              flexShrink: 0,
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  textWrap: 'nowrap',
                }}
              >
                {isDown800 ? (
                  <>
                    <FixedHeadTableCell>Project</FixedHeadTableCell>
                    <StyledHeadTableCell>Project Name</StyledHeadTableCell>
                  </>
                ) : (
                  <StyledHeadTableCell>Project</StyledHeadTableCell>
                )}
                <StyledHeadTableCell>Chain</StyledHeadTableCell>
                <StyledHeadTableCell>Participants</StyledHeadTableCell>
                <StyledHeadTableCell>Ticket Size</StyledHeadTableCell>
                <StyledHeadTableCell>User Status</StyledHeadTableCell>
                <StyledHeadTableCell>IDO Status</StyledHeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflowY: 'hidden',
                overflowX: 'auto',
                backgroundColor: '#0F1113',
              }}
            >
              {mySales.map((item: IResponseUserPortalItem, index: number) => {
                return <HistoryTableRow key={index} sale={item} index={index} />
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {!isShowButton && Number(meta?.total_records) > 5 && (
        <Box mt={2} textAlign="center">
          {Number(meta?.total_records) > mySales.length && (
            <LoadMoreButton isLoading={isFetching} disabled={isFetching} onClick={() => handleShowMoreWithScroll()} />
          )}
          {mySales.length > 5 && (
            <OutlinedButton
              onClick={() => handleShowMoreWithScroll(true)}
              sx={{ height: '48px', width: '100%', marginTop: '12px' }}
            >
              Collapse
            </OutlinedButton>
          )}
        </Box>
      )}
    </Box>
  )
}

export default HistoryTable
