import { Box, Button, Typography, styled, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  getChainCoinNameByChainId,
  shortenAddress,
  truncateExact,
} from '../../utils/'
import { ethers } from 'ethers'
import { useAccount, useBalance, usePublicClient } from 'wagmi'
import DisconnectWalletModal from '../../components/DisconnectWalletModal'
import { ClipboardIcon, BNBIcon } from '../../assets'
import CopyToClipboard from 'react-copy-to-clipboard'
import getIconByNetwork from '../../pages/RevampedOverview/IconByNetwork'
import toast from 'react-hot-toast'
import { useAppSelector } from '../../hooks'

interface BalanceAndAddressBoxProps {
  sx?: any
}

const BalanceAndAddressBoxContainer = styled(Button)(() => ({
  height: 40,
  padding: '0 8px',
  backgroundColor: 'rgba(15, 17, 19, 1)',
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: 'max-content',
  textTransform: 'capitalize',

  '&:hover': {
    backgroundColor: 'rgba(15, 17, 19, 1)',
    opacity: 0.8,
  },
}))

const Divider = styled(Box)(() => ({
  width: '0.5px',
  height: 16,
  backgroundColor: 'rgba(49, 51, 53, 1)',
  margin: '0 10px',
}))

const BalanceAndAddressBox: React.FC<BalanceAndAddressBoxProps> = ({ sx }) => {
  const { address, chain } = useAccount()
  const provider = usePublicClient({ chainId: chain?.id })
  const user = useAppSelector(state => state.user)
  const IconComponent = chain ? getIconByNetwork(chain?.name) : null
  const isDown1050 = useMediaQuery('(max-width:1050px)')
  const [userBalance, setUserBalance] = useState('')

  const { data: ethBalanceData } = useBalance({
    address,
  })

  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false)

  const [showDisconnectWalletModal, setShowDisconnectWalletModal] =
    useState(false)

  useEffect(() => {
    ;(async () => {
      const balance = address && provider ? 0 : 0

      setUserBalance(ethers.utils.formatEther(balance.toString()).toString())
    })()
  }, [provider, address])

  const handleClick = () => {
    if (address || user.email) {
      setShowDisconnectWalletModal(true)
    } else setShowConnectWalletModal(true)
  }

  return (
    <BalanceAndAddressBoxContainer onClick={handleClick} sx={{ ...sx }}>
      {IconComponent ? (
        <IconComponent
          height={'20px'}
          width={'20px'}
          style={{ marginRight: '4px' }}
        />
      ) : (
        <img
          src={BNBIcon}
          style={{ marginRight: '4px', height: '20px' }}
          alt="Chain Icon"
        />
      )}
      <Typography
        color="rgba(255, 255, 255, 1)"
        fontWeight={500}
        fontSize={isDown1050 ? 12 : 14}
        fontFamily={'Inter'}
      >
        {truncateExact(parseFloat(ethBalanceData?.formatted || '0') || 0, 4)}{' '}
        {getChainCoinNameByChainId(chain?.id ? chain?.id : 56)}
      </Typography>

      {address && (
        <>
          <Divider />
          <Typography
            fontWeight={400}
            fontSize={isDown1050 ? 12 : 14}
            fontFamily={'Inter'}
            color={'rgba(118, 118, 118, 1)'}
            sx={{
              '&:hover': {
                color: '#63ea71',
              },
            }}
          >
            {shortenAddress(address!, 5, 4)}
          </Typography>
        </>
      )}

      {address && (
        <CopyToClipboard text={address!} onCopy={() => toast.success('Copied')}>
          <img
            src={ClipboardIcon}
            style={{ marginLeft: '4px', height: '16px' }}
            onClick={e => {
              e.stopPropagation()
            }}
            alt="Clipboard Icon"
          />
        </CopyToClipboard>
      )}

      {(user.email || user.emailRegisterUserAccount) && (
        <>
          <Divider />
          <Typography
            fontWeight={400}
            fontSize={isDown1050 ? 12 : 16}
            fontFamily={'Inter'}
            color={'rgba(118, 118, 118, 1)'}
            sx={{
              '&:hover': {
                color: '#63ea71',
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBlockStart: 0,
              textTransform: 'lowercase',
              marginBlockEnd: 0,
            }}
          >
            {shortenAddress(user.email || user.emailRegisterUserAccount, 5, 4)}
          </Typography>
        </>
      )}

      <DisconnectWalletModal
        open={showDisconnectWalletModal}
        setOpen={setShowDisconnectWalletModal}
      />
    </BalanceAndAddressBoxContainer>
  )
}

export default BalanceAndAddressBox
