import _ from 'lodash'
import VideoPlayer from '../../components/homepage/video-player'
import AnimatedStatistics from '../../components/homepage/animated-statistics/AnimatedStatistics'
import { useFetchAllLaunchesQuery, useFetchLaunchpadPublicStatsQuery } from '../../services/modules/project'
import UpcomingSales from '../../components/homepage/upcoming-sale'
import { CompletedSale } from '../../components/homepage/completed-sales'
import { Participation } from '../../components/homepage/participation-section'
import FeaturedLaunch from '../RevampedLaunchpad/FeaturedLaunch'
import SkeletonForFeaturedLaunch from '../RevampedLaunchpad/SkeletonForFeaturedLaunch'
import SkeletonForUpcomingLaunches from '../RevampedLaunchpad/SkeletonForUpcomingLaunches'
import { Welcome } from '../../components/Welcome/Welcome'
import FloatingRWAButton from "../../components/FloatingRWAButton";

const Home = () => {
  const {
    data: launchpadStats,
    isLoading: launchpadStatsLoading,
    isError: launchpadStatsError,
  } = useFetchLaunchpadPublicStatsQuery();

  const {
    data: allLaunchesData,
    isLoading: allLaunchesLoading,
    isError: allLaunchesError,
  } = useFetchAllLaunchesQuery();

  const featuredLaunch =
    _.isArray(allLaunchesData) &&
    allLaunchesData.find(launch => launch.type == 'featured')

  return (
    <div className="launchpad-main">
      {/* Featured Video
      <VideoPlayer /> */}
      <Welcome />
      {/*/!* Invest/Connect Split *!/*/}
      {/*<InvestConnectSplit />*/}

      <AnimatedStatistics launchpadStats={launchpadStats} />

      {!allLaunchesData && (
        <>
          <SkeletonForFeaturedLaunch />
          <SkeletonForUpcomingLaunches />
        </>
      )}

      {allLaunchesData && featuredLaunch && (
        <>
          <FeaturedLaunch featuredLaunchData={featuredLaunch} isShowTitle={false} />
          <UpcomingSales />
        </>
      )}


      {/* Completed Sales */}
      <CompletedSale />
      <Participation />

      <FloatingRWAButton />
    </div>
  )
}

export default Home
