import { Box, Typography, useMediaQuery } from '@mui/material'
import BoxShowDataProfile from './BoxShowDataProfile'
import KYCnSocialBox from './KYCnSocialBox'
import {
  PortfolioGreenKYC,
  PortfolioOrangeKYC,
  PortfolioRedKYC,
  PortfolioSocials,
  SearchIcon,
} from '../../assets'
import { useAppSelector } from '../../hooks'
import { useAccount } from 'wagmi'
import {
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import KYCButton from '../../components/KYCBox/kycButton'
import SubscribeSubscription from './SubscribeSubscription'
import MyReferralsTable from '../../pages/UserPortal/user-transactions/MyReferralsTable'
import { useFetchUserReferralsWithSearchQuery } from '../../services/modules/reffer'
import NoDataTable from '../../pages/UserPortal/user-transactions/NoDataTable'
import NoDataBox from '../../pages/UserPortal/user-transactions/NoDataBox'
import SkeletonForMyReferralsTable from '../../pages/UserPortal/user-transactions/SkeletonForMyReferralsTable'

enum EStatusKyc {
  NOT_LOGGED = 'not-logged',
  REJECTED = 'rejected',
  UNVERIFIED = 'unverified',
  IN_REVIEW = 'inreview',
  APPROVED = 'approved',
}
const referralsHeaders = [
  'Projects',
  'People used my link',
  'Multiplier',
  'Status',
  'My Referral links',
]

const getStatusTextDisplayKyc = (status: string) => {
  switch (status) {
    case EStatusKyc.APPROVED:
      return {
        kycStatus: EStatusKyc.APPROVED,
        title: 'Your identity has been confirmed.',
        buttonText: 'Approved',
        icon: PortfolioOrangeKYC,
        color: 'green',
      }
    case EStatusKyc.IN_REVIEW:
      return {
        kycStatus: EStatusKyc.IN_REVIEW,
        title: 'Your identity is being reviewed.',
        buttonText: 'In Review',
        icon: PortfolioOrangeKYC,
        color: 'orange',
      }

    case EStatusKyc.REJECTED:
      return {
        kycStatus: EStatusKyc.REJECTED,
        title: 'Your identity has been rejected.',
        buttonText: 'Complete KYC',
        icon: PortfolioRedKYC,
        color: 'red',
      }
    case EStatusKyc.NOT_LOGGED:
      return {
        kycStatus: EStatusKyc.NOT_LOGGED,
        title: 'Complete a simple KYC process.',
        buttonText: 'Complete KYC',
        icon: PortfolioGreenKYC,
        color: 'green',
        helpText: 'Connect wallet to proceed',
      }
    default:
      return {
        kycStatus: EStatusKyc.UNVERIFIED,
        title: 'Complete a simple KYC process.',
        buttonText: 'Complete KYC',
        icon: PortfolioGreenKYC,
        color: 'green',
      }
  }
}

const AccountInfo = () => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown770 = useMediaQuery('(max-width:770px)')
  const user = useAppSelector(state => state.user)
  const { accessTokenGoogle, mainAppToken } = user
  const [searchName, setSearchName] = useState('')
  const [debouncedSearchName, setDebouncedSearchName] = useState('')
  const [isShowButton, setIsShowButton] = useState(false)
  const { address } = useAccount()
  const [kYCStatus, setKYCStatus] = useState(
    user.kycStatus || EStatusKyc.UNVERIFIED,
  )
  const [isKYCVerified, setIsKYCVerified] = useState(false)
  const referralsTableRef = useRef<HTMLDivElement>(null)

  const handleReferralsClick = () => {
    if (referralsTableRef.current) {
      referralsTableRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const {
    data: userReferralData,
    isLoading: userReferralDataLoading,
    refetch: fetchUserReferrals,
  } = useFetchUserReferralsWithSearchQuery(
    { name: searchName || undefined },
    {
      skip: !address && !mainAppToken && !accessTokenGoogle, 
      refetchOnFocus: false,
    },
  )

  const handleSearchChange = (e: {
    target: { value: SetStateAction<string> }
  }) => {
    setSearchName(e.target.value)
  }

  const handleSearchSubmit = (
    e: KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()
    fetchUserReferrals()
    setIsShowButton(true)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchSubmit(e)
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchName(searchName)
    }, 700)
    return () => {
      clearTimeout(handler)
    }
  }, [searchName])

  useEffect(() => {
    if (debouncedSearchName) {
      fetchUserReferrals()
    } else {
      setIsShowButton(false)
    }
  }, [debouncedSearchName, fetchUserReferrals])

  useEffect(() => {
    if ((mainAppToken && address) || accessTokenGoogle) {
      fetchUserReferrals()
    }
  }, [mainAppToken, address, accessTokenGoogle, fetchUserReferrals])

  useEffect(() => {
    if (user.kycStatus && address) {
      setKYCStatus(user.kycStatus as EStatusKyc)
    }
    if (!address) {
      setKYCStatus(EStatusKyc.NOT_LOGGED)
    }
  }, [user.kycStatus, address])

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        width={'100%'}
        alignSelf={'center'}
        display={'flex'}
        flexDirection={'column'}
        paddingTop={isDown1000 ? '20px' : '80px'}
      >
        <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: isDown770 ? 'column' : 'row',
              gap: '8px',
            }}
          >
            <BoxShowDataProfile
              type="wallet"
              heading={address! ? 'User Wallet Address' : ''}
              isShowButton={!address!}
              subheading={address! ? address! : ''}
            />

            <BoxShowDataProfile
              type="email"
              heading="User Email"
              subheading={
                !user.email && !user.emailRegisterUserAccount
                  ? 'No data available'
                  : user.email || user.emailRegisterUserAccount
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: isDown770 ? 'column' : 'row',
              gap: '8px',
            }}
          >
            <KYCnSocialBox
              image={getStatusTextDisplayKyc(kYCStatus).icon}
              heading="KYC Verification"
              subheading={getStatusTextDisplayKyc(kYCStatus).title}
              buttonText={getStatusTextDisplayKyc(kYCStatus).buttonText}
              buttonAction={() => {
                setIsKYCVerified(!isKYCVerified)
              }}
              status={getStatusTextDisplayKyc(kYCStatus).kycStatus}
              color={getStatusTextDisplayKyc(kYCStatus).color}
              helpText={getStatusTextDisplayKyc(kYCStatus).helpText}
              KYCButton={
                ['approved', 'inreview', 'not-logged'].includes(
                  getStatusTextDisplayKyc(kYCStatus).kycStatus,
                )
                  ? null
                  : KYCButton
              }
            />
            <KYCnSocialBox
              image={PortfolioSocials}
              heading="Social Connections"
              subheading="Increase your chances of winning."
              buttonText="Referrals"
              buttonAction={handleReferralsClick}
            />
          </Box>
        </Box>
      </Box>
      <Box
        ref={referralsTableRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '16px',
          width: '100%',
          marginTop: '32px',
          scrollMarginTop: '30px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: '#fff',
              fontFamily: 'Hellix',
              fontSize: isDown770 ? '16px' : '24px',
              fontWeight: '600',
              lineHeight: isDown770 ? '24px' : '32px',
              textAlign: 'left',
            }}
          >
            My Referrals
          </Typography>
          <div
            className="user-transactions-wrapper"
            style={{
              paddingBottom: '0',
            }}
          >
            <div
              className="user-transactions--header"
              style={{
                borderTop: 'none',
                borderBottom: 'none',
                padding: 0,
                marginBottom: 0,
              }}
            >
              <div className="search-box">
                <input
                  type="text"
                  placeholder="Search"
                  style={{
                    minWidth: isDown770 ? '200px' : '250px',
                  }}
                  value={searchName}
                  onChange={handleSearchChange}
                  onKeyPress={handleKeyPress}
                />
                <button onClick={handleSearchSubmit}>
                  <img src={SearchIcon} alt="SearchIcon" />
                </button>
              </div>
            </div>
          </div>
        </Box>
        {userReferralDataLoading ? (
          <SkeletonForMyReferralsTable />
        ) : userReferralData?.length ? (
          <MyReferralsTable
            sx={{
              paddingTop: 0,
              marginBottom: 0,
            }}
            isShowButton={isShowButton}
            myReferrals={userReferralData}
          />
        ) : (
          <>
            {!isDown1000 ? (
              <NoDataTable headers={referralsHeaders} />
            ) : (
              <NoDataBox />
            )}
          </>
        )}
      </Box>
      <Box sx={{ width: '100%', marginTop: '32px' }}>
        <SubscribeSubscription />
      </Box>
    </Box>
  )
}
export default AccountInfo
