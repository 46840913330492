import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import { useNavigate } from 'react-router-dom';

export default function FloatingRWAButton() {
  const navigate = useNavigate();

  return (
    <Box sx={{ position: 'fixed', bottom: '32px', right: '32px', zIndex: '999' }}>
      <Fab
        className='rwa-ripple-animate'
        variant="extended"
        sx={{
          color: '#fff !important',
          backgroundColor: '#4d94fb !important',
          textTransform: 'capitalize',
        }}
        onClick={() => navigate('/rwasui-airdrop')}
      >
        <NavigationIcon sx={{ mr: 1 }} />
        RWASUI Airdrop
      </Fab>
    </Box>
  );
}
