export const AccountIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.00004 7.79484C7.42505 7.79484 6.93686 7.59416 6.53547 7.19278C6.13408 6.79139 5.93339 6.3032 5.93339 5.72821C5.93339 5.15321 6.13408 4.66502 6.53547 4.26364C6.93686 3.86225 7.42505 3.66156 8.00004 3.66156C8.57503 3.66156 9.06321 3.86225 9.4646 4.26364C9.86599 4.66502 10.0667 5.15321 10.0667 5.72821C10.0667 6.3032 9.86599 6.79139 9.4646 7.19278C9.06321 7.59416 8.57503 7.79484 8.00004 7.79484ZM3.53339 12.3384V11.1231C3.53339 10.9188 3.59429 10.722 3.71609 10.5327C3.83787 10.3434 4.00817 10.1795 4.22697 10.041C4.80817 9.70684 5.42014 9.45064 6.06287 9.27244C6.7056 9.09424 7.35133 9.00514 8.00004 9.00514C8.64875 9.00514 9.29447 9.09424 9.9372 9.27244C10.5799 9.45064 11.1919 9.70684 11.7731 10.041C11.9919 10.1684 12.1622 10.3295 12.284 10.5244C12.4058 10.7192 12.4667 10.9188 12.4667 11.1231V12.3384H3.53339Z"
        fill="#F1EDF2"
        fillOpacity="0.6"
      />
    </svg>
  );
};
