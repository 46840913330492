import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { IncomingSvg, IncomingSvgMobile } from '../../assets'
import GreenButton from '../../components/GreenButton'
import { PATHS } from '../../constants/config'

const LaunchPools = () => {
  const navigate = useNavigate()

  const isDown767 = useMediaQuery('(max-width:767px)')
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: `calc(100vh - 266px)`,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
          padding: "0 20px"
        }}
      >
        <Box style={{}}>
          <Typography
            fontSize={isDown767 ? 48 : 64}
            fontWeight={600}
            lineHeight={'100%'}
            color="#63ea71"
            letterSpacing={'-0.02em'}
            textAlign={'center'}
            fontFamily={'Hellix'}
            sx={{
              alignSelf: 'center',
              whiteSpace: 'nowrap',
              marginBottom: '32px'
            }}
          >
            Launch Pools
          </Typography>
          <Typography
            fontSize={isDown767 ? 14 : 16}
            fontWeight={600}
            // lineHeight={'1.6'}
            color="#c8cbca"
            // letterSpacing={'-0.02em'}
            textAlign={'left'}
            fontFamily={'Hellix'}
            maxWidth="756px"
            sx={{
              alignSelf: 'center',
              whiteSpace: 'wrap',
            }}
          >
            Launchpools allow Ape users indirect exposure to launches via the staking of different cryptocurrencies such as stable tokens and large caps like ETH and BTC.<br /><br />
            Your staked crypto earns a share of a project's token based on the size of your stake compared to other participants and the duration of your stay in the pool.<br /><br />
            There is no cap on the amount you can stake.<br /><br />
            Launchpools are coming to Ape in Q1, and further details will be announced via our social media channels.<br />
          </Typography>
        </Box>
        <GreenButton
          text={'Go back home'}
          br="24px"
          fs={15}
          fw={400}
          fm="Inter"
          lh="22.5px"
          mt={4}
          w={"fit-content"}
          p="14px 32px"
          h="48px"
          // w={isDown1152 ? '100%' : 'fit-content'}
          onClick={() => navigate(PATHS.home)}
        />
      </Box>
    </Box>
  )
}

export default LaunchPools
