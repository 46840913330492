import { Box, Typography, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
// import NewUpcomingLaunchCard from './NewUpcomingLaunchCard'
import OutlinedButton from '../../Buttons/OutlinedButton'
import FillerCard from '../../FillerCard'
import { getReplacedCdnEndpoint } from '../../../utils'
import { DEFAULTS } from '../../../constant'
import { ILaunch } from '../../../services/modules/project'
import NewUpcomingLaunchCard from '../../../components/NewUpcomingLaunchCard'

interface UpcomingLaunchesProps {
  upcomingLaunchesData: ILaunch[]
}

const UpcomingLaunches = ({ upcomingLaunchesData }: UpcomingLaunchesProps) => {
  const isDown767 = useMediaQuery('(max-width:767px)')

  const launchesPerPage = 3

  const [visibleLaunches, setVisibleLaunches] = useState(launchesPerPage)

  const isiPhone = /iPhone/i.test(navigator.userAgent)

  useEffect(() => {
    if (launchesPerPage > upcomingLaunchesData.length) {
      setVisibleLaunches(upcomingLaunchesData.length)
    }
  }, [upcomingLaunchesData.length])

  const totalFillers =
    launchesPerPage - (upcomingLaunchesData.length % launchesPerPage)

  const handleShowMore = () => {
    if (visibleLaunches < upcomingLaunchesData.length) {
      setVisibleLaunches(prevVisibleLaunches =>
        prevVisibleLaunches + launchesPerPage > upcomingLaunchesData.length
          ? upcomingLaunchesData.length
          : prevVisibleLaunches + launchesPerPage,
      )
    } else {
      setVisibleLaunches(launchesPerPage)
    }
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    largeMobile: {
      breakpoint: { max: 766, min: 640 },
      items: 1.5,
      partialVisibilityGutter: 40,
    },
    mediumMobile: {
      breakpoint: { max: 639, min: 500 },
      items: 1.2,
      partialVisibilityGutter: 30,
    },
    smallmobile: {
      breakpoint: { max: 499, min: 450 },
      items: 1.05,
      partialVisibilityGutter: 10,
    },
    extraSmallMobile: {
      breakpoint: { max: 449, min: 300 },
      items: 1,
      partialVisibilityGutter: 10,
    },
  }

  return (
    <Box width="100%" mt={isDown767 ? 0 : 3} mb={'20px'}>
      {isDown767 && (
        <Box width={`calc(${window.innerWidth}px - 40px)`}>
          <Carousel
            responsive={responsive}
            containerClass="carousel-container"
            itemClass="custom-carousel-item"
            ssr={true}
            arrows={isiPhone ? true : false}
            showDots={false}
            rewindWithAnimation={true}
            partialVisible={true}
            rewind={true}
            infinite={true}
            swipeable={isiPhone ? false : true}
          >
            {upcomingLaunchesData
              .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
              .slice(0, upcomingLaunchesData.length)
              .map((launch: ILaunch) => (
                <NewUpcomingLaunchCard
                  key={launch._id}
                  image={getReplacedCdnEndpoint(
                    launch.bannerImageUrl || DEFAULTS.PROJECT_BANNER_URL,
                  )}
                  heading={launch.projectTitle}
                  titleImage={getReplacedCdnEndpoint(launch.logoUrl)}
                  chips={launch.projectTags}
                  totalRaise={launch.totalRaise}
                  startsIn={launch.startsIn}
                  registrationStarted={launch.registrationStarted}
                  registrationEndDate={launch.registrationEndDate}
                  routeName={launch.routeName}
                  saleType={launch.saleType}
                  marketMaker={launch.marketMaker}
                  active={launch.active}
                  videoUrl={getReplacedCdnEndpoint(launch.videoUrl)}
                  type={launch.type}
                  started={true}
                  backers={launch.backers}
                  hostedBy={launch.hostedBy}
                />
              ))}
            {visibleLaunches % launchesPerPage !== 0 &&
              Array.from({ length: totalFillers }).map((_, index) => (
                <>
                  <FillerCard height="100%" />
                </>
              ))}
          </Carousel>
        </Box>
      )}
      {!isDown767 && (
        <>
          <Box
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            gap={2}
            mb={2}
          >
            {upcomingLaunchesData
              .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
              .slice(0, visibleLaunches)
              .map((launch: any) => (
                <NewUpcomingLaunchCard
                  key={launch._id}
                  image={getReplacedCdnEndpoint(
                    launch.bannerImageUrl || DEFAULTS.PROJECT_BANNER_URL,
                  )}
                  heading={launch.projectTitle}
                  titleImage={getReplacedCdnEndpoint(launch.logoUrl)}
                  chips={launch.projectTags}
                  totalRaise={launch.totalRaise}
                  startsIn={launch.startsIn}
                  registrationStarted={launch.registrationStarted}
                  registrationEndDate={launch.registrationEndDate}
                  routeName={launch.routeName}
                  saleType={launch.saleType}
                  marketMaker={launch.marketMaker}
                  active={launch.active}
                  videoUrl={getReplacedCdnEndpoint(launch.videoUrl)}
                  type={launch.type}
                  started={true}
                  backers={launch.backers}
                  hostedBy={launch.hostedBy}
                />
              ))}
            {visibleLaunches % launchesPerPage !== 0 && (
              <>
                {Array.from({ length: totalFillers }).map((_, index) => (
                  <>
                    <FillerCard />
                  </>
                ))}
              </>
            )}
          </Box>
          {upcomingLaunchesData.length > 3 && (
            <OutlinedButton
              onClick={handleShowMore}
              sx={{
                height: '48px',
                lineHeight: '16px',
                maxWidth: '1200px',
                margin: '0 auto',
                display: 'block',
              }}
            >
              {visibleLaunches < upcomingLaunchesData.length
                ? 'Show More'
                : 'Show Less'}
            </OutlinedButton>
          )}
        </>
      )}
    </Box>
  )
}

export default UpcomingLaunches
