import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Circle } from 'rc-progress'
import { Box, useMediaQuery } from '@mui/system'
import { Alert, Snackbar, TableRow, Typography } from '@mui/material'
import {
  StyledBodyTableCell,
  FixedBodyTableCell,
} from '../components/TableStyled'
import {
  consoleLog,
  getChainName,
  getExceptionMessage,
  getReplacedCdnEndpoint,
  replaceUnderscoresWithSpaces,
} from '../../../../utils'
import { getStatusStyleFilter } from '../FilterStatusMySales'
import { formatUnixToDate } from '../../../../constants/formatDate'
import { IResponseUserPortalItem } from '../../../../services/modules/participationsV2'
import { getNetworkIconByChain } from '../IconByNetworkUserPortal'
import ClaimButtonDisabled from '../components/ClaimButtonDisabled'
import { RefundedButton } from '../components/RefundedButton'
import { ClaimStatus } from '../components/LabelConstant'
import ClaimButtonContainer from '../components/ClaimButtonContainer'
import {
  computeTotalTokens,
  computeTicketSize,
  isRequestRefund,
  getClaimStatus,
  computeDistribution,
} from '../components/helper'
import { ViewClaimButton } from './ViewClaimButton'
import { CommonButton } from './CommonButton'
import { SolanaClaimButton } from './SolanaClaimButton'
import { useState, memo, useEffect, useMemo } from 'react'
import { useAppSelector } from '../../../../hooks'
import {
  CHAIN_ID,
  RPC_PROVIDER,
  CONTRACT_ADDRESS,
  ETH_TOPIC_HASH,
  MAIN_CHAIN_ID,
} from '../../../../constant'
import { ethers } from 'ethers'
import { sleep, getEpoch } from '../../../../utils'
import _ from 'lodash'
import { useAccount } from 'wagmi'
import toaster from 'react-hot-toast'
import { switchChain } from '@wagmi/core'
import {
  wagmiConfig,
  useEthersSigner,
} from '../../../../components/Web3Provider'
import { getPr0x1edVestingAddress } from '../../../RevampedOverview/pr0x1edVesting'
import { Skeleton } from '@mui/material'
import refundUserByProject from '../../../../contracts/refund-user.json'

type ClaimTableRowProps = {
  sale: IResponseUserPortalItem
  onRefundSuccess: (isVestingClaim?: boolean | undefined) => void
  onClaimSuccess: (isSuccess: boolean) => void
  onRequestRefund: (isSuccess?: boolean) => void
}

const LEGACY_VESTING_ABI = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_account',
        type: 'address',
      },
    ],
    name: 'getClaimableAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'claimedAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'returnTokenDecimal',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'claim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
]

const UV_VESTING_ABI = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'projectId_',
        type: 'uint256',
      },
      {
        internalType: 'bytes32[]',
        name: 'proof_',
        type: 'bytes32[]',
      },
    ],
    name: 'claimToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'projectId_',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
    ],
    name: 'getRefundProof',
    outputs: [
      {
        internalType: 'bytes32[]',
        name: '',
        type: 'bytes32[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'projectId_',
        type: 'uint256',
      },
      {
        internalType: 'bytes32[]',
        name: 'proof',
        type: 'bytes32[]',
      },
    ],
    name: 'requestRefund',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'projectId',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'getAccountStatsAt',
    outputs: [
      {
        internalType: 'uint256',
        name: 'refundRequestedAt',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'refundedAt',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'claimedAmount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'claimableAmount',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'projectId',
        type: 'uint256',
      },
    ],
    name: 'getProject',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'name',
            type: 'string',
          },
          {
            internalType: 'bool',
            name: 'active',
            type: 'bool',
          },
          {
            internalType: 'uint256',
            name: 'investors',
            type: 'uint256',
          },
          {
            internalType: 'bytes32',
            name: 'merkleProofRoot',
            type: 'bytes32',
          },
          {
            internalType: 'uint256',
            name: 'tgeAt',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tgeAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'cliffDuration',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'vestingDuration',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'vestingAmount',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'tokenAddr',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'tokenDeposited',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenRemains',
            type: 'uint256',
          },
          {
            internalType: 'uint8',
            name: 'tokenDecimals',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'refundInvestors',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'refundDeadlineAt',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'refundTokenAddr',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'refundAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint8',
            name: 'refundTokenDecimals',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'refundTokenDeposited',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'refundTokenRemains',
            type: 'uint256',
          },
        ],
        internalType: 'struct VestingProject',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'projectId',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 's_refundRequestedAt',
    outputs: [
      {
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]

const UV_REFUND_ABI = [
  ...UV_VESTING_ABI,
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'projectId',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 's_refundedAt',
    outputs: [
      {
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]

const APE_INVESTMENT_ABI: any[] = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'projectId_',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'investor_',
        type: 'address',
      },
    ],
    name: 'getInvestmentProof',
    outputs: [
      {
        internalType: 'bytes32[]',
        name: 'proof',
        type: 'bytes32[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]

// #warning this function is duplicated elsewhere
const getPastClaimedAmount = async (
  accountAddress: string,
  chainId: number,
  pastClaimContracts: string[] | any,
) => {
  if (!_.isArray(pastClaimContracts) || !accountAddress || !chainId) return 0n
  const pastClaimedAmounts = await Promise.all(
    pastClaimContracts.map((claimContractAddress: string) => {
      const claimContract = new ethers.Contract(
        claimContractAddress,
        LEGACY_VESTING_ABI,
        RPC_PROVIDER[chainId],
      )
      return claimContract.claimedAmount(accountAddress)
    }),
  )
  const res = pastClaimedAmounts.reduce(
    (x, y) => BigInt(x || 0) + BigInt(y || 0),
    0n,
  )
  return res
}

// #warning this function is duplicated elsewhere
const getPastAirdropedAmount = async (
  accountAddress: string,
  chainId: number,
  pastAirdropTxs: string[] | any,
) => {
  if (!_.isArray(pastAirdropTxs) || !accountAddress || !chainId) return 0n
  let pastAirdropedAmount: bigint = 0n
  const defaultAbiCoder = ethers.utils.defaultAbiCoder
  try {
    await Promise.all(
      pastAirdropTxs.map(async (txHash: string) => {
        const provider = RPC_PROVIDER[chainId]
        const receipt = await provider.getTransactionReceipt(txHash)
        const logs = _.get(receipt, 'logs')
        if (!_.isArray(logs)) return
        logs.forEach(log => {
          const { topics, data } = log
          const logTopic = _.get(topics, 0)
          if (logTopic !== ETH_TOPIC_HASH.ERC20_TRANSFER) return
          const [toAddress] = defaultAbiCoder.decode(
            ['address'],
            _.get(topics, 2),
          )
          const [amount] = defaultAbiCoder.decode(['uint256'], data)
          if (toAddress !== accountAddress) return
          pastAirdropedAmount += BigInt(amount)
        })
      }),
    )
  } catch (e) {
    console.error(e)
  }
  return pastAirdropedAmount
}

interface ClaimStats {
  // legacy means each IDO vesing is deployed on a separate contact
  vestingType?: 'legacy' | 'unified' | 'unknown'
  claimableAmount: bigint
  claimedAmount: bigint
  refundRequestedAt: number
  refundedAt: number
  refundDeadlineAt?: number
  tokenDecimals?: number
  isRefundable?: boolean
  // deposited means claimable
  isRefundTokenDeposited?: boolean
}

const PONDER_IDO_OID = '65f1c30cf950d4fa807a9f2c'
const MICROGPT_IDO_OID = '6735e623d93397346746b489'
const BRAVO_READY_IDO_OID = '673f2e402eda3206ccc98a42'
const RWA_INC_IDO_OID = '673de40492f33ef5f26c138e'
const SYNTHR_IDO_OID = '67828018190f6da5e10ff15d'

const MIN_GAS_LIMIT: Record<string, BigInt> = {
  UV_CLAIM: 250_000n,
  UV_REFUND_REQUEST: 250_000n,
}

const ClaimTableRowC = (props: ClaimTableRowProps) => {
  const { sale, onClaimSuccess, onRefundSuccess, onRequestRefund } = props
  const {
    idoTgeAmount,
    idoVestingAmount,
    idoCliffDuration,
    idoTgeDate,
    idoVestingDuration,
    allocation,
    idoClaimContract,
    projectContractId: projectInvestmentId,
    projectVestingId,
    tokenSymbol,
    idoId,
    idoStatus,
    idoPastAirdropTxs,
    idoPastClaimContracts,
    projectClaimId: projectRefundId,
  } = sale
  const { address, chainId } = useAccount()
  const signer = useEthersSigner({ chainId })
  const [nonce, setNonce] = useState<number>(0)
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [stats, setStats] = useState<ClaimStats | undefined>(undefined)
  const isLegacyProject = sale.projectIsLegacyProject
  const isDown800 = useMediaQuery('(max-width:800px)')
  const navigate = useNavigate()
  const netWork: string | undefined =
    sale?.idoClaimNetwork || sale?.tokenNetwork
  // @ts-ignore
  const claimChainId = netWork ? CHAIN_ID[netWork] : undefined
  const totalTokens = computeTotalTokens(sale)
  const refundChainId: number = MAIN_CHAIN_ID
  const epoch = getEpoch()

  const [openToast, setOpenToast] = useState(false)
  const [message, setMessage] = useState('')

  const currentUser = useAppSelector(state => state.user)

  const walletAddress: string = currentUser?.walletAddress

  const isSolanaIdo = netWork === 'Solana'

  const isUnifiedVesting =
    !!claimChainId &&
    !!idoClaimContract &&
    (idoClaimContract === CONTRACT_ADDRESS.APE_VESTING_UNIFIED[claimChainId] ||
      idoId === PONDER_IDO_OID ||
      idoId === MICROGPT_IDO_OID)

  const apeRefundUnifiedContract = useMemo(() => {
    return new ethers.Contract(
      CONTRACT_ADDRESS.APE_REFUND_UNIFIED[MAIN_CHAIN_ID],
      UV_REFUND_ABI,
      RPC_PROVIDER[MAIN_CHAIN_ID],
    )
  }, [])

  const apeInvestmentUnifiedContract = useMemo(() => {
    let address: string = CONTRACT_ADDRESS.APE_INVESTMENT_UNIFIED[MAIN_CHAIN_ID]
    // those are special case where we still using legacy investment contract
    // legacy investment contract dont have ability to generate investment proof so need a polyfill contract
    if (
      [
        PONDER_IDO_OID,
        MICROGPT_IDO_OID,
        RWA_INC_IDO_OID,
        BRAVO_READY_IDO_OID,
      ].includes(idoId)
    ) {
      address = '0x597b53a550e788cd7fbfb6f281dade989c107293'
    }
    // context: an abuser come and we need to deploy an other contract and import user addrs
    if(idoId === SYNTHR_IDO_OID) {
      address = '0xc35f8dBDD1437D15eDB51845231891af37EEc2f6';
    }
    return new ethers.Contract(
      address,
      APE_INVESTMENT_ABI,
      RPC_PROVIDER[MAIN_CHAIN_ID],
    )
  }, [idoId])

  useEffect(() => {
    if (!walletAddress || !claimChainId) {
      console.warn()
      return setStats(undefined)
    }
    if (!idoClaimContract) {
      return setStats(undefined)
    }
    let intervalId: any
    const fetchClaimStats = async () => {
      try {
        const rpcProvider = RPC_PROVIDER[claimChainId]
        if (!rpcProvider) return
        // UV VESTING
        if (isUnifiedVesting) {
          if (!(projectVestingId >= 0)) {
            return setStats(undefined)
          }
          const vestingContract = new ethers.Contract(
            idoClaimContract,
            UV_VESTING_ABI,
            rpcProvider,
          )
          const [claimStats, projectData, refundedAtBN, refundProjectData] =
            await Promise.all([
              vestingContract.getAccountStatsAt(
                projectVestingId,
                walletAddress,
              ),
              vestingContract.getProject(projectVestingId),
              projectRefundId >= 0
                ? apeRefundUnifiedContract.s_refundedAt(
                    projectRefundId,
                    walletAddress,
                  )
                : ethers.BigNumber.from(0),
              projectRefundId >= 0
                ? apeRefundUnifiedContract.getProject(projectRefundId)
                : {},
            ])
          const { tokenDecimals, refundDeadlineAt: refundDeadlineAtBN } =
            projectData
          const {
            claimableAmount,
            claimedAmount,
            refundRequestedAt,
            refundedAt,
          } = claimStats
          const claimImpossible =
            refundRequestedAt.toNumber() > 0 || refundedAt.toNumber() > 0
          const stats = {
            claimableAmount: claimImpossible ? 0n : claimableAmount.toBigInt(),
            claimedAmount: claimedAmount.toBigInt(),
            refundRequestedAt: refundRequestedAt.toNumber(),
            refundedAt:
              refundRequestedAt.toNumber() > 0 ? refundedAtBN.toNumber() : 0,
            tokenDecimals: tokenDecimals,
            vestingType: 'unified' as 'unified',
            refundDeadlineAt: refundDeadlineAtBN.toNumber(),
            isRefundTokenDeposited: refundProjectData.refundTokenDeposited > 0n,
          }
          // if (tokenSymbol === 'STAGE') {
          //   stats.claimedAmount = 0n
          //   stats.refundDeadlineAt = 1737331200
          //   stats.refundedAt = 0
          //   stats.refundRequestedAt = 0
          // }
          setStats(stats)
        } else {
          const vestingContract = new ethers.Contract(
            idoClaimContract,
            LEGACY_VESTING_ABI,
            rpcProvider,
          )
          const [
            claimableAmount,
            claimedAmount,
            tokenDecimals,
            pastClaimedAmount,
            pastAirdropAmount,
          ] = await Promise.all([
            vestingContract.getClaimableAmount(walletAddress),
            vestingContract.claimedAmount(walletAddress),
            vestingContract.returnTokenDecimal(),
            getPastClaimedAmount(
              walletAddress,
              claimChainId,
              idoPastClaimContracts,
            ),
            getPastAirdropedAmount(
              walletAddress,
              claimChainId,
              idoPastAirdropTxs,
            ),
          ])
          setStats({
            claimableAmount: claimableAmount.toBigInt(),
            claimedAmount:
              claimedAmount.toBigInt() + pastClaimedAmount + pastAirdropAmount,
            refundRequestedAt: 0,
            refundedAt: 0,
            tokenDecimals: tokenDecimals.toNumber(),
            vestingType: 'legacy',
          })
          // console.warn(
          //   'token',
          //   tokenSymbol,
          //   claimableAmount,
          //   claimedAmount,
          //   pastClaimedAmount,
          // )
        }
        clearInterval(intervalId)
      } catch (e) {
        console.error(`getStats for ${tokenSymbol}`, e)
        await sleep(1)
      }
    }
    intervalId = setInterval(fetchClaimStats, 1000)
    return () => {
      console.warn('remove interval')
      clearInterval(intervalId)
    }
  }, [
    walletAddress,
    claimChainId,
    idoClaimContract,
    projectVestingId,
    tokenSymbol,
    isUnifiedVesting,
    idoPastClaimContracts,
    idoPastAirdropTxs,
    apeRefundUnifiedContract,
    projectRefundId,
    nonce,
  ])

  // if (
  //   tokenSymbol === 'AIPUMP' ||
  //   tokenSymbol === 'PFT' ||
  //   tokenSymbol === 'STAGE'
  // ) {
  //   consoleLog('stats', tokenSymbol, stats)
  // }

  if (!walletAddress) {
    console.error('walletAddress not found')
    return null
  }
  // if (!chainId && netWork !== 'Solana') {
  //   console.error('unsupported chainId', chainId, netWork)
  //   return null
  // }

  const handleCloseToast = () => {
    setOpenToast(false)
    setMessage('')
  }

  const isMissedData =
    !idoTgeAmount ||
    !idoVestingAmount ||
    !idoCliffDuration ||
    !idoTgeDate ||
    !idoVestingDuration ||
    !allocation

  const calculateDistributionPercentage = () => {
    if (sale.allocation === 0) return 100
    if (isRequestRefund(sale)) return 0

    if (getClaimStatus(sale) === ClaimStatus.REQUEST_REFUND) {
      return 0
    }

    const percentage = (computeDistribution(sale) / totalTokens) * 100

    return Number(percentage.toFixed(2).toString()).toLocaleString('en')
  }

  const isBeforeDistribution = () => dayjs().isBefore(sale.idoTgeDate)

  const handleRefund = (isVestingClaim = true) => {
    onRefundSuccess(isVestingClaim)
  }

  const handleRequestRefund = (isSuccess = true) => {
    onRequestRefund(isSuccess)
  }

  const handleClaimSuccess = (isSuccess = true) => {
    onClaimSuccess(isSuccess)
  }

  const onClickViewClaim = (event: any) => {
    event.stopPropagation()
    navigate(`/project/${sale.projectName}?claim=true`)
  }

  const claimAction = () => {
    if (idoStatus !== 'TGE' && idoStatus !== 'Cancelled') return
    const idoClaimUrl = sale.idoClaimUrl
    if (Array.isArray(idoClaimUrl) && idoClaimUrl.length > 0) {
      const purposeByIndex: Record<number, string> = {
        0: 'TGE',
        1: 'Vesting',
      }
      return (
        <Box sx={{ display: 'flex', gap: '8px' }}>
          {idoClaimUrl.map((url: string, index: number) => (
            <SolanaClaimButton
              url={url}
              index={index}
              key={index}
              purpose={purposeByIndex[index]}
            />
          ))}
        </Box>
      )
    }

    if (!idoClaimContract) {
      return <></>
    }

    if (!stats) {
      return <Skeleton variant="rounded" width={48} height={22} />
    }

    if (stats.refundedAt > 0) {
      return <RefundedButton />
    }

    if (stats.vestingType === 'unified') {
      return (
        <Box display={'flex'} gap={'8px'}>
          {!stats.refundRequestedAt && (
            <ViewClaimButton
              disabled={isBusy || stats.claimableAmount === 0n}
              onClick={(e: Event) => {
                onUVClaim()
                e.preventDefault()
                e.stopPropagation()
              }}
            />
          )}
          {!!stats.refundDeadlineAt &&
            epoch < stats.refundDeadlineAt &&
            stats.refundRequestedAt === 0 &&
            stats.claimedAmount === 0n && (
              <CommonButton
                text="Request Refund"
                disabled={isBusy}
                onClick={(e: Event) => {
                  onUVRefundRequest()
                  e.preventDefault()
                  e.stopPropagation()
                }}
              />
            )}
          {(stats.refundRequestedAt > 0) && (
            <CommonButton
              text="Claim Refund"
              disabled={isBusy || !stats.isRefundTokenDeposited}
              onClick={(e: Event) => {
                onUVRefundClaim()
                e.preventDefault()
                e.stopPropagation()
              }}
            />
          )}
        </Box>
      )
    }

    if (stats.vestingType === 'legacy') {
      return (
        <ViewClaimButton
          disabled={isBusy || stats.claimableAmount === 0n}
          onClick={(e: Event) => {
            onLegacyClaim()
            e.preventDefault()
            e.stopPropagation()
          }}
        />
      )
    }

    if (isLegacyProject || isMissedData) {
      // if(stats && (stats.refundedAt > 0 || stats.refundRequestedAt > 0)) return;
      return <ViewClaimButton onClick={onClickViewClaim} />
    }

    return isBeforeDistribution() || !sale.isInvestor ? (
      <ClaimButtonDisabled />
    ) : (
      <ClaimButtonContainer
        sale={sale}
        onRefundSuccess={handleRefund}
        onRequestRefund={handleRequestRefund}
        onClaimSuccess={handleClaimSuccess}
        onOpenToast={isOpen => setOpenToast(!!isOpen)}
        onMessage={message => setMessage(message || '')}
      />
    )
  }

  const nextUnlockText = () => {
    return Number(sale.nextUnlock) ? formatUnixToDate(sale.nextUnlock) : 'TBD'
  }

  const allocationText = () => {
    if (isLegacyProject || isMissedData) return ''

    return isRequestRefund(sale)
      ? 0
      : sale.allocation
      ? Number(Number(sale.allocation).toFixed(2)).toLocaleString('en')
      : '0'
  }

  const distributionText = () => {
    if (isLegacyProject || isMissedData) return <></>

    return (
      <div className="received">
        <span className="graph">
          <Circle
            percent={Number(calculateDistributionPercentage())}
            strokeWidth={3}
            strokeColor="#78D372"
          />
          <b>{Number(calculateDistributionPercentage())}%</b>
        </span>
      </div>
    )
  }

  const claimableText = () => {
    // if (isLegacyProject || isMissedData) return <></>
    if (isSolanaIdo) return
    // if (!isUnifiedVesting) return
    if (!stats) return
    if (stats.claimableAmount === 0n) return

    return (
      <>
        <>
          {(+(+ethers.utils.formatUnits(
            stats.claimableAmount,
            stats.tokenDecimals || 18,
          )).toFixed(4)).toLocaleString()}
        </>
        <> {idoStatus === 'Cancelled' ? 'USDC' : sale.tokenSymbol}</>
      </>
    )
  }

  const claimedText = () => {
    // if (isLegacyProject || isMissedData) return <></>
    if (isSolanaIdo) return
    // if (!isUnifiedVesting) return
    if (!stats) return
    if (stats.claimedAmount === 0n) return

    return (
      <>
        <>
          {(+(+ethers.utils.formatUnits(
            stats.claimedAmount,
            stats.tokenDecimals || 18,
          )).toFixed(4)).toLocaleString()}
        </>
        <> {idoStatus === 'Cancelled' ? 'USDC' : sale.tokenSymbol}</>
      </>
    )
  }

  const onLegacyClaim = async () => {
    let toastId: any
    try {
      setIsBusy(true)
      if (!idoClaimContract) throw new Error('idoClaimContract is not set')
      if (!chainId) throw new Error('chainId is not set or unsupported')
      if (chainId !== claimChainId) {
        toaster.error(`Please switch network to ${getChainName(claimChainId)}`)
        await switchChain(wagmiConfig, { chainId: claimChainId })
        return
      }
      const vestingAddr = getPr0x1edVestingAddress(idoClaimContract, address)
      const vestingContract = new ethers.Contract(
        vestingAddr,
        LEGACY_VESTING_ABI,
        signer,
      )
      toastId = toaster.loading(`Claim token`, {
        duration: Infinity,
      })
      const tx = await vestingContract.claim()
      consoleLog('tx hash', _.get(tx, 'hash'))
      await tx.wait(2)
      setNonce(current => current + 1)
      onClaimSuccess(true)
    } catch (e) {
      console.error(e)
      toaster.error(getExceptionMessage(e))
    } finally {
      setIsBusy(false)
      if (toastId) {
        toaster.remove(toastId)
      }
    }
  }

  // unified vesting claim
  const onUVClaim = async () => {
    console.warn('onUVClaim')
    let toastId: any
    try {
      if (!address) {
        throw new Error('Wallet not connected')
      }
      if (!claimChainId)
        throw new Error('claimChainId is not set or unsupported')

      if (!idoClaimContract) {
        throw new Error('No idoClaimContract has been set')
      }
      if (!(projectVestingId >= 0))
        throw new Error('projectVestingId is not set')
      if (!(projectInvestmentId >= 0))
        throw new Error('projectInvestmentId has been set')

      if (chainId !== claimChainId) {
        toaster.error(`Please switch network to ${getChainName(claimChainId)}`)
        await switchChain(wagmiConfig, { chainId: claimChainId })
        return
      }
      setIsBusy(true)
      const vestingContract = new ethers.Contract(
        idoClaimContract,
        UV_VESTING_ABI,
        signer,
      )
      const investmentProof =
        await apeInvestmentUnifiedContract.getInvestmentProof(
          projectInvestmentId,
          address,
        )
      toastId = toaster.loading('Claim token', {
        duration: Infinity,
      })
      let estGas: ethers.BigNumber | BigInt =
        await vestingContract.estimateGas.claimToken(
          projectVestingId,
          investmentProof,
        )
      estGas = estGas.toBigInt()
      if (estGas < MIN_GAS_LIMIT.UV_CLAIM) {
        estGas = MIN_GAS_LIMIT.UV_CLAIM
      }
      const tx = await vestingContract.claimToken(
        projectVestingId,
        investmentProof,
        {
          gasLimit: estGas,
        },
      )
      consoleLog('tx hash', _.get(tx, 'hash'))
      await tx.wait(2)
      consoleLog('tx confirmed', _.get(tx, 'hash'))
      setNonce(current => current + 1)
      onClaimSuccess(true)
    } catch (e) {
      console.error('onUVClaim error', e)
      toaster.error(getExceptionMessage(e))
    } finally {
      setIsBusy(false)
      if (toastId) {
        toaster.remove(toastId)
      }
    }
  }

  const onUVRefundRequest = async () => {
    let toastId: any
    try {
      setIsBusy(true)
      if (!idoClaimContract) {
        throw new Error('idoClaimContract is not set')
      }
      const claimProjectId = projectVestingId
      if (!(claimProjectId >= 0)) {
        consoleLog('claimProjectId is not set')
        return
      }
      if (!claimChainId) throw new Error('claimChainId is not set')
      if (!(projectInvestmentId >= 0))
        throw new Error('projectInvestmentId is not set')
      if (chainId !== claimChainId) {
        toaster.error(`Please switch network to ${getChainName(claimChainId)}`)
        await switchChain(wagmiConfig, { chainId: claimChainId })
        return
      }
      const vestingContract = new ethers.Contract(
        idoClaimContract,
        UV_VESTING_ABI,
        signer,
      )
      const investmentProof =
        await apeInvestmentUnifiedContract.getInvestmentProof(
          projectInvestmentId,
          address,
        )
      let estGas: ethers.BigNumber | BigInt =
        await vestingContract.estimateGas.requestRefund(
          claimProjectId,
          investmentProof,
        )
      estGas = estGas.toBigInt()
      if (estGas < MIN_GAS_LIMIT.UV_REFUND_REQUEST) {
        estGas = MIN_GAS_LIMIT.UV_REFUND_REQUEST
      }
      const tx = await vestingContract.requestRefund(
        claimProjectId,
        investmentProof,
        {
          gasLimit: estGas,
        },
      )
      consoleLog('tx hash', tx, _.get(tx, 'hash'))
      await tx.wait(2)
      consoleLog('tx confirmed', _.get(tx, 'hash'))
      setNonce(current => current + 1)
    } catch (error) {
      console.error('onUVRefundRequest error', error)
      toaster.error(getExceptionMessage(error))
    } finally {
      setIsBusy(false)
      if (toastId) {
        toaster.remove(toastId)
      }
    }
  }

  const onUVRefundClaim = async () => {
    let toastId: any
    try {
      if (!idoClaimContract) {
        throw new Error('idoClaimContract is not set')
      }
      if (!(projectVestingId >= 0)) {
        throw new Error('projectVestingId is not set')
      }
      if (!claimChainId)
        throw new Error('claimChainId is not set or unsupported')
      if (!(projectInvestmentId >= 0))
        throw new Error('investmentProjectId is not set')
      if (!(projectRefundId >= 0)) throw new Error('projectRefundId is not set')
      setIsBusy(true)
      let tx: any
      let proof: any
      toastId = toaster.loading('Claim refund', {
        duration: Infinity,
      })
      if (idoId === PONDER_IDO_OID || idoId === MICROGPT_IDO_OID) {
        const refundProofGenerator =
          '0xc57b048deac276aa6a1d267d06d24af226fc913c'
        const refundUserList: string[] = (
          refundUserByProject as Record<string, string[]>
        )[idoId]
        const userIndex = refundUserList.findIndex(addr => addr === address)
        const proofGeneratorContract = new ethers.Contract(
          refundProofGenerator,
          [
            {
              inputs: [
                {
                  internalType: 'address[]',
                  name: 'refundAccountList_',
                  type: 'address[]',
                },
                { internalType: 'uint256', name: 'index_', type: 'uint256' },
              ],
              name: 'getRefundProof',
              outputs: [
                { internalType: 'bytes32[]', name: '', type: 'bytes32[]' },
              ],
              stateMutability: 'view',
              type: 'function',
            },
          ],
          RPC_PROVIDER[CHAIN_ID.ETH],
        )
        proof = await proofGeneratorContract.getRefundProof(
          refundUserList,
          userIndex,
        )
      } else if (idoId === BRAVO_READY_IDO_OID) {
        // bravo ready is a cancelled project so every one get refund
        proof = await apeInvestmentUnifiedContract[
          'getInvestmentProof(uint256,address)'
        ](projectInvestmentId, address)
      } else {
        const claimContractInstance = new ethers.Contract(
          idoClaimContract,
          UV_VESTING_ABI,
          RPC_PROVIDER[claimChainId],
        )
        proof = await claimContractInstance['getRefundProof(uint256,address)'](
          projectInvestmentId,
          address,
        )
      }
      // Refund always on BSC
      if (chainId !== refundChainId) {
        toaster.error(`Please switch network to ${getChainName(refundChainId)}`)
        await switchChain(wagmiConfig, { chainId: MAIN_CHAIN_ID })
        return
      }
      const refundContract = new ethers.Contract(
        CONTRACT_ADDRESS.APE_REFUND_UNIFIED[refundChainId],
        UV_REFUND_ABI,
        signer,
      )
      tx = await refundContract['claimRefundToken(uint256,bytes32[])'](
        projectRefundId,
        proof,
      )
      consoleLog('tx hash', tx, _.get(tx, 'hash'))
      if (tx) {
        await tx.wait(2)
        setNonce(current => current + 1)
      }
    } catch (error) {
      console.error('onUVRefundClaim error', error)
      toaster.error(getExceptionMessage(error))
    } finally {
      setIsBusy(false)
      if (toastId) {
        toaster.remove(toastId)
      }
    }
  }

  return (
    <>
      <TableRow
        key={sale.id}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#000000',
          },
        }}
        onClick={() => navigate(`/project/${sale?.projectName}`)}
      >
        {isDown800 ? (
          <>
            <FixedBodyTableCell>
              <img
                src={getReplacedCdnEndpoint(sale?.projectLogoUrl)}
                alt={sale.projectName}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 10,
                  objectFit: 'cover',
                }}
              />
            </FixedBodyTableCell>
            <StyledBodyTableCell>
              <Box ml={'12px'} sx={{ width: '100%', overflow: 'hidden' }}>
                <Typography
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '15px',
                    fontWeight: 400,
                    width: '100%',
                    overflow: 'hidden',
                    display: 'inline-block',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {replaceUnderscoresWithSpaces(sale.projectName)}
                </Typography>
              </Box>
            </StyledBodyTableCell>
          </>
        ) : (
          <StyledBodyTableCell>
            <Box display="flex" alignItems="center" sx={{ maxWidth: '220px' }}>
              <img
                src={sale.projectLogoUrl}
                alt={sale.projectName}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 10,
                  objectFit: 'cover',
                }}
              />
              <Box ml={'12px'} sx={{ width: '100%', overflow: 'hidden' }}>
                <Typography
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '15px',
                    fontWeight: 400,
                    width: '100%',
                    overflow: 'hidden',
                    display: 'inline-block',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {replaceUnderscoresWithSpaces(sale.projectName)}
                </Typography>
                <Typography
                  sx={{
                    color: '#7E8180',
                    fontSize: '12px',
                    fontWeight: 400,
                  }}
                >
                  {sale.tokenSymbol}
                </Typography>
              </Box>
            </Box>
          </StyledBodyTableCell>
        )}
        {/* <StyledBodyTableCell>
        {nextUnlockText()}
      </StyledBodyTableCell> */}
        <StyledBodyTableCell>
          <img
            width={'24px'}
            src={getNetworkIconByChain(netWork)}
            alt={netWork}
          />
        </StyledBodyTableCell>
        <StyledBodyTableCell>
          {
            <Box
              sx={{
                height: '28px',
                width: 'fit-content',
                padding: '6px 12px',
                borderRadius: '80px',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                fontSize: '12px',
                fontWeight: 500,
                fontFamily: 'Inter',
                ...getStatusStyleFilter(sale.idoStatus),
              }}
            >
              {sale.idoStatus}
            </Box>
          }
        </StyledBodyTableCell>
        <StyledBodyTableCell>{computeTicketSize(sale)}</StyledBodyTableCell>
        {/* <StyledBodyTableCell>
        {allocationText()}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {distributionText()}
      </StyledBodyTableCell>*/}
        <StyledBodyTableCell>{claimableText()}</StyledBodyTableCell>
        <StyledBodyTableCell>{claimedText()}</StyledBodyTableCell>
        <StyledBodyTableCell>{claimAction()}</StyledBodyTableCell>
      </TableRow>
      <Snackbar
        open={openToast}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: '100%', zIndex: 1000 }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}

const ClaimTableRow = memo(ClaimTableRowC)

export default ClaimTableRow
