import React from 'react'
import { AmberCircles } from '../../assets'
import RenderContainer from './RenderContainer'
import { useMediaQuery } from '@mui/material'

const RenderProcessingPhase = ({
  idoData,
  score,
  totalParticipants,
  totalAssetsConnected,
  multiplierData,
}: any) => {
  const isDown800 = useMediaQuery('(max-width:800px)')
  return (
    <RenderContainer
      idoData={idoData}
      score={score}
      totalParticipants={totalParticipants}
      totalAssetsConnected={totalAssetsConnected}
      multiplierData={multiplierData}
      type="processing"
      sideComponent={
        <img
          src={AmberCircles}
          style={{
            objectFit: 'cover',
            width: isDown800 ? '100%' : '',
          }}
          alt="Amber Circles"
        />
      }
    />
  )
}

export default RenderProcessingPhase
