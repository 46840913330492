import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  useMediaQuery,
} from '@mui/material'
import {
  StyledHeadTableCell,
  FixedHeadTableCell,
  StyledBodyTableCell,
  FixedBodyTableCell,
} from './components/TableStyled';

const SkeletonForClaimRow = ({ isDown800 }: { isDown800: boolean }) => (
  <TableRow>
    {isDown800 ? (
      <>
        <FixedBodyTableCell>
          <Skeleton
            variant="rounded"
            width={40}
            height={40}
            sx={{
              borderRadius: '10px',
              backgroundColor: 'rgba(255, 255, 255, 0.1)'
            }} />
        </FixedBodyTableCell>
        <StyledBodyTableCell>
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
        </StyledBodyTableCell>
      </>
    ) : (
      <StyledBodyTableCell>
        <Box display="flex" alignItems="center">
          <Skeleton
            variant="rounded"
            width={40}
            height={40}
            sx={{ 
              borderRadius: '10px',
              backgroundColor: 'rgba(255, 255, 255, 0.1)'
            }}
          />
          <Box ml={'12px'}>
            <Skeleton
              variant="text"
              width={120}
              height={24}
              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
            />
            <Skeleton
              variant="text"
              width={60}
              height={20}
              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
            />
          </Box>
        </Box>
      </StyledBodyTableCell>
    )}
    {/* <StyledBodyTableCell>
      <Skeleton
        variant="text"
        width={100}
        height={24}
        sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
      />
    </StyledBodyTableCell> */}
    <StyledBodyTableCell>
      <Skeleton
        variant="text"
        width={100}
        height={24}
        sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
      />
    </StyledBodyTableCell>
    <StyledBodyTableCell>
      <Skeleton
        variant="text"
        width={100}
        height={24}
        sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
      />
    </StyledBodyTableCell>
    <StyledBodyTableCell>
      <Skeleton
        variant="text"
        width={80}
        height={24}
        sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
      />
    </StyledBodyTableCell>
    {/* <StyledBodyTableCell>
      <Skeleton
        variant="rounded"
        width={69}
        height={28}
        sx={{ 
          borderRadius: '80px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }}
      />
    </StyledBodyTableCell>
    <StyledBodyTableCell>
      <Skeleton
        variant="rounded"
        width={69}
        height={28}
        sx={{ 
          borderRadius: '80px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }}
      />
    </StyledBodyTableCell>
    <StyledBodyTableCell>
      <Skeleton
        variant="rounded"
        width={69}
        height={28}
        sx={{ 
          borderRadius: '80px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }}
      />
    </StyledBodyTableCell>
    <StyledBodyTableCell>
      <Skeleton
        variant="rounded"
        width={69}
        height={28}
        sx={{ 
          borderRadius: '80px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }}
      />
    </StyledBodyTableCell> */}
    <StyledBodyTableCell>
      <Skeleton
        variant="rounded"
        width={69}
        height={28}
        sx={{ 
          borderRadius: '80px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }}
      />
    </StyledBodyTableCell>
  </TableRow>
)

const SkeletonForClaimTable = () => {
  const isDown800 = useMediaQuery('(max-width:800px)')
  const skeletonRows = Array(5).fill(null)

  return (
    <Box width={'100%'} mb={'60px'}>
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        paddingLeft={'0px'}
        paddingTop={'0px'}
      >
        <TableContainer
          component={Paper}
          sx={{
            height: '100%',
            maxHeight: '414px',
            border: '1px solid #1D1F21',
            boxShadow: 'none',
            backgroundImage: 'none',
            flexShrink: 1,
            width: '100%',
            borderRadius: '16px',
            '&::-webkit-scrollbar': {
              display: 'flex',
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#313335',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#0F1113',
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              minHeight: '100%',
              height: '100%',
              flexShrink: 0,
            }}
          >
            <TableHead>
              <TableRow>
                {isDown800 ? (
                  <>
                    <FixedHeadTableCell>Project</FixedHeadTableCell>
                    <StyledHeadTableCell>Project Name</StyledHeadTableCell>
                  </>
                ) : (
                  <StyledHeadTableCell>Project</StyledHeadTableCell>
                )}
                {/* <StyledHeadTableCell>Next Unlock</StyledHeadTableCell> */}
                <StyledHeadTableCell>Chain</StyledHeadTableCell>
                <StyledHeadTableCell>Status</StyledHeadTableCell>
                <StyledHeadTableCell>Ticket Size</StyledHeadTableCell>
                {/* <StyledHeadTableCell>Allocation</StyledHeadTableCell>
                <StyledHeadTableCell>Distribution</StyledHeadTableCell>
                <StyledHeadTableCell>Claimable</StyledHeadTableCell>
                <StyledHeadTableCell>Claimed</StyledHeadTableCell> */}
                <StyledHeadTableCell>Action</StyledHeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflowY: 'hidden',
                overflowX: 'auto',
                backgroundColor: '#0F1113',
              }}
            >
              {skeletonRows.map((_, index) => (
                <SkeletonForClaimRow key={index} isDown800={isDown800} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default SkeletonForClaimTable
