import { Box, useMediaQuery } from '@mui/material'
import React from 'react'

const PrivacyPolicy = () => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  return (
    <Box
      sx={{
        padding: isMobile ? '0 24px 0 24px' : '0 100px 0 100px',
        paddingTop: 10,
      }}
      className="privacy-policy"
    >
      <div className="flex flex-col gap-1rem">
        <h1
          style={{
            textAlign: 'center',
          }}
        >
          Privacy Policy
        </h1>
        <p>
          The Ecosystem Operator will not collect any cookies, IP addresses, or
          user data in connection with your use of the website and app, with the
          exception of cookies related to browsing behavior for the Google
          analytics SEO which helps us enhance your experience with our site and
          relay visitor traffic statistics to the Google search engine
        </p>
        <p>
          Each User understands that the Binance Smart Chain (BSC) blockchain
          network and any other Chain that may be used within the ecosystem
          operator’s platforms is a public blockchain, and all transaction
          history initiated through the website will be made public ;
        </p>
        <p>
          The Ecosystem Operator has no influence on secondary markets, such as
          Crypto Exchanges which have independent terms of service agreed to by
          you, should you choose to utilize these sites
        </p>
        <p>
          Should the Company be required to collect or store data for future
          activities such as merchandise launches or transfer of any benefits
          such as airdrops, you will be required to agree to the collection of
          this data;
        </p>
      </div>
    </Box>
  )
}

export default PrivacyPolicy
