import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import OrangeButton from '../../components/OrangeButton'
import { AcademyThumbnail, IconWatchlist } from '../../assets'

const WhatApeTerminal: React.FC = () => {
  const isDown1023 = useMediaQuery('(max-width:1023px)')
  const isDown766 = useMediaQuery('(max-width:766px)')
  const theme = useTheme()
  const isBetweenScreen = useMediaQuery(theme.breakpoints.between(767, 1200))
  const isBetweenScreen1200 = useMediaQuery(
    theme.breakpoints.between(767, 1366),
  )

  const videos = [
    {
      link: 'https://cdn.apeterminal.io/videos/Explainer%207%20-%20Ape%20Terminal%20comparison%20to%20other%20launchpads.mp4',
      thumbnail: 'https://cdn.apeterminal.io/videos/what-is-ape-terminal.jpg',
    }, {
      link: 'https://cdn.apeterminal.io/videos/Launchpads%20are%20one%20of%20the.mp4',
      thumbnail: 'https://cdn.apeterminal.io/videos/get-started.jpg',
    }, {
      link: 'https://cdn.apeterminal.io/videos/Explainer%206%20-%20Crypto%20security.mp4',
      thumbnail: 'https://cdn.apeterminal.io/videos/keep-wallet-safe.jpg'
    },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '64px',
        maxWidth: isBetweenScreen1200 ? '90%' : '1200px',
        width: '100%',
        margin: '0 auto',
        paddingX: isDown766 ? '25px' : isBetweenScreen ? '16px' : '',
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Typography
          color="#000"
          fontFamily="Hellix"
          fontSize={isDown1023 ? '40px' : '48px'}
          fontWeight={600}
          lineHeight={isDown1023 ? '48px' : '52px'}
          textAlign="left"
        >
          What is Ape Terminal?
        </Typography>
        <Typography
          fontFamily="Inter"
          fontSize={isDown1023 ? '18px' : '22px'}
          fontWeight={400}
          lineHeight={isDown1023 ? '24px' : '33px'}
          textAlign="left"
          color="#000"
        >
          Ape Terminal has offered opportunities to buy in pre-sales alongside
          investors like <br /> Binance Labs, Samsung NEXT, Arthur Hayes, and many
          more.
        </Typography>
        <Box
          sx={{
            width: '161px',
            borderRadius: '50px',
            minWidth: '161px',
            height: '53px',
            marginTop: '20px',
            '&:hover': {
              borderBottom: '5px solid #000'
            }
          }}
        >
          <OrangeButton
            text="Learn More"
            br="24px"
            fs={15}
            fw={500}
            fm="Inter"
            lh="22.5px"
            bgColor="#FF9228"
            border="1px solid #000"
            IconStart={() => (
              <img
                src={IconWatchlist}
                style={{
                  marginRight: '10px',
                }}
                alt="IconWatchlist"
              />
            )}
            h="48px"
            w="161px"
            sx={{
              textTransform: 'none',
            }}
          />
        </Box>
      </Box>

      {/* Image Gallery Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isDown766 ? 'column' : isBetweenScreen ? 'row' : 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '24px',
          width: '100%',
        }}
      >
        {videos.map((video, index) => (
          <Box
            key={index}
            sx={{
              borderRadius: '20px',
              overflow: 'hidden',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {/* <img
              style={{
                objectFit: 'cover',
                width: '100%',
                maxWidth: isDown766 ? '340px' : ''
              }}
              loading="lazy"
              src={bgThumbnail}
              alt={`bgThumbnail-${index}`}
            /> */}
            <video
              src={video.link}
              controls
              poster={video.thumbnail}
              style={{
                objectFit: 'cover',
                width: '100%',
                maxWidth: isDown766 ? '340px' : '',
                borderRadius: '20px',
                // height: '375px',
              }}>
            </video>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default WhatApeTerminal
