import { Button, Typography } from '@mui/material'
// import { FilledBlackArrow } from '../../../../assets'
// import LaunchIcon from '@mui/icons-material/Launch'

type SolanaClaimButtonProps = {
  url?: string
  index: number
  purpose?: string
}

export const SolanaClaimButton = (props: SolanaClaimButtonProps) => {
  const { url, index, purpose } = props

  const onClickClaim = (event: any) => {
    if (!url) return

    event.stopPropagation()

    window.open(url, '_blank')
  }

  return (
    <Button
      variant="greenContainer"
      // startIcon={<img src={FilledBlackArrow} alt="filled black arrow" />}
      // endIcon={<LaunchIcon />}
      sx={{
        maxWidth: '120px',
        borderRadius: '24px',
        height: '32px',
        display: 'flex',
        '&:hover': {
          opacity: 0.8,
        },
      }}
      onClick={onClickClaim}
    >
      <Typography
        fontWeight={600}
        fontSize={'12px'}
        lineHeight={'16px'}
        color="#000"
        fontFamily={'Inter'}
      >
        {purpose ? `Claim ${purpose}` : `Claim #${index + 1}`}
      </Typography>
    </Button>
  )
}
