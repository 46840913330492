import React, { Suspense, useEffect, useState } from 'react'
import Navbar from '../../components//Navbar'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Footer from '../../components/Footer/index'
import Loader from '../../components//Loader'
import { useAppDispatch, useAppSelector } from '../../hooks/'
import { Alert, Box, Snackbar } from '@mui/material'
import {
  initialStateSnackbar,
  setSnackbar,
} from '../../redux/slices/themeSlice'
import { useUpdateHoldingMutation } from '../../services/modules/users'
import Lottie from 'lottie-react'
import LogoAnimation from '../../animations/logo loading white.json'
import ThemeContainer from './ThemeContainer'
import PrivacyPolicy from '../../pages/PrivacyPolicy'
import TermsOfServices from '../../pages/TermsOfServices'
import { useAccount } from 'wagmi'
import NotFound from '../../components/NotFound'
import RevampedLaunchpad from '../../pages/RevampedLaunchpad'
import RevampedOverview from '../../pages/RevampedOverview'
import RevampedParticipationFlow from '../../pages/RevampedParticipationFlow'
import RevampedPortfolio from '../../pages/RevampedPortfolio'
import RevampedTransparency from '../../pages/RevampedTransparency'
import FAQ from '../../pages/FAQ'
import GiftCardBanner from '../../components/GiftCardBanner'
import { AnalyticsProvider } from '../../AmplitudeContext'
import { NotificationSystem } from '../../components/common/NotificationSystem'
import { ESocketEvent, EventData, events } from '../../sockets/socket'
import eventBus from '../../sockets/event-bus'
import { BaseSocket } from '../../sockets/BaseSocket'
import ToastMessage from '../../components/ToastMessage'
import { Toaster } from 'react-hot-toast'
import { Web3General } from '../About/Web3General'

// ===========================================================
import Layout from '../../pages/layouts'
import Home from '../../pages/Home'
import { RevampLaunchPad } from '../../pages/Launchpad'
import RevampPorfolioManager from '../../pages/RevampPortfolioManager'
import UserPortal from '../../pages/UserPortal'
import { PATHS } from '../../constants/config'
import Account from '../../pages/Account'
import RevampedHome from '../../pages/RevampedHome'
import RwaSUIAirdrop from '../../pages/RWA-SUI-Airdrop'
import { minHeight } from '@mui/system'
import LaunchPools from '../../components/LaunchPools'
import InComming from '../../components/InComing'
// ===========================================================

const Router = () => {
  const dispatch = useAppDispatch()
  const snackbarSelector = useAppSelector(state => state.theme.snackbar)
  const txInProgress = useAppSelector(state => state.wallet.txInProgress)
  const [updateHoldings] = useUpdateHoldingMutation()
  const { address } = useAccount()
  const mainAppToken = useAppSelector(state => state.user.mainAppToken)
  const [pageLoading, setPageLoading] = useState(true)
  const user = useAppSelector(state => state.user)
  const appState = useAppSelector(state => state.app)
  const { isSidebarCollapse } = appState

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(setSnackbar(initialStateSnackbar))
  }
  useEffect(() => {
    if (mainAppToken && address) updateHoldings(address)
  }, [mainAppToken, address])

  useEffect(() => {
    console.log('setting timer useEffect', pageLoading)
    if (pageLoading) {
      console.log('setting timer', pageLoading)
      setTimeout(() => {
        console.log('5 seconds passed', pageLoading)
        document?.body?.classList?.remove('no-scroll')

        setPageLoading(false)
      }, 500)
    }
  }, [pageLoading])

  const [giftBannerHeight, setShowGiftBannerHeight] = useState('16px')

  const handleBannerDisplay = (data: any) => {
    setShowGiftBannerHeight(data ? '45px' : '0px')
  }

  const [eventData, setEventData] = useState<EventData | null>(null)

  const handleEvent = (eventType: ESocketEvent, data: EventData) => {
    console.log(`${eventType} data received:`, data)
    if (data) setEventData(data)
  }

  useEffect(() => {
    events.forEach(event => {
      eventBus.on(event, (data: EventData) => handleEvent(event, data))
    })

    return () => {
      events.forEach(event => {
        eventBus.remove(event, (data: EventData) => handleEvent(event, data))
      })
    }
  }, [])

  useEffect(() => {
    if (address) {
      BaseSocket.getInstance().emitContribution(address)
    }
  }, [address])

  return (
    <>
      <BrowserRouter>
        <NotificationSystem />
        {eventData ? (
          <ToastMessage data={eventData} />
        ) : (
          <Toaster
            position="bottom-right"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: '',
              duration: 2500,
              style: {
                background: '#fafafa',
                color: '#000',
              },
            }}
          />
        )}
        <AnalyticsProvider apiKey="73b7617d7e536d286e2c757693a70218">
          {txInProgress && <Loader />}

          {/* <Navbar isLoading={false} giftBannerHeight={giftBannerHeight} /> */}
          <ThemeContainer container flexDirection={'column'}>
            <Box
              alignSelf={'center'}
              display={'flex'}
              justifyContent={'center'}
              width={'100%'}
              minHeight={'calc(100vh - 133px)'}
              // maxWidth={isSidebarCollapse ? '1200px' : 'unset'}
              maxWidth={isSidebarCollapse ? 'calc(50vw + 26.5rem)' : 'unset'}
              margin={isSidebarCollapse ? '0 auto' : ''}
            >
              <Routes>
                {/* ============================================================================ */}
                <Route path={PATHS.home} element={<Home />} />
                <Route path={PATHS.userPortal} element={<UserPortal />} />
                <Route
                  path={PATHS.portfolio}
                  element={<RevampPorfolioManager />}
                />
                <Route path={PATHS.account} element={<Account />} />
                <Route
                  path={PATHS.investmentPortal}
                  element={<RevampLaunchPad />}
                />
                <Route path={PATHS.launchPools} element={<LaunchPools />} />
                <Route
                  path={`${PATHS.investmentPortal}/:projectName`}
                  element={
                    <Suspense
                      fallback={
                        <Box
                          sx={{
                            backgroundColor: '#000',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 1000000,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Lottie
                            animationData={LogoAnimation}
                            loop={true}
                            style={{
                              height: '20vh',
                            }}
                          />
                        </Box>
                      }
                    >
                      <RevampedOverview />
                    </Suspense>
                  }
                />
                <Route
                  path="/participate/:projectName/:idoNumber"
                  element={
                    <Suspense
                      fallback={
                        <Box
                          sx={{
                            backgroundColor: '#000',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 1000000,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Lottie
                            animationData={LogoAnimation}
                            loop={true}
                            style={{
                              height: '20vh',
                            }}
                          />
                        </Box>
                      }
                    >
                      <RevampedParticipationFlow />
                    </Suspense>
                  }
                />
                {/* ============================================================================ */}
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="faq" element={<FAQ isBreakTitle={false} />} />
                <Route path="rwasui-airdrop" element={<RwaSUIAirdrop />} />
                <Route path="terms-of-service" element={<TermsOfServices />} />
                {/* <Route path="/" element={<Launchpad />} /> */}
                {/* <Route path="/" element={<RevampedLaunchpad />} /> */}
                {/* <Route path="/" element={<Home />} /> */}
                <Route
                  path="/participate/ido"
                  element={<RevampedParticipationFlow />}
                />
                <Route path="/guide" element={<InComming />} />
                <Route path="/news" element={<InComming />} />
                {/* <Route
                  path="/project/:projectName"
                  element={
                    <Suspense
                      fallback={
                        <Box
                          sx={{
                            backgroundColor: '#000',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 1000000,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Lottie
                            animationData={LogoAnimation}
                            loop={true}
                            style={{
                              height: '20vh',
                            }}
                          />
                        </Box>
                      }
                    >
                      <RevampedOverview />
                    </Suspense>
                  }
                /> */}
                <Route
                  path="/portfolio-legacy"
                  element={
                    <Suspense
                      fallback={
                        <Box
                          sx={{
                            backgroundColor: '#000',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 1000000,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Lottie
                            animationData={LogoAnimation}
                            loop={true}
                            style={{
                              height: '20vh',
                            }}
                          />
                        </Box>
                      }
                    >
                      <RevampedPortfolio />
                    </Suspense>
                  }
                />

                <Route
                  path="/transparency"
                  element={<RevampedTransparency />}
                />

                <Route path="*" element={<NotFound />} />
                <Route path="/about/web3-general" element={<Web3General />} />
                <Route
                  path="/about/web2-institutions"
                  element={<div>Web2 Institutions</div>}
                />
                <Route
                  path="/about/web2-retail"
                  element={<div>Web2 Retail</div>}
                />
              </Routes>
            </Box>
          </ThemeContainer>
          {/* <Footer /> */}
          <Snackbar
            open={snackbarSelector?.open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <Alert
              onClose={handleClose}
              severity={snackbarSelector?.severity}
              sx={{ width: '100%' }}
            >
              {snackbarSelector?.message}
            </Alert>
          </Snackbar>
        </AnalyticsProvider>
      </BrowserRouter>
    </>
  )
}

export default Router
