import { Box } from "@mui/system";
import FAQComponent from "./FAQ";
import { FaqBg } from "../../assets";

const FAQ = ({ isBreakTitle = true }) => {
  return (
    <Box
      sx={{
        // backgroundImage: `url(${FaqBg})`,
        backgroundSize: 'cover',
        backgroundPositionY: '-270%',
        backgroundRepeat: 'no-repeat',
        width: '100%',
      }}
    >
      <Box margin={'0 auto'}>
        <FAQComponent isBreakTitle={isBreakTitle} />
      </Box>
    </Box>

  )
}

export default FAQ
