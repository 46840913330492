import { SetStateAction, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { useMediaQuery } from '@mui/material'
import { useAppSelector } from '../../../../hooks'
import SalesTable from '../SalesTable'
import NoDataTable from '../NoDataTable'
import NoDataBox from '../NoDataBox'
import SkeletonSalesTable  from '../SkeletonSalesTable'
import { IResponseUserPortalItem, useFetchUserParticipationsSalesQuery } from '../../../../services/modules/participationsV2'

interface LocationState {
  activeTab?: ETabsName
}

export enum ETabsName {
  IDOs = 'IDOs',
  Claim = 'Claim',
  Referral = 'Referrals',
}
const salesHeaders = [
  'Project',
  'Chain',
  'Ticket Size',
  'Sale Status',
  'User Status',
  'Action',
]

type SaleTableContainerProps = {
  keyword: string,
  isVisible: boolean,
  isRefresh?: boolean,
}

const SaleTableContainer = (props: SaleTableContainerProps) => {
  const { keyword, isVisible, isRefresh } = props;

  const limit = 5
  const location = useLocation()
  const { state } = location as { state: LocationState | null }
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const [page, setPage] = useState(1)
  const [data, setData] = useState<IResponseUserPortalItem[]>([])
  const [debouncedSearchName, setDebouncedSearchName] = useState('')
  const [activeTab, setActiveTab] = useState(ETabsName.IDOs)
  const user = useAppSelector(state => state.user)
  const { accessTokenGoogle, mainAppToken } = user
  const { address } = useAccount()
  const [isShowButton, setIsShowButton] = useState(false)

  // Fetch user paticipate sales
  const {
    data: fetchedData,
    isLoading,
    isFetching,
    refetch: fetchUserParticipationSales,
  } = useFetchUserParticipationsSalesQuery(
    { page, limit, name: keyword || undefined, },
    {
      skip: !address && !mainAppToken && !accessTokenGoogle
    },
  )

  useEffect(() => {
    if (mainAppToken && address || accessTokenGoogle) {
      setPage(1)
      fetchUserParticipationSales();
    }
  }, [keyword])

  useEffect(() => {
    if (!isVisible || isRefresh) {
      setPage(1)
    }

    if (isRefresh) {
      fetchUserParticipationSales()
    }
  }, [isVisible, isRefresh])

  // Concat data when load more
  useEffect(() => {
    if (fetchedData?.data && (address || accessTokenGoogle)) {
      if (page === 1) {
        setData(fetchedData.data)
      } else {
        setData(prevData => [...prevData, ...fetchedData.data])
      }
    }
  }, [address, mainAppToken, accessTokenGoogle, fetchedData])

  useEffect(() => {
    if (mainAppToken && address || accessTokenGoogle) {
      fetchUserParticipationSales()
    } else {
      setData([])
    }
  }, [mainAppToken, address, fetchUserParticipationSales])

  useEffect(() => {
    if (state?.activeTab) {
      setActiveTab(state.activeTab)
    }
  }, [state])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchName(keyword)
    }, 700)
    return () => {
      clearTimeout(handler)
    }
  }, [keyword])

  useEffect(() => {
    if (debouncedSearchName) {
      if (fetchedData && fetchedData?.meta?.total_records <= 5) {
        setPage(1)
        setIsShowButton(true)
      } else {
        setIsShowButton(false)
      }
  
      fetchUserParticipationSales()
    } else {
      setIsShowButton(false)
    }
  }, [
    activeTab,
    debouncedSearchName,
    fetchUserParticipationSales,
  ])

  const handleShowMore = (isCollapes = false) => {
    if (isCollapes) {
      setPage(1)
      return;
    }

    if (Number(fetchedData?.meta?.total_records) > data.length) {
      setPage(p => p+1)
    } else {
      setPage(1)
    }
  }

  return (
    <>
      {isLoading ? (
        <SkeletonSalesTable  />
      ) : data?.length ? (
        <SalesTable
          isShowButton={isShowButton}
          mySales={data}
          meta={fetchedData?.meta}
          handleShowMore={handleShowMore}
          isFetching={isFetching}
        />
      ) : (
        <>
          {!isDown1000 ? (
            <NoDataTable headers={salesHeaders} />
          ) : (
            <NoDataBox />
          )}
        </>
      )}
    </>
  )
}

export default SaleTableContainer;
