import React, { useMemo } from 'react'
import Chart from 'react-apexcharts'
import { ITokenAssetInfo } from '../../services/modules/portfolio-manager'
import { ChartColors } from '../../utils'
import { numberFormat } from '../../utils/function'

const populateChartColors = (count: number): string[] => {
  const colors = []
  for (let i = 0; i < count; i++) {
    colors.push(ChartColors[i % ChartColors.length])
  }
  return colors
}

interface TokenAllowcationChartProps {
  data: ITokenAssetInfo[]
}

const TokenAllowcationChart: React.FC<TokenAllowcationChartProps> = props => {
  let { data } = props

  const series = useMemo(() => {
    if (!data || !data.length) {
      return []
    }

    return !data ? [] : data?.map(i => parseFloat(i.usd_value || '0'))
  }, [data])

  const options = useMemo(() => {
    if (!data || !data.length) {
      return {}
    }

    const labels = !data
      ? []
      : data?.map(
          i => numberFormat(parseFloat(i.balance), 2, 2) + ' ' + i.token.denom,
        )
    const colors = !data ? [] : populateChartColors(data.length)

    return {
      chart: {
        width: 380,
        type: 'donut' as const,
      },
      colors,
      labels,
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Inter, sans-serif',
                color: 'rgba(241,237,242,0.6)',
                offsetY: 25,
                fontWeight: 300,
                formatter: (val: string) => {
                  return val
                },
              },
              value: {
                show: true,
                fontSize: '18px',
                fontFamily: 'Inter, sans-serif',
                color: '#F1EDF2',
                offsetY: -25,
                formatter: function (val: string) {
                  return `${numberFormat(parseFloat(val), 2, 2, '$')}`
                },
              },
              total: {
                show: false,
                label: 'Total',
                color: '#373d3f',
                formatter: function (w: any) {
                  let total = w?.globals?.seriesTotals?.reduce?.(
                    (a: number, b: number) => {
                      return a + b
                    },
                    0,
                  )

                  return numberFormat(parseFloat(total), 2, 2, '$')
                },
              },
            },
            size: '85%',
          },
        },
      },
      stroke: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    }
  }, [data])

  if (!data || data.length === 0) {
    return <></>
  }

  return <Chart options={options} series={series} type="donut" width={'100%'} />
}

export default React.memo(TokenAllowcationChart)
