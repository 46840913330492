import { darken, styled } from '@mui/material/styles'

export default styled('button')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: '#FFFFFF',
  border: `1px solid #1D1F21`,
  padding: '10px',
  cursor: 'pointer',
  borderRadius: '24px',
  transition: 'all 0.25s ease',
  fontFamily: 'Inter',
  fontSize: '15px',
  height: 35,
  width: '100%',
  '&:hover': {
    color: '#FFFFFF',
    border: `1px solid #313335`,
    backgroundColor: '#0F1113',
  },
  '&:disabled': {
    color: '#767676',
    cursor: 'not-allowed',
    backgroundColor: '#0F1113',
  },
}))
