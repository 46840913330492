import { SetStateAction, useCallback, useEffect, useState } from 'react'
import NoDataTable from '../NoDataTable'
import NoDataBox from '../NoDataBox'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import ClaimTable from '../ClaimTable'
import SkeletonForClaimTable from '../SkeletonForClaimTable'
import { useAppSelector } from '../../../../hooks'
import { useAccount } from 'wagmi'
import { IResponseUserPortalItem, useFetchUserParticipationsClaimQuery } from '../../../../services/modules/participationsV2'
import { useLocation } from 'react-router-dom'

interface LocationState {
  activeTab?: ETabsName
}

export enum ETabsName {
  IDOs = 'IDOs',
  Claim = 'Claim',
  Referral = 'Referrals',
}

const claimHeaders = [
  'Project',
  // 'Next Unlock',
  'Chain',
  'Status',
  'Ticket Size',
  // 'Allocation',
  // 'Distribution',
  // 'Claimable',
  // 'Claimed',
  'Action',
]

type ClaimTableComponentProps = {
  keyword: string,
  isVisible: boolean,
  isRefresh: boolean,
}

const ClaimTableContainer = (props: ClaimTableComponentProps) => {
  const { keyword, isVisible, isRefresh } = props
  const theme = useTheme()
  const limit = 5
  const location = useLocation()
  const { state } = location as { state: LocationState | null }
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown1536 = useMediaQuery('(max-width:1536px)')
  const [page, setPage] = useState(1)
  const [data, setData] = useState<IResponseUserPortalItem[]>([])
  const [debouncedSearchName, setDebouncedSearchName] = useState('')
  const [activeTab, setActiveTab] = useState(ETabsName.IDOs)
  const user = useAppSelector(state => state.user)
  const { accessTokenGoogle, mainAppToken } = user
  const { address } = useAccount()
  const [isShowButton, setIsShowButton] = useState(false)

  // Fetch user paticipate sales
  const {
    data: fetchedData,
    isLoading,
    isFetching,
    refetch: fetchUserParticipationClaim,
  } = useFetchUserParticipationsClaimQuery(
    { page, limit, name: keyword || undefined, },
    {
      skip: !address && !mainAppToken && !accessTokenGoogle,
    },
  )

  useEffect(() => {
    if (mainAppToken && address || accessTokenGoogle) {
      setPage(1)
      fetchUserParticipationClaim();
    }
  }, [keyword])

  useEffect(() => {
    if (!isVisible || isRefresh) {
      setPage(1)
    }

    if (isRefresh) {
      fetchUserParticipationClaim()
    }
  }, [isVisible, isRefresh])

  // Concat data when load more
  useEffect(() => {
    if (fetchedData?.data && (address || accessTokenGoogle)) {
      if (page === 1) {
        setData(fetchedData.data)
      } else {
        setData(prevData => [...prevData, ...fetchedData.data])
      }
    }
  }, [address, accessTokenGoogle, fetchedData])

  useEffect(() => {
    if (mainAppToken && address || accessTokenGoogle) {
      fetchUserParticipationClaim()
    } else {
      setData([])
    }
  }, [mainAppToken, address, fetchUserParticipationClaim])

  useEffect(() => {
    if (state?.activeTab) {
      setActiveTab(state.activeTab)
    }
  }, [state])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchName(keyword)
    }, 700)
    return () => {
      clearTimeout(handler)
    }
  }, [keyword])

  useEffect(() => {
    if (debouncedSearchName) {
      if (fetchedData && fetchedData?.meta?.total_records <= 5) {
        setPage(1)
        setIsShowButton(true)
      } else {
        setIsShowButton(false)
      }

      fetchUserParticipationClaim()
    } else {
      setIsShowButton(false)
    }
  }, [
    activeTab,
    debouncedSearchName,
    fetchUserParticipationClaim,
  ])

  const handleShowMore = (isCollapes = false) => {
    if (isCollapes) {
      setPage(1)
      return;
    }

    if (Number(fetchedData?.meta?.total_records) > data.length) {
      setPage(p => p + 1)
    } else {
      setPage(1)
    }
  }

  const refetchCurrentData = useCallback(() => {
    fetchUserParticipationClaim()
  }, [fetchUserParticipationClaim]);

  return (
    <Box
      width={'100%'}
      alignSelf={'center'}
      display={'flex'}
      flexDirection={'column'}
      sx={{
        [theme.breakpoints.between(1024, 1300)]: {
          // maxWidth: '800px',
        },
        [theme.breakpoints.between(1300, 1367)]: {
          maxWidth: '880px',
        },
        [theme.breakpoints.between(1368, 1630)]: {
          // maxWidth: '996px',
          maxWidth: '1140px',
        },
        [theme.breakpoints.between(1631, 1826)]: {
          maxWidth: '1200px',
        },
      }}
    >
      {isLoading ? (
        <SkeletonForClaimTable />
      ) : data?.length ? (
        <ClaimTable
          isShowButton={isShowButton}
          meta={fetchedData?.meta}
          claimSales={data}
          handleShowMore={handleShowMore}
          refetchCurrentData={refetchCurrentData}
          isFetching={isFetching}
        />
      ) : (
        <>
          {!isDown1000 ? (
            <NoDataTable headers={claimHeaders} />
          ) : (
            <NoDataBox />
          )}
        </>
      )}
    </Box>
  )
}

export default ClaimTableContainer;
