import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserPortalState {
  isOpenInvest: boolean
  projectSelected: { [key: string]: any }
}

const initialState: UserPortalState = {
  isOpenInvest: false,
  projectSelected: {},
}

export const userPortalSlice = createSlice({
  name: 'user-portal',
  initialState,
  reducers: {
    setOpenInvestModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenInvest = action.payload
      if (!action.payload) {
        state.projectSelected = {}
      }
    },
    setProjectSelected: (state, action: PayloadAction<object>) => {
      state.projectSelected = action.payload
    },
  },
})

export const {
  setOpenInvestModal,
  setProjectSelected,
} = userPortalSlice.actions

export default userPortalSlice.reducer
