export const KEY_READ_NOTIFICATIONS_NO_AUTH = 'read-ids-no-auth'
export const KEY_READ_NOTIFICATIONS_AUTH = 'read-ids-auth'

/**
 * @param id 
 */
export const storeReadNotifications = (id: any, key = KEY_READ_NOTIFICATIONS_NO_AUTH) => {
  let readIds = getReadNotifications(key);
  if (readIds && readIds.includes(id)) return;

  readIds = readIds.concat([id]);

  localStorage.setItem(key, JSON.stringify(readIds || []))
}

/**
 * Get read notification ids from storage
 * @returns array
 */
export const getReadNotifications = (key = KEY_READ_NOTIFICATIONS_NO_AUTH) => {
  let ids = localStorage.getItem(key)
  if (!ids) return [];

  return JSON.parse(ids || '[]') || [];
}
