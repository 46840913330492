import GreenButton from "../../../../components/GreenButton"
import { FilledBlackArrow } from '../../../../assets'
import { useCallback, useMemo, useState } from "react"
import _ from 'lodash'
import { Contract, ethers } from 'ethers'
import { AbiFunction } from 'viem'
import { useAccount } from 'wagmi'
import { switchChain } from '@wagmi/core'
import { useEthersSigner, wagmiConfig } from '../../../../components/Web3Provider'
import { CONTRACT_ADDRESS, MAIN_CHAIN_ID, RPC_URL, CHAIN_ID, RPC_PROVIDER } from '../../../../constant'
import InvestmentUnifiedAbi from '../../../../contracts/ape-investment-unified.json'
import VestingUnifiedAbi from '../../../../contracts/ape-vesting-unified.json'
import { getChainIdFromName } from '../../../../utils/index'
import toaster from 'react-hot-toast'
import { getExceptionMessage, getChainName } from '../../../../utils/index'
import refundUserByProject from '../../../../contracts/refund-user.json'
import ApeRefundProofGeneratorAbi from '../../../../contracts/ape-refund-proof-generator.json'
import RefundUnifiedAbi from '../../../../contracts/ape-refund-unified.json'

const APE_INVESTMENT_UNIFIED_ABI: AbiFunction[] = InvestmentUnifiedAbi as AbiFunction[]
const REFUND_UNIFIED_ABI: AbiFunction[] = RefundUnifiedAbi as AbiFunction[]

const PONDER_PROJECT_OID = '65f1c302ee95544c6558f145'
const MICROGPT_PROJECT_OID = '6735e623d93397346746b482'
const RWA_INC_OID = '673de40392f33ef5f26c1387'
const BRAVO_READY_OID = '673f2e402eda3206ccc98a3b'


const RefundButton = ({sale, onRefundSuccess}: any) => {
  const netWork = sale?.idoClaimNetwork || sale?.tokenNetwork
  const claimChainId: number | undefined = getChainIdFromName(netWork)
  const claimContract = sale?.idoClaimContract || CONTRACT_ADDRESS.APE_VESTING_UNIFIED[claimChainId!]
  const claimProjectId = Number(sale?.projectVestingId) || 0
  const investmentProjectId = Number(sale?.investmentProjectId) || Number(sale?.projectContractId) || 0
  const refundProjectId = Number(sale?.refundProjectId) || Number(sale?.projectClaimId) || 0
  // refund always on BSC
  const refundChainId: number = MAIN_CHAIN_ID

  const [isLoading, setIsLoading] = useState(false)
  const { address, chainId } = useAccount()
  const signerBSC = useEthersSigner({ chainId: MAIN_CHAIN_ID })

  const projectOid = _.get(sale, 'project._id')

  const iconStartFnDisable = useCallback(
    () => (
      <img
        src={FilledBlackArrow} alt="filled black arrow" 
        style={{ marginRight: '8px', marginLeft: '-4px' }}
      />
    ),
    [],
  )

  const onClaimRefund = async () => {
    if (!claimContract || !(claimProjectId >= 0)) {
      console.error('No vesting contract has been set')
      return
    }

    if (!claimChainId) throw new Error('claimChainId is not set')

    try {
      setIsLoading(true)
      let proof: any

      if (
        projectOid === PONDER_PROJECT_OID ||
        projectOid === MICROGPT_PROJECT_OID
      ) {
        const refundProofGenerator =
          '0xc57b048deac276aa6a1d267d06d24af226fc913c'
        const refundUserList: string[] = (
          refundUserByProject as Record<string, string[]>
        )[projectOid]
        const userIndex = refundUserList.findIndex(addr => addr === address)
        const proofGeneratorContract = new ethers.Contract(
          refundProofGenerator,
          ApeRefundProofGeneratorAbi,
          RPC_PROVIDER[CHAIN_ID.ETH],
        )
        proof = await proofGeneratorContract.getRefundProof(
          refundUserList,
          userIndex,
        )
      } else if (projectOid === BRAVO_READY_OID) {
        let addressContract: string = CONTRACT_ADDRESS.APE_INVESTMENT_UNIFIED[MAIN_CHAIN_ID]
        if ([PONDER_PROJECT_OID, MICROGPT_PROJECT_OID, RWA_INC_OID, BRAVO_READY_OID].includes(projectOid)) {
          addressContract = '0x597b53a550e788cd7fbfb6f281dade989c107293'
        }

        const apeInvestmentUnifiedContract = new ethers.Contract(
          addressContract,
          // @ts-ignore
          APE_INVESTMENT_UNIFIED_ABI,
          RPC_PROVIDER[MAIN_CHAIN_ID],
        )

        // bravo ready is a cancelled project so every one get refund
        proof = await apeInvestmentUnifiedContract[
          'getInvestmentProof(uint256,address)'
        ](investmentProjectId, address)
      } else {
        const claimContractInstance = new Contract(
          claimContract,
          VestingUnifiedAbi,
          RPC_PROVIDER[claimChainId],
        )

        proof = await claimContractInstance['getRefundProof(uint256,address)'](
          claimProjectId,
          address,
        )
      }
      // Refund always on BSC
      if (chainId !== refundChainId) {
        toaster.error(`Please switch network to ${getChainName(refundChainId)}`)
        await switchChain(wagmiConfig, { chainId: MAIN_CHAIN_ID })
        setIsLoading(false)
        return
      }
      const refundContract = new ethers.Contract(
        CONTRACT_ADDRESS.APE_REFUND_UNIFIED[MAIN_CHAIN_ID],
        REFUND_UNIFIED_ABI as any[],
        signerBSC,
      )

      const tx = await refundContract['claimRefundToken(uint256,bytes32[])'](refundProjectId,proof)
      if (!tx) {
        setIsLoading(false)

        return false;
      }

      await tx.wait(2)
      setIsLoading(false)
      onRefundSuccess(true)
    } catch (error) {
      console.error('refundClaim error', error)
      toaster.error(getExceptionMessage(error))
      setIsLoading(false)
      onRefundSuccess(false)
    }
  }

  return (
    <>
      <GreenButton
        fs={15}
        lh="22.5px"
        fm="Inter"
        fw={400}
        br="24px"
        h="32px"
        w="fit-content"
        border={'1px solid #08493C'}
        sx={{ gap: '0', background: '#63EA71', opacity: '1' }}
        outlined={false}
        IconStart={iconStartFnDisable}
        DisabledIcon={iconStartFnDisable}
        isLoading={isLoading}
        disabled={isLoading}
        onClick={e => {
          e.stopPropagation()
          onClaimRefund()
        }}
        content={
          <span
            style={{
              fontSize: '12px',
              textTransform: 'none',
              color: '#000'
            }}
          >
            Claim Refund
          </span>
        }
      />
    </>
  )
}

export default RefundButton
