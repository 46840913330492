import { useEffect, useState } from 'react'
import {
  Box,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  debounce,
  styled,
  tableRowClasses,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '../../redux/slices/themeSlice'
import { isValidEthAddress } from '../../utils'
import InputWithLabel from '../../components/InputWithLabel'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { ArrowTopRight, ClipboardIcon, LinkIcon } from '../../assets'
import toast from 'react-hot-toast';

const WinnersTable = ({ winners }: { winners: string[] }) => {
  const theme = useTheme()
  const isDown570 = useMediaQuery('(max-width:570px)')
  const isDown600 = useMediaQuery('(max-width:600px)')
  
  interface HeadCell {
    id: string
    label: string
  }
  
  const StyledBodyTableCell = styled(TableCell)({
    height: '56px',
    borderBottom: '1px solid #1D1F21',
    color: '#B1B4B3',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    flexShrink: 1,
    whiteSpace: 'nowrap',
    padding: '0px 20px',
    lineHeight: '15px',
    alignItems: 'center',
  })
  
  const headCells: HeadCell[] = [
    {
      id: 'address',
      label: 'Address',
    },
    {
      id: 'url',
      label: 'Review Wallet',
    },
  ]
  
  const dispatch = useDispatch()
  
  const itemsPerPage = 20
  const [currentPage, setCurrentPage] = useState(1)
  const [rowHovered, setRowHovered] = useState(-1)
  
  const handleMouseEnter = debounce((index: number) => {
    setRowHovered(index)
  }, 100)
  
  const handleMouseLeave = debounce(() => {
    setRowHovered(-1)
  }, 100)
  
  const totalPages = Math.ceil(winners.length / itemsPerPage)
  
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  
  const [currentItems, setCurrentItems] = useState(
    winners.slice(startIndex, endIndex),
  )
  
  useEffect(() => {
    if (winners.length === 0) return
    
    setCurrentItems(winners.slice(startIndex, endIndex))
  }, [winners, startIndex, endIndex])
  console.log('winners', winners)
  
  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
  }
  
  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(1, prevPage - 1))
  }
  
  const handleEtherscanClick = (address: string) => {
    const url = `https://debank.com/profile/${address}`
    
    window.open(url, '_blank', 'noopener,noreferrer')
  }
  
  const [searchedAddress, setSearchedAddress] = useState('')
  
  const handleSearchedAddress = () => {
    // if searched address is not valid
    if (!searchedAddress || !isValidEthAddress(searchedAddress)) {
      dispatch(
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Please enter a valid address',
        }),
      )
      return
    }
    
    // find address in winners array
    const index = winners.findIndex(
      winner => winner.toLowerCase() === searchedAddress.toLowerCase(),
    )
    
    if (index === -1) {
      dispatch(
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Address not found',
        }),
      )
      return
    }
    
    setCurrentItems([winners[index]])
  }
  
  // show all results if search is empty
  useEffect(() => {
    if (!searchedAddress) {
      setCurrentItems(winners.slice(startIndex, endIndex))
    }
  }, [searchedAddress])
  
  return (
    <Box
      width={'100%'}
      marginTop={'8px'}
      bgcolor={'#000000'}
      border={'1px solid #1D1F21'}
      borderRadius={'12px'}
    >
      <Box
        sx={{
          padding: '13px',
          paddingX: '20px',
          borderRadius: '12px',
          display: 'flex',
          flexDirection: isDown600 ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isDown600 ? 'flex-start' : 'center',
        }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={isDown600 ? '100%' : 'fit-content'}
        >
          <Typography
            fontSize={'15px'}
            fontWeight={400}
            lineHeight={'22.5px'}
            fontFamily={'Inter'}
            color="#B1B4B3"
          >
            Winners
          </Typography>{' '}
          {!isDown600 ? (
            <InputWithLabel
              value={searchedAddress}
              onChange={setSearchedAddress}
              placeholder="Search Wallet"
              bgColor="#1D1F21"
              color="#767676"
              caretColor="#767676"
              fm="Inter"
              mb={0}
              sx={{
                ml: '10px',
              }}
              fs={15}
              fw={400}
              w={'297px'}
              br="44px"
              lh="22.5px"
              h="38px"
              px="8px"
              onAdornmentClick={handleSearchedAddress}
            />
          ) : (
            <PaginatorBox
              startIndex={startIndex}
              endIndex={endIndex}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              currentPage={currentPage}
              winners={winners}
            />
          )}
        </Box>
        {!isDown600 && (
          <PaginatorBox
            startIndex={startIndex}
            endIndex={endIndex}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            currentPage={currentPage}
            winners={winners}
          />
        )}
        {isDown600 && (
          <InputWithLabel
            value={searchedAddress}
            onChange={setSearchedAddress}
            placeholder="Search Wallet"
            bgColor="#1D1F21"
            color="#767676"
            caretColor="#767676"
            fm="Inter"
            mb={0}
            fs={15}
            fw={400}
            w={'100%'}
            br="44px"
            lh="22.5px"
            h="38px"
            px="8px"
            onAdornmentClick={handleSearchedAddress}
          />
        )}
      </Box>
      <Box
        width={'100%'}
        maxWidth={`${window.innerWidth - 40}px`}
        display={'flex'}
        flexDirection={'column'}
        paddingLeft={'0px'}
        paddingTop={'0px'}
      >
        <TableContainer
          sx={{
            border: '1px solid #1D1F21',
            borderRadius: '12px',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            boxShadow: 'none',
            maxHeight: '440px',
            backgroundImage: 'none',
            flexShrink: 1,
            overflowX: 'auto',
            width: '100%',
            '&::-webkit-scrollbar': {
              display: 'flex',
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#313335',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#0F1113',
            },
          }}
        >
          <Table stickyHeader sx={{ height: '100%', flexShrink: 1 }}>
            <TableHead>
              <TableRow>
                {headCells.map(headCell => (
                  <TableCell
                    key={headCell.id}
                    sx={{
                      backgroundColor: '#00000',
                      textAlign: 'left',
                      color: '#767676',
                      fontFamily: 'Inter',
                      fontWeight: 400,
                      borderBottom: 'none',
                      height: '48px',
                      padding: '0px 20px',
                    }}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                overflowY: 'hidden',
                overflowX: 'auto',
                backgroundColor: '#0F1113',
              }}
            >
              {currentItems.map((item: any, index: number) => (
                <TableRow
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#000000',
                    },
                    [`&.${tableRowClasses.root}`]: {
                      height: '56px',
                      maxHeight: '56px',
                    },
                  }}
                  key={index}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <StyledBodyTableCell>
                    <Grid
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                    >
                      <img
                        src={LinkIcon}
                        style={{
                          marginRight: '8px',
                          height: '16px',
                          width: '16px',
                        }}
                        onClick={e => {
                          e.stopPropagation()
                        }}
                        alt="link icon"
                      />
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '14px',
                          lineHeight: '16.8px',
                          color: '#B1B4B3',
                        }}
                      >
                        {isDown600 ? item?.slice(0, 10) + '...' : item}
                      </Typography>
                      <CopyToClipboard
                        text={item}
                        onCopy={() => toast.success('Copied')}
                      >
                        <img
                          src={ClipboardIcon}
                          style={{
                            marginLeft: '8px',
                            height: '16px',
                          }}
                          onClick={e => {
                            e.stopPropagation()
                          }}
                          alt="clipboard icon"
                        />
                      </CopyToClipboard>
                    </Grid>
                  </StyledBodyTableCell>
                  <StyledBodyTableCell
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Chip
                      label={'Debank'}
                      onClick={() => {
                        handleEtherscanClick(item)
                      }}
                      sx={{
                        height: '24px',
                        backgroundColor: '#1D1F21',
                        '& .MuiChip-label': {
                          textTransform: 'none',
                          fontFamily: 'Inter',
                          fontStyle: 400,
                          fontSize: '14px',
                          color: '#7E8180',
                        },
                      }}
                    />
                    
                    <img
                      src={ArrowTopRight}
                      style={{
                        marginLeft: '8px',
                        height: '24px',
                        position: 'relative',
                        visibility: rowHovered === index ? 'visible' : 'hidden',
                      }}
                      onClick={e => {
                        handleEtherscanClick(item)
                      }}
                      alt="arrow top right"
                    />
                  </StyledBodyTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

interface IPaginatorBox {
  currentPage: number
  endIndex: number
  startIndex: number
  handlePrevPage: () => void
  handleNextPage: () => void
  winners: string[]
}

const PaginatorBox: React.FC<IPaginatorBox> = ({
                                                 startIndex,
                                                 endIndex,
                                                 handleNextPage,
                                                 handlePrevPage,
                                                 currentPage,
                                                 winners,
                                               }) => {
  return (
    <Grid
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      borderRadius={'32px'}
      bgcolor={'#000000'}
      height={'46px'}
    >
      <Typography
        fontSize={14}
        fontWeight={400}
        lineHeight={'16.8px'}
        fontFamily={'Inter'}
        color="#767676"
      >
        {startIndex + 1} -{' '}
        {endIndex > winners.length ? winners.length : endIndex} of{' '}
        {winners.length}
      </Typography>
      <KeyboardArrowLeftIcon
        onClick={() => (currentPage > 1 ? handlePrevPage() : null)}
        sx={{
          cursor: 'pointer',
          height: '24px',
          color: '#767676',
          opacity: currentPage > 1 ? 1 : 0.5,
        }}
      />
      <KeyboardArrowRightIcon
        onClick={() =>
          currentPage < Math.ceil(winners.length / 20) ? handleNextPage() : null
        }
        sx={{
          cursor: 'pointer',
          height: '24px',
          color: '#767676',
          opacity: currentPage < Math.ceil(winners.length / 20) ? 1 : 0.5,
        }}
      />
    </Grid>
  )
}

export default WinnersTable
