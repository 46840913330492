import { Button, Typography } from "@mui/material"
import { FilledBlackArrow } from '../../../../assets';

type Props = {
  onClick: (event: any) => void
}
export const InvestButton = (props: Props) => {
  const { onClick } = props;

  return (
    <Button
      variant="greenContainer"
      startIcon={<img src={FilledBlackArrow} alt="filled black arrow" />}
      sx={{
        maxWidth: '120px',
        borderRadius: '24px',
        height: '32px',
        display: 'flex',
        '&:hover': {
          opacity: 0.8,
        },
      }}
      onClick={onClick}
    >
      <Typography
        fontWeight={400}
        fontSize={'12px'}
        lineHeight={'16px'}
        color="#000"
        fontFamily={'Inter'}
      >
        Invest
      </Typography>
    </Button>
  )
}
