export const VerifiedIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVG">
        <path
          id="Vector"
          d="M5 8.01001L7.5 10.51L11.5 6.51001"
          stroke="#63EA71"
          stroke-width="1.6"
        />
        <path
          id="Vector_2"
          d="M8 15.51C11.866 15.51 15 12.376 15 8.51001C15 4.64402 11.866 1.51001 8 1.51001C4.13401 1.51001 1 4.64402 1 8.51001C1 12.376 4.13401 15.51 8 15.51Z"
          stroke="#63EA71"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}
