import { Button, Typography } from '@mui/material'
import { FilledBlackArrow } from '../../../../assets'

type ViewClaimButtonProps = {
  onClick?: (event: any) => void
  disabled?: boolean
  text?: string
}

export const ViewClaimButton = (props: ViewClaimButtonProps) => {
  const { onClick, disabled, text = 'Claim' } = props
  return (
    <Button
      variant="greenContainer"
      // startIcon={<img src={FilledBlackArrow} alt="filled black arrow" />}
      sx={{
        maxWidth: '120px',
        borderRadius: '24px',
        height: '32px',
        border: disabled ? '1px solid transparent' : '1px solid #63EA71',
        display: 'flex',
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography
        fontWeight={600}
        fontSize={'12px'}
        lineHeight={'16px'}
        color="#000"
        fontFamily={'Inter'}
        textTransform="capitalize"
      >
        {text}
      </Typography>
    </Button>
  )
}
