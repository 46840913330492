//@ts-nocheck
import bscIcon from "../assets/revamp/images/bsc.png";
import ethereumIcon from "../assets/revamp/icons/eth_logo.svg";
import polygonIcon from "../assets/revamp/images/tokens/Polygon.png";
import solanaIcon from "../assets/revamp/icons/solana-active.svg";

export const ERROR_CODE = {
  USER_REJECT: 4001,
};

export const NETWORKS_NAME = {
  ETHEREUM: "Ethereum",
  BSC: "BSC",
  POLYGON: "Polygon",
  SOLANA: "Solana",
};

export const NETWORKS_CURRENCY = {
  ETHEREUM: "ETH",
  BSC: "BNB",
  POLYGON: "POL",
  SOLANA: "SOL",
}

export const METAMASK_DEEPLINK = '';
// export const METAMASK_DEEPLINK = import.meta?.env?.VITE_APP_METAMASK_DEEPLINK || '';
export const PHANTOM_DEEPLINK = '';
// export const PHANTOM_DEEPLINK = import.meta?.env?.VITE_APP_PHANTOM_DEEP_LINK || '';

export const PROJECT_ID = '';
// export const PROJECT_ID = import.meta?.env?.VITE_APP_PROJECT_ID || '';

// RPC network
export const ETH_RPC_URL = '';
// export const ETH_RPC_URL = import.meta?.env?.VITE_APP_NETWORK_URL || '';
export const POLYGON_RPC_URL = '';
// export const POLYGON_RPC_URL = import.meta?.env?.VITE_APP_POLYGON_RPC_URL || '';
export const BSC_RPC_URL = '';
// export const BSC_RPC_URL = import.meta?.env?.VITE_APP_BSC_RPC_URL || '';

// Blockchain scan url
export const ETHER_SCAN_BASE_URL = '';
// export const ETHER_SCAN_BASE_URL = import.meta?.env?.VITE_APP_ETHER_SCAN_BASE_URL || '';
export const BSC_SCAN_BASE_URL = '';
// export const BSC_SCAN_BASE_URL = import.meta?.env?.VITE_APP_BSC_SCAN_BASE_URL || '';
export const POL_SCAN_BASE_URL = '';
// export const POL_SCAN_BASE_URL = import.meta?.env?.VITE_APP_POL_SCAN_BASE_URL || '';
export const SOL_SCAN_URL = '';
// export const SOL_SCAN_URL = import.meta?.env?.VITE_APP_SOL_SCAN_URL || '';
export const SOLANA_ENV = '';
// export const SOLANA_ENV = import.meta?.env?.VITE_APP_NETWORK_SOLANA_CLUSTER || '';

// ntf contract address
export const BSC_NFT_ADDRESS = '';
// export const BSC_NFT_ADDRESS = import.meta?.env?.VITE_APP_BSC_NFT_ADDRESS || '';

// chainID network
export const ETH_CHAIN_ID = '1';
// export const ETH_CHAIN_ID = import.meta?.env?.VITE_APP_ETH_CHAIN_ID || '';
export const POLYGON_CHAIN_ID = '2';
// export const POLYGON_CHAIN_ID = import.meta?.env?.VITE_APP_POLYGON_CHAIN_ID || '';
export const BSC_CHAIN_ID = '3';
// export const BSC_CHAIN_ID = import.meta?.env?.VITE_APP_BSC_CHAIN_ID || '';
export const SOLANA_CHAIN_ID = '4';
// export const SOLANA_CHAIN_ID = import.meta?.env?.VITE_APP_SOLANA_CHAIN_ID || '';

// token USDT Addres
export const USDT_ETH_ADDRESS = '';
// export const USDT_ETH_ADDRESS = import.meta?.env?.VITE_APP_USDT_SMART_CONTRACT || '';
export const USDT_POLYGON_ADDRESS = '';
// export const USDT_POLYGON_ADDRESS = import.meta.env.VITE_APP_USDT_POLYGON_SMART_CONTRACT;
export const USDT_BSC_ADDRESS = '';
// export const USDT_BSC_ADDRESS = import.meta.env.VITE_APP_USDT_BSC_SMART_CONTRACT;
export const USDT_SOLANA_ADDRESS = '';
// export const USDT_SOLANA_ADDRESS = import.meta.env.VITE_APP_SMART_CONTRACT_SOLANA_USDT_ADDRESS;

// token USDC Addres
export const USDC_ETH_ADDRESS = '';
// export const USDC_ETH_ADDRESS = import.meta?.env?.VITE_APP_USDC_SMART_CONTRACT || '';
export const USDC_POLYGON_ADDRESS = '';
// export const USDC_POLYGON_ADDRESS = import.meta.env.VITE_APP_USDC_POLYGON_SMART_CONTRACT;
export const USDC_BSC_ADDRESS = '';
// export const USDC_BSC_ADDRESS = import.meta.env.VITE_APP_USDC_BSC_SMART_CONTRACT;
export const USDC_SOLANA_ADDRESS = '';
// export const USDC_SOLANA_ADDRESS = import.meta.env.VITE_APP_SMART_CONTRACT_SOLANA_USDC_ADDRESS;

export const networks = {
  ethereum: {
    chainId: ETH_CHAIN_ID,
    networkName: NETWORKS_NAME.ETHEREUM,
    icon: ethereumIcon,
  },
  bsc: {
    chainId: BSC_CHAIN_ID,
    networkName: NETWORKS_NAME.BSC,
    icon: bscIcon,
  },
  polygon: {
    chainId: POLYGON_CHAIN_ID,
    networkName: NETWORKS_NAME.POLYGON,
    icon: polygonIcon,
  },
  Solana: {
    chainId: SOLANA_CHAIN_ID,
    networkName: NETWORKS_NAME.SOLANA,
    icon: solanaIcon,
  },
};

export const getUSDTAddress = (chainId) => {
  switch (chainId) {
    case SOLANA_CHAIN_ID:
      return USDT_SOLANA_ADDRESS;

    case POLYGON_CHAIN_ID:
      return USDT_POLYGON_ADDRESS;

    case BSC_CHAIN_ID:
      return USDT_BSC_ADDRESS;

    case ETH_CHAIN_ID:
      return USDT_ETH_ADDRESS;

    default:
      return USDT_BSC_ADDRESS;
  }
};

export const getUSDCAddress = (chainId) => {
  switch (chainId) {
    case SOLANA_CHAIN_ID:
      return USDC_SOLANA_ADDRESS;

    case POLYGON_CHAIN_ID:
      return USDC_POLYGON_ADDRESS;

    case BSC_CHAIN_ID:
      return USDC_BSC_ADDRESS;

    case ETH_CHAIN_ID:
      return USDC_ETH_ADDRESS;

    default:
      return USDC_BSC_ADDRESS;
  }
};

export const getCurrency = (chainId) => {
  switch (chainId) {
    case POLYGON_CHAIN_ID:
      return NETWORKS_CURRENCY.POLYGON;

    case BSC_CHAIN_ID:
      return NETWORKS_CURRENCY.BSC;

    case ETH_CHAIN_ID:
      return NETWORKS_CURRENCY.ETHEREUM;

    default:
      return "";
  }
};