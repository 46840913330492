import { Button, Typography } from "@mui/material"
import { FilledBlackArrow } from '../../../../assets';

type ClaimButtonDisabledProps = {
  label?: string
}

const ClaimButtonDisabled = (props: ClaimButtonDisabledProps) => {
  const { label = 'Claim' } = props;

  const handleOnclick = (event: any) => {
    event.stopPropagation();
  }

  return (
    <Button
      variant="greenContainer"
      startIcon={<img src={FilledBlackArrow} alt="filled black arrow" />}
      sx={{
        borderRadius: '24px',
        height: '32px',
        display: 'flex',
        cursor: 'not-allowed',
        '&:hover': {
          opacity: 0.8,
        },
      }}
      disabled
      onClick={handleOnclick}
    >
      <Typography
        fontWeight={400}
        fontSize={'12px'}
        lineHeight={'16px'}
        color="#000"
        fontFamily={'Inter'}
      >
        { label }
      </Typography>
    </Button>
  )
}

export default ClaimButtonDisabled;
