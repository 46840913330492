import React, { useCallback, useEffect, useState } from 'react'
import { getChainIdFromName } from '../../utils'
import GeneralClaim from './GeneralClaim'
import InspectClaim from './InspectClaim'
import ChainChangeModal from '../../components/ChainChangeModal'
import { useAccount } from 'wagmi'
import GreenButton from '../../components/GreenButton'
import { GreenFilledArrow } from '../../assets'
import _ from 'lodash'

const ClaimWrapper = ({ idoData, project, closeClaimTab }: any) => {
  const { chain, address } = useAccount()

  const [showChainChangeModal, setShowChainChangeModal] = useState(false)

  let chainId: number | undefined = getChainIdFromName(idoData?.claimNetwork || idoData?.project?.token?.network)
  const isInspectIdo = idoData._id === '6554d8e0bf282065fe95f222'
  const claimNetwork = idoData?.claimNetwork
  const claimUrl = idoData?.claimUrl
  const isSolanaIdo = claimNetwork === 'Solana'

  const handleChainChange = () => {
    setShowChainChangeModal(true)
  }

  useEffect(() => {
    if(chainId){
      if (chain?.id !== chainId && address) {
        handleChainChange()
      } else {
        setShowChainChangeModal(false)
      }
    }
  }, [address, chain, chainId])

  const iconStartFn = useCallback(
    () => (
      <img
        src={GreenFilledArrow}
        style={{ marginRight: '0px' }}
        alt="forward icon"
      />
    ),
    [],
  )

  const solanaClaimPurposeByIndex: Record<number, string> = {
    0: 'TGE',
    1: 'Vesting',
  }

  if (isSolanaIdo) {
    if (_.isArray(claimUrl) && claimUrl.length > 0) {
      return (
        <>
          <div
            style={{
              height: '10rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#0F1113',
              border: '1px solid rgba(255, 255, 255, 0.09)',
              borderRadius: '12px',
              gap: '0.5rem',
            }}
          >
            {claimUrl.map((url: string, index: number) => {
              return (
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <GreenButton
                    fs={15}
                    lh="22.5px"
                    fm="Inter"
                    fw={400}
                    br="24px"
                    IconStart={iconStartFn}
                    text={`Claim ${solanaClaimPurposeByIndex[index] || index + 1}`}
                    disabled={false}
                    mt={3}
                    outlined={true}
                    // w="137px"
                    h="48px"
                    border={'1px solid #08493C'}
                    sx={{
                      background: '#000000 !important',
                      border: '1px solid #08493C !important',
                    }}
                  />
                </a>
              )
            })}
          </div>
        </>
      )
    }
    return (
      <>
        <div
          style={{
            height: '10rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#0F1113',
            border: '1px solid rgba(255, 255, 255, 0.09)',
            borderRadius: '12px',
          }}
        >
          Claim URL is not yet available
        </div>
      </>
    )
  }

  return (
    <>
      {showChainChangeModal && (
        <ChainChangeModal
          open={showChainChangeModal && !!chainId && isInspectIdo}
          handleClose={() => {
            closeClaimTab()
            setShowChainChangeModal(false)
          }}
          chainId={chainId as number}
        />
      )}


      {chain?.id === chainId ? (
        isInspectIdo ? (
          <InspectClaim projectId={project._id} idoData={idoData} />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {!isInspectIdo && (
        <GeneralClaim projectId={project._id} idoData={idoData} project={project} />
      )}
    </>
  )
}

export default ClaimWrapper
