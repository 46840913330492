import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Modal } from 'antd'
import GreenButton from '../GreenButton'

export interface ConfirmModalProps {
  open: boolean
  desc: string
  onClose: () => void
  onConfirm?: () => void
  onCancel?: () => void
  title?: string
  buttonLoading?: boolean
  buttonDisabled?: boolean
}

const ConfirmModal: React.FC<ConfirmModalProps> = props => {
  const {
    open,
    onClose,
    onConfirm,
    onCancel,
    desc,
    title = 'Confirmation',
    buttonLoading = false,
    buttonDisabled = false,
  } = props

  return (
    <Modal
      className="confirm-modal"
      centered
      open={open}
      onCancel={onClose}
      footer={null}
      maskClosable={false}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          padding: '20px',
          borderRadius: '12px',
        }}
      >
        <Box
          sx={{
            color: '#fff',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '48px',
            textAlign: 'center',
          }}
        >
          {title}
        </Box>

        <Typography
          sx={{
            color: '#7E8180',
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '18px',
            textAlign: 'center',
          }}
        >
          {desc}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
          <GreenButton
            fs={15}
            lh="22.5px"
            fm="Inter"
            fw={400}
            br="24px"
            text={'No'}
            onClick={onCancel}
            outlined={true}
            w={'fit-content'}
            textColor={'#B1B4B3'}
            sx={{
              minWidth: '89px',
              height: '28px',
              border: '1px solid #1D1F21',
              padding: '16px 20px',
              backgroundColor: '#1D1F21',
              '&:hover': {
                border: '1px solid #B1B4B3',
                backgroundColor: 'transparent',
              },
            }}
          />
          <GreenButton
            fs={15}
            lh="22.5px"
            fm="Inter"
            fw={400}
            br="24px"
            text={'Yes'}
            onClick={onConfirm}
            outlined={true}
            isLoading={buttonLoading}
            disabled={buttonDisabled}
            w={'fit-content'}
            sx={{
              minWidth: '89px',
              height: '28px',
              border: '1px solid #08493C',
              padding: '16px 20px',
              backgroundColor: '#1D1F21',
              '&:hover': {
                border: '1px solid #27B079',
                backgroundColor: 'transparent',
              },
            }}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmModal
