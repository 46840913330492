import {
  Box,
  Divider,
  Grid,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import {
  IDOMetrics1,
  IDOMetrics2,
  IDOMetrics3,
  IDOMetrics4,
  IDOMetrics5,
  IDOMetrics6,
  NavGreenArrow,
  FirstStep,
  SecondStep,
  ThirdStep,
  StepArrowDown,
} from '../../assets'
import React, { useEffect, useState } from 'react'
import InfoBox from './InfoBox'
import { getReplacedCdnEndpoint } from '../../utils'

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: '8px',
  // [theme.breakpoints.down('smd')]: {
  //   flexDirection: 'column',
  // },
}))

const Heading: React.FC<{
  children: string
  as: 'h1' | 'h2'
  id?: string
  style?: React.CSSProperties
}> = ({ children, id, as: Element, ...props }) => {
  const theId = id || getId(children)
  return (
    <Element
      id={theId}
      {...props}
      style={{
        fontFamily: 'Hellix',
        fontSize: '24px',
        fontWeight: 600,
        color: '#FFFFFF',
        margin: '0px',
        lineHeight: '31.2px',
        paddingBottom: '24px',
        ...props.style,
      }}
    >
      {children}
    </Element>
  )
}

type HeadingType = {
  id: string
  text: string
}
const useHeadings = () => {
  const [headings, setHeadings] = useState<HeadingType[]>([])
  useEffect(() => {
    const elements = Array.from(document.querySelectorAll('h1'))
      .filter(element => element.id)
      .map(element => ({
        id: element.id,
        text:
          element.textContent === 'Terms & Conditions'
            ? 'Terms And Conditions'
            : element.textContent === 'IDO Details'
            ? 'Overview'
            : element.textContent || '',
      }))
    setHeadings(elements)
  }, [])
  return headings
}

const getId = (children: string) => {
  return children
    .split(' ')
    .map(word => word.toLowerCase())
    .join('-')
}

const useScrollSpy = (ids: string[]) => {
  const [activeId, setActiveId] = useState<string>()

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2

      for (let i = ids.length - 1; i >= 0; i--) {
        const section = document.getElementById(ids[i])

        if (section && section.offsetTop <= scrollPosition) {
          setActiveId(ids[i])
          return
        }
      }

      setActiveId(ids[0])
    }

    document.addEventListener('scroll', handleScroll)

    return () => document.removeEventListener('scroll', handleScroll)
  }, [ids])

  return activeId
}

const TableOfContent: React.FC = () => {
  const headings = useHeadings()
  const activeId = useScrollSpy(headings.map(({ id }) => id))
  const [updateActiveHeading, setUpdateActiveHeading] = useState('')
  const isDown1200 = useMediaQuery('(max-width:1200px)')

  const scrollToHeading = (id: string) => {
    const element = document.getElementById(id)

    if (element) {
      const offset = element.offsetTop
      const navHeight = 70

      window.scrollTo({
        top: offset + navHeight,
        behavior: 'smooth',
      })

      setTimeout(() => {
        setUpdateActiveHeading(id)
      }, 200)
    }
  }

  useEffect(() => {
    if (updateActiveHeading) {
      const element = document.getElementById(updateActiveHeading)

      if (element) {
        const offset = element.offsetTop
        const midOfScreen = window.innerHeight / 2

        window.scrollTo({
          top: offset - midOfScreen,
          behavior: 'smooth',
        })
      }
      setUpdateActiveHeading('')
    }
  }, [updateActiveHeading])
  return (
    <Box
      sx={{
        position: 'sticky',
        top: '5em',
        // width: `calc(${window.innerWidth}px - 44px)`,
        maxHeight: '100vh',
        overflowY: 'auto',
        paddingBottom: isDown1200 ? '32px' : '0px',
        minWidth: '300px',
        width: '100%',
      }}
    >
      <Box
        className="toc"
        component={'nav'}
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '& a:hover': {
            color: !isDown1200 ? '#ffffff !important' : null,
          },
          scrollbarWidth: 'none',
          p: 1,
          background: isDown1200 ? '#000000dd' : '',
          borderRadius: '8px',
        }}
      >
        <ul
          style={{
            listStyle: 'none',
            margin: 0,
            padding: 0,
            display: 'flex',
            flexDirection: isDown1200 ? 'row' : 'column',
            gap: isDown1200 ? '16px' : '0px',
          }}
        >
          {headings.map(heading => (
            <li
              key={heading.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '14px',
                paddingBottom: isDown1200 ? '0px' : '12px',
                textWrap: 'nowrap',
              }}
            >
              <img
                src={NavGreenArrow}
                style={{
                  display: isDown1200 ? 'none' : 'block',
                  opacity: activeId === heading.id ? 1 : 0,
                }}
                alt="arrow"
              />
              <a
                onClick={() => scrollToHeading(heading.id)}
                style={{
                  display: 'block',
                  color: activeId === heading.id ? '#FFFFFF' : '#767676',
                  textDecoration: 'none',
                  transition: 'all 0.3s ease-out',
                  fontWeight: 400,
                  fontFamily: 'Inter',
                  fontSize: '15px',
                }}
                href={`#${heading.id}`}
              >
                {heading.text}
              </a>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  )
}

const termsAndConditions = [
  '1. Participants must have at least $200 worth of USDC tokens (EVM chains) in their connected wallet. The more you engage on socials, the greater the chances of winning. Create an account to make the checkout process during the lottery phase smoother.',
  '2. To redeem allocated IDO tokens, participants will need to pay blockchain gas fees in some cases, which may vary due to network conditions.',
  '3. If you have any questions, please submit a ticket on Telegram.',
  '4. In order to be eligible to receive IDO tokens, please check & fulfil all snapshot requirements established by each IDO project.',
  '5. Disclaimer: The information and project data provided above are sourced from the project or public information and are for reference only.',
]

const termsAndConditionsFairlaunch = [
  'Disclaimer: The information and project data provided above are sourced from the project or public information and are for reference only.',
]

const IDODetails = ({
  idoData,
  token,
  timeline,
  totalParticipants,
  isFairlaunch,
}: {
  idoData: any
  token: any
  timeline: any
  totalParticipants: any
  isFairlaunch?: boolean
}) => {
  console.log('🚀 ~ IDODetails ~ timeline:', timeline)
  const isBelow1000 = useMediaQuery('(max-width:1000px)')

  const idoTimeline = [
    {
      phase: 'Registration',
      date: timeline[0]?.subHeading,
      description: [
        'Participants must have at least $200 worth of USDC tokens (EVM chains) in their connected wallet. The more you engage on socials, the greater the chances of winning. Create an account to make the checkout process during the lottery phase smoother.',
      ],
      image: FirstStep,
    },
    {
      phase: 'Snapshot',
      date: timeline[1]?.subHeading,
      description: [
        'Hold min. $200 USDC tokens (EVM chains)',
        `The snapshot will take place at ${timeline[1]?.subHeading}`,
        'Failure to maintain this balance during the Snapshot Period will result in ineligibility.',
      ],
      image: SecondStep,
    },
    {
      phase: 'Contribution',
      date: timeline[2]?.subHeading,
      description: [
        "Ape Launchpad uses a smart contract to randomly select tickets, making it fair for all applicants to win token allocations. At this time, you'll be able to check your participation to see if you're a winner. You can only use USDC to invest. Remember to engage on Twitter/X to increase your chances.",
      ],
      image: ThirdStep,
    },
  ]

  function formatNumber(num: number) {
    if (num === 0) return "0";

    const numStr = num.toString();

    // Very small numbers (less than 0.000001)
    if (num > 0 && num < 0.000001) {
        return "0.000000...";
    }

    // Numbers that should truncate after 9 significant characters
    if (numStr.includes('.')) {
        let numArr = numStr.split('.')
        return numArr[0] + '.' + numArr[1].slice(0,6) + `${numArr[1].length > 6 ? '...' : ''}`
    }

    // Numbers between 0.000001 and 1, which also need truncation
    if (num >= 0.000001 && num < 1) {
        return num
    }

    // All other cases
    return numStr;
  }

  const launchPrice = () => {
    const { amountToRaise, totalWinners, TGEAmount, vestingAmount } = idoData || {};
    const totalPlatformRaise = Number(TGEAmount || 0) + Number(vestingAmount || 0);
    if (!totalPlatformRaise) return '0';

    let presaleTokenPrice = (Number(amountToRaise || 0) / Number(totalWinners || 0)) / totalPlatformRaise
    if (!presaleTokenPrice) {
      return 'Launch Drop'
    }

    return presaleTokenPrice;
  }

  const isLegacyProject = idoData?.project?.isLegacyProject

  const legacyPresalePrice = () => {
    return idoData?.presaleTokenPrice === -1 ? 'Launch Drop' : idoData?.presaleTokenPrice
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={3}
        sx={{
          position: 'sticky',
          top: '1rem',
          // backgroundColor: '#000000',
          // height: '55px',
        }}
      >
        <TableOfContent />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={9}>
        <article style={{ maxWidth: '100%' }}>
          <Heading as="h1">
            {isFairlaunch ? 'Fair Launch Details' : 'IDO Details'}
          </Heading>
          <StyledBox>
            <InfoBox
              imageSrc={IDOMetrics1}
              headingText={isFairlaunch ? 'Initial Price' : 'Launch Price'}
              // headingVal={
              //   idoData?.presaleTokenPrice === -1
              //     ? 'Launch Drop'
              //     : idoData?.presaleTokenPrice
              // }
              headingVal={legacyPresalePrice()}
              // tooltipHeadingVal={isLegacyProject ? null : launchPrice()}
              spanText={idoData?.presaleTokenPrice === -1 ? '' : 'USDC'}
              hidden={isFairlaunch && !idoData?.presaleTokenPrice}
            />
            <InfoBox
              imageSrc={IDOMetrics2}
              headingText="Total Fund Raising"
              headingVal={
                idoData?.amountToRaise === -1
                  ? 'N/A'
                  : idoData?.amountToRaise?.toLocaleString('en')
              }
              spanText={idoData?.amountToRaise !== -1 ? 'USDC' : ''}
              hidden={isFairlaunch && !idoData.amountToRaise}
            />
          </StyledBox>
          <StyledBox>
            <InfoBox
              imageSrc={IDOMetrics3}
              headingText={isFairlaunch ? 'Total Supply' : 'Total Issued'}
              headingVal={idoData?.totalIssued?.toLocaleString('en')}
              spanText={token.symbol}
            />
            {!isFairlaunch && (
              <InfoBox
                imageSrc={IDOMetrics4}
                headingText="Total Winning Tickets"
                headingVal={idoData?.totalWinners?.toLocaleString('en')}
              />
            )}
          </StyledBox>
          {!isFairlaunch && (
            <StyledBox>
              <InfoBox
                imageSrc={IDOMetrics5}
                headingText="Investment Amount"
                headingVal={
                  idoData?.amountToRaise === -1
                    ? 'Launch Drop'
                    : (
                        idoData?.amountToRaise / idoData?.totalWinners || ''
                      )?.toLocaleString('en')
                }
                spanText={
                  idoData?.amountToRaise === -1 ? '' : 'USDC per Ticket'
                }
              />
              <InfoBox
                imageSrc={IDOMetrics6}
                headingText="Total Participants"
                headingVal={totalParticipants?.toLocaleString('en')}
              />
            </StyledBox>
          )}

          {!isFairlaunch && (
            <>
              <Divider
                sx={{
                  backgroundColor: '#1D1F21',
                  height: '1px',
                  mt: '40px',
                  mb: '48px',
                }}
              />
              <Heading as="h1">IDO Timeline</Heading>
            </>
          )}

          {(isFairlaunch ? [] : idoTimeline).map((item: any, index: number) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '24px',
                mt: index === 0 ? '0px' : '16px',
              }}
              alignItems="stretch"
            >
              <Box
                alignItems="center"
                height={'auto'}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                flexGrow={1}
              >
                <img
                  src={getReplacedCdnEndpoint(item.image)}
                  alt={`${index}`}
                  style={{
                    width: '40px',
                  }}
                />
                <img
                  src={StepArrowDown}
                  alt={`${index}`}
                  style={{
                    display: index !== 2 ? 'flex' : 'none',
                    width: '20px',
                    height: 'auto',
                    //height:"100%",
                    // objectFit: 'cover',
                  }}
                />
              </Box>
              <Box width={'100%'} minHeight={'132px'} marginTop={'6px'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isBelow1000 ? 'column' : 'row',
                    justifyContent: isBelow1000
                      ? 'flex-start'
                      : 'space-between',
                    width: '100%',
                    pb: '6.5px',
                    gap: isBelow1000 ? '0' : '8px',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#FFFFFF',
                      fontFamily: 'Inter',
                      fontSize: '17px',
                      fontWeight: 500,
                      lineHeight: '27.2px',
                    }}
                  >
                    {item.phase}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#7E8180',
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '27.2px',
                    }}
                  >
                    {item.date}
                  </Typography>
                </Box>

                {item.description.length > 1 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexWrap: 'wrap',
                    }}
                  >
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        margin: 0,
                        paddingLeft: '16px',
                        listStyleType: 'disc',
                      }}
                    >
                      {item.description.map((desc: any, index: number) => (
                        <li
                          key={index}
                          style={{
                            color: '#B1B4B3',
                            fontFamily: 'Inter',
                            fontSize: '15px',
                            fontWeight: 400,
                            lineHeight: '24px',
                          }}
                        >
                          {desc}
                        </li>
                      ))}
                    </ul>
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      color: '#B1B4B3',
                      fontFamily: 'Inter',
                      fontSize: '15px',
                      fontWeight: 400,
                      lineHeight: '24px',
                    }}
                  >
                    {item.description}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}

          <Divider
            sx={{
              backgroundColor: '#1D1F21',
              height: '1px',
              mt: '48px',
              mb: '48px',
            }}
          />
          <Heading as="h1">Terms & Conditions</Heading>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
            }}
          >
            {(isFairlaunch
              ? termsAndConditionsFairlaunch
              : termsAndConditions
            ).map((item: any, index: number) => (
              <Typography
                key={index}
                sx={{
                  color: '#B1B4B3',
                  fontFamily: 'Inter',
                  fontSize: '15px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  mb: 1.5,
                }}
              >
                {item}
              </Typography>
            ))}
          </Box>
        </article>
      </Grid>
    </Grid>
  )
}

export default IDODetails
function formatNumber(presaleTokenPrice: number) {
  throw new Error('Function not implemented.')
}

