import { Box, useMediaQuery } from '@mui/system'
import { Typography } from '@mui/material'
import {
  BgUnparalleled,
  BgUnparalleledMobile,
  BuyNow,
  BuyNowMobile,
  OpenAccess,
  OpenAccessMobile,
  Refundable,
  RefundableMobile,
} from '../../assets'


const UnparalleledFeatures: React.FC = () => {
  const isDown1023 = useMediaQuery('(max-width:1023px)')
  const isDown600 = useMediaQuery('(max-width:600px)')

  const features = [
    {
      icon: isDown600 ? OpenAccessMobile : OpenAccess,
      title: 'Open Access',
      description: <><span style={{display: 'block', marginBottom: '12px'}}>Ape Terminal doesn’t require you to stake a launchpad token like other platforms. 
      Join into our refundable sales with zero collateral.</span><span style={{display: 'block'}}>Sign up in less than 60 seconds to 
      access IDOs like AIT, where $1,000 turned to $80,000+.</span></>,
    },
    {
      icon: isDown600 ? BuyNowMobile : BuyNow,
      title: 'Buy Now, Pay Later',
      description: <><span style={{display: 'block', marginBottom: '12px'}}>Opt into a sale. Ape Terminal pays your ticket for you! Watch the token list, 
      if you like the performance, pay your ticket to Ape, and claim the tokens.</span>
      <span style={{display: 'block'}}>If you don’t like the performance, pay nothing.</span>
      <b>Commit. Don’t Pay. Win.</b>
      </>,
    },
    {
      icon: isDown600 ? RefundableMobile : Refundable,
      title: 'Refundable',
      description: <>
      <span style={{display: 'block', marginBottom: '12px'}}>All the upside, none of the downside. Buy into tokens backed by leading funds. 
        Watch the token list: if it does well, congratulations. </span>
        <span style={{display: 'block'}}>If you don’t like the  performance, claim a full refund – no questions asked.</span>
      </>,
    },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: isDown600 ? '56px' : '96px',
        maxWidth: '1200px',
        width: '100%',
        margin: '0 auto',
      }}
    >
      {/* Header Section */}
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'16px'}
        textAlign="center"
      >
        <Typography
          fontFamily="Hellix"
          fontSize={isDown1023 ? '40px' : '48px'}
          fontWeight={600}
          lineHeight={isDown1023 ? '48px' : '57.6px'}
          color="#000"
        >
          Unparalleled Features
        </Typography>
        <Typography
          fontFamily="Inter"
          fontSize={isDown1023 ? '18px' : '22px'}
          fontWeight={400}
          lineHeight={isDown1023 ? '24px' : '35px'}
          color="#000"
        >
          Designed to empower your portfolio.
        </Typography>
      </Box>

      {/* Features Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isDown1023 ? 'column-reverse' : 'row',
          alignItems: isDown1023 ? 'center' : 'flex-start',
          justifyContent: 'center',
          gap: isDown1023 ? '48px' : '192px',
        }}
      >
        <Box
          sx={{
            width: isDown600 ? '100%' : 'auto',
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <img
            src={isDown600 ? BgUnparalleledMobile : BgUnparalleled}
            alt="BgUnparalleled"
            style={{
              width: isDown1023 ? '100%' : 'auto',
              maxWidth: isDown600 ? '355px' : '',
              margin: '0 auto',
            }}
          />
        </Box>
        <Box
          sx={{
            maxWidth: '486px',
            display: 'flex',
            flexDirection: 'column',
            gap: '48px',
          }}
        >
          {features.map((feature, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: isDown600 ? 'column' : 'row',
                alignItems: 'start',
                gap: '24px',
              }}
            >
              <img loading="lazy" src={feature.icon} alt={feature.title} />
              <Box>
                <Typography
                  fontFamily="Inter"
                  fontSize="24px"
                  fontWeight={650}
                  lineHeight="40px"
                  color="#000"
                >
                  {feature.title}
                </Typography>
                <Typography
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight={400}
                  lineHeight="25px"
                  color="#000"
                >
                  {feature.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default UnparalleledFeatures
