import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { CommunityIcon, ContactIcon, FaqIcon } from '../../assets'
import OrangeButton from '../../components/OrangeButton'
import { PATHS } from '../../constants/config'

const TelegramLink = 'https://t.me/ApeTerminalChat'
const TwitterLink = 'https://twitter.com/apeterminal'

const QuestionHome: React.FC = () => {
  const isDown1023 = useMediaQuery('(max-width:1023px)')
  const isDown992 = useMediaQuery('(max-width:992px)')
  const isDown600 = useMediaQuery('(max-width:767px)')
  const isDown476 = useMediaQuery('(max-width:476px)')
  const theme = useTheme()
  const isBetweenScreen = useMediaQuery(theme.breakpoints.between(767, 1200))
  const isBetweenScreen1200 = useMediaQuery(theme.breakpoints.between(767, 1366))
  const sections = [
    {
      icon: CommunityIcon,
      alt: 'Community Icon',
      title: 'Community',
      description:
        'Join our online community so you can be the first to hear about company news and more.',
      buttonText: 'Join Us',
      link: TwitterLink,
    },
    {
      icon: ContactIcon,
      alt: 'Contact Icon',
      title: 'Contact Us',
      description:
        'We are always available to lend a helping hand through our online chat.',
      buttonText: 'Start Chat',
      link: TelegramLink,
    },
    {
      icon: FaqIcon,
      alt: 'FAQ Icon',
      title: 'FAQ Page',
      description:
        'Our FAQ Page is filled with in-depth articles, and you can always submit new questions.',
      buttonText: 'Learn More',
      link: PATHS.faq,
    },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '64px',
        maxWidth: isBetweenScreen1200 ? '90%' : '1200px',
        margin: '0 auto',
        width: '100%',
        paddingX: isDown600 ? '25px' : isBetweenScreen ? '16px' : '',
      }}
    >
      <Typography
        fontWeight={600}
        lineHeight="100%"
        color="#000"
        fontSize={isDown1023 ? 40 : 48}
        textAlign="left"
        fontFamily="Hellix"
      >
        Questions? We’re here for you!
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isDown1023 ? 'column' : 'row',
          alignItems: 'center',
          width:'100%',
          gap: isDown600 ? '50px' : '58px',
        }}
      >
        {sections.map((section, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width:'100%',
              alignItems: 'flex-start',
              gap: isDown600 ? '12px' : isBetweenScreen ? '24px' : '24px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: isBetweenScreen ? 'row' : 'column',
                gap: isDown1023 ? '12px' : '24px',
                width: '100%',
              }}
            >
              <img
                loading="lazy"
                src={section.icon}
                alt={section.alt}
                style={{ maxWidth: '78px' }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: isDown600 ? '12px' : isBetweenScreen? '12px' : '24px',
                }}
              >
                <Typography
                  fontWeight={600}
                  fontSize={24}
                  color="#000"
                  fontFamily="Inter"
                >
                  {section.title}
                </Typography>
                <Typography
                  fontWeight={400}
                  fontSize={16}
                  color="#000"
                  fontFamily="Inter"
                  maxWidth={'384px'}
                  width={'100%'}
                >
                  {section.description}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: isDown476 ? '100%' : 'max-content',
                minWidth: '146px',
                maxWidth: isDown476 ? '335px' : '',
                borderRadius: '50px',
                height: '53px',
                marginTop: isDown992 ? '16px' : '',
                '&:hover': {
                  borderBottom: '5px solid #000',
                }
              }}
            >
              <OrangeButton
                text={section.buttonText}
                br="24px"
                fs={15}
                fw={500}
                fm="Inter"
                lh="22.5px"
                bgColor="#FF9228"
                border="1px solid #000"
                h="48px"
                w="100%"
                sx={{
                  marginBottom: '4px',
                  textTransform: 'none',
                  '&:hover': {
                    border: '5px solid #000000 !important',
                    boxShadow: '0px 4px 0px 0px #000000',
                  },
                }}
                onClick={() => window.open(section.link)}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default QuestionHome
