import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Web3Auth } from '@web3auth/modal'

interface AppState {
  web3AuthInstance: null | Web3Auth
  toastExpandedItem: string | null | undefined
  isSidebarCollapse: boolean
}

const initialState: AppState = {
  web3AuthInstance: null,
  toastExpandedItem: null,
  isSidebarCollapse: Boolean(localStorage.getItem('isSidebarCollapse')) || false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setWeb3AuthInstance: (state, action: PayloadAction<Web3Auth>) => {
      state.web3AuthInstance = action.payload
    },
    setToastExpandItem: (state, action: PayloadAction<any>) => {
      state.toastExpandedItem = action.payload
    },
    setToggleSidebar: (state, action: PayloadAction<any>) => {
      state.isSidebarCollapse = action.payload
      localStorage.setItem('isSidebarCollapse', action.payload ? 'open' : '')
    },
  },
})

export const { setWeb3AuthInstance, setToastExpandItem, setToggleSidebar } = appSlice.actions

export default appSlice.reducer
