import { Box } from '@mui/material'
import OverviewUserPortal from './OverviewUserPortal'
import UserTransactions from './user-transactions'
import { useAccount } from 'wagmi'
import { useAppSelector } from '../../hooks'
import AccountLogin from '../../pages/Account/AccountLogin'

const UserPortal = () => {
  const { address } = useAccount()
  const user = useAppSelector(state => state.user)
  const isLogIned = address || user.email

  return (
    <Box
      sx={{
        width: '100%',
        display: isLogIned ? 'block' : 'flex'
      }}
    >
      {isLogIned ? (
        <>
          <OverviewUserPortal />
          <UserTransactions />
        </>
      ) : (
        <AccountLogin />
      )}
    </Box>
  )
}
export default UserPortal
