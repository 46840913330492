// @ts-ignore
import { CircularProgressBar } from '@tomickigrzegorz/react-circular-progress-bar'
import { AmberBall, GreenPattern } from '../../assets'
import { Box, Typography, useMediaQuery } from '@mui/material'

const CircularProgess = ({ percent }: { percent: number | string }) => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const props = {
    percent: percent,
    colorSlice: '#1D1F21',
    colorCircle: '#1D1F21',
    fontColor: '#365b74',
    fontSize: '1.6rem',
    fontWeight: 400,
    size: 125,
    stroke: 7,
    strokeBottom: 7,
    speed: 60,
    cut: 10,
    rotation: -70,
    fill: isDown1000 ? 'transparent' : '#000',
    unit: '%',
    textPosition: '9em',
    animationOff: false,
    strokeDasharray: '',
    inverse: false,
    round: true,
    number: false,
    linearGradient: ['#FFB152', '#ff7722'],
    styles: {
      borderRadius: '0%',
      boxShadow: 'none',
    },
  }

  return (
    <Box
      justifyContent={'center'}
      alignItems={'center'}
      height={isDown1000 ? '208px' : '123px'}
      width={isDown1000 ? '208px' : '123px'}
      display={'flex'}
      flexDirection={'column'}
      textAlign={'center'}
      // background image GreenPattern behind the CircularProgressBar
      style={{
        background: `url(${GreenPattern})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <img
        src={AmberBall}
        alt="Amber Ball"
        height={'16.19px'}
        style={{
          position: 'relative',
          left: '2px',
          top: '16px',
          zIndex: 100,
        }}
      />
      <Box
        sx={{
          position: 'relative',
          zIndex: 99,
          width: 'fit-content',
        }}
      >
        <CircularProgressBar
          {...props}
          // stop animation
          animationOff={Number(percent) < 1 ? true : false}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '74%',
            left: '49%',
            transform: `translate(-50%, -100%)`,
          }}
        >
          <Typography
            sx={{
              color: '#FFFFFF',
              fontSize: '36px',
              fontWeight: 600,
              fontFamily: 'Hellix',
              lineHeight: '35px',
              textAlign: 'center',
            }}
          >
            {percent}
            <span
              style={{
                fontSize: '16px',
                position: 'absolute',
                top: '-5px',
                paddingLeft: '2px',
              }}
            >
              %
            </span>
          </Typography>
          <Typography
            sx={{
              color: '#7E8180',
              fontSize: '13px',
              fontWeight: 400,
              fontFamily: 'Inter',
              textAlign: 'center',
            }}
          >
            Chance
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default CircularProgess
