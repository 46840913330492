import { Box, Typography, useMediaQuery } from '@mui/material'
import {
  PortfolioSocials,
  PortfolioInvestment,
  PortfolioMoney,
  PortfolioOrangeKYC,
  PortfolioGreenKYC,
  PortfolioAnnualized,
  PortfolioBg2,
  PortfolioRedKYC,
  PortfolioMoneyWhite,
  PortfolioInvestmentWhite,
  PortfolioAnnualizedWhite,
} from '../../assets'
import InfoBox from './InfoBox'
import KYCnSocialBox from './KYCnSocialBox'
import { useEffect, useMemo, useState } from 'react'
import ParticipationTypeTabs from './ParticipationTypeTabs'
import MySalesTable from './MySalesTable'
import MyReferralsTable from './MyReferralsTable'
import ClaimTable from './ClaimTable'
import NoDataTable from './NoDataTable'
import {
  IParticipation,
  useFetchUserPortfolioStatsQuery,
  useLazyFetchUserAllParticipationsQuery,
} from '../../services/modules/participations'
import {
  sortIDOsOnPortfolioPageByTimestamp,
  sortItemsBySmallerTimestamp,
} from '../../utils'
import { useAppSelector } from '../../hooks'
import KYCButton from '../../components/KYCBox/kycButton'
import { useFetchUserReferralsQuery } from '../../services/modules/reffer'
import { useAccount } from 'wagmi'
import { IdoStatus } from '../../services/modules/project'
import NoDataBox from './NoDataBox'
import { useSearchParams } from 'react-router-dom'
import { useAnalytics } from '../../AmplitudeContext'
import SkeletonForInfoBox from './SkeletonForInfoBox'
import SkeletonForMySalesTable from './SkeletonForMySalesTable'
import SkeletonForMyReferralsTable from './SkeletonForMyReferralsTable'
import SkeletonForClaimTable from './SkeletonForClaimTable'
import BoxShowDataProfile from './BoxShowDataProfile'
import SignInWithGoogleModal from '../../components/SignInWithGoogleModal'
import SubscribeSubscription from './SubscribeSubscription'
import AccountLogin from '../../pages/Account/AccountLogin'

enum EStatusKyc {
  NOT_LOGGED = 'not-logged',
  REJECTED = 'rejected',
  UNVERIFIED = 'unverified',
  IN_REVIEW = 'inreview',
  APPROVED = 'approved',
}

const RevampedPortfolio = () => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown770 = useMediaQuery('(max-width:770px)')
  const user = useAppSelector(state => state.user)
  const { address } = useAccount()
  const isLogIned = address || user.email

  const [fetchUserAllParticipations, { data, isLoading: mySalesLoading, isSuccess }] =
    useLazyFetchUserAllParticipationsQuery()
  // const [openSignInWithGoogleModal, setOpenSignInWithGoogleModall] =
  //   useState(false)

  const { data: portfolioStats, refetch: fetchUserPortfolioStats, isLoading: portfolioStatsLoading } =
    useFetchUserPortfolioStatsQuery()

  const [participationType, setParticipationType] = useState(0)
  const handleParticipationTypeChange = (value: number) => {
    setParticipationType(value)
  }
  const mainAppToken = useAppSelector(state => state.user.mainAppToken)

  // get query params
  const [searchParams] = useSearchParams()
  const [showLoader, setShowLoader] = useState(false)

  const {
    data: userReferralData,
    isLoading: userReferralDataLoading,
    refetch: fetchUserReferrals,
  } = useFetchUserReferralsQuery(undefined, {
    skip: !address || !mainAppToken,
    refetchOnFocus: false,
  })

  useEffect(() => {
    if (mainAppToken) {
      fetchUserReferrals();
      fetchUserPortfolioStats();
      fetchUserAllParticipations();
    }
  }, [mainAppToken, fetchUserReferrals, fetchUserPortfolioStats, fetchUserAllParticipations]);

  // useEffect(() => {
  //   if (mainAppToken) {
  //     setTimeout(() => {
  //       fetchUserPortfolioStats()
  //     }, 3000)
  //   }
  // }, [mainAppToken, fetchUserPortfolioStats])

  // useEffect(() => {
  //   if (mainAppToken) {
  //     setTimeout(() => {
  //       fetchUserAllParticipations()
  //     }, 3000)
  //   }
  // }, [mainAppToken, fetchUserAllParticipations])

  useEffect(() => {
    if (searchParams.get('referral') && userReferralData) {
      // setMainTab(2) after 1 second
      setTimeout(() => {
        setParticipationType(1)
      }, 100)
    }
  }, [searchParams, userReferralData])

  const statusOrder: IdoStatus[] = [
    IdoStatus.PARTICIPATION,
    IdoStatus.INVESTMENT,
    IdoStatus.TGE,
    IdoStatus.COMPLETED,
  ]

  // const sortedArray = useMemo(() => {
  //   if (userReferralData) {
  //     let sortedArray: any = []

  //     sortedArray = [...userReferralData].sort((a, b) => {
  //       const statusA = statusOrder.indexOf(a.ido.status)
  //       const statusB = statusOrder.indexOf(b.ido.status)

  //       // Sorting in ascending order based on the order defined in statusOrder
  //       return statusA - statusB
  //     })

  //     sortedArray = sortItemsBySmallerTimestamp(sortedArray)

  //     // keep participation items status at top and then other items
  //     sortedArray = [
  //       ...sortedArray.filter(
  //         (item: any) => item.ido.status === IdoStatus.PARTICIPATION,
  //       ),
  //       ...sortedArray
  //         .filter((item: any) => item.ido.status !== IdoStatus.PARTICIPATION)
  //         .reverse(),
  //     ]


  //     return sortedArray
  //   } else return []
  // }, [userReferralData])

  const [mySales, setMySales] = useState<IParticipation[]>([])
  const [kYCStatus, setKYCStatus] = useState(EStatusKyc.NOT_LOGGED)
  const [isKYCVerified, setIsKYCVerified] = useState(false)
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    if (data && address) {
      setMySales(sortIDOsOnPortfolioPageByTimestamp([...data]))
    }
    if(!address){
      setMySales(sortIDOsOnPortfolioPageByTimestamp([]))
    }
  }, [address, data])

  useEffect(() => {
    if (user.kycStatus && address) {
      setKYCStatus(user.kycStatus as EStatusKyc )
    }
    if (!address) {
      setKYCStatus(EStatusKyc.NOT_LOGGED)
    }
  }, [user.kycStatus, address])

  const scrollToParticipationTypeTabs = () => {
    const participationTypeTabs = document.querySelector(
      '.participation-type-tabs',
    )
    if (participationTypeTabs) {
      participationTypeTabs.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleReferralsClick = () => {
    trackEvent('PortfolioReferralsOpen', { referrer: document.referrer })
  }

  const mySalesHeaders = ['Projects', 'Participants', 'Connected', 'Status'];
  const referralsHeaders = ['Projects', 'People used my link', 'Multiplier', 'Status', 'My Referral links'];
  const claimHeaders = ['Projects', 'Available', 'Tokens', 'Claimed', ''];

  const getStatusTextDisplayKyc = (status: string) => {
    switch (status) {
      case EStatusKyc.APPROVED:
        return {
          kycStatus: EStatusKyc.APPROVED,
          title: 'Your identity has been confirmed.',
          buttonText: 'Approved',
          icon: PortfolioOrangeKYC,
          color: 'green',
        }
      case EStatusKyc.IN_REVIEW:
        return {
          kycStatus: EStatusKyc.IN_REVIEW,
          title: 'Your identity is being reviewed.',
          buttonText: 'In Review',
          icon: PortfolioOrangeKYC,
          color: 'orange',
        }

      case EStatusKyc.REJECTED:
        return {
          kycStatus: EStatusKyc.REJECTED,
          title: 'Your identity has been rejected.',
          buttonText: 'Complete KYC',
          icon: PortfolioRedKYC,
          color: 'red',
        }
      case EStatusKyc.UNVERIFIED:
        return {
          kycStatus: EStatusKyc.UNVERIFIED,
          title: 'Complete a simple KYC process.',
          buttonText: 'Complete KYC',
          icon: PortfolioGreenKYC,
          color: 'green',
        }
      default:
        return {
          kycStatus: EStatusKyc.NOT_LOGGED,
          title: 'Complete a simple KYC process.',
          buttonText: 'Complete KYC',
          icon: PortfolioGreenKYC,
          color: 'green',
          helpText: 'Connect wallet to proceed',
        }
    }
  }

  if (!isLogIned) {
    return <AccountLogin />
  }

  return (
    <Box
      sx={{
        position: 'relative',
        // backgroundColor: '#fff',
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        alignItems: 'center',
        paddingX: '20px',
        // backgroundImage: `url(${PortfolioBg2})`,
        backgroundPositionY: '12%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box
        maxWidth={'996px'}
        width={'100%'}
        alignSelf={'center'}
        display={'flex'}
        flexDirection={'column'}
        paddingTop={'80px'}
      >
        <Box
          display={'flex'}
          flexDirection={isDown1000 ? 'column-reverse' : 'column'}
          gap={'8px'}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: isDown770 ? 'column' : 'row',
              gap: '8px',
            }}
          >
            <BoxShowDataProfile
              type="wallet"
              heading={address! ? 'User Wallet Address' : ''}
              isShowButton={!address!}
              subheading={address! ? address! : ''}
              setShowLoader={setShowLoader}
            />

            <BoxShowDataProfile
              type="email"
              heading="User Email"
              subheading={
                !user.email && !user.emailRegisterUserAccount
                  ? 'No data available'
                  : user.email || user.emailRegisterUserAccount
              }
              setShowLoader={setShowLoader}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: isDown770 ? 'column' : 'row',
              gap: '8px',
            }}
          >
            <KYCnSocialBox
              image={getStatusTextDisplayKyc(kYCStatus).icon}
              heading="KYC Verification"
              subheading={getStatusTextDisplayKyc(kYCStatus).title}
              buttonText={getStatusTextDisplayKyc(kYCStatus).buttonText}
              buttonAction={() => {
                setIsKYCVerified(!isKYCVerified)
              }}
              status={getStatusTextDisplayKyc(kYCStatus).kycStatus}
              color={getStatusTextDisplayKyc(kYCStatus).color}
              helpText={getStatusTextDisplayKyc(kYCStatus).helpText}
              KYCButton={
                ['approved', 'inreview', 'not-logged'].includes(
                  getStatusTextDisplayKyc(kYCStatus).kycStatus,
                )
                  ? null
                  : KYCButton
              }
            />
            <KYCnSocialBox
              image={PortfolioSocials}
              heading="Social Connections"
              subheading="Increase your chances of winning."
              buttonText="Referrals"
              buttonAction={() => {
                setParticipationType(1)
                scrollToParticipationTypeTabs()
                handleReferralsClick()
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                gap: '8px',
              }}
            >
              {portfolioStatsLoading ? (
                <>
                  <SkeletonForInfoBox />
                  <SkeletonForInfoBox />
                  {!isDown1000 && <SkeletonForInfoBox />}
                </>
              ) : (
                <>
                  <InfoBox
                    imageSrc={PortfolioMoneyWhite}
                    headingText="Amount Invested"
                    headingVal={
                      portfolioStats && portfolioStats?.amountInvested
                        ? `$${portfolioStats?.amountInvested.toLocaleString('en')}`
                        : '$0,000.00'
                    }
                    noBal={!portfolioStats?.amountInvested}
                  />
                  <InfoBox
                    imageSrc={PortfolioInvestmentWhite}
                    headingText="Investment Gain"
                    headingVal={
                      portfolioStats && portfolioStats?.investmentGain
                        ? `$${portfolioStats?.investmentGain.toLocaleString('en')}`
                        : '$0,000.00'
                    }
                    noBal={!portfolioStats?.investmentGain}
                  />
                  {!isDown1000 && (
                    <InfoBox
                      imageSrc={PortfolioAnnualizedWhite}
                      headingText="Annualized ROI"
                      headingVal={
                        portfolioStats && portfolioStats?.annualizedROI !== null
                          ? portfolioStats?.annualizedROI?.toFixed(2)
                          : '0.00'
                      }
                      noBal={!portfolioStats?.annualizedROI}
                    />
                  )}
                </>
              )}
            </Box>
            {isDown1000 && (
              portfolioStatsLoading ? (
                <SkeletonForInfoBox />
              ) : (
                <InfoBox
                  imageSrc={PortfolioAnnualized}
                  headingText="Annualized ROI"
                  headingVal={
                    portfolioStats && portfolioStats?.annualizedROI !== null
                      ? portfolioStats?.annualizedROI?.toFixed(2)
                      : '0.00'
                  }
                  noBal={!portfolioStats?.annualizedROI}
                />
              )
            )}
          </Box>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={isDown1000 ? 'column' : 'row'}
          mt={'52px'}
          mb={'32px'}
          alignItems={'center'}
          gap={'24px'}
        >
          <Typography
            sx={{
              fontFamily: 'Hellix',
              fontSize: isDown1000 ? '32px' : '48px',
              fontWeight: 600,
              color: '#fff',
              margin: '0px',
              lineHeight: '57.6px',
              flex: 2,
              width: '100%',
            }}
          >
            {participationType === 0
              ? 'Participated Sales'
              : participationType === 1
              ? 'My Referrals'
              : participationType === 2
              ? 'Claim tokens'
              : 'Participated Sales'}
          </Typography>

          {/* {hasData && ( */}
          <ParticipationTypeTabs
            tab={participationType}
            handleTabChange={handleParticipationTypeChange}
          />
          {/* )} */}
        </Box>

        {participationType === 0 &&
          (mySalesLoading ? (
            <SkeletonForMySalesTable />
          ) : mySales?.length ? (
            <MySalesTable mySales={mySales} />
          ) : (
            <>{!isDown1000 ? <NoDataTable headers={mySalesHeaders} /> : <NoDataBox />}</>
          ))}
        {participationType === 1 &&
          (userReferralDataLoading ? (
            <SkeletonForMyReferralsTable />
          ) : userReferralData && userReferralData.length ? (
            <MyReferralsTable myReferrals={userReferralData} />
          ) : (
            <>{!isDown1000 ? <NoDataTable headers={referralsHeaders} /> : <NoDataBox />}</>
          ))}
        {participationType === 2 &&
          (mySalesLoading ? (
            <SkeletonForClaimTable />
          ) : mySales?.filter(
            sale => sale.ido.status === IdoStatus.TGE && sale.isInvestor,
          )?.length > 0 ? (
            <ClaimTable
              claimSales={mySales.filter(
                sale => sale.ido.status === IdoStatus.TGE && sale.isInvestor,
              )}
            />
          ) : (
            <>{!isDown1000 ? <NoDataTable headers={claimHeaders} /> : <NoDataBox />}</>
          ))}

        <Box sx={{ width: '100%', marginTop: '150px' }}>
          <SubscribeSubscription />
        </Box>
      </Box>
      {/* <SignInWithGoogleModal
        open={openSignInWithGoogleModal}
        onClose={() => setOpenSignInWithGoogleModall(false)}
      /> */}
    </Box>
  )
}

export default RevampedPortfolio
