import { generateUUID } from '../../../utils/function'
import { RootState } from '../../../redux/configureStore'
import {
  setAccessTokenGoogle,
  setAddressLinkEmail,
  setAddressRegisterUserAccount,
  setEmail,
  setEmailRegisterUserAccount,
  setGoogleToken,
  setIsLoggedIn,
  setIsLoggedInWithEmail,
  setMainAppToken,
  setPrimaryReferralCode,
  setProfilePictureUrl,
  setRefferCode,
  setWalletAddress,
  setWeb3AuthToken,
} from '../../../redux/slices/userSlice'
import { api } from '../../api'

interface IVerifyGoogleTokenPayload {
  email: string
  googleToken: string
  fullName: string
}

interface IRegisterWithEmailPayload {
  address: string
  signature: string
  message: string
  email: string
  googleToken: string
}

interface ISubscribeEmailPayload {
  email: string
}

interface ISubscribeEmailResponse {
  status: number
  message: string
}

interface IGoogleTokenResponse {
  user: {
    _id: string
    address: string
    email: string
  }
  token: {
    expires: number
    expiresPrettyPrint: string
    token: string
  }
  code: string
}
interface IUnLinkWidthAddressResponse {
  message: string
  status: string
}
export interface IUser {
  _id: string
  profileId: string
  address: string
  email: string
  holdingsHistory: number[]
  scoreTokensHoldingHistory: number[]
  elligibleForGiftCard: boolean
}

export interface IKYCResponse {
  status: string
  kycStatus: string
}

interface ISendEmail {
  projectId: string
  idoNumber: number
}

interface IRegisterEmail {
  appPubKey: string
  web3AuthToken: string
  email: string
}

interface IRegisterAccount {
  signature: string
  address: string
  chainId: number
}

interface IRegisterAccountResponse {
  addressToken: {
    expires: string
    expiresPrettyPrint: string
    token: string
  }
  code: string
  user: {
    account: string
    email: string
  }
}

interface IRegisterEmailResponse {
  token: {
    expires: string
    expiresPrettyPrint: string
    token: string
  }
  user: {
    profilePicture: string
    email: string
  }
}

interface IVerifySubscribeEmailResponse {
  status: number
  isSubscribed: boolean
  receivedEmail: string
  verifiedAt: string
}

interface IAddresLinkedResponse {
  status: number
  email: string
}

interface IUnSubscribeEmailResponse {
  status: number
  message: string
}

interface IRequestOtpReq {
  email: string
  idempotencyKey?: string
}

interface IRequestOtpResponse {
  message: string
  nextSendableTime: number
}

interface IVerifySubcribedEmailReq {
  otp: string
  email: string
}

interface IVerifySubcribedEmailResponse {
  verifiedAt: string
}

export const userApi = api.injectEndpoints({
  endpoints: build => ({
    // * For get requests
    fetchUserScore: build.query<number, string>({
      query: address => `profile/score/${address}`,
    }),
    isKycVerified: build.query<IKYCResponse, string>({
      query: refId => `profile/kyc/${refId}?timestamp=${Date.now()}`,
    }),
    fetchUserHoldings: build.query<number[][], string>({
      query: address => `profile/holdings`,
    }),

    sendEmail: build.query<void, ISendEmail>({
      query: ({ projectId, idoNumber }) =>
        `auth/send-mail?projectId=${projectId}&idoNumber=${idoNumber}`,
    }),
    fetchUserSubscribe: build.query<IVerifySubscribeEmailResponse, void>({
      query: () => `profile/is-subscribed-for-newsletter`,
    }),

    // update holding patch request
    updateHolding: build.mutation({
      query: () => ({
        url: `profile/update/user/holdings`,
        method: 'POST',
      }),
    }),
    createAccount: build.mutation<IUser, { email: string; address: string }>({
      query: user => ({
        url: 'profile',
        method: 'POST',
        body: user,
      }),

      // track('User Registered', { user_id: user._id });
    }),

    registerUserAccount: build.mutation<
      IRegisterAccountResponse,
      IRegisterAccount
    >({
      query: ({ address, signature, chainId }) =>
        `/auth/register-account?signature=${signature}&address=${address}&chainId=${chainId}`,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'))
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(setMainAppToken(data.addressToken.token))
          dispatch(setRefferCode(data.code))
          dispatch(setPrimaryReferralCode(data.code))
          dispatch(setEmailRegisterUserAccount(data.user.email))
          dispatch(setAddressRegisterUserAccount(data.user.account))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching post!'))
          console.error(err, 'could not fetch token from backend')
        }
      },
    }),

    registerUserEmail: build.mutation<IRegisterEmailResponse, IRegisterEmail>({
      query: data => ({
        url: 'auth/authenticate',
        method: 'POST',
        body: data,
      }),
      // query: (body) => `auth/authenticate`,
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'))
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(setMainAppToken(data.token.token))
          dispatch(setEmail(data.user.email))
          dispatch(setIsLoggedIn(true))
          dispatch(setProfilePictureUrl(data.user.profilePicture))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching post!'))
          console.log(
            '🚀 ~ file: index.ts:131 ~ onQueryStarted ~ err, auth/authenticate:',
            err,
          )

          let appState = (await getState()) as RootState

          console.error(appState, 'app state from error')

          await appState.app.web3AuthInstance?.logout()
          dispatch(setWeb3AuthToken(''))
          dispatch(setEmail(''))
          dispatch(setIsLoggedIn(false))
          dispatch(setProfilePictureUrl(''))
        }
      },
    }),

    // * For post requests
    // registerUserAccount1: build.mutation<
    //   IRegisterAccountResponse,
    //   IRegisterAccount
    // >({
    //   query: ({ address, signature }) => ({
    //     url: `/auth/register-account?signature=${signature}&address=${address}`,
    //     method: 'GET',
    //     async onQueryStarted(id, { dispatch, queryFulfilled }) {
    //       // `onStart` side-effect
    //       // dispatch(messageCreated('Fetching post...'))
    //       try {
    //         const { data } = await queryFulfilled
    //         // `onSuccess` side-effect
    //         // dispatch(messageCreated('Post received!'))
    //       } catch (err) {
    //         // `onError` side-effect
    //         // dispatch(messageCreated('Error fetching post!'))
    //       }
    //     },
    //   }),
    // }),

    verifyGoogleToken: build.mutation<
      IGoogleTokenResponse,
      IVerifyGoogleTokenPayload
    >({
      query: payload => ({
        url: 'auth/verify-google-token',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            console.log('verifyGoogleToken:', data)
            dispatch(setEmail(data.user.email))
            dispatch(setRefferCode(data.code))
            dispatch(setPrimaryReferralCode(data.code))
            dispatch(setIsLoggedInWithEmail(false))
            dispatch(setAddressLinkEmail(data.user.address))
            dispatch(setAccessTokenGoogle(data.token.token))
          } else {
            dispatch(setIsLoggedInWithEmail(true))
          }
        } catch (err) {
          dispatch(setEmail(''))
          dispatch(setWalletAddress(''))
          dispatch(setGoogleToken(''))
          dispatch(setRefferCode(''))
          console.error('verifyGoogleToken err:', err)
        }
      },
    }),
    registerWithEmail: build.mutation<
      IGoogleTokenResponse,
      IRegisterWithEmailPayload
    >({
      query: payload => ({
        url: 'auth/register-with-email',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            console.log('registerWithEmail:', data)
            dispatch(setEmail(data.user.email))
            dispatch(setAddressLinkEmail(data.user.address))
            dispatch(setRefferCode(data.code))
            dispatch(setPrimaryReferralCode(data.code))
            dispatch(setMainAppToken(data.token.token))
          }
        } catch (err) {
          // let appState = (await getState()) as RootState
          // console.error(appState, 'app state from error')
          // await appState.app.web3AuthInstance?.logout()
          // dispatch(setEmail(''))
          // dispatch(setWalletAddress(''))
          // dispatch(setGoogleToken(''))
          // dispatch(setIsLoggedInWithEmail(false))
          console.error('registerWithEmail:', err)
        }
      },
    }),
    subscribeForNewsLetter: build.mutation<
      ISubscribeEmailResponse,
      ISubscribeEmailPayload
    >({
      query: payload => ({
        url: 'profile/subscribe-for-newsletter',
        method: 'POST',
        body: payload,
      }),
    }),
    subscribeForNewsLetterAuth: build.mutation<
      ISubscribeEmailResponse,
      ISubscribeEmailPayload
    >({
      query: payload => ({
        url: 'profile/subscribed-for-newsletter-with-auth',
        method: 'POST',
        body: payload,
      }),
    }),

    unSubscribeForNewsLetter: build.mutation<IUnSubscribeEmailResponse, void>({
      query: payload => ({
        url: 'profile/unsubscribe-for-newsletter',
        method: 'POST',
        body: payload,
      }),
    }),

    unLinkEmailWidthAddress: build.mutation<IUnLinkWidthAddressResponse, void>({
      query: payload => ({
        url: 'auth/unlink-email-with-address',
        method: 'POST',
        body: payload,
      }),
    }),

    firebaseSubscribeTopic: build.mutation<any, void>({
      query: payload => ({
        url: '/firebase/subscribe-topic',
        method: 'POST',
        body: payload,
      }),
    }),

    authenticateUser: build.mutation<
      {
        user: IUser
        token: {
          expires: string
          expiresPrettyPrint: string
          token: string
        }
      },
      {
        email: string
        address: string
        web3AuthToken: string
        appPubKey: string
      }
    >({
      query: payload => ({
        url: 'auth/authenticate',
        method: 'POST',
        body: payload,
      }),
    }),

    getPendingNotifications: build.mutation<any, any>({
      query: () => `/notification/pending`,
    }),

    ackNotifications: build.mutation<any, any>({
      query: notificationIds => {
        return {
          url: `notification/ack`,
          method: 'POST',
          body: {
            notificationIds,
          },
        }
      },
    }),

    // verify email apis
    requestOtp: build.mutation<IRequestOtpResponse, IRequestOtpReq>({
      query: payload => ({
        url: 'mail/request-otp',
        method: 'POST',
        body: { ...payload, idempotencyKey: generateUUID() },
      }),
    }),

    verifySubcribedEmail: build.mutation<
      IVerifySubcribedEmailResponse,
      IVerifySubcribedEmailReq
    >({
      query: payload => ({
        url: 'mail/verify-subscribed-email',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useSendEmailQuery,
  useLazySendEmailQuery,
  useFetchUserHoldingsQuery,
  useLazyFetchUserHoldingsQuery,
  useFetchUserScoreQuery,
  useLazyFetchUserScoreQuery,
  useIsKycVerifiedQuery,
  useLazyIsKycVerifiedQuery,
  useFetchUserSubscribeQuery,
  useLazyFetchUserSubscribeQuery,
  useAuthenticateUserMutation,
  useRegisterUserAccountMutation,
  useRegisterUserEmailMutation,
  useVerifyGoogleTokenMutation,
  useRegisterWithEmailMutation,
  useSubscribeForNewsLetterMutation,
  useSubscribeForNewsLetterAuthMutation,
  useUnSubscribeForNewsLetterMutation,
  useUnLinkEmailWidthAddressMutation,
  useUpdateHoldingMutation,
  useGetPendingNotificationsMutation,
  useAckNotificationsMutation,
  useFirebaseSubscribeTopicMutation,
  useRequestOtpMutation,
  useVerifySubcribedEmailMutation,
} = userApi
