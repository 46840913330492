import { Box, useMediaQuery } from '@mui/material'
import React from 'react'

const TermsOfServices = () => {
  const isMobile = useMediaQuery('(max-width: 768px)')

  return (
    <Box
      sx={{
        padding: isMobile ? '0 24px 0 24px' : '0 100px 0 100px',
        paddingTop: 10,
      }}
      className="tos-page"
    >
      <div className="flex flex-col gap-1rem">
        <h1
          style={{
            textAlign: 'center',
          }}
        >
          Terms of Services of the Ecosystem Operator
        </h1>
        <p>
          BY ACCESSING OR USING OUR SERVICES, WHICH INCLUDE OUR VARIOUS
          WEBSITES, ; APPLICATIONS, AND OTHER SERVICES THAT LINK TO THESE TERMS,
          AS WELL AS ANY INFORMATION, TEXT, LINKS, GRAPHICS, PHOTOS, AUDIO,
          VIDEO, OR OTHER MATERIALS STORED, RETRIEVED OR APPEARING THEREON,
          WHETHER ACCESSED THROUGH THE SITE OR OTHERWISE (COLLECTIVELY, THE
          <b>“SERVICES”</b>), YOU ARE ENTERING INTO A BINDING AGREEMENT WITH US
          THAT INCLUDES THESE TERMS OF SERVICES, PRIVACY POLICY (FOUND HERE),
          AND OTHER POLICIES REFERENCED HEREIN (COLLECTIVELY, THE{' '}
          <b>“AGREEMENT”</b>).
        </p>
        <p className="uppercase">
          These Ecosystem Operator’ Terms of Service govern your access to and
          the use of the app or Terminal (as defined below) and the Services (as
          defined below) offered by the ecosystem operator.
        </p>
        <p className="uppercase">
          These Terms of Service together with any documents expressly
          incorporated by reference<b>(“Terms”)</b> are entered into by and
          between you (<b>“you”</b>
          or <b>“User”</b>) and the Ecosystem Operator (as defined below, or
          <b>“we”</b>). You and the Ecosystem Operator are each a <b>“Party”</b>{' '}
          and, together, the <b>“Parties”</b> to these Terms.
        </p>
        <p className="uppercase">
          Please read the Terms carefully before you start using the
          App/Terminal or any of the Services. By accessing or using the App or
          the Services you acknowledge and warrant that you have read,
          understood and accepted to be bound by all of the terms and conditions
          stipulated in these Terms (including all other documents, incorporated
          herein by reference). If you don't agree with these Terms, you may not
          use the Services and should not visit the Site or otherwise engage
          with the Services.
        </p>
        <p>
          The content and materials featured or linked to Ecosystem Operator’s
          website, or App/Termainal are for your information and education only
          and are not attended to address your particular personal requirements.
        </p>
        <p>
          The information does not constitute financial advice or recommendation
          and should not be considered as such.
        </p>
        <p>
          Always do your own research and seek independent financial advice when
          required. Any arrangement made between you and any third party named
          or linked to from the site is at your sole risk and responsibility.
          Creative Money and its associated writers assume no liability for your
          actions.
        </p>
        <p className="uppercase">
          We reserve the right, at our sole discretion, to change or modify
          these Terms at any time and without prior notice to you. Any and all
          changes or modifications will become effective immediately upon
          publication on the app or on our website (with the
          <b>“Last Revised”</b> date at the end) and you agree and acknowledge
          that you will not be explicitly notified about possible amendments and
          modifications. By continuing to access the App and the Services once
          revised Terms are published, you agree to be bound by its terms.
          Please ensure that you check the applicable Terms every time you use
          our App in order to understand which Terms apply. If you have any
          questions or comments regarding these Terms of Service, please contact
          us directly at
          <a className="tos-email-link" href="mailto:support@apeterminal.io">
            {' '}
            support@apeterminal.io
          </a>
        </p>
        <h2>1. Interpretation and definitions</h2>
        <p>
          1.1. The following capitalized terms shall have the meanings
          hereinafter assigned to them unless the context clearly otherwise
          requires:
        </p>
        <ul>
          <li>
            <b>“Ecosystem Operator”</b> shall mean a product consisting of an
            App and related Services ;
          </li>
          <li>
            <b>“AML-CTF”</b> shall mean Anti Money Laundering and counter
            Terrorism Financing as set forth in <b>Clause 5</b>;
          </li>
          <li>
            <b>“App”</b> shall mean a mobile, web, and software as a service
            application accessible through the Website, fully owned by the
            Ecosystem Operator;
          </li>
          <li>
            <b>“Claims”</b> shall have the meaning set out in <b>Clause 10</b>;
          </li>
          <li>
            <b>“Content”</b> includes, without limitation: (1) any general news
            and information, commentary, research reports, educational material
            and information and data concerning the financial markets,
            securities and other subjects; (2) financial and investment
            interactive tools, such as alerts or calculators; (3) Ecosystem
            Operator’ name, logos, products and service names, trademarks owned
            by the Ecosystem Operator and (4) any other information, content,
            services, or software.
          </li>
          <li>
            <b>“DEX”</b> shall mean (Crypto) Decentralized Exchange;
          </li>
          <li>
            <b>“DLT”</b> shall mean distributed ledger technology;
          </li>
          <li>
            <b>“ETH”</b> shall mean the cryptocurrency Ethereum;
          </li>
          <li>
            <b>“IDO”</b> shall have the meaning as set forth in{' '}
            <b>Clause 3.4.</b>;
          </li>
          <li>
            <b>“IMO”</b> shall have the meaning as set forth in{' '}
            <b>Clause 3.5.</b>;
          </li>
          <li>
            <b>“ISO”</b> shall have the meaning as set forth in{' '}
            <b>Clause 3.7.</b>;
          </li>
          <li>
            <b>“Indemnified Party(ies)”</b> shall have the meaning set forth in
            <b>Clause 10</b>;
          </li>
          <li>
            <b>“Intellectual Property”</b> shall mean all of our intellectual
            property, including inventions, discoveries, processes, methods,
            compositions, formulae, techniques, information, source code, brand
            names, graphics, User interface design, text, logos, images,
            information and data pertaining to the Services, whether or not
            patentable, copyrightable or protectable in trademark, and any
            trademarks, copyrights or patents based thereon;
          </li>
          <li>
            <b>“KYC”</b> shall mean Know Your Customer procedures as set forth
            in Clause 5;
          </li>
          <li>
            <b>“Last Revised”</b> shall mean the latest version of the Terms
            denoted by its date positioned at the end of the document;
          </li>
          <li>
            <b>“$APEX”</b> shall mean a unique utility cryptographic token that
            is native to Ape Terminal;
          </li>
          <li>
            <b>“Privacy Policy”</b> shall mean Ape Terminal Privacy Policy,
            accessible at the Website and incorporated herein by reference, for
            additional information regarding our privacy terms.
          </li>
          <li>
            <b>“Restricted Areas”</b> shall have the meaning as set out in
            <b>Clause 5.1</b>;
          </li>
          <li>
            <b>“Ineligible Persons”</b> shall have the meaning as set out in
            <b>Clause 5.1</b>;
          </li>
          <li>
            <b>“Services”</b> shall have the meaning as set out in{' '}
            <b>Clause 3</b>;
          </li>
          <li>
            <b>“Terms”</b> shall mean these Ape Terminal Terms of Service,
            including any other documents incorporated herein by reference;
          </li>
          <li>
            <b>“Tokens”</b> shall mean any digital asset which is based on the
            cryptographic protocol of a computer network that may be (i)
            centralized or decentralized, (ii) closed or open-source, and (iii)
            used as a medium of exchange and/or store of value. Furthermore, a
            Token is expressed as a unit; capable of being transferred, stored,
            and traded on a peer-to-peer basis with or without conditions or
            limitations.
          </li>
          <li>
            <b>“User” or “you”</b> shall mean you, the user of the Ape Terminal
            App;
          </li>
          <li>
            <b>“Wallet”</b> shall mean a combination of a User’s public address
            and corresponding private key (or mnemonic phrase or keystore file
            with password) that holds any tokens that are created and/or
            received on a public blockchain such as Ethereum or Binance Chain,
            which may associate with corresponding transactions related to this
            address;
          </li>
          <li>
            <b>“Website”</b> shall mean the Company’s website located at
            <a className="tos-email-link" href="https://apeterminal.io">
              {" "}apeterminal.io
            </a>
            .
          </li>
        </ul>
        <h2>2. Access to the App and Services</h2>
        <p>
          2.1. By using the App or Services, you represent and warrant that:
        </p>
        <ul
          style={{
            listStyleType: 'lower-latin',
            paddingLeft: '2rem',
          }}
        >
          <li>
            you have full legal capacity and authority to agree and bind
            yourself to these Terms;
          </li>
          <li>
            you are aware of applicable laws and regulations governing your use
            of the Services. You shall be solely responsible for ensuring
            compliance with any laws and regulations applicable to you and your
            use of the App or Services, and you shall be solely liable for any
            liability that may arise due to a breach of your legal and
            regulatory obligations in this regard;
          </li>
          <li>
            your use of the App or Services is (A) not prohibited by applicable
            law, and (B) at all times compliant with all applicable laws and
            regulations;
          </li>
          <li>
            you are solely responsible for use of the Services and App and, if
            applicable, for all activities that occur on or through your User
            account. The Company shall not be liable for any damages, losses, or
            liabilities arising out of errors, failures, or inaccuracies in the
            App or its Services that are directly attributable to actions,
            omissions, or negligence on the part of the User;
          </li>
          <li>
            you take steps to ensure the confidentiality of your personal
            information and restrict access to the devices you use to access the
            App.
          </li>
          <li>
            you waive the right to participate in a class or representative
            action lawsuit or a class wide arbitration against Ape Terminal, the
            Company and any of its representatives or affiliates.
          </li>
        </ul>
        <p>
          2.2. To access the App and any of the Services, or some of the
          resources they offer, you may be asked to provide certain registration
          details, personal data or other information. It is a condition of your
          use of the App and any of the Services, that all the information you
          provide is accurate, correct, current, and complete. You agree that
          all information you provide is governed by our Privacy Policy, and you
          consent to all actions we take with respect to your information
          consistent with our Privacy Policy.
        </p>
        <p>
          2.3. You are solely responsible for implementing reasonable measures
          for securing your account, user name, password, or any other piece of
          information as part of our security procedures, you must treat such
          information as confidential, and you must not disclose it to any other
          person or entity. You also acknowledge that your App accounts are
          personal to you and agree not to provide any other person with access
          to the App, or portions of it, using your user name, password, or
          other security information. You are not allowed to use another User’s
          account. To avoid any unauthorized access to your account, you agree
          to ensure you log out of your account at the end of each session. You
          agree to notify us immediately of any unauthorized access to, or use
          of, your user name or password or any other breach of security.
        </p>
        <p>
          2.4. The Ecosystem Operator shall not be liable for any damages,
          losses, or liabilities arising out of errors, failures, or
          inaccuracies in the App or its Services that are directly attributable
          to actions, omissions, or negligence on the part of the User. The User
          expressly acknowledges that their actions, decisions, and compliance
          with security practices play a critical role in the proper functioning
          and security of the App. The Ecosystem Operator cannot and will not be
          liable for any loss or damage arising from your failure to comply with
          these Terms or to secure your App accounts and passwords, including,
          but not limited, to selecting a password that is not easily
          compromised.
        </p>
        <h2>3. Services</h2>
        <p>
          3.1. We offer the User access to a suite of tools within the App that
          facilitate the native trading and storage of cryptocurrencies. The
          tools provided by the App are intended to assist the User in
          monitoring, analyzing, and executing cryptocurrency trades on various
          supported cryptocurrency exchanges.
        </p>
        <p>
          3.2. Ecosystem Operator offers a range of advanced services and
          features intended to maximize Users´ financial opportunities,
          including:
        </p>
        <ul
          style={{
            listStyleType: 'lower-latin',
            paddingLeft: '2rem',
          }}
        >
          <li>Non-custodial Wallet;</li>
          <li>
            allowing users to set a minimum amount of money required to make as
            many transactions as possible to buy as much of the token supply for
            a cryptocurrency that launches<b>(“Sniping Bot”)</b>;
          </li>
          <li>
            dollar cost averaging (i.e. keeping track of average buys and sells
            that are fully automated for Users without having to keep track of
            price)
            <b>(“DCA Bot”)</b>
          </li>
          <li>
            order matching that automatically, and according to pre-established
            criteria, matches Users’ trades with open orders from other Users in
            respect of Tokens
          </li>
          <li>
            Sandwich Trades that enable you to generate yield by strategically
            utilizing your altcoin positions to front-run trades
            <b>(“Sandwich Bot”)</b>;
          </li>
          <li>
            Allowing users to stake other native cryptocurrencies to give them
            access to non-inflationary yield on their staked tokens with up to
            5% per day
          </li>
          <li>
            Enabling users to enter into presale’s performed through Ecosystem
            Operator’s launchpad/accelerator program
          </li>
        </ul>
        <p>
          3.3. The Ecosystem Operator provides the User with a non-custodial
          Wallet feature. This wallet allows the User to securely store, manage,
          and transfer their cryptocurrency holdings. It is important to note
          that the Ecosystem Operator does not have access to or control over
          the User's private keys or stored cryptocurrencies. The User is solely
          responsible for maintaining the security and confidentiality of their
          wallet information. Our Services facilitate peer-to-peer transactions
          that match Users with other Users. You hereby acknowledge and agree
          that we do not operate as a centralized third party exchange and that
          we will not be held liable for any losses resulting from your
          transactions.
        </p>
        <p>
          3.4. <b>Initial DEX Offering (“IDO”)</b>. The Ecosystem Operator
          provides a platform for the Initial DEX Offering (“IDO”) for utility
          tokens. After the User undergoes necessary KYC Procedures, the Users
          are given the option to enter and participate in the IDO.
        </p>
        <p>
          3.5. <b>Initial Snipe Offering (“ISO”)</b>. The Ecosystem Operator
          allows the Users to participate in the "Initial Snipe Offering"
          (“ISO”), which is scheduled to commence subsequent to the IDO. This
          process establishes a regulated and engaging framework for the issue
          and launch of the
          <b>utility tokens</b>. It involves a predetermined assembly of
          participants
          <b>(“Whitelisted Users”)</b>, offering them an early opportunity to
          acquire
          <b>the issued tokens</b> before other Users.
        </p>
        <p>
          3.6. For eligibility to engage in the sale and secure the chance to
          acquire any tokens in the ISO, Users are required to deposit ETH or
          USDT/USDC. The ISO operates on a whitelist mechanism, where
          Whitelisted Users are pre-selected using a lottery-based system and
          granted the privilege to purchase <b>(“snipe”)</b> tokens in advance,
          preceding the public launch of any such tokens. Upon the conclusion of
          the whitelisting process, Users are informed of their eligibility for
          sniping, while other Users receive a refund of their assets and retain
          the opportunity to partake in an alternative Offering.
        </p>
        <p>
          3.7. <b>Initial Mev Offering (“IMO”)</b>. The Ecosystem Operator
          offers Users to engage in the Initial Mev Offering (“IMO”), which is
          scheduled to commence subsequent to the ISO. The IMO allows Users to
          stake assets and front-run other Users who are selling their tokens.
          Users engaged in staking are presented with details like the annual
          percentage yield and other interface elements.
        </p>
        <p>
          3.8. The Ecosystem Operator also provides with its native utility
          token, known as
          <b>$APET</b>, with various utilities and benefits for holders and
          stakers, including discounts on fees (if paid in $APET token), Access
          to presales hosted through the Ecosystem Operator’s platform based on
          a tier system of token holdings of $APET, and other utilities as
          described (and as amended from time to time) on the Ecosystem
          Operator’ website.
        </p>
        <p>
          3.9. By using our Services you acknowledge and agree that we do not
          provide any financial services, including but not limited to
          investment advice, financial planning, or brokerage services. You
          further acknowledge and agree that the <b>$APET</b> token is an
          utility token and is not intended to be used as a financial instrument
          or investment vehicle. It is designed solely for specific uses within
          our App, such as accessing certain features, services, or benefits.
        </p>
        <p>
          3.10. <b>Changes to Services</b>. The Ecosystem Operator shall
          endeavor, as far as possible, to keep the App and the Website
          accessible 7 days a week and 24 hours a day. However, access to the
          App and the Website may be temporarily suspended due to technical
          maintenance, migration or updates, or due to breakdowns or constraints
          in relation with the operation of the internet. The Ecosystem Operator
          is not liable for any malfunction or inability to access the App and
          the Website attributable to third parties, because of unsuitable
          equipment of the User or because of any other circumstances not
          attributable to the Ecosystem Operator.
        </p>
        <p>
          3.11. Although the Ecosystem Operator intends to provide accurate and
          timely information on the App and the Website, the App and the Website
          may not always be entirely accurate, complete or current and may also
          include technical inaccuracies or typographical errors. Accordingly,
          the User shall verify all information before relying on it, and all
          decisions based on information contained on the App and the Website
          are in the sole responsibility of the User and the Ecosystem Operator
          shall have no liability for such decisions.
        </p>
        <h2>4. Fees and taxes</h2>
        <p>
          4.1. By using the Services you agree to pay all applicable fees. Any
          fees or any other prices of the Services shall be stated at all times
          on our App and made available to you before using the Services. Unless
          explicitly stated otherwise, all fees and prices are exclusive of any
          applicable value-added tax (VAT) or other taxes.
        </p>
        <p>
          4.2. As User, you bear sole responsibility for paying any and all
          taxes, duties, and assessments now or hereafter claimed or imposed by
          any governmental authority associated with their use of the Services,
          and/or payable as the result of using and/or exploiting any crypto
          assets and interacting with smart contracts. Blockchain-based
          transactions are novel, and their tax treatment is uncertain.
        </p>
        <p>
          4.3. <b>Fee changes</b>. Please note that the Ecosystem Operator’ fees
          are indicated at the moment of the purchase. We may, in our sole
          discretion and at any time, modify the fees for the Services. We will
          provide you with a notice 7 days prior to any change in fees to give
          you an opportunity to terminate your account before such change
          becomes effective. Fee guide is located here{' '}
          <b>ADD DEDICATED LINKS TO YOUR FEES</b>;. If you do not agree to the
          fee change you must cancel your account in order to avoid future
          charges. If you use the Services after a fee increase you will be
          deemed to have accepted the fee change. We will not be liable to you
          or any third party for any fee change.
        </p>
        <p>
          4.4. <b>Refunds</b>. Except when required by law, fees are
          non-refundable.
        </p>
        <p>
          4.5. The User can place Transactions from Monday to Friday from 9am to
          4pm Swiss time (the <b>“Business hours”</b>). The Ecosystem Operator
          shall use its best efforts to execute the transactions during the
          Business hours as they come, subject however to potential KYC/AML
          clarifications when needed.
        </p>
        <p>
          4.6. The transactions which failed due to a technical issue, extreme
          network conditions or any other cause on the third party service's
          side will not be automatically rolled back to your originating address
          by technical and security reasons.
        </p>
        <h2>
          5. Know Your Customer / Anti Money Laundering and Counter Terrorism
          Financing Disclosures
        </h2>
        <p>
          5.1. Under Swiss law, the services of cryptoassets is subject to
          anti-money laundering obligations. The Ecosystem Operator has adopted
          internal procedures in accordance with the Swiss Federal Act on
          Combating Money Laundering and Terrorist Financing and related
          regulations : <br />
          (i) to ensure that its services are not used for criminal purposes and
          <br />
          (ii) to obtain Know Your Customer (KYC) information from the User (the
          “AML Requirements”) <br />
          (iii) to confirm that the funds and/or crypto assets transiting via
          our services are from a legitimate source which shall be further
          disclosed through the in-App onboarding process. The Ecosystem
          Operator expects the Users to be acting in good faith regarding the
          information provided.
        </p>
        <p>
          5.2. Any person or entity, including anyone acting on its behalf,
          being based, domiciled, located or incorporated in or is a citizen or
          resident or green card holder in any territory under the jurisdiction
          of the United States of America, including any state of the United
          States of America or the district of Columbia and all of its
          territories (the “USA”), or Canada, Republic of Korea, Singapore, The
          people’s republic of China, Bosnia, Albania, Belarus, Burma, Côte
          d'ivoire, Cuba, Democratic Republic of Congo, Congo-Brazzaville,
          Congo-Kinshasa, Iran, Iraq, Liberia, Democratic People's Republic of
          Korea, Seychelles, Sudan, Syria, Tajikistan, Russian Federation,
          Zimbabwe, Uzbekistan or any other country or territory included in the
          OFAC or any other US, UN, EU or other applicable sanctions list
          (together
          <b>“Restricted Area(s)”</b>), as well as an individual, or an
          individual employed by or associated with an entity, identified on
          bis’s denied persons, unverified, or entity lists, or OFAC’s list of
          specially designated nationals, foreign sanctions evaders, or list of
          consolidated sanctions may not use the Ecosystem Operator’ Services{' '}
          <b>(“Ineligible Person(s)”)</b>.
        </p>
        <p>
          5.3. You agree that we have the right to restrict your access to the
          Services via any technically available methods if we suspect, in our
          sole discretion, that :
        </p>
        <ul
          style={{
            listStyleType: 'lower-latin',
            paddingLeft: '2rem',
          }}
        >
          <li>
            you are using the Services for money laundering or any illegal
            activity;
          </li>
          <li>you have engaged in fraudulent activity;</li>
          <li>
            you have acquired cryptoassets using inappropriate methods,
            including the use of stolen funds to purchase such assets;
          </li>
          <li>
            you are the target of any sanctions administered or enforced by the
            U.S. Department of the Treasury’s Office of Foreign Assets Control
            <b>(“OFAC”)</b>, the United Nations Security Council, the European
            Union, Her Majesty’s Treasury, or any other legal or regulatory
            authority in any applicable jurisdiction;
          </li>
          <li>
            either you, as an individual or an entity, or your wallet address is
            listed on the Specially Designated Nationals and Blocked Persons
            List
            <b>(“SDN List”)</b>, Consolidated Sanctions List{' '}
            <b>(“Non-SDN Lists”)</b>, or any other sanctions lists administered
            by OFAC;
          </li>
          <li>
            you have otherwise acted in violation of these Terms. If we have a
            reasonable suspicion that you are utilizing the Services for illegal
            purposes, we reserve the right to take whatever action we deem
            appropriate.
          </li>
        </ul>
        <p>
          5.4. The User hereby acknowledges that Know Your Customer{' '}
          <b>(“KYC”)</b> and Anti-Money Laundering <b>(“AML”)</b> verifications
          are a fundamental requirement for compliance and security. The
          Ecosystem Operator is obliged to exert control over Users in order to
          comply with KYC standard practices and AML applicable laws and
          regulations. The Ecosystem Operator expects the Users to be acting in
          good faith regarding the information provided.
        </p>
        <p>
          5.5. Based on the risk rating of the <b>(“Business relationship”)</b>{' '}
          in accordance with the AML-CTF requirements or should the Ecosystem
          Operator have any concerns about the information and documents or the
          source of funds, the Ecosystem Operator has the right to request
          additional documents and information or proof for the source of funds
          from You, or immediately terminates the relationship without any
          justification. The additional personal information might be : name,
          surname, other identification documentation (as required), in order to
          maintain compliance with any applicable Swiss laws, regulation or
          policy, including any KYC or AML requirements and policies.
          Third-party service providers shall conduct the necessary identity
          verification checks to ensure compliance with applicable regulations.
          In this situation, the Ecosystem Operator may authorize independent
          KYC third-party service providers to collect such information on our
          behalf for the purposes of completing any compliance verification
          process in accordance with the applicable law, regulation or policy,
          including any KYC or AML requirements and policies.
        </p>
        <p>
          5.6. The User acknowledges that the information submitted for KYC/AML
          verification might be processed by a third-party service provider and
          that the Ecosystem Operator shall have access to the verified
          information in compliance with data protection regulations. The
          Ecosystem Operator shall not be liable for any discrepancies or issues
          arising during the verification process conducted by third-party
          service providers. The User expressly agrees to cooperate with
          third-party service providers and the Ecosystem Operator in providing
          accurate and authentic information for KYC/AML checks. By accepting
          these Terms, the User acknowledges and agrees that the User is
          required to provide personal identifiable information and must pass
          KYC protocols. The User consents to the utilization of third-party
          services and agrees to provide the correct information as requested by
          the third-party service provider. Failure to provide necessary
          information may result in the inability to access or use certain
          services or functionalities provided by the App. The User
          acknowledges, agrees and understands that the Ecosystem Operator will
          transfer to a third party service provider any collected KYC/AML data
          and that the Ecosystem Operator has the independent right to terminate
          the User’s participation based on the results of the KYC/AML checks.
          The Ecosystem Operator
        </p>
        <h2>6. Your responsibilities</h2>
        <p>
          6.1. You shall not use the Services in any manner except as expressly
          permitted in these Terms. Without limiting the generality of the
          preceding sentence, you may not:
        </p>
        <ul
          style={{
            listStyleType: 'lower-latin',
            paddingLeft: '2rem',
          }}
        >
          <li>
            infringe any proprietary rights, including but not limited to
            copyrights, patents, trademarks, or trade secrets of the Ecosystem
            Operator;
          </li>
          <li>
            use the Services to transmit any data or send or upload any material
            that contains viruses, Trojan horses, worms, time-bombs, keystroke
            loggers, spyware, adware, or any other harmful programs or similar
            computer code designed to adversely affect the operation of any
            computer software or hardware;
          </li>
          <li>
            use any robot, spider, other automated device, or manual process to
            monitor or copy the Services or any portion thereof other than
            copying or exporting as permitted by the Terms;
          </li>
          <li>
            make any back-up or archival copies of the App or any part thereof,
            including the disassembling or de-compilation, or
            reverse-engineering of any of the closed-source or proprietary
            software running on the App;
          </li>
          <li>
            trade or obtain financing on or through the App, or use any Service,
            with anything other than funds, keys, or digital tokens that you
            have legally obtained;
          </li>
          <li>
            if you are a resident or national of a Prohibited Jurisdiction or a
            U.S. Person, access the App or any Services using any virtual
            private network, proxy service, or any other third party service,
            network, or product with the intent of disguising your IP address or
            location;
          </li>
          <li>
            use the Services in (A) any unlawful manner or (B) for fraudulent or
            malicious activities, or (C) in any manner inconsistent with these
            Terms, or (D) violate applicable laws in any manner.
          </li>
        </ul>
        <p>
          6.2. By using the Services you expressly agree that you are solely
          responsible:
        </p>
        <ul
          style={{
            listStyleType: 'lower-latin',
            paddingLeft: '2rem',
          }}
        >
          <li>
            for keeping your own keystore files, passwords, mnemonic phrases
            and/or private keys. We shall not ask for any password or private
            keys from you, nor shall we ask you to transmit any funds or digital
            tokens, as applicable. Accordingly, we shall not be responsible for
            any losses caused by your transmittal of funds or Tokens, as
            applicable.
          </li>
          <li>
            You are solely responsible for any loss to your Wallet. Your Wallet
            is not accessible by the Ecosystem Operator, and we will not keep
            your keystore files, passwords, mnemonic phrases and/or private
            keys.
          </li>
          <li>
            to familiarise yourself with the App, the Services, and its intended
            usage. You agree to follow all the requisite steps involved in using
            the App as intended.
          </li>
        </ul>
        <p>
          6.3. You represent and warrant that you are the ultimate and effective
          legal and beneficial owner of any Tokens transferred to your account
          or Wallet on the App, that you shall not act as nominee or trustee for
          any other person and that you shall not transfer, assign, pledge,
          charge or otherwise create any security interest or encumbrance
          whatsoever over such Tokens.
        </p>
        <h2>7. Acknowledgement and assumption of risks</h2>
        <p>
          7.1. By engaging with our App, you acknowledge and assume the inherent
          risks associated with the holding and trading in cryptographic tokens,
          blockchain technology and DLT field. Risks include, but are not
          limited to, the following:
        </p>
        <ul
          style={{
            listStyleType: 'lower-latin',
            paddingLeft: '2rem',
          }}
        >
          <li>
            <b>Market Risk</b>. The market for cryptographic tokens is still new
            and uncertain. You should only have funds invested in cryptographic
            tokens or speculate in cryptographic tokens, if you are prepared to
            lose the entirety of such tokens invested in. Whether the market for
            one or more cryptographic tokens will move up or down, or whether a
            particular token will lose all or substantially all of its value, is
            unknown. You should be cautious about holding cryptographic tokens.
            We do not invite or make any offer to acquire, purchase, sell,
            transfer or otherwise deal in any crypto asset. You are solely
            responsible and liable for any and all trading and non-trading
            activity on or through the App and for any gains and losses
            sustained when using the Services.
          </li>
          <li>
            <b>Liquidity and Listing Risk</b>. Markets for cryptographic tokens
            have varying degrees of liquidity. Some are quite liquid while
            others may be thinner. Thin markets can amplify volatility. There is
            never a guarantee that there will be an active market for one to
            sell, buy, or trade cryptographic tokens or products derived from or
            ancillary to them. We make no representations or warranties about
            whether a cryptographic that may be staked via the App will be
            traded on or through the App any point in the future, if at all.
          </li>
          <li>
            <b>Trading Risk</b>. In addition to liquidity risks, values in any
            digital token marketplace are volatile and can shift quickly. You
            should pay close attention to your position and holdings, and how
            they may be impacted by sudden and adverse shifts in trading and
            other market activities. You also acknowledge and agree that costs
            of transacting on blockchain technology is variable and may increase
            at any time. You acknowledge and agree to these risks and represent
            that we cannot be held liable to changes and fluctuations in value
            or increased costs.
          </li>
          <li>
            <b>Legal Risk</b>. The legal status of certain cryptographic tokens
            is uncertain. This can mean that the legality of holding or trading
            them is not always clear. Whether and how one or more cryptographic
            tokens constitute property, or assets, or rights of any kind may
            also seem unclear. You are responsible for knowing and understanding
            how cryptographic tokens are addressed, regulated, and taxed under
            laws that apply to you.
          </li>
          <li>
            <b>Technology Failure Risks</b>. Risks associated with using an
            internet-based currency are: risk of hardware, software and internet
            connection failure or problems, risk of malicious software
            introduction, and risk of third parties obtaining unauthorized
            access to infrastructure stored within your Wallet. You accept and
            acknowledge that we will not be responsible for any communications
            failures, disruptions, errors, distortions or delays when connecting
            your Wallet and using Ethereum network or any chain or similar
            device for processing transactions, however caused. You agree and
            acknowledge that we do not represent or warrant that any of the
            Services or the App are secure from hacker or other malicious
            attack, which may result in stealing or loss of User confidential
            information or any other data. You acknowledge that we will not be
            responsible for and will not replace any tokens lost due to
            third-party malicious acts or due to other circumstances beyond our
            control.
          </li>
          <li>
            <b>Risks of Hacks</b>. While it is less likely a blockchain will be
            hacked, there is a greater potential for hacks on the system layers
            that exist above the blockchain layer. For example, applications
            such as wallets, browsers, websites or software programs are all
            common targets for hackers. These hacks often lead to a substantial
            loss of funds for both the token issuer and the token purchaser.
            Please be aware that many blockchain projects are uninsured which
            will likely result in the complete loss of your funds in the event
            your the victim of a hack.
          </li>
          <li>
            <b>3rd Party Underlying Protocol Failure</b>: Many crypto projects
            execute their project on top of existing blockchains. Common
            blockchains include, but are not limited to, Bitcoin, Ethereum, NXT
            and here BNB Chain for the Ecosystem Operator. Therefore, many
            crypto projects rely on the proper functioning of these underlying
            blockchains. However, issues such as forks, system failures, or
            newer technologies such as quantum computing could introduce new
            risks for these underlying blockchains and therefore the projects
            built on top of them.
          </li>
          <li>
            <b>The Services-related risks</b>. You acknowledge the risks of
            using the Services. You bear sole responsibility for evaluating the
            Services before using them, and all transactions accessed through
            the Services are irreversible, final, and without refunds. The
            Services may be disabled, disrupted or adversely impacted as a
            result of sophisticated cyber-attacks, surges in activity, computer
            viruses, and/or other operational or technical challenges, among
            other things. We disclaim any ongoing obligation to notify you of
            all of the potential risks of using and accessing our Services. You
            agree to accept these risks and agree that you will not seek to hold
            us responsible for any consequent losses.
          </li>
          <li>
            <b>Quality or accessibility risks</b>. As a condition to accessing
            or using the App or the Services, you acknowledge, understand, and
            agree that from time to time, the App and the Services may be
            inaccessible or inoperable for any reason, including, but not
            limited to equipment malfunctions, periodic maintenance procedures
            or repairs, causes beyond our control or that we could not
            reasonably foresee, disruptions and temporary or permanent
            unavailability of underlying blockchain infrastructure or
            unavailability of third-party service providers or external partners
            for any reason.
          </li>
          <li>
            <b>Novel technology risks</b>. Blockchain technology or distributed
            ledger technology-related projects are new and relatively untested
            and outside of our exclusive control. You acknowledge and agree that
            cryptography is a progressing field and that there are risks we
            cannot foresee and it is unreasonable to believe that such risks
            could have been foreseeable. Advances in code cracking or technical
            advances may present risks to smart contracts, cryptographic tokens
            and the App, which could result in theft or loss of your
            cryptographic tokens or property, among other potential
            consequences. By using the Services you acknowledge and agree to
            undertake these risks.
          </li>
          <li>
            <b>Accidental Loss of Tokens</b>: It is possible to lose the entire
            balance of your token based on many different factors. For example,
            if you fail to follow the exact ICO or Token Sale instructions,
            including providing a correct and compatible receiving address you
            may lose your tokens. You may also lose your tokens if you fail to
            write down your password, private key or passphrase (depending on
            the rules of each token sale). Generally, failing to follow very
            strict guidelines will result in the total loss of all tokens. In
            the majority of these cases the tokens will be forever
            unrecoverable.
          </li>
        </ul>
        <p>
          Please note that this statement serves as a general description of
          risks associated with blockchain technology, DLT field and
          cryptographic tokens and should not be considered exhaustive. It is
          essential to conduct thorough research and seek professional advice to
          fully understand the risks involved before engaging in activities
          related to cryptographic tokens or blockchain technology.
        </p>
        <h2>8. Restrictions</h2>
        <p>On the Ecosystem Operator’s websites &amp; Apps, you will not:</p>
        <ul
          style={{
            listStyleType: 'disc',
            paddingLeft: '2rem',
          }}
        >
          <li>
            use Our Content to engage in, pay for, or support any illegal,
            fraudulent, deceptive, or manipulative conduct, including illegal
            gambling activities, money-laundering, or terrorist activities,
          </li>
          <li>
            use Our Content in any way or for any purpose that infringes,
            misappropriates, or otherwise violates any intellectual property
            rights or other rights of us or any third party, or applicable
            local, state, or federal law or regulation, or that is prohibited by
            these Terms,
          </li>
          <li>
            remove, delete, alter, or obscure any trademarks, specifications,
            warranties, or disclaimers, or any intellectual property or
            proprietary rights notices from Our Content,
          </li>
          <li>
            use, export, reexport or transfer any of Our Content for any purpose
            prohibited by Switzerland or local export or import control laws and
            regulations,
          </li>
          <li>
            copy, modify, disassemble, decompile, or reverse engineer Our
            Content (except to the extent such restrictions are prohibited by
            applicable law),
          </li>
          <li>
            use (except as expressly permitted in these Terms), license,
            sublicense, sell, resell, transfer, assign, distribute or otherwise
            commercially exploit or make available to any third party Our
            Content in any way,
          </li>
          <li>
            ake any action or use Our Content in any manner that could damage,
            destroy, disrupt, disable, impair, overburden, or otherwise impede
            or harm in any manner Our Content, or interfere with any other
            party’s use of Our Content,
          </li>
          <li>
            disrupt, interfere with, violate the security of, or attempt to gain
            unauthorized access to Our Content or any computer network,
          </li>
          <li>
            bypass, breach, avoid, remove, deactivate, impair, descramble, or
            otherwise circumvent any security device, protection, or
            technological measure implemented by us or any of our service
            providers to protect Our Content,
          </li>
          <li>
            use any device, software, or routine that interferes with the
            function of Our Content or transmit in or through Our Content, or
            use in connection with Our Content, any virus, trojan horse, worm,
            backdoor, time bomb, malware, or other software or hardware devices
            designed to permit unauthorized access to, or disable, erase, or
            otherwise harm, any computer, systems, or software,
          </li>
          <li>
            access or use Our Content to build or support products or services
            competitive to our products or services,
          </li>
          <li>
            use any web scraping, web harvesting, or data extraction methods to
            extract any data from Our Content,
          </li>
          <li>
            create, use, operate, or employ any bots, robots, parsers, spiders,
            scripts, programs, routines, or any other forms of automation to
            engage in any activity on Our Content,
          </li>
          <li>
            develop any third-party applications that interact with Our Content
            without our prior written consent,
          </li>
          <li>
            use or attempt to use another user’s account without authorization,
          </li>
          <li>
            use or attempt to use Our Content for any person other than yourself
          </li>
          <li>
            provide false, inaccurate, or misleading information in connection
            with your use of or access to Our Content, or
          </li>
          <li>
            encourage, permit, or enable any other person or entity to do any of
            the foregoing.
          </li>
        </ul>
        <p>
          In addition, not all of our services are available on every Platform
          or in every location. You won’t attempt to access any services that
          are unavailable in your location.
        </p>
        <h2>9. No representation and warranties of the Ecosystem Operator</h2>
        <p>
          9.1. We make no representations and warranties. The Services and the
          App are provided on an “as is” and “as available” basis. We make no
          guarantees of any kind in connection with the Services. Your use of
          the Services and the App shall be at your own risk. The Services, and
          the App are provided without any express or implied warranty of any
          kind, including warranties of merchantability, merchantable quality,
          legal effect, accuracy, appropriateness, completeness,
          non-infringement, or fitness for a particular purpose. We do not
          warrant that the Services or the App will be error-free, will meet
          your requirements, or be timely and secure.
        </p>
        <p>
          9.2. We make no warranties and representations that the Services and
          the App have been and will be provided with due skill, care and
          diligence or about the accuracy or completeness of Services and the
          App content and assumes no responsibility for.
        </p>
        <ul
          style={{
            listStyleType: 'lower-latin',
            paddingLeft: '2rem',
          }}
        >
          <li>errors, mistakes or inaccuracies of Content;</li>
          <li>
            personal injury or property damage, of any nature whatsoever,
            resulting from your access and use of the Services and the App;
          </li>
          <li>
            any unauthorized access or use of our servers and/or any and all
            personal information and/or financial information stored therein;
          </li>
          <li>
            any interruption or cessation of transmission to or from the App;
          </li>
          <li>
            any bugs, viruses, Trojan horse, or the like that may be transmitted
            to or through the actions of any third party;
          </li>
          <li>any loss of your data or Content from the App; and/or</li>
          <li>
            any errors and omissions in any content or for any loss or damage of
            any kind incurred as a result of the use of any Content posted,
            emailed, transmitted, or otherwise made available via the Services
            and the App;
          </li>
          <li>
            any damages arising out of improper use of the App, errors or
            failures unauthorized access or use of our servers and/or any and
            all personal information and/or financial information stored therein
            You will not have the right to make or pass any representations or
            warranties on behalf of Ecosystem Operator to any third party.
          </li>
        </ul>
        <p>
          The Provider clarifies that the App does not provide trading services
          or execute trades on behalf of the User. The trading functionality
          within the App is designed to provide information and tools to assist
          the User in making informed trading decisions. The User acknowledges
          and understands that all trading activities, decisions, and outcomes
          are the sole responsibility of the User.
        </p>
        <p>
          9.3. <b>No advertisements</b>. You explicitly acknowledge and agree
          that we do not advertise, recommend or advise any trading or
          exchanging of Tokens, or any other form transaction on our App. It is
          your sole responsibility to exercise caution, conduct your own due
          diligence, and seek professional advice before engaging in any
          transactions on or through our App.
        </p>
        <p>
          9.4. <b>No endorsement</b>. We do not warrant, endorse, guarantee, or
          assume responsibility for any Services on our App and we will not be
          liable for any transaction between you and other users. You are solely
          responsible for the execution of your transaction.
        </p>
        <p>
          9.5. <b>No financial advice</b>. The information on the App and on the
          Website does not constitute any legal, financial, tax or investment
          advice and is not intended as a recommendation for buying, trading or
          selling cryptocurrencies. The Ecosystem Operator fully recommends
          seeking the advice of legal, tax and financial experts before starting
          to buy, trade or sell crypto assets. Crypto assets are highly
          volatile. Buying and Selling of crypto assets carries with it a high
          degree of risk. The User should be fully aware of the level of risk
          involved before trading. Any loss of data, crypto assets or profit is
          on the User's sole responsibility. The Ecosystem Operator will not be
          responsible for the consequences of reliance upon any opinion or
          statement contained herein or for any omission.
        </p>
        <p>
          9.6. There is always the possibility an unexpected event happens with
          regard to crypto assets that causes the User's cryptocurrencies to be
          lost.
        </p>
        <p>
          9.7. The Ecosystem Operator strongly recommends to the User not to
          invest more than he/she is willing to lose.
        </p>
        <p>
          9.8. THE USER IS SOLELY RESPONSIBLE FOR ANY INVESTMENT DECISIONS WHICH
          ARE EXECUTED ON THE EXCLUSIVE INITIATIVE OF THE USER.
        </p>
        <h2>10. Limitation of Liability</h2>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE
          ECOSYSTEM OPERATOR INDEMNIFIED PARTY BE LIABLE TO YOU FOR ANY LOSS,
          DAMAGE, OR INJURY OF ANY KIND INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
          INCIDENTAL, EXEMPLARY, CONSEQUENTIAL, OR PUNITIVE LOSSES OR DAMAGES,
          OR DAMAGES FOR SYSTEM FAILURE OR MALFUNCTION OR LOSS OF PROFITS, DATA,
          USE, BUSINESS OR GOOD-WILL OR OTHER INTANGIBLE LOSSES, ARISING OUT OF
          OR IN CONNECTION WITH:
        </p>
        <ul>
          <li>THE SERVICES OR YOUR INABILITY TO USE OR ACCESS THE SERVICES</li>
          <li>
            MISUSE OF THE SERVICES (INCLUDING WITHOUT LIMITATION, UNAUTHORIZED
            ACCESS OF THE SERVICES)
          </li>
          <li>ANY USER CONDUCT ON THE SERVICES</li>
        </ul>
        <p>
          IN ADDITION TO THE FOREGOING, THE ECOSYSTEM OPERATOR SHALL NOT BE
          LIABLE FOR ANY DAMAGES CAUSED IN WHOLE OR IN PART BY:
        </p>
        <ul>
          <li>
            USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUCTED
            SMART CONTRACTS OR OTHER TRANSACTIONS
          </li>
          <li>SERVER FAILURE OR DATA LOSS</li>
          <li>
            THE MALFUNCTION, UNEXPECTED FUNCTION OR UNINTENDED FUNCTION OF THE
            BLOCKCHAIN, ANY COMPUTER OR CRYPTOASSET NETWORK (INCLUDING ANY
            WALLET PROVIDER), INCLUDING WITHOUT LIMITATION LOSSES ASSOCIATED
            WITH NETWORK FORKS, REPLAY ATTACKS, DOUBLE-SPEND ATTACKS, SYBIL
            ATTACKS, 51% ATTACKS, GOVERNANCE DISPUTES, MINING DIFFICULTY,
            CHANGES IN CRYPTOGRAPHY OR CONSENSUS RULES, HACKING, OR
            CYBERSECURITY BREACHES
          </li>
          <li>ANY CHANGE IN VALUE OF ANY CRYPTOASSET</li>
          <li>ANY CHANGE IN LAW, REGULATION, OR POLICY</li>
          <li>EVENTS OF FORCE MAJEURE</li>
          <li>ANY THIRD PARTY</li>
        </ul>
        <p>
          THIS LIMITATION OF LIABILITY IS INTENDED TO APPLY WITHOUT REGARD TO
          WHETHER OTHER PROVISIONS OF THESE TERMS HAVE BEEN BREACHED OR HAVE
          PROVEN INEFFECTIVE. THE LIMITATIONS SET FORTH IN THIS SECTION SHALL
          APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER THE ASSERTED LIABILITY
          OR DAMAGES ARE BASED ON CONTRACT, INDEMNIFICATION, TORT, STRICT
          LIABILITY, STATUTE, OR ANY OTHER LEGAL OR EQUITABLE THEORY, AND
          WHETHER OR NOT THE ECOSYSTEM OPERATOR INDEMNIFIED PARTIES HAVE BEEN
          INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE. UNDER NO CIRCUMSTANCES
          SHALL THE ECOSYSTEM OPERATOR INDEMNIFIED PARTY BE REQUIRED TO DELIVER
          TO YOU ANY VIRTUAL CURRENCY AS DAMAGES, MAKE SPECIFIC PERFORMANCE, OR
          ANY OTHER REMEDY. IF YOU WOULD BASE YOUR CALCULATIONS OF DAMAGES IN
          ANY WAY ON THE VALUE OF VIRTUAL CURRENCY, YOU AND WE AGREE THAT THE
          CALCULATION SHALL BE BASED ON THE LOWEST VALUE OF THE VIRTUAL CURRENCY
          DURING THE PERIOD BETWEEN THE ACCRUAL OF THE CLAIM AND THE AWARD OF
          DAMAGES.
        </p>
        <p>
          The Ecosystem Operator’s website may contain links to external
          websites that are not provided or maintained by or in any way
          affiliated with the Ecosystem Operator;
        </p>
        <p>
          Please note that the Ecosystem Operator does not guarantee the
          accuracy, relevance, timeliness, or completeness of any information on
          these external websites.
        </p>
        <p>
          The information given by the Service is for general guidance on
          matters of interest only;
        </p>
        <p>
          Even if the Ecosystem Operator takes every precaution to insure that
          the content of the Service is both current and accurate, errors can
          occur;
        </p>
        <p>
          Plus, given the changing nature of laws, rules and regulations, there
          may be delays, omissions or inaccuracies in the information;
        </p>
        <p>
          The Ecosystem Operator is not responsible for any errors or omissions,
          or for the results obtained from the use of this information;
        </p>
        <p>
          Some jurisdictions do not allow the exclusion or limitation of certain
          warranties and liabilities provided in this section; accordingly, some
          of the above limitations and disclaimers may not apply to you.
        </p>
        <p>
          To the extent applicable law does not permit The Ecosystem Operator
          Indemnified Parties to disclaim certain warranties or limit certain
          liabilities, the extent of The Ecosystem Operator’ Indemnified
          Parties’ liability and the scope of any such warranties will be as
          permitted under applicable law.
        </p>
        <h2>11. Indemnification</h2>
        <p>
          11.1. By agreeing to these Terms and accessing the App and the
          Services, you agree, to the fullest extent permitted by applicable
          law, to indemnify, defend, and hold harmless the Ecosystem Operator,
          its past, present and future affiliates, subsidiaries and service
          providers, and each of their past, present and future officers,
          directors, agents, joint venturers, employees, representatives,
          partners, and licensors (collectively
          <b>“Indemnified Parties”</b>, and each such person or entity
          individually,<b>“Indemnified Party”</b>) from and against any and all
          actual or alleged claims, demands, damages, losses, liabilities,
          obligations, penalties, interest, fees, expenses (including, without
          limitation, attorneys’ fees and expenses) and costs (including,
          without limitation, court costs, costs of settlement, and costs of
          pursuing indemnification and insurance), of every kind and nature
          whatsoever, whether known or unknown, foreseen or unforeseen, matured
          or unmatured, or suspected or unsuspected, in law or equity, whether
          in tort, contract, or otherwise, (collectively, <b>“Claims”</b>), that
          arise directly or indirectly out of:
        </p>
        <ul
          style={{
            listStyleType: 'lower-latin',
            paddingLeft: '2rem',
          }}
        >
          <li>
            your use or misuse of the App and the Services or any contract
            related thereto;
          </li>
          <li>
            violation, breach or alleged breach of any provision of these Terms
            by you or any person using the Services on your behalf;
          </li>
          <li>
            your violation of any applicable laws, rules, regulations, codes,
            statutes, ordinances, or orders of any governmental or
            quasi-governmental authorities;
          </li>
          <li>anything you contribute to the Services;</li>
          <li>
            your violation of the rights or obligations of any third party,
            including any intellectual property right, publicity,
            confidentiality, property, or privacy right;
          </li>
          <li>your negligence or wilful misconduct;</li>
          <li>your use of a third-party product, service, and/or App;</li>
          <li>any misrepresentation made by you or</li>
          <li>
            any other matter for which you are responsible hereunder or under
            law.
          </li>
        </ul>
        <p>
          11.2. You agree that your use of the App and Services shall be in
          compliance with all applicable laws, regulations and guidelines.
        </p>
        <p>
          11.3. We reserve the right to assume, at your expense, the exclusive
          defense and control of any matter subject to indemnification by you.
          You agree to promptly notify us of any Claims and cooperate with our
          defense of any Claims. You will not in any event settle any Claims
          without our prior written consent.
        </p>
        <h2>12. Termination rights</h2>
        <p>
          12.1. These Terms shall remain in force and effect unless terminated
          for the time you use the App. Without limiting any other provision of
          these Terms, we reserve the right to, in our sole discretion and
          without notice or liability, deny access to or use the App (including
          blocking certain IP addresses) to any person for any reason or for no
          reason, including without limitation, for violation or breach of any
          representation, warranty or covenant contained in these Terms or any
          applicable law or regulation. We may, in our sole discretion and
          without notice, terminate your use or participation on the App or
          delete your account and any content or related information and files
          in your account and/or bar any further access to such files or the
          Services.
        </p>
        <p>
          12.2. If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake and borrowed name, or the name of any third party, even
          if you may be acting on behalf of the third party. In addition to
          terminating or suspending your account, we reserve the right to take
          appropriate legal action, including without limitation pursuing civil,
          criminal and injunctive redress.
        </p>
        <h2>13. Intellectual property</h2>
        <p>
          13.1. These Terms shall not be understood and interpreted in a way
          that they would mean the assignment of Intellectual Property rights
          unless it is explicitly defined so in these Terms. You may not use any
          of Intellectual Property for any reason, except with our express,
          prior, written consent.
        </p>
        <p>
          13.2. The Website, the App and any of the Services, including their
          design elements or concepts and any and all underlying Intellectual
          Property, including, but not limited to copyrights, patents, service
          marks, any registered trademarks, domain names and other proprietary
          rights, are the property of the Ecosystem Operator, and are protected
          by copyright, patent, trade secret and other intellectual property
          laws. Unless otherwise expressly stated, the Ecosystem Operator
          retains any and all rights, title and interest in and to the App and
          the Services (including, without limitation, all Intellectual Property
          rights), including all copies, modifications, extensions and
          derivative works thereof. Your right to use the App and the Services
          is limited to the rights expressly granted in these Terms. No licenses
          to use any of trademarks or any other Ecosystem Operator’s brands are
          to be inferred or assumed pursuant to the use of any of the Services.
          All rights not expressly granted to you are reserved and retained by
          the Ecosystem Operator.
        </p>
        <h2>14. Privacy Policy</h2>
        <p>
          14.1. The Ecosystem Operator will not collect any cookies, IP
          addresses, or user data in connection with your use of the website and
          app, with the exception of cookies related to browsing behavior for
          the Google analytics SEO which helps us enhance your experience with
          our site and relay visitor traffic statistics to the Google search
          engine
        </p>
        <p>
          14.2. Each User understands that the Binance Smart Chain (BSC)
          blockchain network and any other Chain that may be used within the
          ecosystem operator’s platforms is a public blockchain, and all
          transaction history initiated through the website will be made public
          ;
        </p>
        <p>
          14.3. The Ecosystem Operator has no influence on secondary markets,
          such as Crypto Exchanges which have independent terms of service
          agreed to by you, should you choose to utilize these sites
        </p>
        <p>
          14.4. Should the Company be required to collect or store data for
          future activities such as merchandise launches or transfer of any
          benefits such as airdrops, you will be required to agree to the
          collection of this data;
        </p>
        <h2>15. Force Majeure</h2>
        <p>
          15.1. We will not be in breach of these Terms or liable for any delay
          or failure to perform any obligation under these Terms where the delay
          or failure results from any event, circumstance or cause beyond our
          reasonable control. The Parties agree that due to the specific nature
          of the blockchain / DLT field, the circumstances of force majeure
          shall in particular include also (but shall not be limited to)
          interruption in telecommunications or internet services or network
          provider services, failure of equipment, and/or software, hacker
          attacks, market disturbances, other major event or natural
          catastrophe, change of laws or regulations, adverse regulatory or
          enforcement action of public authorities, technical failures and the
          like.
        </p>
        <h2>16. Consumer Exclusion</h2>
        <p>
          16.1. You hereby acknowledge and represent that you are not considered
          a consumer in relation to the Services provided, and the provisions of
          any applicable consumer protection rules, or any consumer protection
          rights, including distance selling rules, shall be excluded to the
          fullest extent permitted by any applicable law. By using this App, you
          waive any consumer protection rights and distance selling rights that
          may be applicable under any relevant law.
        </p>
        <h2>17. Consents and Authorizations</h2>
        <p>
          17.1. You agree and consent to receive electronically via your
          verification email all Communications, that The Ecosystem Operator may
          be willing to communicate to you. For the purposes hereof{' '}
          <b>“Communications”</b> shall mean all and any communication,
          agreement, document, receipt, notice and disclosure, which may be from
          time to time addressed to User by The Ecosystem Operator. You may
          withdraw your consent to receive electronic Communications by sending
          a withdrawal notice to support. If this is a case you waive your right
          to plead ignorance.
        </p>
        <p>
          17.2. By accepting these Terms of Service you also agree to our
          General Terms and Conditions and our Privacy Policy. Our Privacy
          Policy is accessible at privacy notice and describes how we collect,
          use, and share your personal information.
        </p>
        <h2>18. Notices</h2>
        <p>18.1. We may provide any notice to you under these Terms by :</p>
        <ul>
          <li>posting a notice on or through the App;</li>
          <li>sending an email to the email associated with you.</li>
        </ul>
        <p>
          Notices we provide by posting on or through the App will be effective
          upon posting, and notices we provide by email will be effective when
          we send the email.
        </p>
        <p>
          18.2. To give us notice under these Terms, you must contact us by
          email to
          <a className="tos-email-link" href="mailto:support@apeterminal.io">
            {' '}
            support@apeterminal.io
          </a>
          . We may update this email address for notices to us by posting a
          notice on or through our app. Notices to us will be effective after
          they are sent.
        </p>
        <p>
          18.3. All communications and notices to be made or given pursuant to
          these Terms must be written in the English language. You agree and
          acknowledge that all agreements, notices, disclosures, and other
          communications that we provide to you, including these Terms, will be
          provided in electronic form.
        </p>
        <h2>19. Miscellaneous</h2>
        <ul
          style={{
            listStyleType: 'decimal',
            paddingLeft: '2rem',
          }}
        >
          <li>
            <b>Entire Agreement</b>. These Terms, including all other documents,
            incorporated herein by reference, represent the entire agreement
            between you and us regarding the subject matter of these Terms, in
            particular, the use of the App, and the Services. In the event of
            any conflict between these Terms and any other agreement you may
            have with The Ecosystem Operator, the terms of that other agreement
            will prevail only if these Terms are specifically identified and
            declared to be overridden by such other agreement. We reserve the
            right to alter, amend or modify these Terms from time to time, in
            our sole discretion.
          </li>
          <li>
            <b>Severability</b>. Should one or several provisions of these Terms
            be or become invalid or prove to be unenforceable, this shall not
            affect the validity of the other provisions.In this case, the
            contracting Parties shall replace the invalid or unenforceable
            provision by another provision that comes closest to the
            economically intended purpose. The same applies in the case of a
            regulatory gap.
          </li>
          <li>
            <b>Assignment</b>. Without our prior written consent, you are
            prohibited from assigning these Terms and any rights, duties, and
            obligations contained herein. However, we retain the right to freely
            assign or transfer these Terms, in whole or in part, without any
            limitations.
          </li>
          <li>
            <b>Third Party Rights</b>. Unless expressly provided to the contrary
            in these Terms, no third party (i.e. person who is not party to
            these Terms) shall have any rights to enforce or to enjoy any
            benefit of any terms contained herein.
          </li>
          <li>
            <b>No Waiver</b>. Any failure by the Ecosystem Operator to exercise
            any of its respective rights, powers, or remedies under these Terms
            of Service, or any delay by the Ecosystem Operator in doing so, does
            not constitute a waiver of any such right, power, or remedy. The
            single or partial exercise of any right, power, or remedy by the
            Ecosystem Operator does not prevent either from exercising any other
            rights, powers, or remedies.
          </li>
          <li>
            <b>Governing law</b>. These Terms will be governed by and construed
            in accordance with the laws of the Republic of Switzerland.
          </li>
          <li>
            <b>Dispute Resolution</b>. Any dispute, controversy or claim arising
            out of or in relation to these Terms, including the validity,
            invalidity, breach or termination thereof, shall be submitted to
            mediation in accordance with the Swiss Rules of Mediation of the
            Swiss Arbitration Centre in force on the date when the request for
            mediation was submitted in accordance with these Rules. The seat of
            the mediation shall be
            <b>Zürich, Switzerland</b>. The mediation shall be conducted in
            <b>English</b>. If such dispute, controversy or claim has not been
            fully resolved by mediation within <b>60 (sixty) days</b> from the
            date when the mediator(s) has (have) been confirmed or appointed by
            the Swiss Arbitration Centre, it shall be settled by arbitration in
            accordance with the Swiss Rules of International Arbitration of the
            Swiss Arbitration Centre in force on the date when the Notice of
            Arbitration was submitted in accordance with those Rules. The number
            of arbitrators shall be
            <b>three</b>. The seat of the arbitration shall be
            <b>Zürich, Switzerland</b>. The arbitration proceedings shall be
            conducted in <b>English</b>.
          </li>
        </ul>
        <p>
          <b>First published on: 24 November, 2023</b> <br />
          <b>Last revised on: 15 December, 2023</b>
        </p>
        <h1>General Risks Assessment</h1>
        <p>
          This Risk Disclosure Statement provides you with information about
          some of the risks associated with use of the Ecosystem Operator’
          Terminal and other services offered. The information presented in this
          Risk Disclosure Statement is not comprehensive and does not reflect
          all of the risks (or other important factors) you should consider
          before using the Services. You must take your own independent decision
          to access or use the Services and should seek any advice that you
          consider necessary or desirable (including financial and/or legal
          advice) from independent advisers. Your capital is at risk. Terms not
          otherwise defined in this Risk Disclosure Statement shall bear the
          same meanings attributed to them in the Terms of Service of the
          Ecosystem Operator.
        </p>
        <p>
          <b>A) Financial risks</b>
        </p>
        <p>1. Trading risk</p>
        <p>
          You use the Services at your own risk. There can be no assurance that
          use of the Services will provide a positive return or profit, that
          significant losses will not be incurred, or that your objectives will
          be achieved. All Contracts traded on the Terminal are margined and
          settled using digital assets, such as BNB Token. It is also important
          to have sufficient relevant experience when entering into financial
          contracts and transactions. You should only fund your wallet and trade
          with digital assets that you own and can afford to lose. It is
          possible that you may lose a substantial proportion or all of your
          capital. In addition, use of some of our Services involves a high risk
          of loss inherently associated with the Contracts that are available.
          As a result, you should carefully consider whether you can afford to
          bear the risks of loss involved in using these Services.
        </p>
        <p>3. Accuracy of information</p>
        <p>
          While the Ecosystem Operator endeavors to keep information displayed
          on the Services as accurate as possible, there is a risk that this may
          not be correct, complete or updated.
        </p>
        <p>4. Price volatility</p>
        <p>
          The price of digital assets can be highly unpredictable and volatile
          when compared to other assets such as stocks, bonds and other tradable
          instruments.
        </p>
        <p>5. Liquidity risk</p>
        <p>
          Digital assets (including those exchanged on the Ecosystem Operator)
          may be riskier, less liquid, more volatile and more vulnerable to
          economic, political, market, industry, regulatory and other changes
          than other derivatives Contract types. The liquidity of the market for
          Contracts will depend on, among other things, supply and demand on the
          Ecosystem Operator’s App/Terminal and the commercial and speculative
          interest in the market for these Contracts. As Contracts are traded on
          a closed system, there is a risk that there may be limited volume and
          liquidity.
        </p>
        <p>6. Deposits and insurance fund</p>
        <p>
          Your Account balance is at risk of total loss, from, among others
          things, security breaches (whether in respect of your Account(s)
          specifically or the Services generally), electronic, technological or
          systems failures (including book- or record keeping errors), and
          insolvency or bankruptcy, or equivalent formal proceedings, in respect
          of the Ecosystem Operator.
        </p>
        <p>
          It is your responsibility to ensure that you use the correct address
          for any deposit, withdrawal, or transfer, and that the address you use
          is a valid address for the digital asset that you intend to transfer
          and that the Ecosystem Operator accepts as a deposit.
        </p>
        <p>
          Any inaccuracy in a specified address, or in the digital asset that
          you attempt to transfer between addresses, may result in total loss of
          the digital assets concerned.
        </p>
        <p>
          Any digital assets transferred to or between your Account(s) will only
          be available for margin or withdrawal purposes when it has been
          credited to your Account.
        </p>
        <p>
          The period between block confirmations and between any block
          confirmation and Account credit is variable and depends on a number of
          factors, including factors that are not within Ecosystem Operator’s
          control.
        </p>
        <p>
          We do not offer any assurance or guarantee in relation to the
          timeframe for a deposit or transfer to be credited or for a withdrawal
          to be broadcast and confirmed.
        </p>
        <p>
          For example, deposits, transfers, and/or withdrawals may not be
          credited or may be delayed if:
        </p>
        <ul>
          <li>
            there are delays, high demand, or technological issues affecting the
            relevant network;
          </li>
          <li>
            the allocated network fee for the transaction is insufficient to
            ensure that your transaction is confirmed according to your
            expectations;
          </li>
          <li>
            variation in the processing time in our systems, which may be
            increased by high demand, technological issues or other conditions
            that impact processing time;
          </li>
          <li>
            if we believe that you are engaged in any suspicious activity or a
            breach of our Terms of Service of the Ecosystem Operator.
          </li>
        </ul>
        <p>
          The protocol underlying a digital asset may change or otherwise cease
          to operate as expected due to changes made to its underlying
          technology or changes resulting from an attack.
        </p>
        <p>
          These changes may include, without limitation, a “fork”, a “rollback”,
          an “airdrop”, or a “bootstrap”.
        </p>
        <p>
          Any such change, or any failure in any applicable protocol, may dilute
          the value of your digital asset(s) and/or your Positions and may
          result in total loss.
        </p>
        <p>
          The Ecosystem Operator will not support any such change and any asset
          (or other digital token or asset) that you may expect to be
          distributed or transferred to you in respect of any wallet balance may
          not be credited to your Account or transferred to you, resulting in a
          total loss of, without any right to claim or request compensation for,
          that asset.
        </p>
        <p>
          <b>B. Regulatory and Oversight Risk</b>
        </p>
        <p>1. General regulatory risk</p>
        <p>
          The regulatory environment concerning cryptocurrencies and other
          digital assets continues to develop. The application and
          interpretation of existing laws and regulations are often largely
          untested and there is a lack of certainty as to how they will be
          applied.
        </p>
        <p>
          New laws and regulations will be promulgated in the future that apply
          to blockchain technology and digital assets, and related services
          providers, and no assurance can be given that any such changes will
          not adversely affect digital assets generally or the Services.
        </p>
        <p>
          It is not possible to predict how such changes would affect the price
          and liquidity of digital assets generally, or the Services.
        </p>
        <p>
          Regulatory actions could negatively impact cryptocurrencies and other
          digital assets in various ways, including, for purposes of
          illustration only, through a determination (with retrospective or
          prospective effect) that digital assets or their derivatives are
          regulated financial instruments requiring registration or licensing in
          certain jurisdictions.
        </p>
        <p>
          The Ecosystem Operator may have to limit the availability of certain
          Contracts or disallow Users based on their citizenship, residence or
          location from engaging in any transactions on the Terminal if doing so
          becomes commercially unsustainable or legally prohibited, which could
          materially affect the price and liquidity of Contracts.
        </p>
        <p>
          You understand that ultimately it is your responsibility to make sure
          that you comply with any and all local regulations, directives,
          restrictions and laws in your place(s) of residence before using our
          Services. We strictly state that we do not permit the use of our
          Services by Users from a jurisdiction in which the use of our Services
          is not permitted (including, without limitation, Restricted
          Jurisdictions).
        </p>
        <p>
          We are not offering or soliciting the use of our Services to any
          person located in any Restricted Jurisdiction or any other
          jurisdiction in which the specific use of our Services is not
          authorised or is otherwise prohibited by local laws.
        </p>
        <p>
          <b>C. Cyber Risk</b>
        </p>
        <p>1. Risks relating to cybersecurity</p>
        <p>
          Malicious individuals, groups or organisations may attempt to
          interfere with the Ecosystem Operator’s Website, App and Terminal in a
          variety of ways, including, but not limited to, malware attacks,
          denial of service attacks, coordinated attacks, account takeovers and
          submitting fake transactions (including spoofing) which could
          negatively affect the operation of the Ecosystem Operator, the
          availability of the Services.
        </p>
        <p>
          With the increased use of technologies and the dependence on computer
          systems to perform necessary business functions, digital assets and
          the Services are susceptible to operational and information security
          risks. In general, cyber incidents can result from deliberate attacks
          or unintentional events.
        </p>
        <p>
          Cyberattacks include but are not limited to gaining unauthorized
          access to digital systems for purposes of misappropriating assets or
          sensitive information, corrupting data, or causing operational
          disruption. Cyberattacks may also be carried out in a manner that does
          not require gaining unauthorised access, such as causing denial of
          service attacks on infrastructure.
        </p>
        <p>
          Cyber security failures or breaches of the third party service
          providers (including, but not limited to, software providers, cloud
          services providers, index providers, the administrator and transfer
          agent) could have a negative impact on digital assets and the
          Services.
        </p>
        <p>It is your responsibility to ensure :</p>
        <ul>
          <li>
            (i) that your access credentials are kept secure and confidential,
            including your email, username, and password, as well as access to
            or use of any two factor authentication hardware, software, or
            secret and;
          </li>
          <li>
            (ii) the security and integrity of any systems (both hardware and
            software) or services that you use to access the Services.
          </li>
        </ul>
        <p>2. Risks relating to platform downtime and IT maintenance</p>
        <p>
          The Ecosystem Operator may, from time to time, perform maintenance on
          the Website, App/Terminal. This may lead to platform downtime and lack
          of access to the Terminal, potentially resulting in a delay or
          cancellation of a submitted Order yet to be processed and Orders
          placed during maintenance or downtime, and the inability for an User
          to submit new or change existing Orders.
        </p>
        <p>
          <b>E. Access risks</b>
        </p>
        <p>
          There are a series of inherent risks with the use of the mobile and/or
          web-based technology such as latency in the prices provided, and other
          issues that are a result of connectivity (including, without
          limitation, the use of mobile networks).
        </p>
        <p>
          Prices displayed on the Ecosystem Operator are solely an indication of
          the executable rates and may not reflect the actual executed or
          executable price.
        </p>
        <p>
          The Ecosystem Operator shall not be liable for any and all
          circumstances in which you experience a delay in price quotation or an
          inability to trade caused by network transmission problems or
          restrictions or any other problems outside our direct control, which
          include but are not limited to the strength of the mobile signal,
          network latency, or any other issues that may arise between you and
          any internet service provider, phone service provider or any other
          service provider.
        </p>
        <p>
          Please note further that some of the features available on the
          Ecosystem Operator may not be available on any mobile application.
        </p>
        <p>
          The Terminal may require Users to download and install updates to the
          application or to their device’s operating system as such updates are
          made available.
        </p>
        <p>
          Failure to do so might lead to certain parts of the Services
          (including trading functions) becoming inaccessible to Users until
          such an update has been successfully downloaded and installed.
        </p>
        <p>
          Performance issues and security risks may arise if the Terminal is
          used on devices with customised or otherwise non-standard operating
          software or as a result of other software installed on such devices.
        </p>
      </div>
    </Box>
  )
}

export default TermsOfServices
