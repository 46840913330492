

// const LandingVideo = () => {
//   //FIXME: change pro env
//   const mainVideoUrl = 'https://cdn.apeterminal.io/videos/Launchpads%20are%20one%20of%20the.mp4'

//   return (
//     <video
//       controls
//       style={{ width: '100%', height: 'auto' }}
//     >
//       <source src={mainVideoUrl} type="video/mp4" />
//       Your browser does not support the video tag.
//     </video>
//   )
// }

// export default LandingVideo

import { useRef, useState } from "react";
import { VideoPlayerIcon, LandingVideoThumbnail } from "../../assets";

const VideoPlayer = () => {
  const mainVideoUrl = 'https://cdn.apeterminal.io/videos/Launchpads%20are%20one%20of%20the.mp4'

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoPlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef?.current?.pause();
      } else {
        videoRef?.current?.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="ipad--video1" style={{ position: 'relative', overflow: 'hidden' }}>
      <video
        ref={videoRef}
        id="video"
        style={{
          width: '100%',
          borderRadius: '32px',
          border: '1px solid #000',
          objectFit: 'cover',
        }}
        controls={false}
        loop
        playsInline
        preload="auto"
        poster={'https://cdn.apeterminal.io/videos/get-started.jpg'}
        onClick={handleVideoPlayPause}
      >
        <source src={mainVideoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <span
        id="play-video"
        onClick={handleVideoPlayPause}
        className="play-icon"
      >
        { !isPlaying && <img src={VideoPlayerIcon} alt="play-icon" /> }
      </span>
    </div>
  );
};

export default VideoPlayer;
