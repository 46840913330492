import { Box, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { NotFoundSvg, NotFoundSvgMobile } from '../../assets'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import GreenButton from '../../components/GreenButton'
import { PATHS } from '../../constants/config'

const NotFound = () => {
  const navigate = useNavigate()
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown400 = useMediaQuery('(max-width:400px)')

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: isDown767 ? 'auto' : 'calc(100vh - 250px)',
        justifyContent: 'center',
      }}
    >
      <img
        src={!isDown767 ? NotFoundSvg : NotFoundSvgMobile}
        style={{
          height: 'auto',
        }}
        alt="not found"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
          // top: isDown400 ? '-35%' : isDown767 ? '-25%' : '-100px',
          top: isDown400 ? '-200px' : isDown767 ? '-25%' : '-100px',
        }}
      >
        <GreenButton
          text={'Go back home'}
          br="24px"
          fs={15}
          fw={400}
          fm="Inter"
          lh="22.5px"
          p="14px 32px"
          h="48px"
          sx={{ marginLeft: isDown767 ? '0%' : '48%' }}
          // w={isDown1152 ? '100%' : 'fit-content'}
          onClick={() => navigate(PATHS.home)}
        />
      </Box>
    </Box>
  )
}

export default NotFound
