import Flex from '../Flex'
import React, { useEffect, useRef, useState } from 'react'
import { useAccount, useSignTypedData } from 'wagmi'
import { NotiActiveIcon, NotiIconDefault } from '../../assets'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import ButtonLogin from '../Buttons/ButtonLogin'
import LoginModal from '../LoginModal'
import DisconnectWalletModal from '../DisconnectWalletModal'
import {
  INotificationHistoryResponse,
  useFetchNotificationHistoryAuthQuery,
  useFetchNotificationHistoryQuery,
} from '../../services/modules/notification'
import NotificationHeader from '../NotificationHeader'
import useDisconnectWalletAndProfile from '../../hooks/useDisconnectWalletAndEmail'
import {
  setKycStatus,
  setMainAppToken,
  setWalletAddress,
} from '../../redux/slices/userSlice'
import {
  getDiffNotification,
  showDiffNotification,
  storeOldNotifications,
  getOldNotifications,
  OLD_AUTH_NOTIFICATION_KEY,
  OLD_NOTIFICATION_KEY
} from '../Navbar/helper'
import { getReadNotifications, KEY_READ_NOTIFICATIONS_AUTH, KEY_READ_NOTIFICATIONS_NO_AUTH } from '../../components/NotificationHeader/helper'

const MobileNavbarNew = () => {
  const { address, chain } = useAccount()
  const dispatch = useAppDispatch()
  const { reset: resetData } = useSignTypedData()
  const { disconnectWalletAndProfile } = useDisconnectWalletAndProfile()
  const navigate = useNavigate()
  const user = useAppSelector(state => state.user)
  const { accessTokenGoogle } = user;
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [openSignautureModal, setOpenSignautureModal] = useState(false)
  const [oldNotifications, setOldNotifications] = useState<any[]>([]);
  const [showDisconnectWalletModal, setShowDisconnectWalletModal] =
    useState(false)


  //FIXME: checking first load notification
  const [isAllowShowing, setAllowShowing] = useState(false);
  setTimeout(() => {
    setAllowShowing(true)
  }, 30000);

  const isShowButtonLogin =
    !user.email && !user.emailRegisterUserAccount && !address

  // Notification
  const isLogIn =
    (user.accessTokenGoogle && user.email) ||
    (user.mainAppToken && user.emailRegisterUserAccount) ||
    user.mainAppToken
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const [showNotifications, setShowNotifications] = useState(false)
  const { data: dataNoti, isLoading: loadingData, isFetching : isFetching } =
    useFetchNotificationHistoryQuery(
      {
        limit: itemsPerPage,
        page: currentPage,
      },
      {
        pollingInterval: 5000,
        skip: !!isLogIn,
      },
    )

  const {
    data: dataNotiAuth,
    refetch: refetchNotificationHistoryAuth,
    isLoading: loadingDataAuth,
    isFetching: isFetchingAuth,
  } = useFetchNotificationHistoryAuthQuery(
    {
      limit: itemsPerPage,
      page: currentPage,
    },
    {
      pollingInterval: 5000,
      skip: !isLogIn,
    },
  )
  const [allNotifications, setAllNotifications] = useState<
    INotificationHistoryResponse[]
  >([])

  const handleRefetchhNotificationList = () => {
    refetchNotificationHistoryAuth();
    storeOldNotifications(dataNotiAuth?.data, OLD_AUTH_NOTIFICATION_KEY)
  }

  useEffect(() => {
    if ((address && user.walletAddress) || accessTokenGoogle) {
      storeOldNotifications(dataNotiAuth?.data, OLD_AUTH_NOTIFICATION_KEY)
    } else {
      storeOldNotifications(dataNoti?.data, OLD_NOTIFICATION_KEY)
    }
  }, [loadingData, loadingDataAuth])

  // Handle manual push notification when FCM not granted
  useEffect(() => {
    if (!isAllowShowing) return;

    if ((!loadingData && isFetching) || (!loadingDataAuth && isFetchingAuth)) {
      let diffNotifications;

      if ((address && user.walletAddress) || accessTokenGoogle) {
        let oldAuth = getOldNotifications(OLD_AUTH_NOTIFICATION_KEY);
        if (oldAuth === null) {
          diffNotifications = [];
          storeOldNotifications((dataNotiAuth?.data || []), OLD_AUTH_NOTIFICATION_KEY)
        } else {
          let oldNoAuth = getOldNotifications(OLD_NOTIFICATION_KEY);
          let oldNotifications = oldAuth.concat(oldNoAuth || [])
          diffNotifications = getDiffNotification(dataNotiAuth?.data || [], oldNotifications)
          storeOldNotifications((dataNotiAuth?.data || []).concat(diffNotifications || []), OLD_AUTH_NOTIFICATION_KEY)
        }
      } else {
        let oldNotifications = getOldNotifications(OLD_NOTIFICATION_KEY);
        diffNotifications = getDiffNotification(dataNoti?.data || [], oldNotifications)
        storeOldNotifications(dataNoti?.data, OLD_NOTIFICATION_KEY)
      }
      
      if (diffNotifications.length) {
        showDiffNotification(diffNotifications)
      }
    }
  }, [loadingData, isFetching, loadingDataAuth, isFetchingAuth])


  useEffect(() => {
    if (!showNotifications) {
      setCurrentPage(1)
      setAllNotifications([])
    }
  }, [showNotifications])

  const clientReadIds = getReadNotifications(isLogIn ? KEY_READ_NOTIFICATIONS_AUTH : KEY_READ_NOTIFICATIONS_NO_AUTH);
  const data = isLogIn ? dataNotiAuth : dataNoti
  const hasReadNotifications = data?.data.some(
    (notification: INotificationHistoryResponse) => !notification.isRead && !clientReadIds.includes(notification._id),
  )

  const handleToggleNotifications = () => {
    setShowNotifications(prevState => !prevState)
  }

  const notificationRef = useRef<HTMLElement | null>(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node)
      ) {
        setShowNotifications(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (!address) {
      setOpenSignautureModal(false)
      disconnectWalletAndProfile()
      dispatch(setWalletAddress(''))
      dispatch(setMainAppToken(''))
      resetData()
    }

    if (address && user.walletAddress === '') {
      dispatch(setWalletAddress(address))

      setOpenSignautureModal(true)
    }

    if (
      address &&
      user.walletAddress !== '' &&
      address !== user.walletAddress
    ) {
      console.log('setWalletAddress cond2', address)
      dispatch(setWalletAddress(address))
      dispatch(setKycStatus('rejected'))

      setOpenSignautureModal(true)
    }
  }, [address, chain, user.walletAddress])

  useEffect(() => {
    if (!address && !user.email) {
      setShowDisconnectWalletModal(false)
    }
  }, [address, navigate, user.email])

  const bellIcon = () => {
    if (isLogIn) {
      return hasReadNotifications
        ? <img src={NotiActiveIcon} width={32} height={32} alt="NotiIconActive" />
        : <img src={NotiIconDefault} width={32} height={32} alt="NotiIconDefault" />
    }

    if (data && data?.data && data?.data.length && !loadingData) {
      return hasReadNotifications
      ? <img src={NotiActiveIcon} width={32} height={32} alt="NotiActiveIcon" />
      : <img src={NotiIconDefault} width={32} height={32} alt="NotiIconDefault" />
    }

    return <img src={NotiIconDefault} width={32} height={32} alt="NotiIconDefault" />
  }

  return (
    <>
      <Flex jc={isShowButtonLogin ? 'flex-end' : 'flex-end'} w="100%" h="100%">
        <Box
          sx={{
            display: isShowButtonLogin ? 'flex' : 'block',
            alignItems: 'center',
          }}
        >
          {isShowButtonLogin && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '16px',
              }}
            >
              <ButtonLogin onClick={() => setOpenLoginModal(true)} />
            </Box>
          )}
          <Box
            onClick={handleToggleNotifications}
            display={'flex'}
            ref={notificationRef}
            flexDirection={'column'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
              position: 'relative',
            }}
          >
            {bellIcon()}
            {showNotifications && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '48px',
                  right: '-10px',
                  zIndex: 999,
                }}
              >
                <NotificationHeader
                  loadingData={loadingData}
                  loadingDataAuth={loadingDataAuth}
                  data={data}
                  currentPage={currentPage}
                  refetchNotificationHistoryAuth={
                    handleRefetchhNotificationList
                  }
                  setAllNotifications={setAllNotifications}
                  allNotifications={allNotifications}
                  showNotifications={showNotifications}
                  setCurrentPage={setCurrentPage}
                  setShowNotifications={setShowNotifications}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Flex>
      <LoginModal
        open={openLoginModal}
        onClose={() => setOpenLoginModal(false)}
        openSignautureModal={openSignautureModal}
        setOpenSignautureModal={setOpenSignautureModal}
      />
      <DisconnectWalletModal
        open={showDisconnectWalletModal}
        setOpen={setShowDisconnectWalletModal}
      />
    </>
  )
}

export default MobileNavbarNew
