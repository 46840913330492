import { TableCell, styled } from '@mui/material'

export const StyledHeadTableCell = styled(TableCell)({
  backgroundColor: '#000000',
  borderBottom: '1px solid #0F1113',
  color: '#767676',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  height: '48px',
  flexShrink: 1,
  whiteSpace: 'nowrap',
})

export const FixedHeadTableCell = styled(StyledHeadTableCell)({
  position: 'sticky',
  left: 0,
  zIndex: 3,
  boxShadow: '5px 0px 10px rgba(0, 0, 0, 0.5)',
  borderRight: '1px solid #1D1F21',
})

export const StyledBodyTableCell = styled(TableCell)({
  height: '72px',
  borderBottom: '1px solid #1D1F21',
  color: '#B1B4B3',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  flexShrink: 1,
  whiteSpace: 'nowrap',
  padding: '0px 20px',
  maxHeight: '56px',
  lineHeight: '15px',
})

export const FixedBodyTableCell = styled(StyledBodyTableCell)({
  position: 'sticky',
  left: 0,
  zIndex: 1,
  backgroundColor: '#0F1113',
  textAlign: 'center',
  boxShadow: '5px 0px 10px rgba(0, 0, 0, 0.5)',
  borderRight: '1px solid #1D1F21',
})
