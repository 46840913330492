export const CloseIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5986_5100)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.81843 0.404221C1.42791 0.0136971 0.794746 0.0136971 0.404221 0.404221C0.0136971 0.794746 0.0136971 1.42791 0.404221 1.81843L8.58848 10.0027L0.404296 18.1869C0.0137712 18.5774 0.0137721 19.2106 0.404296 19.6011C0.794821 19.9916 1.42798 19.9916 1.81851 19.6011L10.0027 11.4169L18.1869 19.6011C18.5774 19.9916 19.2106 19.9916 19.6011 19.6011C19.9916 19.2106 19.9916 18.5774 19.6011 18.1869L11.4169 10.0027L19.6012 1.81843C19.9917 1.42791 19.9917 0.794746 19.6012 0.404221C19.2106 0.0136971 18.5775 0.013697 18.187 0.404221L10.0027 8.58848L1.81843 0.404221Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5986_5100">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
