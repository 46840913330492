export const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <mask
        id="mask0_508_1124"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="20"
      >
        <rect x="0.625" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_508_1124)">
        <path
          d="M4.04175 14.1957V13.1124H17.2084V14.1957H4.04175ZM4.04175 10.5418V9.45854H17.2084V10.5418H4.04175ZM4.04175 6.888V5.80469H17.2084V6.888H4.04175Z"
          fill="#F1EDF2"
          fillOpacity="0.6"
        />
      </g>
    </svg>
  );
};
