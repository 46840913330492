import { Box } from '@mui/material'
import Lottie from 'lottie-react'
import LogoAnimation from '../../animations/logo loading white.json'

const AnimatedLoading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '50vh',
        height: '100%',
      }}
    >
      <Lottie
        animationData={LogoAnimation}
        loop={true}
        style={{
          height: '200px',
        }}
      />
    </Box>
  )
}

export default AnimatedLoading
