import { TypeNoti } from '../../services/modules/notification'
import eventBus from '../../sockets/event-bus'
import { ESocketEvent } from '../../sockets/socket'

export const OLD_AUTH_NOTIFICATION_KEY = 'oldAuthNotifications'
export const OLD_NOTIFICATION_KEY = 'oldNotifications'
export const LAST_TIME_NOTIFY = 'lastTimeNotify'


const isNotificationGranted = () => {
  const grantedNotificationPerm = 'granted'

  if (typeof Notification !== 'function') return false

  return Notification.permission === grantedNotificationPerm
}

/**
 * Get list of notification different with old list
 * 
 * @param newList 
 * @param oldList 
 * 
 * @returns {Array}
 */
export const getDiffNotification = (newList: any = [], oldList: any = []) => {
  if (!Array.isArray(oldList) || !Array.isArray(newList)) return [];

  let currentTime = new Date().getTime() - 5000;
  let lastTimeNotify: any = localStorage.getItem(LAST_TIME_NOTIFY)
  lastTimeNotify = Number(lastTimeNotify) || currentTime;
  localStorage.setItem(LAST_TIME_NOTIFY, JSON.stringify(currentTime))

  const oldIds = oldList.map((i: any) => i._id);
  const newIds = newList.map((i: any) => i._id);

  let diffIds = newIds.filter((item: any) => !oldIds.includes(item));
  if (!diffIds.length) return []

  return newList.filter((i: { _id: string, time: number }) => i.time >= lastTimeNotify && diffIds.includes(i._id))
}

/**
 * 
 * @param notifications 
 * @void
 */
export const showDiffNotification = (notifications: any[]) => {
  let delay = 3000 // 3s

  let isAllowNotification = true // FIXME: continuously noti

  if (isNotificationGranted() || !isAllowNotification) return

  notifications.forEach(function (notification, index) {
    var run = setTimeout(function () {
      if (notification.type === TypeNoti.MANUAL) {
        eventBus.dispatch(ESocketEvent.BROADCAST, {
          title: notification.title || 'Ape Terminal Notification',
          content: notification.content || 'You have a new notification!',
          time: notification.time || new Date().getTime(),
        })
      } else {
        let notiPayload = {
          projectId: notification?.project?._id || '',
          projectName: notification?.project?.name || 'APE Terminal',
          routeName: notification.routeName || '',
          projectLogo: notification?.project.logoUrl || '',
          startRegistration: Number(notification.startRegistration) || '',
          endRegistration: Number(notification.endRegistration) || '',
          investmentStartDate: Number(notification.investmentStartDate) || '',
          time: Number(notification.time) || new Date().getTime(),
          type: notification.type || '',
        }

        eventBus.dispatch(notification.type === TypeNoti.REGISTRATION ? ESocketEvent.EVENT_REGISTRATION : ESocketEvent.RESPONSE_EVENT_CONTRIBUTION, notiPayload)
      }

      
      clearTimeout(run);
    }, delay * index);
  });

  // localStorage.setItem(LAST_TIME_NOTIFY, JSON.stringify(new Date().getTime()))
}

/**
 * @param notifications 
 */
export const storeOldNotifications = (notifications: any, key = OLD_NOTIFICATION_KEY) => {
  localStorage.setItem(key, JSON.stringify(notifications || []))
}

/**
 * Get old notification from storage
 * @returns array
 */
export const getOldNotifications = (key = OLD_NOTIFICATION_KEY) => {
  let oldNotifications = localStorage.getItem(key)
  if (!oldNotifications) return null;

  return JSON.parse(oldNotifications || '[]') || [];
}
