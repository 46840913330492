import {
  Box,
  Dialog,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { LightCross } from '../../assets'
import { replaceUnderscoresWithSpaces } from '../../utils'

type Props = {
  open: boolean
  handleClose: () => void
  onSelection: (project: any) => void
  projectList: any[]
}

const ProjectItem = ({ logo, title, symbol, onSelection }: any) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      flexWrap={'wrap'}
      padding={'8px'}
      sx={{
        borderRadius: '12px',
        height: '56px',
        maxHeight: '56px',
        '&:hover': {
          backgroundColor: '#1D1F21',
          cursor: 'pointer',
        },
      }}
      onClick={onSelection}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'row'}
      >
        <img
          src={logo}
          alt="launch-logo"
          style={{
            width: '40px',
            height: '40px',
            objectFit: 'cover',
          }}
        />
        <Box display={'flex'} flexDirection={'column'} ml={'16px'}>
          <Tooltip
            title={
              title.length >= 30 ? replaceUnderscoresWithSpaces(title) : ''
            }
            placement="top"
          >
            <Typography
              fontWeight={400}
              fontSize={15}
              lineHeight={'22.5px'}
              letterSpacing={'0px'}
              textAlign={'left'}
              fontFamily={'Inter'}
              color="#FFFFFF"
              sx={{
                maxWidth: '226px',
                overflow: 'hidden',
                display: '-webkit-box',
                wordBreak: 'break-word',
                marginBottom: '6px',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              {replaceUnderscoresWithSpaces(title)}
            </Typography>
          </Tooltip>
          <Typography
            fontWeight={400}
            fontSize={12}
            lineHeight={'15.6px'}
            letterSpacing={'0px'}
            textAlign={'left'}
            fontFamily={'Inter'}
            color="#7E8180"
          >
            {symbol}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const NewSelectProjectModal = ({
  open,
  handleClose,
  onSelection,
  projectList,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          paddingX: '16px',
          paddingY: '10px',
          maxWidth: '332px',
          width: '100%',
          height: '363px',
          borderRadius: '24px',
          border: '0.5px solid rgba(255, 255, 255, 0.16)',
          backgroundColor: '#0F1113',
          backgroundImage:
            'radial-gradient(69.23% 49.77% at 40.74% -11.21%, rgba(200, 254, 11, 0.08) 0%, rgba(200, 254, 11, 0) 100%)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginBottom: '16px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '17px',
            fontWeight: 500,
            lineHeight: '27.2px',
            letterSpacing: '0px',
            textAlign: 'left',
            color: '#FFFFFF',
            paddingTop: '14px',
            paddingLeft: '8px',
          }}
        >
          Select Project
        </Typography>
        <IconButton
          edge="end"
          onClick={handleClose}
          aria-label="close"
          sx={{
            backgroundColor: '#1D1F21',
            height: '32px',
            width: '32px',
            marginRight: '0px',
            transform: 'translateX(6px)',
          }}
        >
          <img src={LightCross} alt="close" height={14} width={14} />
        </IconButton>
      </Box>

      <Stack
        direction={'column'}
        width="100%"
        sx={{
          flex: 1,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
        }}
      >
        {projectList.length > 0 &&
          projectList.map(project => (
            <ProjectItem
              key={project.projectTitle || project.title}
              logo={project.logoUrl || project.logo}
              title={project.projectTitle || project.title}
              symbol={project.symbol}
              onSelection={() => onSelection(project.routeName || project)}
            />
          ))}
      </Stack>
    </Dialog>
  )
}

export default NewSelectProjectModal
