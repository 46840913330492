import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  styled,
  Box,
  useMediaQuery,
  useTheme,
  Collapse,
} from '@mui/material';
import { ForwardArrow } from '../../assets';
import { useAppSelector } from '../../hooks';

type Phase = 'Subscription' | 'Snapshot' | 'Contribution' | 'Redemption';

const CircleContainer = styled('div')(({ theme }) => ({
  width: 32,
  height: 32,
  background: '#0F1113',
  boxShadow:
    '0px 0.5px 0px rgba(255, 255, 255, 0.15), inset 0px 0.5px 0px rgba(255, 255, 255, 0.16)',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '16px',
}))

const NumberInsideCircle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Hellix',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '120%',
  letterSpacing: '-0.01em',
  color: '#FFFFFF',
}))

const getShortenedMonthName = (text: string | any) => {
  if (typeof text != 'string') return text
  return text
    .replaceAll(/January/gi, 'Jan.')
    .replaceAll(/February/gi, 'Feb.')
    .replaceAll(/September/gi, 'Sep.')
    .replaceAll(/October/gi, 'Oct.')
    .replaceAll(/November/gi, 'Nov.')
    .replaceAll(/December/gi, 'Dec.')
}

const TimeboxList = ({ data, idoData }: { data: any; idoData?: any }) => {
  const theme = useTheme();
  const now = new Date().getTime();
  const [currentTime, setCurrentTime] = useState(now);
  const [isExpanded, setIsExpanded] = useState(false);
  const appState = useAppSelector(state => state.app)
  const { isSidebarCollapse } = appState
  const isScreenUp1440 = useMediaQuery(theme.breakpoints.up(isSidebarCollapse ? 1390 : 1440))
  const isScreenUp1680 = useMediaQuery(theme.breakpoints.up(1680))
  const isScreenBetween = useMediaQuery(theme.breakpoints.between(1200, 1440))

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(now);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getCurrentPhase = () => {
    if (currentTime < idoData?.endDate) {
      return 'Subscription';
    } else if (currentTime > idoData?.endDate && currentTime < idoData?.investmentStartDate) {
      return 'Snapshot';
    } else if (currentTime >= idoData?.investmentStartDate && currentTime <= idoData?.investmentEndDate) {
      return 'Contribution';
    } else if (currentTime > idoData?.investmentEndDate) {
      return 'Redemption';
    }
    return 'Subscription';
  };

  const renderListItem = (item: any, index: number) => {
    const currentPhase = getCurrentPhase()
    const phases = ['Subscription', 'Snapshot', 'Contribution', 'Redemption']

    const getPhaseColor = (currentPhase: Phase, index: number): string => {
      const currentPhaseIndex = phases.indexOf(currentPhase)
      // Past phases are green or current phase is green
      if (index === currentPhaseIndex) return '#63EA71'; 
      // Next phase is white
      if (index === currentPhaseIndex + 1) return '#FFFFFF'; 
      return '#767676'
    }
    const phaseColor = getPhaseColor(currentPhase, index)

    const isActive = (index: number) => {
      return phases.indexOf(currentPhase) === index
    }

    return (
      <ListItem
        key={index}
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
          display: 'flex',
          padding: '0px',
          gap: '12px',
        }}
      >
        <ListItemAvatar sx={{ width: '32px', minWidth: '32px' }}>
          <CircleContainer className={isActive(index) ? 'ripple-animate' : ''}>
            <NumberInsideCircle sx={{ color: phaseColor }}>
              {index + 1}
            </NumberInsideCircle>
          </CircleContainer>
        </ListItemAvatar>
        <ListItemText
          primary={phases[index]}
          secondary={getShortenedMonthName(item.subHeading)}
          sx={{
            textAlign: 'left',
            '& .MuiListItemText-primary': {
              color: phaseColor,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: 500,
            },
            '& .MuiListItemText-secondary': {
              color: '#767676',
              textWrap: 'nowrap',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              fontSize: '12px',
              fontFamily: 'Inter',
              fontWeight: 400,
            },
          }}
        />

        <img
          src={ForwardArrow}
          alt="arrow"
          style={{
            width: '10px',
            height: '20px',
            marginInline: '24px',
            display: index === 3 ? 'none' : 'block',
          }}
        />
      </ListItem>
    )
  }

  return (
    <Box
      sx={{
        background:
          'linear-gradient(142.67deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.09) 51.04%, rgba(255, 255, 255, 0.16) 100%)',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
        my: '24px',
        // width: isDown1520 ? '100%' : `calc(${window.innerWidth}px - 32px)`,
        width:'100%',
        // maxWidth: '1200px',
        maxWidth: isSidebarCollapse ? 'calc(50vw + 26.5rem)' : '1200px',
      }}
    >
      {isScreenUp1440 ? (
        <List
          sx={{
            display: 'flex',
            overflowX: 'auto',
            bgcolor: 'background.paper',
            // paddingBottom: '24px',
            alignItems: 'stretch',
            flexDirection: 'row',
            justifyContent: 'center',
            // height: '64px',
            // padding: isScreenUp1520 ? '30px' : '16px',
            margin: '1px',
            background: '#0F1113',
            border: 'none',
            borderRadius: '16px',
            paddingInlineStart: isScreenUp1680 ? '40px' : '15px',
            width: `calc(100% - 2px)`,
          }}
        >
          {data.map(renderListItem)}
        </List>
      ) : (
        <List
          sx={{
            display: 'flex',
            overflowX: 'auto',
            bgcolor: 'background.paper',
            paddingBottom: '24px',
            gap: isExpanded ? '0px' : '24px',
            alignItems: 'stretch',
            flexDirection: isExpanded ? 'column' : 'row',
            justifyContent: isScreenBetween ? 'start' : 'center',
            height: isExpanded ? isScreenBetween ? '160px' : '232px' : '72px',
            paddingX: '16px',
            paddingY: '8px',
            margin: '1px',
            background: '#0F1113',
            borderRadius: '16px',
            width: `calc(100% - 2px)`,
            flexWrap: isScreenBetween ? 'wrap' : 'nowrap',
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isScreenBetween ? (
            data.map((item: any, index: any) => (
              <Box
                key={index}
                sx={{ flex: '0 1 calc(50% - 16px)', marginBottom: '16px' }}
              >
                {renderListItem(item, index)}
              </Box>
            ))
          ) : (
            <>
              {renderListItem(data[0], 0)}
              <Collapse in={isExpanded} timeout={0} unmountOnExit orientation="vertical">
                {data.slice(1, 4).map((item: any, index: any) => renderListItem(item, index + 1))}
              </Collapse>
            </>
          )}
        </List>
      )}
    </Box>
  );
};

export default TimeboxList;
