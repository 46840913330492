import {
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { SearchIcon } from '../../../assets'
import NoDataTable from './NoDataTable'
import NoDataBox from './NoDataBox'
import { Box, Typography, useMediaQuery } from '@mui/material'
import MyReferralsTable from './MyReferralsTable'
import SkeletonForMyReferralsTable from './SkeletonForMyReferralsTable'
import FilterStatusMySales from './FilterStatusMySales'
import { IFilterStatus } from './components/LabelConstant'
import { useFetchUserReferralsWithSearchQuery } from '../../../services/modules/reffer'
import { useAppSelector } from '../../../hooks'
import { useAccount } from 'wagmi'
import { IdoStatus } from '../../../services/modules/project'
import { IResponseUserPortalItem } from '../../../services/modules/participationsV2'
import { useLocation } from 'react-router-dom'
import SaleTableContainer from './components/SaleTableContainer'
import ClaimTableContainer from './components/ClaimTableContainer'
import HistoryTableContainer from './components/HistoryTableContainer'

interface LocationState {
  activeTab?: ETabsName
}

export enum ETabsName {
  IDOs = 'IDOs',
  Claim = 'Claim',
  Referral = 'Referrals',
}

const referralsHeaders = [
  'Projects',
  'People used my link',
  'Multiplier',
  'Status',
  'My Referral links',
]

const statusOrder: IdoStatus[] = [
  IdoStatus.PARTICIPATION,
  IdoStatus.INVESTMENT,
  IdoStatus.TGE,
  IdoStatus.COMPLETED,
]

const UserTransactions = () => {
  const limit = 5
  const location = useLocation()
  const { state } = location as { state: LocationState | null }
  const isDown1536 = useMediaQuery('(max-width:1536px)')
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown600 = useMediaQuery('(max-width:600px)')
  const isDown434 = useMediaQuery('(max-width:434px)')
  const [page, setPage] = useState(1)
  const [statusFilter, setStatusFilter] = useState<IFilterStatus>(IFilterStatus.SALES)
  const [data, setData] = useState<IResponseUserPortalItem[]>([])
  const [searchName, setSearchName] = useState('')
  const [debouncedSearchName, setDebouncedSearchName] = useState('')
  const [activeTab, setActiveTab] = useState(ETabsName.IDOs)
  const user = useAppSelector(state => state.user)
  const { accessTokenGoogle, mainAppToken } = user
  const { address } = useAccount()
  const [isShowButton, setIsShowButton] = useState(false)
  const [isRefresh, setRefresh] = useState(false)

  const isIDOsTab = activeTab === ETabsName.IDOs;
  const isReferralTab = activeTab === ETabsName.Referral;

  const {
    data: userReferralData,
    isLoading: userReferralDataLoading,
    refetch: fetchUserReferrals,
  } = useFetchUserReferralsWithSearchQuery(
    { name: searchName || undefined },
    {
      skip: !address && !mainAppToken && !accessTokenGoogle,
    },
  )

  useEffect(() => {
    if ((mainAppToken && address) || accessTokenGoogle) {
      fetchUserReferrals()
    } else {
      setData([])
    }
  }, [mainAppToken, address, accessTokenGoogle, fetchUserReferrals])

  useEffect(() => {
    if (state?.activeTab) {
      setActiveTab(state.activeTab)
    }
  }, [state])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchName(searchName)
    }, 700)
    return () => {
      clearTimeout(handler)
    }
  }, [searchName])

  useEffect(() => {
    if (debouncedSearchName) {
      if (isReferralTab) {
        fetchUserReferrals()
      }
    } else {
      setIsShowButton(false)
    }
  }, [
    activeTab,
    debouncedSearchName,
    fetchUserReferrals,
  ])

  const handleSearchChange = (e: {
    target: { value: SetStateAction<string> }
  }) => {
    setSearchName(e.target.value)
  }

  const handleSearchSubmit = (
    e: KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()
    setPage(1)
    if (isReferralTab) {
      fetchUserReferrals()
    }
    setIsShowButton(true)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchSubmit(e)
    }
  }

  const handleTabClick = (tab: string) => {
    setPage(1)
    setActiveTab(tab as ETabsName)
    setStatusFilter(IFilterStatus.SALES)
  }

  const handleFilterChange = (newStatus: IFilterStatus) => {
    setPage(1)
    setStatusFilter(newStatus)
  }

  const handleRefresh = () => {
    //TODO: try another way to refresh
    setSearchName('')
    setStatusFilter(IFilterStatus.SALES)
    setRefresh(true)
    setTimeout(() => {
      setRefresh(false)
    }, 1000);
  }

  const isShowSale = statusFilter === IFilterStatus.SALES
  const isShowClaim = statusFilter === IFilterStatus.CLAIM
  const isShowHistory = statusFilter === IFilterStatus.HISTORY

  return (
    <div className="user-transactions-wrapper">
      <div className="user-transactions--header">
        <aside>
          <b
            className={`transaction-toggle--invest`}
            style={{
              backgroundColor: isIDOsTab ? '#27B079' : '',
              border: isIDOsTab ? 'unset' : '1px solid #2b3d33',
            }}
            onClick={() => handleTabClick(ETabsName.IDOs)}
          >
            <span style={{ color: isIDOsTab ? '#fff' : '' }}>{ETabsName.IDOs}</span>
          </b>
          <b
            className={`transaction-toggle--connectfi `}
            style={{
              backgroundColor: isReferralTab ? '#386a91' : '',
              border: isReferralTab ? 'unset' : '1px solid rgb(128, 156, 233)',
            }}
            onClick={() => handleTabClick(ETabsName.Referral)}
          >
            <span style={{ color: isReferralTab ? '#fff' : '' }}>{ETabsName.Referral}</span>
          </b>
        </aside>
        <aside style={{ width: isDown434 ? '100%' : '' }}>
          <div className="search-box">
            <input
              type="text"
              placeholder="Search"
              value={searchName}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
            />
            <button onClick={handleSearchSubmit}>
              <img src={SearchIcon} alt="SearchIcon" />
            </button>
          </div>
        </aside>
      </div>

      {isIDOsTab && (
        <>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: isDown600 ? 'column-reverse' : 'row'
          }}>
            <FilterStatusMySales
              status={statusFilter}
              onChange={handleFilterChange}
            />
            <Box
              sx={{
                backgroundColor: '#252525',
                borderRadius: '40px',
                border: '1px solid #767676',
                padding: '4px 24px',
                cursor: 'pointer',
                alignSelf: isDown600 ? 'flex-end' : '',
                marginBottom: isDown600 ? '-24px' : '',
                zIndex: '1'
              }}
              onClick={handleRefresh}
            >
              <Typography
                fontSize={'12px'}
                fontWeight={500}
                lineHeight={'22.5px'}
                fontFamily={'Inter'}
                sx={{
                  color: '#B1B4B3',
                }}
              >
                Refresh
              </Typography>
            </Box>
          </Box>
          {/* {statusFilter === IFilterStatus.SALES && <Box sx={{display: 'none'}}><SaleTableContainer keyword={searchName}/></Box>}
          {statusFilter === IFilterStatus.CLAIM && <ClaimTableContainer keyword={searchName}/>}
          {statusFilter === IFilterStatus.HISTORY && <HistoryTableContainer keyword={searchName}/>} */}
          <Box sx={{
            display: isShowSale ? 'block' : 'none',
            width: '100%',
          }}>
            <SaleTableContainer isRefresh={isRefresh} isVisible={isShowSale} keyword={searchName}/>
          </Box>
          <Box sx={{
            display: isShowClaim ? 'block' : 'none',
            width: '100%',
          }}>
            <ClaimTableContainer isRefresh={isRefresh} isVisible={isShowClaim} keyword={searchName}/>
          </Box>
          <Box sx={{
            display: isShowHistory ? 'block' : 'none',
            width: '100%',
          }}>
            <HistoryTableContainer isRefresh={isRefresh} isVisible={isShowHistory} keyword={searchName}/>
          </Box>
        </>
      )}

      {isReferralTab &&
        (userReferralDataLoading ? (
          <SkeletonForMyReferralsTable />
        ) : userReferralData?.length ? (
          <MyReferralsTable
            isShowButton={isShowButton}
            myReferrals={userReferralData}
          />
        ) : (
          <>
            {!isDown1000 ? (
              <NoDataTable headers={referralsHeaders} />
            ) : (
              <NoDataBox />
            )}
          </>
        ))}
    </div>
  )
}
export default UserTransactions;
