import { FormControl, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Modal } from 'antd'
import { isArray } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CancelIcon } from '../../assets'
import { VerifiedIcon } from '../../assets/revamp/icons/VerifiedIcon'
import { WrappedEmailIcon } from '../../assets/revamp/icons/WrappedEmailIcon'
import ConfirmModal, { ConfirmModalProps } from '../../components/ConfirmModal'
import VerifyEmailModal from '../../components/VerifyEmailModal'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setSnackbar } from '../../redux/slices/themeSlice'
import {
  useFetchUserSubscribeQuery,
  useRequestOtpMutation,
  useSubscribeForNewsLetterAuthMutation,
  useUnSubscribeForNewsLetterMutation,
} from '../../services/modules/users'
import { validateEmail } from '../../utils/function'
import GreenButton from '../GreenButton'

export enum EVerifyEmailActionType {
  verifyEmail = 'verify-email',
  subcribeNewsletter = 'subcribe-newsletter',
}

export interface VerifyEmailModalProps {
  open: boolean
  onClose: () => void
  onChangeEmailSuccess?: () => void
}

const ProcessSubscriptionModal: React.FC<VerifyEmailModalProps> = props => {
  const { open, onClose, onChangeEmailSuccess } = props

  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user)
  const { data, refetch } = useFetchUserSubscribeQuery(undefined, {
    skip: !user.accessTokenGoogle && !user.mainAppToken,
  })

  const [focusVisible, setFocusVisible] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const [requestOtp] = useRequestOtpMutation()
  const [subscribeForNewsLetter] = useSubscribeForNewsLetterAuthMutation()
  const [unSubscribeForNewsLetter] = useUnSubscribeForNewsLetterMutation()

  const [isSubscribed, setIsSubscribed] = useState(data?.isSubscribed || false)
  const [valueEmail, setValueEmail] = useState('')
  const [confirmModalProps, setConfirmModalProps] =
    useState<ConfirmModalProps | null>(null)
  const [nextSendableTime, setNextSendableTime] = useState<number | undefined>(
    undefined,
  )
  const [isUnsubscribing, setIsUnsubscribing] = useState(false)
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false)

  const isEmailVerified = useMemo(
    () => !!data?.verifiedAt && data?.receivedEmail,
    [data],
  )
  const isVerifyingEmail = !isSubscribed && data?.receivedEmail

  const showMessage = useCallback(
    (message: string, severity?: 'success' | 'error') =>
      dispatch(
        setSnackbar({
          open: true,
          severity: severity ?? 'error',
          message,
        }),
      ),
    [dispatch],
  )

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value?.trim?.()
    setValueEmail(email)
    if (email && !validateEmail(email)) {
      setError('Please enter a valid email address.')
    } else {
      setError(null)
    }
  }

  const handleVerifyEmail = useCallback(
    async (isResend?: boolean) => {
      try {
        setIsLoading(true)
        const response = await requestOtp({
          email: data?.receivedEmail ?? '',
        }).unwrap()
        if (response?.nextSendableTime) {
          setNextSendableTime(response?.nextSendableTime)
          showMessage(` ${response?.message}!`, 'success')
          if (isResend) {
            return
          }
          setShowVerifyEmailModal(true)
        }
      } catch (error: any) {
        const displayMessage = isArray(error?.data?.message)
          ? error?.data?.message?.[0]
          : error?.data?.message

        showMessage(displayMessage)
        refetch()
      } finally {
        setIsLoading(false)
      }
    },
    [requestOtp, data?.receivedEmail, showMessage, refetch],
  )

  const handleUnSubscribe = useCallback(async () => {
    try {
      setIsUnsubscribing(true)
      setIsSubscribed(false)
      if (user.accessTokenGoogle || user.mainAppToken) {
        const result = await unSubscribeForNewsLetter().unwrap()
        if (result) {
          // showMessage(
          //   `${result.message.split('!')[0]} ${valueEmail} !`,
          //   'success',
          // )
          setValueEmail('')
          onChangeEmailSuccess?.()
          refetch()
        }
      }
    } catch (error) {
      refetch()
      setIsSubscribed(false)
      console.log(error, 'error')
    } finally {
      setIsUnsubscribing(false)
      // setShowConfirmModal(false)
    }
  }, [
    onChangeEmailSuccess,
    refetch,
    unSubscribeForNewsLetter,
    user.accessTokenGoogle,
    user.mainAppToken,
  ])

  const handleSubscribe = useCallback(async () => {
    if (!validateEmail(valueEmail)) {
      setError('Please enter a valid email address.')
      return
    }

    try {
      setIsLoading(true)
      const result = await subscribeForNewsLetter({
        email: valueEmail,
      }).unwrap()
      if (result) {
        onChangeEmailSuccess?.()
        // const exclamationCount = result.message.split('!').length - 1
        // const parts = result.message.split('!')
        // let message = `${parts[0]}!`

        // if (exclamationCount > 1) {
        //   message += `${parts[1]} ${valueEmail} !`
        // } else {
        //   message += `${parts[1]} ${valueEmail} !`
        // }

        // showMessage(message, 'success')
        const response = await requestOtp({
          email: valueEmail ?? '',
        }).unwrap()
        if (response?.nextSendableTime) {
          setNextSendableTime(response?.nextSendableTime)
          showMessage(` ${response?.message}!`, 'success')
          // if (isResend) {
          //   return
          // }
          setShowVerifyEmailModal(true)
        }

        refetch()
      }
    } catch (error: any) {
      const displayMessage = isArray(error?.data?.message)
        ? error?.data?.message?.[0]
        : error?.data?.message

      showMessage(displayMessage)
      refetch()
      setIsSubscribed(false)
      console.log(error, 'error')
    } finally {
      setIsLoading(false)
      setIsLoading(false)
    }
  }, [
    onChangeEmailSuccess,
    refetch,
    requestOtp,
    showMessage,
    subscribeForNewsLetter,
    valueEmail,
  ])

  const onFocusVisible = (visible: boolean) => {
    setFocusVisible(visible)
  }

  const handleProcessing = useCallback(() => {
    if (isVerifyingEmail) {
      handleVerifyEmail()
      return
    }

    handleSubscribe()
  }, [handleSubscribe, handleVerifyEmail, isVerifyingEmail])

  useEffect(() => {
    if (data?.isSubscribed !== undefined) {
      setIsSubscribed(data.isSubscribed)
    }
    if (data?.receivedEmail) {
      setValueEmail(data?.receivedEmail)
    }
  }, [data])

  useEffect(() => {
    if ((user.email || user.emailRegisterUserAccount) && !data?.receivedEmail) {
      setValueEmail(user.email || user.emailRegisterUserAccount || '')
    }
  }, [user.email, user.emailRegisterUserAccount])

  return (
    <>
      <Modal
        className="process-subscription-modal"
        centered
        open={open}
        onCancel={onClose}
        footer={null}
        maskClosable={false}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '20px',
            borderRadius: '12px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <WrappedEmailIcon />
          </Box>

          <Box
            sx={{
              color: '#fff',
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '48px',
              textAlign: 'center',
            }}
          >
            Verify Email
          </Box>

          {/* {!isVerifyingEmail ? ( */}
          <Typography
            sx={{
              color: '#7E8180',
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: '18px',
            }}
          >
            Please subscribe to Ape Terminal Newsletter to participate in IDO
            Projects
          </Typography>
          {/* ) : null} */}
          <FormControl>
            <TextField
              value={valueEmail}
              placeholder="Enter your email"
              error={!!error}
              helperText={error}
              onChange={handleEmailChange}
              onFocus={() => {
                onFocusVisible && onFocusVisible(true)
              }}
              onBlur={() => {
                onFocusVisible && onFocusVisible(false)
              }}
              autoComplete="off"
              variant="outlined"
              InputProps={{
                readOnly: isSubscribed || !!data?.receivedEmail,
                renderSuffix: () => {
                  // if (!isSubscribed && !data?.receivedEmail) {
                  //   return null
                  // }

                  if (isEmailVerified) {
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginRight: '10px',
                        }}
                      >
                        <VerifiedIcon />
                      </Box>
                    )
                  }

                  if (
                    !isSubscribed &&
                    data?.receivedEmail &&
                    !data?.verifiedAt
                  ) {
                    return (
                      <img
                        src={CancelIcon}
                        alt="cancel-icon"
                        onClick={() => {
                          setConfirmModalProps({
                            open: true,
                            onClose: () => setConfirmModalProps(null),
                            desc: `Are you sure you want to remove email: ${data?.receivedEmail}? You can update your email later`,
                            onCancel: () => setConfirmModalProps(null),
                            onConfirm: async () => {
                              await handleUnSubscribe()
                              setConfirmModalProps(null)
                            },
                            buttonDisabled: isUnsubscribing,
                            buttonLoading: isUnsubscribing,
                          })
                        }}
                        style={{
                          cursor: 'pointer',
                          width: '20px',
                          height: '20px',
                          borderRadius: '100%',
                          backgroundColor: '#1D1F21',
                          padding: '4px',
                          color: '#B1B4B3',
                          marginRight: '10px',
                        }}
                      />
                    )
                  }

                  return null
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  '&:hover': {
                    '& fieldset': { border: 'none' },
                  },
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px !important',
                  border:
                    focusVisible && !error
                      ? '0.5px solid white'
                      : '0.5px solid rgba(255, 255, 255, 0.16)',
                  fontFamily: 'Inter',
                  fontSize: '17px',
                  fontWeight: '500',
                  lineHeight: '32px',
                  color: '#fff !important',
                  outline: 'unset !important',
                },

                '& .MuiOutlinedInput-input': {
                  color: '#fff !important',
                  outline: 'unset !important',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  outline: 'unset !important',
                },
              }}
            />
          </FormControl>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <GreenButton
              fs={15}
              lh="22.5px"
              fm="Inter"
              fw={400}
              br="24px"
              text={isVerifyingEmail ? 'Verify' : 'Proceed'}
              onClick={handleProcessing}
              outlined={true}
              w={'fit-content'}
              isLoading={isLoading}
              disabled={isLoading}
              sx={{
                minWidth: '89px',
                height: '28px',
                border: '1px solid #08493C',
                padding: '16px 20px',
                backgroundColor: '#1D1F21',
                '&:hover': {
                  border: '1px solid #27B079',
                  backgroundColor: 'transparent',
                },
              }}
            />
          </Box>
        </Box>
      </Modal>

      {showVerifyEmailModal ? (
        <VerifyEmailModal
          open={showVerifyEmailModal}
          onClose={() => setShowVerifyEmailModal(false)}
          actionType={EVerifyEmailActionType.verifyEmail}
          onResend={() => handleVerifyEmail(true)}
          onSuccess={message => {
            showMessage(message ?? '', 'success')
            refetch()
            onClose()
          }}
          onError={message => {
            showMessage(message ?? '')
          }}
          subcribedEmail={data?.receivedEmail}
          nextSendableTime={nextSendableTime}
        />
      ) : null}

      {confirmModalProps ? <ConfirmModal {...confirmModalProps} /> : null}
    </>
  )
}

export default ProcessSubscriptionModal
