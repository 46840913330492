import React from 'react'
import { ReactComponent as ArbitrumIcon } from '../../assets/arbitrum_icon.svg'
import { ReactComponent as PolygonIcon } from '../../assets/polygon_icon.svg'
import { ReactComponent as AvalancheIcon } from '../../assets/avalanche_icon.svg'
import { ReactComponent as BSCIcon } from '../../assets/bsc_icon.svg'
import { ReactComponent as BTCIcon } from '../../assets/btc_icon.svg'
import { ReactComponent as EthereumIcon } from '../../assets/ethereum_icon.svg'
import { ReactComponent as MoneroIcon } from '../../assets/monero_icon.svg'
import { ReactComponent as PolkadotIcon } from '../../assets/polkadot_icon.svg'
import { ReactComponent as SolanaIcon } from '../../assets/solana_icon.svg'
import { ReactComponent as TronIcon } from '../../assets/tron_icon.svg'
import { ReactComponent as ShibaIcon } from '../../assets/shiba_icon.svg'
import { ReactComponent as CardanoIcon } from '../../assets/cardano_icon.svg'
import { ReactComponent as LitecoinIcon } from '../../assets/litecoin_icon.svg'
import { ReactComponent as USDCIcon } from '../../assets/usdc_icon.svg'
import { ReactComponent as TetherIcon } from '../../assets/tether_icon.svg'
import { ReactComponent as XRPIcon } from '../../assets/xrp_icon.svg'
import { ReactComponent as UniswapIcon } from '../../assets/uniswap_icon.svg'
import { ReactComponent as BNBIcon } from '../../assets/bnb_icon.svg'
import { ReactComponent as BaseIcon } from '../../assets/base.svg'

interface IconMap {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

const iconMap: IconMap = {
  arbitrum: ArbitrumIcon,
  polygon: PolygonIcon,
  avalanche: AvalancheIcon,
  bsc: BSCIcon,
  'bnb smart chain': BSCIcon,
  'arbitrum one': ArbitrumIcon,
  btc: BTCIcon,
  ethereum: EthereumIcon,
  eth: EthereumIcon,
  monero: MoneroIcon,
  polkadot: PolkadotIcon,
  solana: SolanaIcon,
  tron: TronIcon,
  shiba: ShibaIcon,
  cardano: CardanoIcon,
  litecoin: LitecoinIcon,
  usdc: USDCIcon,
  tether: TetherIcon,
  xrp: XRPIcon,
  uniswap: UniswapIcon,
  base: BaseIcon,
  'binance smart chain testnet': BNBIcon,
}

const getDefaultIcon = (): React.FunctionComponent<
  React.SVGProps<SVGSVGElement>
> => EthereumIcon

const getIconByNetwork = (
  network: string,
): React.FunctionComponent<React.SVGProps<SVGSVGElement>> => {
  const normalizedNetwork = network.toLowerCase()
  //eg. 'btc and ethereum' => 'ethereum'
  if (normalizedNetwork.includes(' and ')) {
    return getIconByNetwork(normalizedNetwork.split(' and ')[1])
  }
  //eg. injective & ethereum => 'ethereum'
  if (normalizedNetwork.includes(' & ')) {
    return getIconByNetwork(normalizedNetwork.split(' & ')[1])
  }
  const iconComponent = iconMap[normalizedNetwork]
  return iconComponent || getDefaultIcon()
}

export default getIconByNetwork
