// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
  Messaging,
} from 'firebase/messaging'
import { userApi } from './modules/users'
import { store } from '../redux/configureStore'
import { FIREBASE_CONFIG } from '../constant'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG)
let messaging: Messaging | null = null

export const getFirebaseMessaging = async () => {
  return isSupported().then(res => {
    console.warn(
      `Firebase is ${res ? 'supported' : 'not supported'} in this browser`,
    )
 
    return res ? getMessaging(app) : null
  })
}

/**
 * Generate FCM token
 *
 * @param address string
 */
export const generateToken = async (address: string = '') => {
  try {
    let fbMessaging = await getFirebaseMessaging()
    if (!fbMessaging) {
      return console.warn('Firebase messaging is not available')
    }
    const token = await getToken(fbMessaging, {
      vapidKey: FIREBASE_CONFIG.vapidKey,
    })

    // Firebase subscribe topic
    subscribeToTopics({ deviceId: token, walletAddress: address })
  } catch (error) {
    console.log('Firebase subscribe: ', error)
  }
}

/**
 * Subscribe to a topic
 *
 * @param topicsData { deviceId: "string" }
 */
const subscribeToTopics = async (topicsData: any) => {
  let fbMessaging = await getFirebaseMessaging()
  if (!fbMessaging) {
    return console.warn('Firebase messaging is not available')
  }

  const mutation = userApi.endpoints.firebaseSubscribeTopic.initiate
  try {
    const result = await store.dispatch(mutation(topicsData)).unwrap()
    console.log('Subscribed to topics successfully:', result)
  } catch (error) {
    console.log('Failed to subscribe to topics:', error)
  }
}

export { messaging, getToken, onMessage, subscribeToTopics }
