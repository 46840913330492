import { Button, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Modal } from 'antd'
import { debounce, isArray } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { WrappedEmailIcon } from '../../assets/revamp/icons/WrappedEmailIcon'
import GreenButton from '../../components/GreenButton'
import {
  useSubscribeForNewsLetterAuthMutation,
  useVerifySubcribedEmailMutation,
} from '../../services/modules/users'
import { validateEmail } from '../../utils/function'
import Countdown from 'react-countdown'

export enum EVerifyEmailActionType {
  verifyEmail = 'verify-email',
  subcribeNewsletter = 'subcribe-newsletter',
}

export interface VerifyEmailModalProps {
  open: boolean
  onClose: () => void
  actionType: EVerifyEmailActionType
  onSuccess?: (message?: string, subcribedEmail?: string) => void
  onError?: (message?: string) => void
  onResend?: () => void
  subcribedEmail?: string
  nextSendableTime?: number
}

const VerifyEmailModal: React.FC<VerifyEmailModalProps> = props => {
  const {
    open,
    onClose,
    actionType,
    onSuccess,
    onError,
    onResend,
    subcribedEmail,
    nextSendableTime,
  } = props

  const [subscribeForNewsLetter] = useSubscribeForNewsLetterAuthMutation()
  const [verifySubcribedEmail] = useVerifySubcribedEmailMutation()

  const [focusVisible, setFocusVisible] = useState(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(
    () => actionType === EVerifyEmailActionType.verifyEmail,
  )
  const [otp, setOtp] = useState('')
  const newlySubcribedEmail = useRef<string | null>(null)
  const [enableResend, setEnableResend] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const inputStyles = {
    '& .MuiInputBase-root': {
      '&:hover': {
        '& fieldset': { border: 'none' },
      },
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px !important',
      border:
        focusVisible && !error
          ? '0.5px solid white'
          : '0.5px solid rgba(255, 255, 255, 0.16)',
      fontFamily: 'Inter',
      fontSize: '17px',
      fontWeight: '500',
      lineHeight: '32px',
      color: '#fff !important',
      outline: 'unset !important',
    },

    '& .MuiOutlinedInput-input': {
      color: '#fff !important',
      outline: 'unset !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      outline: 'unset !important',
    },
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value?.trim?.()
    setEmail(email)
    if (email && !validateEmail(email)) {
      setError('Please enter a valid email address.')
    } else {
      setError(null)
    }
  }
  const handleOTPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value?.trim?.()
    if (value) {
      setOtp(value)
      return
    }
    setOtp('')
  }

  const handleProcessing = useCallback(
    debounce(async () => {
      try {
        setIsLoading(true)
        if (isVerifyingEmail) {
          const response = await verifySubcribedEmail({
            email: newlySubcribedEmail.current ?? subcribedEmail ?? '',
            otp,
          }).unwrap()

          if (response?.verifiedAt) {
            onSuccess?.(`Email verified successfully`)
            onClose()
          }
          return
        }

        if (!email) {
          setError('Email must not be empty.')
          return
        }

        const response = await subscribeForNewsLetter({ email }).unwrap()
        newlySubcribedEmail.current = email

        await onSuccess?.(`${response.message}`, newlySubcribedEmail.current)
        setIsVerifyingEmail(true)
        // setEnableResend(false)
      } catch (error: any) {
        const displayMessage = isArray(error?.data?.message)
          ? error?.data?.message?.[0]
          : error?.data?.message
        if (isVerifyingEmail) {
          onError?.(`Verify email failed: ${displayMessage}`)
          return
        }

        onError?.(`Subscription failed: ${displayMessage}`)
      } finally {
        setIsLoading(false)
      }
    }, 300),
    [
      email,
      isVerifyingEmail,
      onClose,
      onError,
      onSuccess,
      otp,
      subcribedEmail,
      subscribeForNewsLetter,
      verifySubcribedEmail,
    ],
  )

  useEffect(() => {
    if (nextSendableTime && nextSendableTime > Date.now()) {
      setEnableResend(false)
    }
  }, [nextSendableTime])

  return (
    <Modal
      className="verify-email-modal"
      centered
      open={open}
      onCancel={onClose}
      footer={null}
      maskClosable={false}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          padding: '20px',
          borderRadius: '12px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <WrappedEmailIcon />
        </Box>

        <Box
          sx={{
            color: '#fff',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '48px',
            textAlign: 'center',
          }}
        >
          Verify Email
        </Box>

        {isVerifyingEmail ? (
          <TextField
            value={otp}
            placeholder="Input OTP"
            error={!!error}
            helperText={error}
            onChange={handleOTPChange}
            onFocus={() => {
              setFocusVisible(true)
            }}
            onBlur={() => {
              setFocusVisible(false)
            }}
            autoComplete="off"
            variant="outlined"
            InputProps={{
              renderSuffix: () => {
                return (
                  <Button
                    sx={{
                      backgroundColor: '#1D1F21',
                      color: '#B1B4B3',
                      fontSize: '14px',
                      marginRight: '10px',
                      textTransform: 'none',
                      borderRadius: '60px',
                      padding: '8px 14px',
                      height: '28px',
                      lineHeight: '18px',
                      minWidth: 'fit-content',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '4px',
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                        backgroundColor: '#1D1F21',
                        opacity: 0.8,
                        border: '1px solid #B1B4B3',
                      },
                    }}
                    onClick={() => onResend?.()}
                    disabled={!enableResend}
                  >
                    <Box
                      sx={{
                        textDecoration: 'underline',
                        lineHeight: '18px',
                        alignSelf: 'center',
                      }}
                    >
                      Resend
                    </Box>

                    {enableResend ? null : (
                      <Countdown
                        date={nextSendableTime}
                        onComplete={() => setEnableResend(true)}
                        renderer={({
                          days,
                          hours,
                          minutes,
                          seconds,
                          completed,
                        }) => {
                          let _timerString = ''
                          if (days > 0) {
                            _timerString = `${days}d${hours}h${minutes}m${seconds}s`
                          } else if (hours > 0) {
                            _timerString = `${hours}h${minutes}m${seconds}s`
                          } else if (minutes > 0) {
                            _timerString = `${minutes}m${seconds}s`
                          } else {
                            _timerString = `${seconds}s`
                          }
                          return (
                            <Typography
                              fontFamily={'Inter'}
                              fontWeight={600}
                              sx={{
                                color: 'rgba(255, 255, 255, 0.3)',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '18px',
                                alignSelf: 'center',
                              }}
                              display={'inline'}
                            >
                              {`(${_timerString})`}
                            </Typography>
                          )
                        }}
                      />
                    )}
                  </Button>
                )
              },
            }}
            sx={inputStyles}
          />
        ) : (
          <>
            <Typography
              sx={{
                color: '#7E8180',
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '18px',
              }}
            >
              Please subscribe to Ape Terminal Newsletter to participate in IDO
              Projects
            </Typography>
            <TextField
              value={email}
              placeholder="Enter Email"
              error={!!error}
              helperText={error}
              onChange={handleEmailChange}
              onFocus={() => {
                setFocusVisible(true)
              }}
              onBlur={() => {
                setFocusVisible(false)
              }}
              autoComplete="off"
              variant="outlined"
              sx={inputStyles}
            />
          </>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <GreenButton
            fs={15}
            lh="22.5px"
            fm="Inter"
            fw={400}
            br="24px"
            text={isVerifyingEmail ? 'Verify' : 'Proceed'}
            onClick={handleProcessing}
            outlined={true}
            w={'fit-content'}
            isLoading={isLoading}
            disabled={isLoading}
            sx={{
              minWidth: '89px',
              height: '28px',
              border: '1px solid #08493C',
              padding: '16px 20px',
              backgroundColor: '#1D1F21',
              '&:hover': {
                border: '1px solid #27B079',
                backgroundColor: 'transparent',
              },
            }}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default VerifyEmailModal
